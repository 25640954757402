<template>
    <div x-spaced-2>
        <ch-icon v-for="icon of icons" :elevation-05-text="icon.greyed" :icon="icon.icon" :size="22"/>
    </div>
</template>

<script>
    export default {
        name: 'ProductAssetsIcon',
        props: {
            product: Object,
            productSummary: Object
        },
        computed: {
            icons() {
                if (this.product) return this.getIconsFromProduct(this.product);
                return this.getIconsFromProductSummary(this.productSummary);
            }
        },
        methods: {
            /**
             * @param {Product} product
             */
            getIconsFromProduct(product) {
                const icons = [];
                if (product.vrQualityAvailable === 'Full' || product.vrQualityInCatalog === 'Full') {
                    const greyed = product.vrQualityAvailable !== 'Full';
                    icons.push({icon: 'fas fa-vr-cardboard', greyed});
                }
                if (product.vrQualityAvailable === 'Blockout' || (product.vrQualityAvailable !== 'Full' && product.vrQualityInCatalog === 'Blockout')) {
                    const greyed = product.vrQualityAvailable !== 'Blockout';
                    icons.push({icon: 'fas fa-cube', greyed});
                }
                if (product.photos || product.photosInCatalog) {
                    const greyed = !product.photos;
                    icons.push({icon: 'image', greyed});
                }
                if (product.webGL || product.webGLInCatalog) {
                    const greyed = !product.webGL;
                    icons.push({icon: 'fas fa-globe', greyed});
                }
                return icons;
            },
            /**
             * @param {ProductSummary} productSummary
             */
            getIconsFromProductSummary(productSummary) {
                const icons = [];
                if (productSummary.vrQualityAvailable === 'Full') icons.push({
                    icon: 'fas fa-vr-cardboard',
                    greyed: false
                });
                if (productSummary.vrQualityAvailable === 'Blockout') icons.push({icon: 'fas fa-cube', greyed: false});
                if (productSummary.availablePhotosCount > 0) icons.push({icon: 'image', greyed: false});
                if (productSummary.webGLAvailable) icons.push({icon: 'fas fa-globe', greyed: false});
                return icons;
            }
        }
    };
</script>

<style scoped>

</style>
