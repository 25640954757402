<template>
    <div class="tagEdit">
        <template v-if="isEditing">
            <ch-field  class="tagEditField" :validators="[ tagNameValidator ]" inline>
                <ch-text-input ref="tagEdit" class="tagEditInput"
                                :placeholder="placeholder" 
                                v-model.trim="newTagName" 
                                :invalid="!isValid && !(newTagName === tag.name)"
                                @keydown.esc.native.stop="onEditCancelled(tag.id)"
                                @keydown.enter.native.stop="onInputEnter"
                />
            </ch-field>
            <div class="actions" x-spaced-1>
                <ch-icon icon="done"  :class="{ valid : isValid}" @click.stop="isValid && onEditDone()"/>
                <ch-icon icon="close" @click.stop="onEditCancelled(tag.id)" />
            </div>
        </template>
        <template v-else>
            <div class="tagName">{{tag.name}}</div>
            <div class="actions" x-spaced-1>
                <ch-icon  icon="edit"   @click.stop="onEditStart(tag.id)"/>
                <ch-icon  icon="delete" @click.stop="onRemove(tag.id)"/>
            </div>
        </template>
    </div>
</template>

<script>
import { tagValidators } from './helpers';
export default {
    name: "TagEdit",
    props: {
        tag: {
            type: Object,
        },
        tagList: {
            type: Array,
            default: () => []
        },
        isEditing: Boolean,
        placeholder: {
            type: String,
            default: ""
        },
        validators: {
            type: Array,
            default: () => []
        },
        cancelOnEmptyEnter: Boolean
    },
    data() {
        return {
            newTagName: this.tag.name,
            isValid: false,
            errMsg: null,
        }
    },
    computed: {
        tagNameValidator() {
            return (name) => {
                this.errMsg  = null;
                this.isValid = [...this.defaultValidators, ...this.validators].every(([f, errorMsg] ) => {
                    const passed = f(name);
                    if( !passed ) { this.errMsg = errorMsg } 
                    return  passed  
                });

                return this.isValid ? null : (this.errMsg || '');
            }    
        },
        defaultValidators(){
            return tagValidators.map( ([f , errMsg]) => [(n) =>f(n, this.tagList), errMsg]);
        }
    },
    methods: {
        onEditStart(tagId) {
            this.$emit('startEdit', tagId);
        },
        onEditEnd() {
            this.$emit('endEdit');
            this.newTagName = this.tag.name;
        },

        onEditCancelled() {
           this.onEditEnd();
           this.$emit('cancelledEdit');
        },
        onEditDone() {
            if(!this.isValid) return;
            this.$emit('doneEdit', { ...this.tag, name : this.newTagName});
            this.onEditEnd();
        },
        onRemove(tagId) {
            this.$emit('removeTag', tagId);
        },
        onInputEnter(event) {
            this.isValid ? this.onEditDone() : 
            (!this.newTagName.length && this.cancelOnEmptyEnter) && this.onEditCancelled(this.tag.id)
        }   
    },
    watch: {
        tag() {
            if( this.tag) {
                this.newTagName = this.tag.name;
            }
        },
        isEditing() {
            if ( this.isEditing ) {
                this.$nextTick(() => {
                    this.$refs.tagEdit.$refs.input.focus();
                    this.$refs.tagEdit.$refs.input.select();
                });
            }
        }
    }
}
</script>

<style scoped>
.tagEdit {
    display: flex;
    flex: 1 1 0;
    max-width: 100%;
    width: 100%;
    align-items: center;
}
.tagEditField {
    width: 100%;
}
.tagEditField >>> .messages > .error {
    margin: var(--halfMargin) 0 !important;
}
.tagEditInput >>> .errorIcon {
    display: none;
}
.noMargins {
    padding: 0 !important;
    margin:  0 !important;        
}

.valid {
    color: var(--success);
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tagName {
    display: flex;
    margin: 0 var(--singleMargin);
    flex: 1 1 auto;
} 
.error {
    color: var(--error);
    margin: var(--halfMargin);
    font-size: 12px;
    line-height: 14px;
}

</style>