<template>
    <div class="downloadManager researchColors">
        <ch-progress-circle :value="overallProgress" class="globalProgress"/>
        <div
            v-if="! shrink"
            class="downloadGlobalDetails"
        >
            <h1>{{overallProgress !== 100 ? 'Downloading' : 'Downloaded'}}</h1>
            <span class="timeLeft">{{overallProgress !== 100 ? `${timeLeft} left` : 'All set'}}</span>
        </div>
        <ch-overlay-panel
            :main-trigger-button-props="{
                type:'flat',
                icon: true,
                style: { 'background-color': 'transparent' },
            }"
            has-main-trigger-slot
            class="downloadPanel"
            title="Downloads"
            type="overlay"
            close-icon="expand_less"
        >
            <template slot="mainTrigger">
                <ch-icon
                    :class="{'panelTrigger': ! shrink}"
                    size="32"
                    icon="expand_more"
                />
            </template>
            <template #content>
                <div class="sectionsScrollzone">
                    <download-manager-section
                        v-for="(section, index) in sections"
                        :key="`${section.name}-${index}`"
                        v-bind="section"
                        :speed="speed"
                        @cancel-all-downloads="cancelAllDownloads()"
                    />
                </div>
            </template>
        </ch-overlay-panel>
        <ch-modal ref="modal"/>
    </div>
</template>


<script>
import DownloadManagerSection from "./DownloadManagerSection"

export default {
    name: 'DownloadManager',
    components: {
        DownloadManagerSection,
    },
    props: {
        /*
        * Sets the in download item list.
        */
        downloads: {
            type: Array,
            default: () => [],
        },
        /*
        * Sets the download speed.
        */
        speed: {
            type: Number,
            default: 0.000001,
        },
        /*
        * Shrinks the UI (progress bar and open icon are visible only).
        */
        shrink: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        timeLeft() {

            const { currentSize, size } = this.overallSectionItems[0];

            return this.$downloadManager.fromSecondsToHHMMSS(
                ( size - currentSize ) / this.speed
            );

        },
        sections() {

            return this.downloads.length
            ? [
                {
                    name: 'Overall progress',
                    expandable: false,
                    items: this.overallSectionItems,
                },
                ... this.downloads.reduce(
                    (
                        acc,
                        curr,
                    ) => {


                        let section = acc.find(
                            el => el.name === curr.section
                        );

                        if( ! section ) {

                            section = {
                                name: curr.section,
                                items: [],
                                expandable: true,
                            };

                            acc.push( section );

                        }

                        section.items.push(
                            {
                                ... curr,
                            }
                        );

                        return acc;

                    },
                    []
                ),
            ]
            : [];
        },
        overallSectionItems() {

            let currentSize = 1;
            let size = 1;

            this.downloads.forEach(
                item => {

                    if(item.status !== 'Cancelled' && item.status !== 'Failed') {

                        currentSize += item.currentSize;
                        size += item.size;

                    }

                }
            );

            return [
                {
                    currentSize,
                    size,
                    status: this.overallStatus,
                }
            ];

        },
        overallProgress() {

            const { currentSize, size } = this.overallSectionItems[0];

            return ( currentSize / size ) * 100 ;

        },
        overallStatus() {

            return this.downloads.some(
                download => download.status === 'ToDownload'
                || download.status === 'Downloading'
            )
            ? 'ToDownload'
            : 'Canceled';

        },
    },
    methods: {
        cancelAllDownloads() {

            return this.$refs.modal.open(
				'Ongoing downloads',
				'Do you want to cancel the pending downloads?',
				'primary',
				'Cancel',
				'No',
			).then(
                () => {
                    this.$downloadManager.cancelAllDownloads();
                    this.$downloadManager.handleQueue();
                }
            ).catch(
                () => {
                    this.$emit('downloads-cancel-aborted')
                }
            );

        },
    },
}
</script>

<style scoped>

	.researchColors {
		--primary-border: none;
		--primary-hover: #87C9E9;
		--on-primary-hover: #0D161E;
		--primary: #5498B7;
		--on-primary: #0D161E;
	}

    .downloadManager {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .globalProgress {
        margin-right: 8px;
    }

    .downloadGlobalDetails {
        display: flex;
        flex-direction: column;
        color: var(--elevation-06)
    }

    .downloadGlobalDetails :first-child {
        font-size: 16px;
        line-height: 20px;
    }

    .timeLeft {
        font-family: Lato, sans-serif;
        font-size: 12px;
        line-height: 12px;
        color: var(--elevation-05);
    }

    ::v-deep .panel {
        width: 416px;
        z-index: 16 !important;
        /* right: 96px; */
    }

    .panelTrigger {
        margin-left: 8px;
    }

    ::v-deep h3 {
        font-size: 24px;
        color: var(--primary);
    }

    ::v-deep .header {
        margin-left: 8px;
    }

    .sectionsScrollzone {
        padding-right: 16px;
        max-height: calc(100vh - 100px);
        overflow-y: auto;
    }

    .sectionsScrollzone::-webkit-scrollbar {
        background: transparent;
    }

    .sectionsScrollzone::-webkit-scrollbar-track-piece {
        margin-top: 48px;
    }

    ::v-deep .panel .header .material-icons {
        font-size: 36px !important;
    }

</style>