<template>
    <div class="option" :selected="selected" :disabled="disabled" @click.stop="onClick">
        <ch-checkbox class="checkbox" v-if="showCheckbox" :value="selected" :disabled="disabled"
                     @click.native="onClick">
            <slot :item="item">{{item.label}}</slot>
        </ch-checkbox>
        <span v-else ellipsis><slot :item="item">{{item.label}}</slot></span>
    </div>
</template>

<script>
    export default {
        name: "ChSelectOption",

        props: {
            item: {},
            selected: Boolean,
            disabled: Boolean,
            showCheckbox: Boolean
        },
        methods: {
            onClick(event) {
                if (!this.item.disabled)
                    this.$emit('click', event)
            }
        }
    }
</script>

<style scoped>

    .option {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .option > .checkbox {
        width: 100%;
    }

    .option:not([disabled]) {
        background-color: var(--elevation-06);
        color: var(--on-elevation-06);
    }

    .option[selected] {
        background-color: var(--primary);
        color: var(--on-primary);
    }

    .option:not([disabled]):hover {
        background-color: var(--elevation-05);
        color: var(--on-elevation-05);
    }

    .option[disabled] {
        background-color: var(--disabled);
        color: var(--on-disabled);
    }

    .option[disabled] > * {
        --disabled: var(--on-disabled);
    }

</style>
