<template>
	<ch-dialog ref="dialog" type="primary" @cancel="close" :title="`${workGroupName} - Users`">
		<div slot="body" y-spaced-2>
			<p>{{ members.length }} {{ members.length > 1 ? 'users' : 'user' }} in workgroup</p>
			<div class="members">
				<ch-list>
					<ch-list-item v-for="member of members">
						<identity-info :id="member.identityId">
							<template v-if="member.isAdmin" slot="append"> (admin)</template>
						</identity-info>
					</ch-list-item>
				</ch-list>
			</div>
		</div>
		<div slot="footer">
			<ch-button type="primary" size="small" @click="close">OK</ch-button>
		</div>
	</ch-dialog>
</template>

<script>
import IdentityInfo from '@/user/workgroups/components/IdentityInfo';

export default {
	name: 'WorkGroupMembersDialog',
	components: {IdentityInfo},
	data() {
		return {
			workGroup: null
		};
	},
	computed: {
		currentIdentityId() {
			return this.$storage.user.id;
		},
		workGroupName() {
			return this.workGroup ? this.workGroup.name : '';
		},
		members() {
			if (!this.workGroup) return [];
			return this.workGroup.members.slice().sort((m1, m2) => m1.identityId === this.currentIdentityId ? -1 : m1.name < m2.name ? -1 : 1);
		}
	},
	methods: {
		open(workGroup) {
			this.workGroup = workGroup;
			this.$refs.dialog.open();
		},
		close() {
			this.workGroup = null;
			this.$refs.dialog.close();
		}
	}
};
</script>

<style scoped>

.members {
	height: 250px;
	overflow-y: auto;
}

</style>
