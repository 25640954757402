import EventsBus      from "../studioapi/EventsBus";
import LayoutUnityAPI from './LayoutUnityAPI';

export default class UnityManager {

    constructor() {
        this.eventsBus = new EventsBus();
        this.isUnityReady = false;

        this.unityInstance = null;
        this.currentState = 'NotLoaded';
        this.promiseToResolve = null;
        this.loadPromise = null;
        this.currentScene = 'IdleScene';
        this.canvas = null;
        this.offScreenParent = null;
        this.materializationCallback = null;

        this.firstResponder = null;
        this.currentPlanogramId = null;

        this.requestHandlers = {};

        this.layoutAPI = new LayoutUnityAPI(this);
    }

    install(Vue, options) {
        Vue.prototype.$unityManager = this;
        window['unityManager'] = this;
    }

    LoadUnity() {
        if (!this.loadPromise)
            this.loadPromise = new Promise((resolve, reject) => {
                this.offScreenParent = document.getElementsByName('offscreenCanvas')[0];
                const script = document.createElement('SCRIPT');
                script.setAttribute('src', '/Build/UnityLoader.js');
                script.setAttribute('async', '');
                script.setAttribute('defer', '');
                this.offScreenParent.appendChild(script);
                script.onload = () => {
                    this.unityInstance = UnityLoader.instantiate(this.offScreenParent, "/Build/Build.json");
                    this.canvas = window.document.getElementById("#canvas");
                };
                this.promiseToResolve = resolve;
            });
        return this.loadPromise;
    }

    OnUnityReady() {
        this.currentState = 'Loaded';
        if (this.promiseToResolve) {
            this.promiseToResolve();
            this.promiseToResolve = null;
        }
    }

    LoadScene(targetElement, sceneName) {
        if (this.currentState === 'NotLoaded') {
            return this.LoadUnity()
                .then(() => this.LoadScene(targetElement, sceneName));
        }
        targetElement.appendChild(this.canvas);
        this.canvas["style"].width = targetElement["offsetWidth"] + "px";
        this.canvas["style"].height = targetElement["offsetHeight"] + "px";
        if (this.currentScene !== sceneName)
            this.unityInstance.SendMessage('SceneManager', 'GoToScreen', sceneName);
        this.currentScene = sceneName;
        this.loadPromise = new Promise((resolve, reject) => this.promiseToResolve = resolve);
        return this.loadPromise;
    }

    SceneLoaded() {
        if(this.promiseToResolve) {
            this.promiseToResolve();
            this.promiseToResolve = null;
        }
    }

    UnloadScene() {
        if (this.currentState === 'NotLoaded') return;
        this.offScreenParent.appendChild(this.canvas);
        this.canvas["style"].width = this.offScreenParent["offsetWidth"] + "px";
        this.canvas["style"].height = this.offScreenParent["offsetHeight"] + "px";
        this.currentScene = 'IdleScene';
        this.unityInstance.SendMessage('SceneManager', 'GoToScreen', 'IdleScene');
        this.UnsubscribeAllEvents();
        this.loadPromise = new Promise((resolve, reject) => this.promiseToResolve = resolve);
        return this.loadPromise;
    }

    Dispatch(name, command) {
        this.unityInstance.SendMessage('APIInterface', 'Dispatch', JSON.stringify({type: name, data: command}));
    }

    UpdateThumbnail(){
        this.unityInstance.SendMessage('APIInterface', 'UpdateThumbnail');
    }

    Subscribe(eventType, callback) {
        this.eventsBus.subscribe(eventType, callback);
    }

    SubscribeToEvent(eventType, callback) {
        this.eventsBus.subscribe(eventType, callback);
    }

    Unsubscribe(eventType, callback) {
        this.eventsBus.unsubscribe(eventType, callback);
    }

    UnsubscribeToEvent(eventType, callback) {
        this.eventsBus.unsubscribe(eventType, callback);
    }

    UnsubscribeAll(eventType) {
        this.eventsBus.unsubscribeAll(eventType);
    }

    UnsubscribeAllEvents() {
        this.eventsBus = new EventsBus();
    }

    OnEventEmit(eventName, data) {
        this.eventsBus.fire(eventName, data);
    }

    HandleRequest(requestId, code, payload) {
        console.log(`Received request ${requestId}, ${code}, ${payload}`);
        this.requestHandlers[code](requestId, JSON.parse(payload));
    }

    AddRequestHandler(code, handler) {
        if (!(code in this.requestHandlers))
            this.requestHandlers[code] = handler;
        else
            console.error("There can't be two simultaneous request handlers")
    }

    RemoveRequestHandler(code) {
        delete this.requestHandlers[code];
    }

    RespondTo(requestId, response) {
        console.log(`Responding to request ${requestId} with response ${response}`);
        this.unityInstance.SendMessage('BrowserManager', 'RespondToRequest', JSON.stringify({
            requestId,
            payload: response
        }));
    }

    OnSceneLoaded(sceneName) {
        this.eventsBus.fire('OnSceneLoaded', sceneName);
        this.isUnityReady = true;
    }


    ComputeMaterialization(element) {
        return JSON.stringify(this.materializationCallback(JSON.parse(element)));
    }

    SetCursor(cursorName) {
        switch (cursorName) {
            default:
            case "default":
                this.canvas.style.cursor = "default";
                break;
            case "Pointer":
                this.canvas.style.cursor = "pointer";
                break;
            case "vertical_resize":
                this.canvas.style.cursor = "ns-resize";
                break;
            case "horizontal_resize":
                this.canvas.style.cursor = "ew-resize";
                break;
        }
    }
}
