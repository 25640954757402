<template>
    <div class="actionBar">
        <div x-spaced-1>
            <ch-button type="overlay" icon @click="goBack" :disabled="inLevel">
                <ch-icon icon="arrow_back"/>
            </ch-button>
            <ch-button type="overlay" icon @click="save" :disabled="!saveEnabled">
                <ch-icon icon="save"/>
            </ch-button>
            <ch-button type="overlay" icon @click="addFreeLine" :disabled="inLevel">
                <ch-icon icon="power_input"/>
            </ch-button>
            <ch-button type="overlay" icon @click="exportConfiguration" :disabled="inLevel">
                <ch-icon icon="present_to_all"/>
            </ch-button>
            <!--            <ch-context-menu type="overlay" :voices="voices">-->
            <!--                <ch-button type="overlay" icon>-->
            <!--                    <ch-icon icon="more_horiz"/>-->
            <!--                </ch-button>-->
            <!--            </ch-context-menu>-->
        </div>
        <div class="layoutName" x-spaced-1>
            <template v-if="!inLevel">
                <span @click="returnToProject" elevation-05-text class="projectName">{{projectName}}</span>
                <ch-icon icon="keyboard_arrow_right"/>
                <ch-field :validators="[nameValidator]">
                    <ch-editable size="narrow" :value="name" @input="renameConfiguration"/>
                </ch-field>
                <span class="saveInfo" v-if="saveMessage">{{saveMessage}}</span>
            </template>
            <template v-else>
                <span>Group editing mode</span>
                <ch-icon icon="close" @click="exitLevel"/>
            </template>
        </div>
        <div class="lafSelection" v-if="lookAndFeels" x-spaced-2>
            <ch-field label="Look and feel" inline label-position="left" :label-width="85">
                <ch-select type="overlay" size="narrow large" :items="lookAndFeels" :disabled="inLevel"
                           :value="selectedLookAndFeelId" @input="saveLookAndFeel"/>
            </ch-field>
            <ch-button type="overlay" icon @click="switchCamera">
                {{toggleCamera ? '2D' : '3D'}}
            </ch-button>
                       <!-- <ch-icon icon="3d_rotation" size="24" @click="switchCamera"/>-->
        </div>
        <ch-input-dialog ref="inputDialog"/>
        <ch-modal ref="modal"/>
    </div>
</template>

<script>
    import StudioRequest    from '../../plugins/studioapi/requests/StudioRequest';
    import LayoutController from './services/LayoutController';
    import Vector3          from './model/common/Vector3';
    import Transform        from '../../plugins/studioapi/api/spaceallocation/model/common/Transform';
    import {download, MediaType} from '@/utils/DownloadService';

    export default {
        name: 'ActionBar',
        props: {
            controller: LayoutController
        },
        data() {
            return {
                levels: [],
                saveMessage: null,
                saveEnabled: false,
                toggleCamera: false
            };
        },
        computed: {
            editor() {
                return this.$parent.$parent;
            },
            inLevel() {
                return this.levels.length > 0;
            },
            projectName() {
                return this.$storage.findProjectById(this.$route.params.projectId).name;
            },
            configurationId() {
                return this.controller.configuration.id;
            },
            name() {
                return this.controller.configuration.info.name;
            },
            selectedLookAndFeelId() {
                return this.controller.configuration.environment.lookAndFeelId;
            },
            lookAndFeels() {
                return [
                    {key: null, label: 'None'},
                    ...this.controller.environment.lookAndFeels.map(laf => ({key: laf.id, label: laf.name}))
                ];
            },
            voices() {
                return [
                    {
                        text: 'Save (ctrl + s)',
                        icon: 'save',
                        disabled: !this.saveEnabled,
                        action: () => this.save()
                    }
                ];
            }
        },
        watch: {
            name: {
                handler() {
                    document.title = this.name + ' Layout Editor | ShelfZone Studio';
                },
                immediate: true
            },
            hasUnsavedChanges() {
                this.saveEnabled = this.hasUnsavedChanges;
            },
            selectedLook() {
                this.currentLook = this.selectedLook;
            }
        },
        mounted() {
            window.addEventListener('keydown', this.onKeyDown);
            this.$unityManager.SubscribeToEvent('ElementCreated', this.enableSave);
            this.$unityManager.SubscribeToEvent('ElementUpdated', this.enableSave);
            this.$unityManager.SubscribeToEvent('ElementDeleted', this.enableSave);
            this.$unityManager.SubscribeToEvent('AssetCreated', this.enableSave);
            this.$unityManager.SubscribeToEvent('AssetDeleted', this.enableSave);
            this.$unityManager.SubscribeToEvent('LevelChanged', this.levelChanged);
        },
        beforeDestroy() {
            window.removeEventListener('keydown', this.onKeyDown);
            this.$unityManager.UnsubscribeToEvent('ElementCreated', this.enableSave);
            this.$unityManager.UnsubscribeToEvent('ElementUpdated', this.enableSave);
            this.$unityManager.UnsubscribeToEvent('ElementDeleted', this.enableSave);
            this.$unityManager.UnsubscribeToEvent('AssetCreated', this.enableSave);
            this.$unityManager.UnsubscribeToEvent('AssetDeleted', this.enableSave);
            this.$unityManager.UnsubscribeToEvent('LevelChanged', this.levelChanged);
        },
        methods: {
            nameValidator(name) {
                if (!name || !/^(\w|-|\.|\s){1,30}$/.test(name.trim()))
                    return 'Valid characters: A-z, 0-9 and . _ -';
                return null;
            },
            returnToProject() {
                this.$router.push({name: 'projectDetails', params: {projectId: this.$route.params.projectId}});
            },
            goBack() {
                this.$router.go(-1);
            },
            onKeyDown(event) {
                if (event.code === 'KeyS' && event.ctrlKey) {
                    this.save();
                    event.preventDefault();
                }
            },
            renameConfiguration(name) {
                this.$configuration.renameConfiguration(this.configurationId, name)
                    .then(() => {
                        this.controller.configuration.info.name = name;
                        window['studioApi'].storage.findEnvironmentById(this.controller.environment.id)
                            .customizations.filter(conf => conf.id === this.configurationId)[0].name = name;
                    });
            },
            addFreeLine() {
                const center = this.controller.environment.topView.areaCenter;
                const length = 500;
                const position = new Vector3(center.x - length / 2, center.y, 0);
                this.$unityManager.layoutAPI.CreateFreeLine(new Transform(position, 0), length);
            },
            levelChanged(levels) {
                this.levels = JSON.parse(levels);
            },
            exitLevel() {
                this.$unityManager.layoutAPI.PopLevel();
            },
            enableSave() {
                this.saveEnabled = true;
                this.$emit('saveEnabled', this.saveEnabled);
            },
            save() {
                if (this.saveEnabled) {
                    this.$unityManager.lastSave = null;
                    this.saveMessage = 'Saving...';
                    this.saveEnabled = false;
                    this.$emit('saveEnabled', this.saveEnabled);
                    this.$studio.saveConfiguration(this.controller.configuration)
                        .then(() => {
                            const d = new Date();
                            this.saveMessage = 'Saved at ' + d.getHours() + ':' + String(d.getMinutes()).padStart(2, '0');
                        })
                        .catch(error => this.saveMessage = 'Error saving. ' + error);
                }
            },
            saveLookAndFeel(lookAndFeelId) {
                const previousLookAndFeelId = this.controller.configuration.environment.lookAndFeelId;
                this.controller.configuration.environment.lookAndFeelId = lookAndFeelId;
                const payload = {
                    configurationId: this.configurationId,
                    lookAndFeelId: lookAndFeelId
                };
                window['studioApi'].sendRequest(new StudioRequest('changeLookAndFeel', payload, (code, response) => {
                    if (code !== 200) {
                        this.$snotify.error(`Error saving look and feel.`, {timeout: 5000, showProgressBar: false});
                        console.error('Error saving look and feel, code: ' + code);
                        this.controller.configuration.environment.lookAndFeelId = previousLookAndFeelId;
                    }
                }));
            },
            switchCamera(){
                this.toggleCamera = !this.toggleCamera;
                this.$unityManager.layoutAPI.ChangeCameraProjection();
            },
	        exportConfiguration() {
		        this.$configuration.findConfiguration(this.controller.configuration.id)
			        .then(({configuration}) =>
				        download(
					        JSON.stringify(configuration),
					        `${configuration.info.name}.json`,
					        MediaType['text/json']
				        )
			        );
	        }
        }
    };
</script>

<style scoped>

    .actionBar {
        padding: var(--doubleMargin);
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        z-index: 10;
        pointer-events: none;
    }

    .actionBar > * {
        flex: 1 1 0;
    }

    .actionBar > * > * {
        pointer-events: all;
    }

    .actionBar > .layoutName {
        flex: 0 0 auto;
        color: var(--on-overlay);
        background-color: var(--overlay);
        border: var(--overlay-border);
        border-radius: 4px;
        padding: 2px var(--singleMargin);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .layoutName > .projectName {
        cursor: pointer;
        text-decoration: underline;
    }


    .saveInfo {
        color: var(--elevation-05);
    }

    .lafSelection {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }


</style>
