<template>
  <ch-table
    v-if="availableProducts"
    class="productAlternatives filled"
    row-height="narrow"
    @headerClick="
      isSelected(product)
        ? choseProduct(availableProducts[0].id)
        : choseProduct(product.id)
    "
    :row-disabled="(r) => isSelected(product)"
    :items="availableProducts"
    @rowClicked="(row) => choseProduct(row.id)"
  >
    <template slot="header">
      <ch-th width="248px" x-spaced-2>
        <ch-icon v-if="isSelected(product)" icon="far fa-circle" size="14" />
        <ch-icon v-else icon="fas fa-check-circle" size="14" />
        <div
          v-if="isSelected(product)"
          class="productNotFound"
          :style="`background-color: ${colorFromId(product.id)};`"
        />
        <span alert-text>{{ product.ean }}</span>
      </ch-th>
      <ch-th width="400px">{{ product.info.name | toLowerCase }}</ch-th>
      <ch-th
        >{{ product.width }} x {{ product.height }} x {{ product.depth }}</ch-th
      >
    </template>
    <template slot-scope="{ row }">
      <ch-td x-spaced-2 class="productInCatalogInfo">
        <ch-icon icon="fas fa-circle" v-if="isSelected(row)" :size="14" />
        <ch-icon icon="far fa-circle" v-else :size="14" />
        <div class="productImage">
          <ch-image
            :src="`${baseURL}/products/${row.id}/photos/thumbnail_front.png`"
          />
        </div>
      </ch-td>
      <ch-td>
        <span class="productName" @click.stop="showProductDetails(row.id)">{{
          row.name | toLowerCase
        }}</span>
      </ch-td>
      <ch-td :elevation-04-text="disableSize"
        >{{ row.width }} x {{ row.height }} x
        {{ row.depth }}
      </ch-td>
      <ch-td action>
        <product-assets-icon :product-summary="availableProducts[0]" />
      </ch-td>
    </template>
  </ch-table>
  <ch-table class="productAlternatives" v-else :items="[]" :key="product.ean">
    <template slot="header">
      <ch-th width="248px" x-spaced-2>
        <ch-icon
          elevation-04-text
          icon="fas fa-check-circle"
          disabled
          :size="14"
        />
        <div
          class="productNotFound"
          :style="`background-color: ${colorFromId(product.id)};`"
        />
        <span>{{ product.ean }}</span>
      </ch-th>
      <ch-th width="400px">{{ product.info.name | toLowerCase }}</ch-th>
      <ch-th
        >{{ product.width }} x {{ product.height }} x {{ product.depth }}</ch-th
      >
    </template>
  </ch-table>
</template>

<script>
import ProductAssetsIcon from "../../../../skucatalog/ProductAssetsIcon";
import { ColorService } from "../../../layouteditor/services/ColorService";
import StaticConfig from "../../../../config";
export default {
  name: "ProductSelectionTable",
  components: { ProductAssetsIcon },
  props: {
    product: Object,
    availableProducts: Array,
    disableSize: Boolean,
    value: String,
  },
  computed: {
    baseURL() {
      return Config.RESTAddress;
    },
  },
  filters: {
    toLowerCase(value) {
      return value.capitalize();
    },
  },
  methods: {
    isSelected(product) {
      return this.value === product.id;
    },
    choseProduct(productId) {
      this.$emit("input", productId);
    },
    showProductDetails(productId) {
      this.$emit("showProductDetails", productId);
    },
    colorFromId(id) {
      return ColorService.colorFromString(id);
    },
  },
};
</script>

<style scoped>
.productAlternatives {
  margin-bottom: var(--tripleMargin);
  width: 100%;
  height: unset !important;
}

.productImage {
  display: inline-block;
  height: 32px;
  width: 44px;
  box-sizing: border-box;
}

.productName {
  text-decoration: underline;
}

.productInCatalogInfo {
  padding-left: var(--quadrupleMargin) !important;
}

.productNotFound {
  display: inline-block;
  width: 44px;
  height: 26px;
}
</style>

<style>
.productAlternatives thead .header {
  height: 42px;
}

.productAlternatives.filled thead {
  cursor: pointer;
}

.productAlternatives thead .header th {
  font-size: 14px;
  font-weight: 400;
}
</style>