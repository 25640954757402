<template>
    <ch-access-layout v-if="error !== 'None'">
        <div slot="logo" class="logo"></div>
        <p v-if="error === 'Used'">This account creation invite has already been used, login with your credentials</p>
        <p v-else-if="error === 'Expired'">Your account creation invite has expired, please request another one</p>
        <p v-else>This account creation invite is invalid, please request another one or login</p>
        <template slot="footer">
            <ch-button @click="gotoLogin">Go to login</ch-button>
        </template>
    </ch-access-layout>
    <ch-access-layout v-else-if="!eulaConfirmed">
        <div slot="logo" class="logo"></div>
        <div class="eulaBody">To use ShelfZone studio you must read and accept the <a primary-text :href="eulaURL" download="eula">License
            Agreement</a>.</div>
        <div class="eulaCheck" @click="eulaAccepted = !eulaAccepted">
            <ch-checkbox  v-model="eulaAccepted">I have read and agree to the terms.<span class="mandatory">*</span>
            </ch-checkbox>
        </div>
        <div slot="footer" class="acceptEULA">
            <ch-button @click="eulaConfirmed = true" :disabled="!eulaAccepted">Next</ch-button>
        </div>
    </ch-access-layout>
    <ch-access-layout v-else-if="!accountCreated">
        <div slot="logo" class="logo"></div>
        <ch-form ref="registrationForm" @submit="submit" :generic-errors="otherErrors">
            <h2>Create your account</h2>
            <div>
                <div class="label">Email</div>
                <div class="email">{{userEmail}}</div>
            </div>
            <ch-field label="Full name" :validators="[fullNameValidator]">
                <ch-text-input placeholder="Full name" v-model="fullName"/>
            </ch-field>
            <ch-field label="Username" :validators="[usernameValidator, usernameTaken]">
                <ch-text-input placeholder="Username" v-model="userName"/>
            </ch-field>
            <ch-field label="Password" :validators="passwordValidator">
                <ch-text-input placeholder="Password" password v-model="password"/>
            </ch-field>
            <ch-field label="Confirm password" :validators="[passwordMatchValidator(password)]">
                <ch-text-input placeholder="Confirm password" password v-model="confirmPassword"/>
            </ch-field>
            <template slot="actions">
                <ch-button submit>Create account</ch-button>
            </template>
        </ch-form>
    </ch-access-layout>
    <ch-access-layout v-else>
        <div slot="logo" class="logo"></div>
        <p>Your account has been created, you can now login in ShelfZone® Studio.</p>
        <template slot="footer">
            <ch-button @click="gotoLogin">Go to login</ch-button>
        </template>
    </ch-access-layout>
</template>

<script>
    import {PasswordValidators} from '../user/services/PasswordValidators';

    export default {
        name: 'AccountCreation',
        props: {
            invitationId: String
        },
        data() {
            return {
                eulaURL: '',
                eulaVersion: '',
                eulaAccepted: false,
                eulaConfirmed: false,
                accountCreated: false,
                userEmail: null,

                fullName: null,
                userName: null,
                password: null,
                confirmPassword: null,

                passwordValidator: PasswordValidators.validators,
                passwordMatchValidator: PasswordValidators.passwordMatch,

                takenUserNames: [],
                otherErrors: [],

                error: 'None'
            };
        },
        mounted() {

            this.$iam.findEula()
                .then(r => {
                    this.eulaURL = r.eula;
                    this.eulaVersion = r.version;
                });

            if (this.invitationId) {
                this.$iam.findInvitation(this.invitationId)
                    .then(r => {
                        this.userEmail = r.mail;
                        if (r.isCancelled)
                            this.error = 'Cancelled';
                        else if (r.isClaimed)
                            this.error = 'Used';
                        else if (r.hasExpired)
                            this.error = 'Expired';
                        else
                            this.error = 'None';
                    })
                    .catch(error => this.error = 'Invalid');
            } else {
                this.error = 'Invalid';
            }
        },
        methods: {
            fullNameValidator(v) {
                if (v === null || !/^(\w|-|\.|,|\s|'|&|[\u00C0-\u024F\u1E00-\u1EFF]){1,150}$/.test(v))
                    return 'Only letters and numbers are allowed';
                return null;
            },
            usernameValidator(v) {
                if (v === null || !/^(\w|-|\.){1,30}$/.test(v))
                    return 'Only letters and numbers are allowed';
                return null;
            },
            usernameTaken(v) {
                if (this.takenUserNames.includes(v))
                    return 'This username is already in use';
                return null;
            },
            submit() {
                this.$iam.register(this.invitationId, this.userName, this.password, this.fullName, this.eulaVersion)
                    .then(() => {
                        this.accountCreated = true;
                    })
                    .catch(e => {
                        if (e.response.data.code === 'NameAlreadyInUse')
                            this.takenUserNames.push(this.userName);
                        else if (e.response.data.code === 'EmailAlreadyInUse')
                            this.otherErrors = ['The given email is already used from another user'];
                        else if (e.response.status)
                            this.otherErrors = ['This invitation is not valid'];
                        this.$refs.registrationForm.validate();
                    });
            },
            gotoLogin() {
                this.$router.push({name: 'login'});
            }
        }
    };
</script>

<style scoped>

    .logo {
        background-image: url("../assets/logo.png");
        background-size: 210px;
    }

    .mandatory {
        color: var(--error);
    }

    .acceptEULA {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .eulaBody{
        margin-bottom: var(--tripleMargin);
    }

    a{
        display:  contents;
    }

</style>
