<template>
  <div class="zoomControlContainer" :class="[orientation]" :disabled="disabled">
      <ch-button :disabled="disabled" class="zoomBtn" icon @click="update(current-step)" >
          <ch-icon class="icon" icon='remove' :size="8" />
      </ch-button>
      <ch-tooltip class="tooltip" type="overlay" :text="`${value}%`" :position="isVertical ? 'left': 'bottom'">
        <input class="slider" type="range" ref="rangeInput"
            @mousewheel="onMouseWheel"
            :style="{'--webkitProgressPercent': percent+'%'}"
            :min="min" 
            :max="max" 
            :step="step" 
            :disabled="disabled" 
            v-model.number="current" />
      </ch-tooltip>
      <ch-button class="zoomBtn" icon @click="update(current+step)" :disabled="disabled">
          <ch-icon class="icon" icon='add' :size="8" />
      </ch-button>
  </div>
</template>

<script>
export default {
    name: "ZoomControl",
    props: {
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 1,
        },
        step: {
            type: Number,
            default: 0.1
        },
        value: {
            type: Number,
            default: 0.5
        },
        orientation: {
            type: String,
            default: 'vertical',
            validation: v => ['vertical', 'horizontal'].includes(v)
        },
        disabled: Boolean
    },
    computed: {
        current: {
            get() {
                return this.value
            },
            set(v) {
                this.update(v);
            }
        },
        percent() {
            if(!this.current) return 0;
            return (this.current-this.min)/(this.max-this.min)*100;
        },
        isVertical() { return this.orientation === 'vertical'}
    },
    methods: {
        update(val){
            if(this.disabled) return;
            const emittedValue = !val  || val < this.min ? this.min : 
                                 val > this.max          ? this.max :
                                 val;
            this.$emit('change', emittedValue );
        },
        onMouseWheel(e) {
            this.update(this.current + ((e.wheelDelta < 0 ) ? -1: 1) * this.step);
        }
    },
}

</script>

<style scoped>
    .zoomControlContainer {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        align-items: center;
        position: relative;
        --icon-size:      8px;
        --btn-size:       12px;
        --enabled-color:       var(--elevation-06);
        --enabled-color-rgb:   240, 245, 247; /* --elevation-06 in rgb*/
        --disabled-color:      var(--elevation-05);
        --disabled-color-rgb:  170, 177, 181; /* --elevation-05 in rgb*/

        --active:            var(--enabled-color);
        --slider-width:      4px;
        --slider-height:     84px;
        --slider-knob-size:  10px;
    }
    .zoomControlContainer[disabled] {
        pointer-events: none;
    }
    .zoomBtn {
        height: var(--btn-size) !important;
        width:  var(--btn-size) !important;
        background-color: var(--elevation-02) !important;
        border: 0.3px solid var(--elevation-04) !important; 
        margin: var(--singleMargin);
    }
    .zoomBtn > .icon {
        color: var(--active);
    }
    .zoomBtn:disabled > .icon {
        --active: var(--disabled-color);
    }
    .tooltip {
        width: var(--slider-height);
        height: var(--slider-width);
    }

    .slider {
        
        width: var(--slider-height);
        height: var(--slider-width);
        border-radius: calc(var(--slider-width)) ;
    }

    .slider:disabled {
        --active: var(--disabled-color);
    }

    .slider,
    .slider::-webkit-slider-runnable-track,
    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        outline: none;
        background: none;
        border-radius:  calc( var(--slider-knob-size));

    }

    .slider:focus {
        outline: none;
    }
    /* Chrome */
    .slider::-webkit-slider-runnable-track {
        --active: var(--enabled-color-rgb);
        width: 100%;
        height: var(--slider-width);
        cursor: pointer;
        background-image: linear-gradient(
            90deg,
            rgba( var(--active), 0.8) var(--webkitProgressPercent),
            rgba( var(--active), 0.3) var(--webkitProgressPercent)
        );
    }
    .slider::-webkit-slider-thumb {
        --active: var(--enabled-color-rgb);
        height: var(--slider-knob-size);
        width: var(--slider-knob-size);
        background-color: rgb(var(--active));
        cursor: pointer;
        margin-top: -3px;
    }

    .slider:disabled::-webkit-slider-runnable-track,
    .slider:disabled::-webkit-slider-thumb {
        --active: var(--disabled-color-rgb);
    }

    /* Firefox */
    .slider::-moz-focus-outer {
        border: 0;
    }

    .slider::-moz-range-track {
        border-radius:  calc( var(--slider-knob-size));
        height: var(--slider-width);
        cursor: pointer;
        background: var(--active);
        opacity: 0.3;
    }

    .slider::-moz-range-progress {
        border-radius:  calc( var(--slider-knob-size));
        background: var(--active);
        opacity: 0.8;
        height: var(--slider-width);
    }
   
    .slider::-moz-range-thumb {
        height: var(--slider-knob-size);
        width: var(--slider-knob-size);
        background: var(--active);
        border: 0;
        cursor: pointer;
    }
    .slider:disabled::-moz-range-track,
    .slider:disabled::-moz-range-progress,
    .slider:disabled::-moz-range-thumb {
        --active: var(--disabled-color);
    }

    .vertical {
        flex-direction: column-reverse;
    }
    .vertical .tooltip {
        height: var(--slider-height);
        width:  var(--slider-width);
    }
    .vertical .slider {
        position: relative;
        right: calc((var(--slider-height) - var(--slider-width)) / 2);
        transform: rotate(-90deg);
    }

</style>