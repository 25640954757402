<template>
    <ch-dialog ref="dialog" type="primary" title="Change password">
        <change-password-form slot="body" @submit="confirm" @cancel="reset"/>
    </ch-dialog>
</template>

<script>
    import ChangePasswordForm from '../../login/password/change/ChangePasswordForm';
    export default {
        name: 'ChangePasswordDialog',
        components: {ChangePasswordForm},
        data() {
            return {
                resolve: null,
                reject: null
            };
        },
        methods: {
            open() {
                this.$refs.dialog.open();
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                }).finally(() => this.$refs.dialog.close());
            },
            confirm() {
                this.resolve()
            },
            reset() {
                this.reject();
            },
        }
    };
</script>

<style scoped>

</style>
