<template>
    <div y-spaced-2>
        <h2 primary-text>Planogram</h2>
        <div class="planogramName" x-spaced-1>
            <ch-indicator :color="color"/>
            <span>{{asset.name}}</span>
        </div>
        <ch-dl label-width="45%">
            <ch-dd label="Products">{{planogram.productSet.length}}</ch-dd>
            <ch-dd label="Products Groups">{{planogram.productsGroups.length}}</ch-dd>
        </ch-dl>
        <ch-button size="fill narrow" @click="openPlanogram" justified>Edit
            <ch-icon icon="edit" size="12"/>
        </ch-button>
    </div>
</template>

<script>

    import LayoutController      from '../services/LayoutController';
    import Transform             from './Transform';
    import Materializer          from './Materializer';
    import RGBColor              from '../model/common/color/RGBColor';
    import DeleteSelectionButton from './DeleteSelectionButton';

    export default {
        name: 'PlanogramReferenceInfo',
        components: {DeleteSelectionButton, Materializer, Transform},
        props: {
            controller: LayoutController,
            asset: Object
        },
        computed: {
            color() {
                const color = this.asset.color;
                return new RGBColor(color.r, color.g, color.b).toString();
            },
            /**
             * @return {Planogram}
             */
            planogram() {
                return this.controller.findPlanogram(this.asset.planogramId);
            }
        },
        methods: {
            openPlanogram() {
                this.$router.push({
                    name: 'planogram',
                    params: {planogramId: this.asset.planogramId}
                });
            }
        }
    };
</script>

<style scoped>

    .planogramName {
        word-break: break-all;
    }

</style>