<template>
  <div class="market-data-sets">
    <p>
      Data sets will be used to calculate the planograms and the performances.
    </p>
    <p>For further information, please see the <a href="#">User guide</a></p>
    <div class="market-data-sets-content-wrapper">
      <div v-if="!dataSets.length" class="market-data-sets-content">
        <ch-icon icon="warning" size="90" />
        <p>You haven't created any data set yet.</p>
        <ch-button type="primary" @click="$emit('add-data-set')">
          Add data set
          <ch-icon
            style="margin-left: 16px;"
            icon="fas fa-plus-circle"
            size="16"
          />
        </ch-button>
      </div>
      <div v-else>
        <div style="width: 100%; display: flex; justify-content: flex-end;">
          <ch-button type="primary" @click="$emit('add-data-set')">
            Add data set
            <ch-icon
              style="margin-left: 16px;"
              icon="fas fa-plus-circle"
              size="16"
            />
          </ch-button>
        </div>
        <ch-table class="data-sets-info" ref="data-sets-info" :items="dataSets">
          <template slot="header">
            <ch-th
              v-for="(headerCell, headerIndex) in header"
              :key="headerIndex"
              :id="headerCell"
              :name="headerCell"
              :width="columnWidth[headerIndex]"
            />
          </template>
          <template slot-scope="{ row }">
            <ch-td>
              {{ row.name }}
            </ch-td>
            <ch-td>
              {{ row.period }}
            </ch-td>
            <ch-td>
              {{ row.storeFormat }}
            </ch-td>
            <ch-td>
              {{ row.location }}
            </ch-td>
            <ch-td>
              {{ row.creationDate | formatDateTime }}
            </ch-td>
            <ch-td>
              <ch-context-menu :voices="getVoices(row.id)">
                <ch-button type="flat" icon>
                  <ch-icon icon="more_horiz" />
                </ch-button>
              </ch-context-menu>
            </ch-td>
          </template>
        </ch-table>
      </div>
    </div>
    <ch-form-dialog
      ref="editInputFormDialog"
      type="primary"
      important
      @submit="editInfo"
      @cancel="resetDataSet"
    >
      <template #header>
        <div class="editInputFormDialogLabel">
          <label-tag label="BETA" />
          <h2>
            {{ `Edit dataset info for ${dataSetName}` }}
          </h2>
        </div>
      </template>
      <ch-field
        :labelWidth="80"
        :validators="[datasetNameValidation]"
        label="Name"
        label-position="left"
      >
        <ch-text-input
          v-model="dataSetName"
          placeholder="Insert the dataset name"
        />
      </ch-field>
      <ch-field
        :labelWidth="80"
        :validators="[datasetInformationsValidation]"
        label="Period"
        label-position="left"
      >
        <ch-text-input
          v-model="dataSetPeriod"
          placeholder="Insert the dataset period"
        />
      </ch-field>
      <ch-field
        :labelWidth="80"
        :validators="[datasetInformationsValidation]"
        label="Store format"
        label-position="left"
      >
        <ch-text-input
          v-model="dataSetStoreFormat"
          placeholder="Insert the dataset store format"
        />
      </ch-field>
      <ch-field
        :labelWidth="80"
        :validators="[datasetInformationsValidation]"
        label="Location"
        label-position="left"
      >
        <ch-text-input
          v-model="dataSetLocation"
          placeholder="Insert the dataset location"
        />
      </ch-field>
      <template slot="actions">
        <ch-button cancel type="secondary" size="small">Cancel</ch-button>
        <ch-button submit type="primary" size="small">Save</ch-button>
      </template>
    </ch-form-dialog>
    <ch-dialog ref="deleteDialog" title="Delete market dataset" type="error">
      <template class="dialogBody" slot="body">
        <p>Are you sure you want to delete {{ dataSetName }}?</p>
        <p>This action is undoable.</p>
      </template>
      <template slot="footer">
        <ch-button type="secondary" size="small" @click="closeDeleteDialog">
          Cancel
        </ch-button>
        <ch-button
          type="error"
          size="small"
          style="font-size: 13.5px;"
          @click="deleteMarketDataSet"
        >
          Yes, delete
        </ch-button>
      </template>
    </ch-dialog>
    <ch-content-dialog
      ref="marketDataTable"
      size="fullscreen"
      type="primary"
      important
    >
      <template #title>
        <div class="marketDataTableLabel">
          <label-tag label="BETA" size="small" />
          <span>
            {{ marketDataTableTitle }}
          </span>
        </div>
      </template>
      <market-data-table :market-data="dataSet" />
    </ch-content-dialog>
    <ch-loader v-if="loading" fullscreen />
  </div>
</template>

<script>
import MarketDataTable from "./MarketDataTable.vue";
import StringUtils from "@/utils/StringUtils";
import LabelTag from "../LabelTag.vue";

export default {
  name: "MarketDataSets",
  components: {
    MarketDataTable,
    LabelTag,
  },
  props: {
    /*
     * Sets the market data pools
     */
    dataSets: {
      type: Array,
      default: () => [],
    },
    /*
     * Sets the planogram id
     */
    planogramId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      header: [
        "Name",
        "Period",
        "Store format",
        "Location",
        "Creation date",
        "",
      ],
      columnWidth: ["200px", "200px", "200px", "200px", "160px", "50px"],
      dataSetName: "",
      dataSetPeriod: "",
      dataSetStoreFormat: "",
      dataSetLocation: "",
      dataSetId: "",
      dataSet: {},
      loading: false,
    };
  },
  computed: {
    marketDataTableTitle() {
      if (!this.dataSetId) return "";

      const currentDataSet = this.dataSets.find(
        (dataset) => dataset.id === this.dataSetId
      );

      return currentDataSet
        ? `Market data for ${
            currentDataSet.name
          } - last update ${StringUtils.formattedDateTimeFromISO(
            currentDataSet.creationDate
          )}`
        : "";
    },
  },
  filters: {
    formatDateTime(date) {
      return StringUtils.formattedDateTimeFromISO(date);
    },
  },
  methods: {
    getVoices(id) {
      return [
        {
          text: "View imported data",
          icon: "search",
          disabled: false,
          action: () => this.openDialog(id, "view"),
        },
        {
          text: "Edit dataset information",
          icon: "edit",
          disabled: false,
          action: () => this.openDialog(id, "edit"),
        },
        {
          text: "Delete",
          icon: "delete",
          disabled: false,
          action: () => this.openDialog(id, "delete"),
        },
      ];
    },
    resetDataSet() {
      this.dataSetName = "";
      this.dataSetPeriod = "";
      this.dataSetStoreFormat = "";
      this.dataSetLocation = "";
      this.dataSetId = "";
    },
    openDialog(id, action) {
      if (!id || !action) return;

      this.dataSetId = id;

      action === "view" && this.handleMarketDataView();

      action === "edit" && this.handleMarketDataEdit();

      action === "delete" && this.handleMarketDataDelete();
    },
    closeDeleteDialog() {
      this.resetDataSet();

      this.$refs.deleteDialog.close();
    },
    async deleteMarketDataSet() {
      try {
        this.loading = true;

        await this.$studio.removeMarketDataPool(
          this.planogramId,
          this.dataSetId
        );

        this.$emit("market-data-pool-deleted", this.dataSetId);
      } catch (error) {
        console.error({
          error,
          planogramId: this.planogramId,
          marketDataPoolId: this.dataSetId,
        });
      } finally {
        this.closeDeleteDialog();

        this.loading = false;
      }
    },
    datasetNameValidation(value) {
      if (!value) return "The value could not be empty";

      if (value.length > 100) return "Maximum 100 characters";

      if (/[^A-Za-z0-9 \-\_\+\(\)]/.test(value))
        return "Only _ - ( ) + - special characters are allowed";

      return null;
    },
    datasetInformationsValidation(value) {
      if (!value) return "The value could not be empty";

      if (value.length > 100) return "Maximum 100 characters";

      if (/[^A-Za-z0-9 \-\(\)]/.test(value))
        return "Only - ( ) special characters are allowed";

      return null;
    },
    async editInfo() {
      try {
        this.loading = true;

        const marketDataPoolInfo = {
          name: this.dataSetName,
          storeFormat: this.dataSetStoreFormat,
          location: this.dataSetLocation,
          period: this.dataSetPeriod,
        };

        await this.$trade.updateMarketDataPoolInfo(
          this.dataSetId,
          marketDataPoolInfo
        );

        this.$emit("market-data-pool-info-update");
      } catch (error) {
        console.error({
          error,
          marketDataPoolId: this.dataSetId,
        });
      } finally {
        this.resetDataSet();

        this.loading = false;
      }
    },
    async handleMarketDataView() {
      try {
        this.loading = true;

        this.dataSet = (
          await this.$studio.findMarketDataPoolImportedData(this.dataSetId)
        ).importedData;

        this.$refs.marketDataTable.open();
      } catch (error) {
        console.error({
          error,
          marketDataPoolId: this.dataSetId,
        });
      } finally {
        this.loading = false;
      }
    },
    handleMarketDataDelete() {
      const dataset = this.dataSets.find(
        (dataSet) => dataSet.id === this.dataSetId
      );

      this.dataSetName = dataset.name || "the selected Market data set";

      this.$refs.deleteDialog.open();
    },
    handleMarketDataEdit() {
      const dataset = this.dataSets.find(
        (dataSet) => dataSet.id === this.dataSetId
      );

      this.dataSetName = dataset.name || "";
      this.dataSetPeriod = dataset.period || "";
      this.dataSetStoreFormat = dataset.storeFormat || "";
      this.dataSetLocation = dataset.location || "";

      this.$refs.editInputFormDialog.open();
    },
  },
};
</script>

<style scoped>
.market-data-sets {
  height: 100%;
  color: var(--elevation-06);
}

.market-data-sets > p {
  padding-top: 12px;
}

.market-data-sets > p > a {
  color: var(--primary);
}

.market-data-sets-content-wrapper {
  display: flex;
  justify-content: center;
  max-height: 720px;
}

.market-data-sets-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.market-data-sets-content > p {
  margin: 8px 0 16px 0;
}

::v-deep .contextMenu > .dropdown {
  width: 200px;
}
.editInputFormDialogLabel {
  display: flex;
  align-items: center;
}

.editInputFormDialogLabel > h2 {
  margin-left: 12px;
}

.marketDataTableLabel {
  display: flex;
  align-items: center;
}

.marketDataTableLabel > span {
  margin-left: 12px;
}
</style>
