<template>
    <ch-application-layout>
        <fields-navigation slot="navigation"/>
        <template slot="version">ShelfZone Studio v. {{version}}</template>
        <div slot="header" fill class="header" v-if="interviews.length > 0">
            <div class="fieldName" x-spaced-2>
                <ch-icon icon="arrow_back" @click="returnToFieldCells(field)" :size="28"/>
                <h2>{{field.name}}</h2>
            </div>
        </div>
        <div class="main" slot="main" fill>
            <ch-table v-if="interviews.length > 0" class="interviewsTable" :items="interviews"
                      @rowClicked="showRecordings">
                <template slot="header">
                    <ch-th name="Respondent"/>
                </template>
                <template slot-scope="{ row }">
                    <ch-td>
                        <div class="interview">
                            <div x-spaced-1>
                                <ch-indicator/>
                                <ch-field inline :validators="[interviewNameValidator]">
                                    <ch-editable :value="row.name"/>
                                </ch-field>
                            </div>
                            <ch-icon v-if="selectedInterview === row" icon="navigate_next" :size="36"/>
                            <ch-icon v-if="selectedInterview !== row" icon="navigate_next" :size="36" class="arrow"/>
                        </div>
                    </ch-td>
                </template>
            </ch-table>
            <ch-table v-if="interviews.length > 0" class="recordingsTable"
                      :items="selectedInterview ? selectedInterview.getRecordings() : []"
                      @rowClicked="goToAnalysis">
                <template slot="header">
                    <ch-th name="#" width="50px"/>
                    <ch-th name="Cell"/>
                    <ch-th name="Date"/>
                    <ch-th name="Duration"/>
                    <ch-th/>
                </template>
                <template slot-scope="{ row }">
                    <ch-td>{{row.index}}</ch-td>
                    <ch-td>{{getCellName(row.cellId)}}</ch-td>
                    <ch-td x-spaced-1>
                        <ch-icon icon="today"/>
                        <span>{{formattedDataFromISO(selectedInterview.creationDate)}}</span></ch-td>
                    <ch-td x-spaced-1>
                        <ch-icon icon="schedule"/>
                        <span>{{formatDuration(row.duration)}}</span></ch-td>
                    <ch-td>
                    </ch-td>
                </template>
            </ch-table>
            <ch-empty-content v-if="interviews.length === 0" firstMessage="You haven’t created any interview yet"/>
        </div>
    </ch-application-layout>
</template>

<script>
    import TimeFormatMixin from '@/mixins/TimeFormatMixin';
    import ContentNavigation from "../content/ContentNavigation";
    import FieldsNavigation from "./FieldsNavigation";
    import StaticConfig from "../config.js";

    export default {
        name: "FieldInterviews",
        mixins: [ TimeFormatMixin ],
        components: {FieldsNavigation, ContentNavigation},
        props: {
            fieldId: String,
            selected: Boolean
        },
        data() {
            return {
                field: null,
                selectedInterview: null,
                checkStatus: false
            };
        },
        beforeRouteEnter(to, from, next) {
          window["studioApi"].storage.findFieldById(to.params.fieldId)
              .then(studio => studio.fetchData())
              .then(foundField => {
                next(vm => (vm.field = foundField));
              }).catch(err => {
            console.log("FieldInterviews::beforeRouteEnter ", err);
            next(err);
          })
        },
        beforeRouteUpdate(to, from, next) {
          window["studioApi"].storage.findFieldById(to.params.fieldId)
              .then(studio => studio.fetchData())
              .then(foundField => {
                this.field = foundField;
                next();
              }).catch(err => {
            console.log("FieldInterviews::beforeRouteUpdate ", err);
            next(err);
          })
        },
        mounted() {
            document.title = this.field.name + " interviews | Shelfzone Studio";
        },
        computed: {
            version() {
                return StaticConfig.version;
            },
            interviews() {
                return (!this.field ? [] : this.field.interviews).sort((i1, i2) => i1.name > i2.name ? 1 : -1);
            },
            environments() {
                return this.$storage.environments
                    .map(environment => {
                        return {
                            name: environment.name,
                            cells: this.field.cells.filter(cell =>
                                environment.getCustomizationById(cell.environmentCustomizationId)
                            )
                        };
                    })
                    .filter(e => e.cells.length > 0);
            }
        },
        methods: {
            interviewNameValidator(name) {
                if (!name || !/^(\w|-|\.|\s){1,30}$/.test(name.trim()))
                    return 'Valid characters: A-z, 0-9 and . _ -';
                return null;
            },
            getStatus(item) {
                if (item.recordings.length > 0) return "success";
                else return "error";
            },

            toggleSelection(interview) {

                const interviewIndex = this.selectedInterviews.indexOf(interview);

                if (interviewIndex > -1)
                    this.selectedInterviews = this.selectedInterviews.splice(interviewIndex, 1)
                else
                    this.selectedInterviews.push(interview);
            },
            getCellName(cellId) {
                return this.field.getCellWithId(cellId).name
            },

            showRecordings(row) {
                this.selectedInterview = row;
                row.selected = true;
                console.log(row.selected)
            },

            returnToFieldCells(field) {
                this.$router.push({name: 'field', params: {fieldId: field.id}});
            },

            goToAnalysis(recording) {
                this.$router.push({
                    name: "analysis",
                    params: {fieldId: this.field.id, cellId: recording.cellId},
                    query: {activeRecordingId: recording.id}
                });
            },
        }
   
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: flex-end;
}

.fieldName {
  display: flex;
  align-items: center;
}

.main {
  display: flex;
  overflow-y: auto;
  padding: 0 var(--quadrupleMargin) var(--quadrupleMargin)
    var(--quadrupleMargin);
  box-sizing: border-box;
}

.interviewsTable {
  flex: 0 0 auto;
  width: 312px;
  margin-right: 8px;
}

.recordingsTable {
  flex: 1 1 auto;
}

.interview {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.arrow {
  visibility: hidden;
}

tr:hover .arrow {
  visibility: visible;
}
</style>
