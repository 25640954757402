<template>
    <ch-form @submit="changePassword" @cancel="reset" type="primary" disableEnter :genericErrors="errorMessages">
        <div>
            <p>Please insert a new password for your account.</p>
            <p>The new password can't be the same as one of the last 5 you have set</p>
        </div>
        <ch-field label="Current password" :validators="[v => !v ? 'Please provide the current password' : null]">
            <ch-text-input v-model="currentPassword" password placeholder=""/>
        </ch-field>
        <ch-field label="New password" :validators="passwordValidators">
            <ch-text-input v-model="newPassword" password placeholder=""/>
        </ch-field>
        <ch-field label="Confirm password" :validators="[passwordMatchValidator(newPassword)]">
            <ch-text-input v-model="confirmPassword" password placeholder=""/>
        </ch-field>
        <div slot="actions" x-spaced-2>
            <ch-button v-if="!confirmOnly" size="small" type="secondary" cancel>Cancel</ch-button>
            <ch-button v-if="confirmOnly" submit>Ok</ch-button>
            <ch-button v-else size="small" type="primary" submit>Ok</ch-button>
        </div>
    </ch-form>
</template>

<script>
    import {PasswordValidators} from '../../../user/services/PasswordValidators';

    export default {
        name: 'ChangePasswordForm',
        props: {
            confirmOnly: Boolean
        },
        data() {
            return {
                currentPassword: null,
                newPassword: null,
                confirmPassword: null,
                errorMessages: [],
                passwordValidators: PasswordValidators.validators,
                passwordMatchValidator: PasswordValidators.passwordMatch
            };
        },
        methods: {
            changePassword() {
                return this.$loading.await(
                    this.$iam.changePassword(this.currentPassword, this.newPassword)
                        .then(() => this.$emit('submit'))
                        .catch(e => this.errorMessages = [e.response.data.msg])
                );
            },
            reset() {
                this.currentPassword = null;
                this.newPassword = null;
                this.confirmPassword = null;
                this.errorMessages = [];
                this.$emit('cancel');
            }
        }
    };
</script>

<style scoped>

</style>
