<template>
  <div class='score-card'>
    <div class='score-card-header'>
      <ch-icon
        v-if='!isExpanded'
        class='header-icon'
        size='32'
        icon='arrow_right'
        @click='isExpanded = !isExpanded'
      />
      <ch-icon
        v-else
        class='header-icon'
        size='32'
        icon='arrow_drop_down'
        @click='isExpanded = !isExpanded'
      />
      <h1 primary-text style='margin-right: 8px;'>Market data</h1>
      <label-tag label='BETA' size='small' />
      <div v-if='dataSetName' v-tooltip.top='dataSetName' class='data-set-name'>
        <span>{{ dataSetName }}</span>
      </div>
      <ch-icon
        class='settings-icon'
        size='16'
        icon='settings'
        @click='handleDataSetAction'
      />
    </div>
    <div v-if='isExpanded' style='font-size: 12px;'>
      <template v-if='kpis.length'>
        <ch-dl
          v-for='(kpi, index) in kpis'
          :key='`${index}-${kpi.value}`'
          label-width='50%'
        >
          <ch-dd
            v-if='kpi.label'
            :label='kpi.label'
            :class='{ disabled: !kpi.value || kpi.value < 0 }'
            v-tooltip.top.default="
              !kpi.value
                ? 'There are no data in the dataset for this KPI'
                : null
            "
          >
                        <span v-if='kpisDisplayedAsString.includes(kpi.key)'
                              class='kpi-value'>
              {{ kpi.value ? kpi.value : '-' }}
            </span>
            <span
              v-else-if='kpisDisplayedAsValues.includes(kpi.key)'
              class='kpi-value'
            >
              {{ kpi.value && kpi.value > 0 ? kpi.value.toFixed(2) : '-' }}
            </span>
            <template v-else>
              <span class='kpi-percentage' :class='{ disabled: !kpi.value }'>
                {{ kpi.value && kpi.value > 0 ? `${Math.trunc(kpi.value * 100)}%` : '-' }}
              </span>
              <ch-progress-bar :value='kpi.value' :max-value='1' />
            </template>
          </ch-dd>
        </ch-dl>
      </template>
      <template v-if='economics.length'>
        <ch-dl
          v-for='(economic, index) in economics'
          :key='index'
          label-width='50%'
        >
          <ch-dd :label='economic.label'>
            <span style='margin-left: 12px;'>
              {{ economic.value }}
            </span>
          </ch-dd>
        </ch-dl>
      </template>
      <!--
        FIXME: records rendering are temporary disabled:
        a refactor of importedData body mapping is required.
      -->
      <!-- <template v-if="records.length">
        <ch-dl
          v-for="(record, index) in records"
          :key="index"
          label-width="50%"
        >
          <ch-dd :label="record.label">
            {{ record.value }}
          </ch-dd>
        </ch-dl>
      </template> -->
    </div>
    <ch-content-dialog
      ref='marketDataScoreCardTable'
      type='primary'
      size='fullscreen'
    >
      <template #title>
        <div class='marketDataScoreCardTableLabel'>
          <label-tag label='BETA' size='small' />
          <span>
            Setup market data
          </span>
        </div>
      </template>
      <ch-table
        class='marketDataPoolList'
        ref='data-sets-info'
        :items='dataSets'
        style='padding: 32px 32px 64px 32px;'
      >
        <template #header>
          <ch-th
            v-for='(headerCell, headerIndex) in header'
            :key='headerIndex'
            :id='headerCell'
            :name='headerCell'
            :width='columnWidth[headerIndex]'
          />
        </template>
        <template slot-scope='{ row }'>
          <ch-td>
            <ch-checkbox
              :value='row.id === currentSelectMarketDataPoolId'
              @input='handleDataSetSelection(row.id)'
            />
          </ch-td>
          <ch-td>
            {{ row.name }}
          </ch-td>
          <ch-td>
            {{ row.period }}
          </ch-td>
          <ch-td>
            {{ row.storeFormat }}
          </ch-td>
          <ch-td>
            {{ row.location }}
          </ch-td>
          <ch-td>
            {{ row.creationDate }}
          </ch-td>
        </template>
      </ch-table>
      <div class='data-sets-info-footer'>
        <ch-button type='secondary' size='small' @click='closeModal'>
          Cancel
        </ch-button>
        <ch-button type='primary' size='fit' @click='changeDataSet'>
          Use dataset
        </ch-button>
      </div>
    </ch-content-dialog>
  </div>
</template>

<script>
import LabelTag from '../../LabelTag.vue';
import KPI from '../../../utils/KPI/index';

export default {
  name: 'MarketDataScoreCard',
  components: {
    LabelTag,
  },
  props: {
    /*
     * Sets the kpis to display.
     */
    kpis: {
      type: Array,
      default: () => [],
    },
    /*
     * Sets the economics to display.
     */
    economics: {
      type: Array,
      default: () => [],
    },
    /*
     * Sets the records to display.
     */
    records: {
      type: Array,
      default: () => [],
    },
    /*
     * Sets the dataset name to display.
     */
    dataSetName: {
      type: String,
      default: '',
    },
    dataSets: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentSelectMarketDataPoolId: null,
      header: ['', 'Name', 'Period', 'Format', 'Location', 'Creation date'],
      columnWidth: ['40px', '200px', '200px', '200px', '200px', '200px'],
      isExpanded: true,
      kpisDisplayedAsValues: KPI.config.kpisDisplayedAsValues,
      kpisDisplayedAsString: KPI.config.kpisDisplayedAsString,
    };
  },
  methods: {
    handleDataSetAction() {
      if (this.value) this.openModal();
      else this.$emit('market-data-clicked');
    },
    openModal() {
      this.currentSelectMarketDataPoolId = this.value;
      this.$refs.marketDataScoreCardTable.open();
    },
    closeModal() {
      this.currentSelectMarketDataPoolId = null;
      this.$refs.marketDataScoreCardTable.close();
    },
    changeDataSet() {
      this.$emit('input', this.currentSelectMarketDataPoolId);
      this.currentSelectMarketDataPoolId = null;
      this.$refs.marketDataScoreCardTable.close();
    },
    handleDataSetSelection(id) {
      this.currentSelectMarketDataPoolId = id;
    },
  },
};
</script>

<style scoped>
.score-card {
  border-bottom: 1px solid var(--elevation-04);
}

.score-card-header {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}

.score-card-header h1 {
  font-size: 16px;
}

.data-set-name {
  max-width: 96px;
  margin: 0 8px;
}

.data-set-name > span {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 12px;
}

.header-icon {
  margin-left: -8px;
  color: var(--primary);
}

::v-deep .icon.settings-icon {
  position: absolute;
  right: 0;
  color: var(--primary);
}

::v-deep .definitionList {
  padding-bottom: 12px;
}

::v-deep .definitionItem > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
}

::v-deep .definitionItem > span > .progressBar {
  width: calc(100% - 32px);
  height: 10px;
  border: none;
  margin-left: 8px;
  border-radius: 2px;
  /* FIXME: #095544 should be setted as variable. :root || .theme || v-deep seems not working */
  background-color: #095544;
}

::v-deep .definitionItem > span > .progressBar > .meter {
  border-radius: 2px;
  background-color: var(--primary);
}

.kpi-value {
  margin-left: 40px;
}

.kpi-percentage {
  width: 32px;
  text-align: right;
}

.kpi-percentage.disabled {
  color: var(--elevation-04);
}

::v-deep .definitionItem.disabled > label {
  color: gray;
}

::v-deep .definitionItem.disabled > span > .progressBar {
  background-color: #0c2b2a;
}

::v-deep .definitionItem.disabled span {
  color: gray;
}

::v-deep .definitionItem > .tooltip {
  z-index: -10 !important;
}

::v-deep .definitionItem.disabled > .tooltip {
  z-index: 10 !important;
  left: -50px;
}

::v-deep .svg-inline--fa.fa-check-circle.fa-w-16 {
  color: var(--elevation-06);
}

.data-sets-info-footer {
  width: calc(100% - 32px);
  height: 40px;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-right: 32px;
}

::v-deep .data-sets-info-footer > button {
  margin-left: 24px;
}

.marketDataScoreCardTableLabel {
  display: flex;
  align-items: center;
}

.marketDataScoreCardTableLabel > span {
  margin-left: 12px;
}

.marketDataPoolList {
  max-height: 732px;
}
</style>
