<template>
    <ch-global-layout>
        <ch-top-menu slot="header" :voices="voices">
            <div slot="logo" class="logo" @click="returnToHome"></div>
            <div class="account" slot="end" x-spaced-1>
	            <inactivity-tracker
                    :maximum-time="30"
                    :disabled="pendingDownloads"
                    @inactive="logout()"
                />
	            <switch-to-work-group-dialog ref="switchAccountDialog"/>
	            <members-indicator v-if="workGroup" :workGroup="workGroup" :max-members="maxShownMembers"/>
                <download-manager
                    v-show="$downloadManager.downloads.length"
                    :downloads="$downloadManager.downloads"
                    :speed="$downloadManager.downloadingSpeedBps"
                    :shrink="isUserMenuOpen"
                />
	            <ch-button-menu
                    class="userMenu"
                    :class="userMenuClasses"
                    :voices="userMenuVoices"
                    @click="isUserMenuOpen = ! isUserMenuOpen"
                >
		            <div class="accountName" :class="{'isUserMenuOpen': isUserMenuOpen,}">
			            <identity-info v-if="!workGroup" hide-you :id="$storage.user.id"/>
			            <div v-else ellipsis>{{ workGroup.name }}</div>
		            </div>
	            </ch-button-menu>
                <ch-dialog
                    ref="ongoingDownloadsDialog"
                    type="primary"
                    title="Ongoing downloads"
                    @cancel="closeOngoingDownloadsDialog()"
                >
                    <template slot="body">
                        Do you really want to logout? You have ongoing downloads!
                    </template>
                    <template slot="footer">
                        <ch-button
                            size="small"
                            type="secondary"
                            @click="closeOngoingDownloadsDialog()"
                        >
                            Cancel
                        </ch-button>
                        <ch-button
                            size="fit"
                            type="primary"
                            @click="logout()"
                        >
                            Yes, logout
                        </ch-button>
                    </template>
                </ch-dialog>
            </div>
        </ch-top-menu>
        <router-view slot="main"/>
    </ch-global-layout>
</template>

<script>
    import InactivityTracker from '../components/InactivityTracker';
    import IdentityInfo from '@/user/workgroups/components/IdentityInfo';
    import SwitchToWorkGroupDialog from '@/user/workgroups/dialog/SwitchToWorkGroupDialog';
    import MembersIndicator from '@/user/workgroups/components/MembersIndicator';
    import DownloadManager from '../components/downloadmanager/DownloadManager';

    export default {
        name: "Main",
        components: {
            MembersIndicator,
            SwitchToWorkGroupDialog,
            IdentityInfo,
            InactivityTracker,
            DownloadManager
        },
	    data() {
        	return {
        		currentWorkGroupId: null,
		        workGroup: null,
                isUserMenuOpen: false,
	        }
	    },
        created() {

            /*
            * WARNING: modern browsers doesn't allow custom message/interaction on beforeunload.
            * https://developers.google.com/web/updates/2016/04/chrome-51-deprecations#remove_custom_messages_in_onbeforeunload_dialogs
            * The only course of action, in this case, is to put a control for every condition the custom browser alert should be fired
            * (in this case, if there are pending downloads)
            */
            window.addEventListener(
                'beforeunload',
                this.preventUnload,
            );

        },
	    mounted() {
		    this.currentWorkGroupId = this.$storage.workGroupId;
	    },
        beforeDestroy() {

            window.removeEventListener(
                'beforeunload',
                this.preventUnload,
            );

        },
	    computed: {
            voices() {
                const isAdmin = this.$storage.user.isAdmin ? [{
                    text: 'Admin',
                    to: {name: 'admin'},
                    activeCondition: path => path.startsWith('/admin')
                }] : [];
                return [
                    {
                        text: "Projects",
                        to: {name: "projects"},
                        activeCondition: path => path.startsWith('/projects')
                    },
                    {
                        text: "Content",
                        to: {name: "planograms"},
                        activeCondition: path => path.startsWith('/content') || path.startsWith('/planograms')
                    },
                    {
                        text: "SKU Catalog",
                        to: {name: "sku"},
                        activeCondition: path => path.startsWith('/sku')
                    },
                    {
                        text: "Research",
                        to: {name: "fields"},
                        activeCondition: path => path.startsWith('/fields')
                    },
                    ...isAdmin
                ]
            },
            userMenuVoices() {
                const isAdmin = this.$storage.user.isAdmin ? [
                    {
                        text: 'My organization',
                        icon: 'domain',
                        action: () => this.$router.push("/admin")
                    }
                ] : [];
                return [
                    ...isAdmin,
                    {
                        text: 'My account',
                        icon: 'account_box',
                        action: () => this.$router.push({name: 'accountInfo'})
                    },
	                {
	                	text: 'Switch to...',
		                icon: 'people',
		                action: () => this.$refs.switchAccountDialog.open()
	                },
                    {
	                	text: 'Help',
		                icon: 'help',
		                action: () => window.open('https://invrsion.atlassian.net/wiki/spaces/01/pages/41616090/ShelfZone+Studio', '_blank'),
	                },
                    {
                        text: 'Logout',
                        icon: 'power_settings_new',
                        action: () => this.logoutIfNotPendingDownloads()
                    }
                ]
            },
		    maxShownMembers() {

                /* sum of the width of every element to the right of the <members-indicator /> component */
                const ctasWidth = 320;

            	return Math.max(
                    1,
                    Math.min(
                        Math.floor(
                            ( this.windowWidth - 1220 - ctasWidth ) / 30
                        ),
                        5
                    )
                );

		    },
            userMenuClasses() {

                return {
                    'userMenuIsOpen': this.isUserMenuOpen,
                }

            },
            pendingDownloads() {

                return this.$downloadManager.isDownloadPending();

            },
        },
	    watch: {
        	currentWorkGroupId() {
        		if(this.currentWorkGroupId)
			        this.$iam.findWorkGroup(this.currentWorkGroupId)
				        .then(response => this.workGroup = response.workGroup)
				        .catch(error => {
					        if (error.response.status === 404)
					        	this.$router.push({name: 'accountSwitch'})
				        })
	        }
	    },
        methods:{
            returnToHome(){
                this.$router.push({name: 'projects'});
            },
            logout() {
                this.$iam.logout()
                    .then(
                        () => this.$downloadManager.emptyDownloads()
                    )
                    .then(() => {
                        window['studioApi'].close();
                        this.$store.commit('logout');
                        this.$storage.empty();
                        this.$iam.emptyCache();
                        this.$nextTick(() => this.$router.push({name: 'login'}))
                    })
            },
            logoutIfNotPendingDownloads() {

                if( ! this.pendingDownloads )
                    this.logout();
                else
                    this.$refs.ongoingDownloadsDialog.open();

            },
            closeOngoingDownloadsDialog() {

                this.$refs.ongoingDownloadsDialog.close();

            },
            preventUnload(
                event
            ) {

                if( this.pendingDownloads )
                    return event.returnValue = 'Do you really want to leave this page? You have ongoing downloads!';

            },
        },
    };
</script>

<style scoped>

    .logo {
        height: 100%;
        background-image: url("../assets/logo.png");
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 170px;
        cursor: pointer;
    }

    .account {
	    display: flex;
	    align-items: center;
    }

    /*
        FIXME:
        The following rules are quick fixes to the main <header />.
        A refactor is needed when the new design will be ready.
        Selectors are strict to avoid side effects.
    */
    .accountName {
	    max-width: 100px;
    }

    .accountName.isUserMenuOpen {
	    max-width: calc(100% - 22px);
    }

    ::v-deep .middle {
        max-width: 800px;
    }

    ::v-deep .end {
        width: calc(100vw - 1160px) !important;
    }
    
    ::v-deep .buttonMenu.userMenu {
        width: 150px;
    }

    ::v-deep .buttonMenu.userMenu.dropdownVisible {
        width: 240px;
    }

    ::v-deep .accountName > .user {
        max-width: 100px;
        justify-content: flex-start;
    }

    ::v-deep .accountName.isUserMenuOpen > .user {
        width: 200px;
        max-width: 100%;
    }

    ::v-deep .buttonMenu.userMenu.dropdownVisible.userMenuIsOpen > .button {
        width: 100%;
    }
</style>
