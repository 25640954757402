<template>
    <div class="downloadItem">
        <span
            class="itemName"
            :class="itemNameClasses"
        >
            {{ itemName }}
        </span>
        <div class="itemProgress">
            <ch-progress-bar
                :value="isCancelled || isFailed ? 0 : progress"
                size="thin"
            />
            <ch-icon
                class="progressIcon retryIcon"
                :class="retryIconClasses"
                icon="refresh"
                @click.stop.native="( isCancelled || isFailed ) && handleAction('retry')"
            />
            <ch-icon
                class="progressIcon"
                :icon="isDownloading ? 'close' : 'delete'"
                @click.stop.native="handleAction( isDownloading ? 'close' : 'delete' )"
            />
        </div>
        <div class="itemStatus">
            <div
                class="statusDescription"
                :class="statusDescriptionClasses"
            >
                <ch-icon
                    v-if="isFailed"
                    icon="error"
                    size="16"
                />
                <span
                    class="statusDescriptionText"
                    :class="statusDescriptionTextClasses"
                >
                    {{statusText}}
                </span>
            </div>
            <span
                v-if="time && isDownloading"
                class="statusTime"
            >
                {{ time }}
            </span>
        </div>
    </div>
</template>


<script>

export default {
    name: 'DownloadManagerItem',
    props: {
        /*
        * Sets the File Name
        */
        itemName: {
            type: String,
            required: true,
        },
        /*
        * Sets the progress
        */
        progress: {
            type: Number,
            default: 0,
        },
        /*
        * Sets the remaining time
        */
        time: {
            type: String,
            default: '',
        },
        /*
        * Sets the item status
        */
        itemStatus: {
            type: String,
            default: '',
        },
    },
    computed: {
        retryIconClasses() {

            return {
                'isRetryIconActive': this.isCancelled || this.isFailed,
            };

        },
        itemNameClasses() {

            return {
                'isCancelled': this.isCancelled,
            };

        },
        statusDescriptionClasses() {

            return {
                'isFailed': this.isFailed,
                'isCancelled': this.isCancelled,
            };

        },
        statusDescriptionTextClasses() {
            
            return {
                'isDownloading': this.isDownloading,
                'isFailed': this.isFailed,
                'isCancelled': this.isCancelled,
            };

        },
        isDownloading() {

            return this.itemStatus === 'Downloading';

        },
        isCancelled() {

            return this.itemStatus === 'Cancelled';

        },
        isFailed() {

            return this.itemStatus === 'Failed';

        },
        statusText() {

            if( this.itemStatus === 'ToDownload' )
                return 'Waiting for download';

            if( this.itemStatus === 'Failed' )
                return 'Download failed';
            
            if( this.itemStatus === 'Completed' )
                return 'Downloaded';

            if( this.itemStatus === 'Cancelled' )
                return 'Canceled';

            return this.itemStatus;

        },
    },
    methods: {
        handleAction( event ) {

            this.$emit(`${event}`, event);

        }
    },
}
</script>

<style scoped>
    .itemName {
        color: var(--elevation-06);
    }

    .itemProgress {
        display: flex;
        align-items: center;
    }

    .progressIcon {
        margin-left: 8px;
    }

    .retryIcon {
        opacity: 0;
    }

    .isRetryIconActive {
        opacity: 1;
    }

    .itemStatus {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 320px;
    }

    .statusDescriptionText {
        margin-left: 2px;
        color: var(--elevation-06);
    }

    .isDownloading {
        color: var(--elevation-05);
    }

    .isDownloading:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: ellipsis steps(4,end) 2500ms infinite;      
        animation: ellipsis steps(4,end) 2500ms infinite;
        /* ascii code for the ellipsis character */
        content: "\2026";
        width: 0px;
    }

    /* with 15px the single dot is completely centered in the keyframe */
    @keyframes ellipsis {
        to {
            width: 15px;    
        }
    }

    @-webkit-keyframes ellipsis {
        to {
            width: 15px;    
        }
    }

    .statusTime {
        color: var(--elevation-05);
    }

    .isFailed {
        color: var(--error);
    }

    .isCancelled {
        color: var(--elevation-04);
    }
</style>