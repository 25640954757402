<template>
    <div class="cardWrapper" @click="onCardClick" :disabled="disabled" :selected="selected">
        <div class="cardBody">
            <ch-image :src="placeholder.preview"/>
        </div>
        <div class="cardDescription">
            <div class="cardName">{{placeholder.name}}</div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "ContentCard",
        props: {
            placeholder: Object,
            selected: Boolean,
            disabled: Boolean
        },
        methods: {
            onCardClick(ev) {
                if (!this.disabled)
                    this.$emit('click', this.placeholder.id);
            }
        }
    }
</script>

<style scoped>

    .cardWrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 24px;
        grid-template-areas: "body" "description";
        overflow: auto;
    }

    .cardWrapper:not([disabled]) {
        cursor: pointer;
    }

    .cardWrapper[selected] {
        background-color: var(--primary);
        color: var(--on-primary);
    }

    .cardWrapper:not([disabled]):hover {
        background: var(--primary-hover);
        color: var(--on-primary-hover);
    }

    .cardWrapper[disabled] {
        background-color: var(--disabled);
        color: var(--on-disabled);
    }

    .cardBody {
        box-sizing: border-box;
        grid-area: body;
        width: 100%;
        height: 100%;
        align-self: center;
        justify-self: center;
        padding: var(--singleMargin);
        pointer-events: none;
        user-select: none;
        background-color: rgb(68, 77, 86);
        overflow: auto;

        -moz-box-shadow: inset 0 -8px 0 rgb(49, 53, 58);
        -webkit-box-shadow: inset 0 -8px 0 rgb(49, 53, 58);
        box-shadow: inset 0 -8px 0 rgb(49, 53, 58);
    }

    .cardDescription {
        grid-area: description;
        max-width: 100%;
        overflow: hidden;
        padding: 4px 0 0 0;

    }

    .cardName {
        width: 100%;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        font-weight: 500;
    }

</style>