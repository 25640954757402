<template>
    <ch-content-dialog v-bind="{...$props, ...$attrs }" ref="inputDialog" @keydown="() => {}" class="researchColors">
        <template #main>
            <ch-application-layout class="layout" :class="{'empty': sortedList.length === 0}">
                <template #header >
                    <div y-spaced-1>
                        <ch-search-bar ref="search" v-model="searchQuery" real-time size="fill" :disabled="!list.length" @keydown.esc.native.stop="onEscSearch()"/>
                        <span v-if="list.length || searchQuery">{{ tagsCounterText }}</span>
                    </div>
                </template>
                <template #main>
                        <tag-list :list="sortedList">
                            <template #default={tag}>
                                <div class="tagListItem">
                                    <ch-indicator :color="tag.color" v-if="editedTag !== tag.id"/>
                                    <tag-edit :tag="tag" 
                                              :tagList="list"
                                              :isEditing="editedTag === tag.id" 
                                              :key="tag.id"
                                              @startEdit    ="editedTag = $event"
                                              @endEdit      ="editedTag = null"
                                              @cancelledEdit="editedTag = null"
                                              @doneEdit     ="onTagEditDone($event)"
                                              @removeTag    ="onTagDelete($event)"
                                    />
                                </div>
                                <template slot="actions"/>
                            </template>
                        </tag-list>
                </template>
                <template #footer>
                    <create-new-tag @newTagName="onCreateNewTag($event)" :list="list"/>
                </template>
            </ch-application-layout>
            <ch-modal ref="tagDelete" tabindex="-1"  @keydown.esc.native.stop.prevent="onEscTagDeleteModal($event)" important/>

        </template>
        
    </ch-content-dialog>
</template>

<script>
import Studio                                               from '../../../plugins/studioapi/storage/Studio';
import CreateNewTag                                         from './CreateNewTag';
import TagList                                              from './TagList';
import TagEdit                                              from './TagEdit';
import { alphabetSortFunc, isTagNameUnique, tagValidators } from './helpers';

const getDefaultData = () => ({
    searchQuery: '',
    editedTag: null,
});

export default {
    name: "ManageTagsDialog",
    components: { CreateNewTag, TagList, TagEdit },
    props: {
        field: Studio,
    },
    data() {
       return {
            tagApi: this.$research.$tagAPI,
            searchQuery: '',
            editedTag: null,
       };
    },
    computed: {
        list() {
            return [ ...this.field.tags.values() ];
        },
        filteredList() {
            return this.list.filter( ({name}) => this.isSubstring((this.searchQuery || '').trim(), name.trim()))
        },
        sortedList() {
            return  [... this.filteredList].sort(alphabetSortFunc);
        },
        tagsCounterText() {
            const tagTxt = (count) => `tag${count !== 1 ? 's' : ''}`; 
            const query = (this.searchQuery || '').trim();
            return query.length ? `${this.sortedList.length} ${tagTxt(this.sortedList.length)} of ${this.list.length}`
                                : `${this.list.length} ${tagTxt(this.list.length)}`
        },
    },
    methods: {
        reset() {
            this.searchQuery = '';
            this.editedTag   = null;
        },

        open() {  
            this.reset();
            return this.$refs.inputDialog.open()
        },
        isSubstring(substr, str) {
            return !substr || str.toLowerCase().includes(substr.toLowerCase());
        },
        onTagEditDone(updatedTag) {
            this.tagApi.rename(this.field.id, updatedTag).then(({tagId, name}) => {
                this.field.updateTags([{ id: tagId, name }]);
            } );
        },
        onTagDelete(tagId) {
            const recordsWithTag = [...this.field.recordings.values()].filter( r => r.tags.has(tagId));
            const isTagAssigned =  (recordsWithTag.length > 0);
            const text = !isTagAssigned ? 'Are you sure to remove the tag from this field?'
                                        : [`This tag is associated to ${recordsWithTag.length} respondents in this field.`," Are you sure to remove the tag?"];
            this.$refs.tagDelete.open(
                    'Remove tag', text,
                    'error' ,
                    'Remove', 'Cancel',
                ).then(() => {
                    if ( isTagAssigned ) {
                        recordsWithTag.forEach( r => {
                            this.tagApi.unassign(this.field.id, r.id, [tagId]).then( () => {
                                r.removeTags([tagId]);
                            }) ;
                        })
                    }
                    this.tagApi.delete(this.field.id, tagId).then(() => {
                        this.field.removeTags([tagId]);
                    });
                }, () => {});
                this.$nextTick(() => {
                    // preventing closing of manage tag modal with `Escape` if delete tag modal is opened;
                    const dialog = this.$refs.tagDelete.$refs.dialog.$el;
                    dialog.tabIndex = -1;
                    dialog.focus();
                })
        },
        onCreateNewTag(name) {
            this.tagApi.create(this.field.id, name).then((res) => {
                const { tag } = res;
                this.field.addTags([tag]);
            }).catch( e => { 
                const message = `Error: ${e}. Can't create tag with name: ${name}`;
                console.error(message);
            })
            this.$refs.search.$refs.input.blur();
        },
        onEscTagDeleteModal(event) {
                this.$refs.tagDelete.onCancel();
        },
        onEscSearch() {
            this.searchQuery = '';
            this.$refs.search.$refs.input.blur();
        }
    }

}
</script>

<style scoped>
    .layout >>> header, 
    .layout >>> footer {
        border: none !important;
        padding: var(--doubleMargin) !important;
        margin: 0 !important;
        height: auto !important;
    }

    .layout >>> main {
        padding:var(--doubleMargin) !important;
    }
    .layout >>> header {

        padding-bottom: 0 !important;
    }
    .layout >>> footer {
        padding-top: 0 !important;
        padding-bottom: var(--tripleMargin) !important;
    }

    .tagListItem {
        display: flex;
        align-items: center;
    }

    .layout.empty >>> main {
        height: 0;
        overflow-y: hidden;
    }
    
</style>