var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stepper"},_vm._l((_vm.steps),function(step,index){return _c('div',{key:(index + "-" + step),staticClass:"step-container"},[_c('div',{staticClass:"step"},[_c('div',{staticClass:"step-number",class:{
                    'step-number-current': index === _vm.currentStep
                },on:{"click":function($event){index !== _vm.currentStep && _vm.$emit(
                    'step-clicked',
                    index
                )}}},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('p',{staticClass:"step-description",class:{
                    'step-description-current': index === _vm.currentStep
                }},[_c('span',[_vm._v(_vm._s(step))])])]),(index !== _vm.steps.length - 1)?_c('div',{staticClass:"step-divider"}):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }