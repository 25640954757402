<template>
    <ch-accordion-menu :active="activeVoice">
        <ch-accordion-voice key="accountInfo" label="User info" @click="goTo('accountInfo')"/>
        <ch-accordion-voice key="workGroups" label="Workgroups" @click="goTo('workGroups')"/>
    </ch-accordion-menu>
</template>

<script>
    export default {
        name: 'Navigation',
        computed: {
            activeVoice() {
                return this.$route.meta.voice;
            }
        },
        methods: {
            goTo(path) {
                this.$router.push({name: path});
            }
        }
    };
</script>

<style scoped>


</style>
