<template>
    <ch-access-layout>
        <div class="logo" slot="logo"></div>
        <div y-spaced-2>
            <p>Please insert the email associated with your account to reset the password</p>
            <ch-form @submit="sendResetEmail">
                <ch-field :validators="[mailValidator]">
                    <ch-text-input placeholder="Email" v-model="emailAddress" size="large"/>
                </ch-field>
                <template slot="actions">
                    <ch-button submit>Reset</ch-button>
                </template>
            </ch-form>
        </div>
    </ch-access-layout>
</template>

<script>

    export default {
        name: "RequestResetPassword",
        data() {
            return {
                emailAddress: null,
                emailRegex: /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
            };
        },
        methods: {
            mailValidator(email) {
                if (!this.emailRegex.test(email))
                    return 'The email is invalid';
                return null;
            },
            sendResetEmail() {
                this.$iam.resetPassword(this.emailAddress)
                    .finally(() => this.$router.push({
                        name: 'passwordResetRequested',
                        query: {emailAddress: this.emailAddress}
                    }))
            }
        }
    };
</script>

<style scoped>

    .logo {
        background-image: url("../../../assets/logo.png");
        background-size: 210px;
    }

</style>
