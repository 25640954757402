import { render, staticRenderFns } from "./PlanogramContainerInfo.vue?vue&type=template&id=b9b4c372&scoped=true&"
import script from "./PlanogramContainerInfo.vue?vue&type=script&lang=js&"
export * from "./PlanogramContainerInfo.vue?vue&type=script&lang=js&"
import style0 from "./PlanogramContainerInfo.vue?vue&type=style&index=0&id=b9b4c372&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9b4c372",
  null
  
)

export default component.exports