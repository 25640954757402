<template>
	<div class="workGroups">
		<div class="section">
			<div y-spaced-2>
				<ch-button @click="createWorkGroup" type="primary" justified>New workgroup
					<ch-icon icon="add_circle_outline"/>
				</ch-button>
				<h1 primary-text>Joined workgroups</h1></div>
			<div class="sectionContent" y-spaced-2>
				<ch-table v-if="workGroups.length > 0" :items="workGroups" @rowClicked="goToWorkGroup">
					<template slot="header">
						<ch-th name="Name" id="name" sortable default-order="ascending"/>
						<ch-th name="N. of users" id="membersCount" sortable width="150px"/>
						<ch-th name="Joined at" id="joinedAt" sortable/>
						<ch-th name="Created by" id="createdBy" sortable/>
					</template>
					<template slot-scope="{row}">
						<ch-td>{{row.name}}</ch-td>
						<ch-td>{{row.membersCount}}</ch-td>
						<ch-td>{{row.joinedAt | formatDateTime}}</ch-td>
						<ch-td><identity-info :id="row.createdBy"/></ch-td>
					</template>
				</ch-table>
				<ch-empty-content v-else first-message="There are no workgroups yet."
				                  second-message="Create a workgroup" @opendialog="createWorkGroup"/>
			</div>
		</div>
		<create-work-group-dialog ref="createWorkGroupDialog"/>
	</div>
</template>

<script>
import Vue from 'vue';
import StringUtils from '@/utils/StringUtils';
import CreateWorkGroupDialog from '@/user/workgroups/dialog/CreateWorkGroupDialog';
import IdentityInfo from '@/user/workgroups/components/IdentityInfo';
import WorkGroupSummary from '@/plugins/studioapi/api/iam/model/WorkGroupSummary';

export default {
	name: 'WorkGroups',
	components: {IdentityInfo, CreateWorkGroupDialog},
	data() {
		return {
			workGroups: []
		}
	},
	beforeRouteEnter(to, from, next) {
		Vue.$iam.listWorkGroups()
			.then(response => next(vm => {
				vm.workGroups = response.workGroups;
			})).catch(err => {
			  console.log("WorkGroups::beforeRouteEnter ",err);
			  next(err);
    })
	},
	created() {
		document.title = 'Workgroups | ShelfZone Studio'
	},
	filters: {
		formatDateTime(date) {
			return StringUtils.formattedDateTimeFromISO(date);
		}
	},
	methods: {
		createWorkGroup() {
			this.$refs.createWorkGroupDialog.open()
				.then(workGroup => this.workGroups.push(WorkGroupSummary.fromWorkGroup(workGroup, this.$storage.user.id)))
		},
		goToWorkGroup(workGroup) {
			this.$router.push({name: 'workgroup', params: {workGroupId: workGroup.id}})
		}
	}
};
</script>

<style scoped>

.workGroups {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}

.section {
	display: flex;
	flex-direction: column;
	padding: var(--quadrupleMargin);
	padding-bottom: 0;
	flex: 1 1 auto;
	overflow-y: auto;
}

.sectionContent {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	border-top: 1px solid var(--elevation-04);
	padding-top: var(--doubleMargin);
	overflow-y: auto;
	padding-bottom: var(--quadrupleMargin);
}

</style>
