export default {

    /**
     * Normalize the value in the range
     *
     * @param {Number} value The value to normalize
     * @param {Number} rangeMin The min value of the range
     * @param {Number} rangeMax The max value of the range
     * @returns {Number} The value normalized
     */
    normalize(value, rangeMin, rangeMax) {
      if (value <= rangeMin)
          return 0
      if (value >= rangeMax)
          return 1
      return (value - rangeMin) / (rangeMax - rangeMin)
    },

    /**
     * Linear interpolation of the value to the range
     *
     * @param {Number} value The value to interpolate
     * @param {Number} from The min value of the target range
     * @param {Number} to The max value of the target range
     * @returns {Number} The interpolated value
     */
    lerpFloat(value, from, to) {
      return value * (to - from) + from
    },

    /**
     * Linear interpolation of the value to the range
     *
     * @param {Number} value The value to interpolate
     * @param {Number} from The min value of the target range
     * @param {Number} to The max value of the target range
     * @returns {Number} The interpolated value floored
     */
    lerpInt(value, from, to) {
      return Math.floor(this.lerpFloat(value, from, to))
    },

    /**
     * Linear interpolation of the value to the range to provide a color
     *
     * @param {{r : Number, g : Number, b : Number}} value The color to interpolate
     * @param {{r : Number, g : Number, b : Number}} from The min color of the target range
     * @param {{r : Number, g : Number, b : Number}} to The max color of the target range
     * @returns {{r : Number, g : Number, b : Number}} The interpolated color
     */
    lerpColor(value, from, to) {
      return {
          r: this.lerpInt(value, from.r, to.r),
          g: this.lerpInt(value, from.g, to.g),
          b: this.lerpInt(value, from.b, to.b),
        }
    },

    /**
     * Linear interpolation of the value to the range to provide a color with alpha
     *
     * @param {{r : Number, g : Number, b : Number, a : Number}} value The color to interpolate
     * @param {{r : Number, g : Number, b : Number, a : Number}} from The min color of the target range
     * @param {{r : Number, g : Number, b : Number, a : Number}} to The max color of the target range
     * @returns {{r : Number, g : Number, b : Number, a : Number}} The interpolated color
     */
    lerpAlphaColor(value, from, to) {
      return {
        r: this.lerpInt(value, from.r, to.r),
        g: this.lerpInt(value, from.g, to.g),
        b: this.lerpInt(value, from.b, to.b),
        a: this.lerpInt(value, from.a, to.a)
      }
    },

    /**
     * Return the object in the set provided nearest to the provided location
     * @param {Number} x The x component of the position
     * @param {Number} y The y component of the position
     * @param {Array<Object>} set The set in which search the nearest object
     * @param {function(Object):{x: number, y: number}} getterCallback The callback to use to retrieve the position
     *  of the objects in the set
     * @param {?(function(Object): boolean)=} filterCallback The callback to use to check if the object
     *  must be filtered or not
     * @returns {Number}
     */
    getNearestObject(x, y, set, getterCallback, filterCallback = null) {
      let nearestIndex = -1
      let minimumDistance = -1
      for (let index = 0; index < set.length; ++index) {
        const object = set[index]
        if (!filterCallback || filterCallback(object)) {
          const otherPosition = getterCallback(object)
          const distance = this.vectorDistance({x, y}, {x: otherPosition.x, y: otherPosition.y})
          if (nearestIndex < 0 || distance < minimumDistance) {
            minimumDistance = distance
            nearestIndex = index
          }
        }
      }

      return nearestIndex
    },

    /**
     * Return the object in the set provided nearest to the segments
     * @param {Number} x The x component of the position
     * @param {Number} y The y component of the position
     * @param {Array<Object>} set The set in which search the nearest object
     * @param {function(Object):Segment} getterCallback The callback to use to retrieve the segment
     *  of the objects in the set
     * @param {?(function(Object): boolean)=} filterCallback The callback to use to check if the object
     *  must be filtered or not
     * @returns {Number}
     */
    getObjectAbove(x, y, set, getterCallback, filterCallback = null) {
      let nearestIndex = -1
      let minimumDistance = -1
      for (let index = 0; index < set.length; ++index) {
        const object = set[index]
        if (!filterCallback || filterCallback(object)) {
          const segment = getterCallback(object)
          const distance = y - segment.getMinY()
          if (distance >= 0 && segment.getMinX() <= x && x <= segment.getMaxX() && (nearestIndex < 0 || distance < minimumDistance)) {
            minimumDistance = distance
            nearestIndex = index
          }
        }
      }

      return nearestIndex
    },

    /**
     * Return the variance among a set of vectors
     *
     * @param {Array<{ x: number, y: number, z: number}>} vv
     */
    dotProduct(v, u) {
      return v.x * u.x + v.y * u.y + v.z * u.z
    },

    /**
     * Return the variance among a set of vectors
     *
     * @param {Array<{ x: number, y: number}>} vv
     */
    variance(vv) {
      const avg = this.vectorsAvg(vv)
      let error = vv.reduce((acc, v) => acc + Math.pow(this.vectorDistance(v, avg), 2), 0)
      return {variance: Math.sqrt(error / vv.length) / this.vectorLength(avg), massCenter: avg}
    },

    /**
     * Return the sum between two vectors
     * @param {Array<{ x: number, y: number}>} v The first vectors
     * @returns {{ x: number, y: number}} The sum between the two vectors
     */
    vectorsAvg(vv, from = 0, to = vv.length) {
      let sum = {x: 0, y: 0}
      for (let i = from; i < to; ++i)
          sum = this.vectorAdd(sum, vv[i])
      let length = to - from
      return {x: sum.x / length, y: sum.y / length}
    },

    /**
     * Return the sum between two vectors
     * @param {{ x: number, y: number}} v The first vectors
     * @param {{ x: number, y: number}} u The second vectors
     * @returns {{ x: number, y: number}} The sum between the two vectors
     */
    vectorAdd(v, u) {
      return {x: v.x + u.x, y: v.y + u.y}
    },

    /**
     * Return the subtraction between two vectors
     * @param {{ x: number, y: number}} v The first vectors
     * @param {{ x: number, y: number}} u The second vectors
     * @returns {{ x: number, y: number}} The subtraction between the two vectors
     */
    vectorSub(v, u) {
      return {x: v.x - u.x, y: v.y - u.y}
    },

    /**
     * Return the distance between the two vectors
     * @param {{ x: number, y: number}} v The first vector
     * @param {{ x: number, y: number}} u The second vector
     * @returns {number} The distance between the two vectors
     */
    vectorDistance(v, u) {
      return this.vectorLength(this.vectorSub(v, u))
    },

    /**
     * Return the length of the given vector
     * @param {{ x: number, y: number}} v The vector for which compute the length
     * @returns {number} The length of the vector
     */
    vectorLength(v) {
      return Math.sqrt(v.x * v.x + v.y * v.y)
    },

    /**
     * Returns a number whose value is limited to the given range.
     *
     * Example: limit the output of this computation to between 0 and 255
     * clamp(x, 0, 255)
     *
     * @param {Number} value The value to be clamped
     * @param {Number} min   The lower boundary of the output range
     * @param {Number} max   The upper boundary of the output range
     * @returns {Number}     A number in the range [min, max]
     */
    clamp(value, min, max) {
      return Math.min(Math.max(value, min), max);
    }
  }
