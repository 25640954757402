<template>
    <div class="paginatedList">
        <div class="list" fill>
            <slot name="list" :pageItems="getItemsForPage(items, pagination.currentPage)"></slot>
        </div>
        <div class="paginationControls">
            <ch-pagination ref="pagination" v-model="pagination.currentPage" :max="getMaxPageNumber(items)"/>
        </div>
    </div>
</template>

<script>
import PaginationMixin from '../mixins/PaginationMixin'

export default {
    name: "PaginatedList",
    mixins: [ PaginationMixin ],
    props: {
        items: {
            default: [],
            type: Array,
        }
    },
}
</script>

<style scope>
    .paginatedList {
        position: relative;
        box-sizing: border-box;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        overflow: hidden;
    }
    .list { 
        box-sizing: border-box;
        margin-bottom: calc( var(--singleMargin) * 5 );
        overflow: auto;
    }
    .paginationControls {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        padding: var(--singleMargin);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
</style>