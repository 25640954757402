<template>
    <ch-dialog ref="dialog" type="primary" title="Add products" size="fullscreen" @cancel="close">
        <ch-application-layout slot="main">
            <sku-catalog slot="main" inDialog selectable @cancel="close" :selection.sync="selectedProducts"
                         :disabledProducts="disabledProducts.map(p => p.id)"/>
            <sku-right-side-bar slot="sidebar" :selection.sync="selectedProducts" @confirm="confirm" @cancel="close">
            </sku-right-side-bar>
        </ch-application-layout>
    </ch-dialog>
</template>

<script>
    import SkuCatalog      from './SkuCatalog';
    import SkuRightSideBar from './SkuRightSideBar';

    export default {
        name: 'ProductFinder',
        components: {SkuRightSideBar, SkuCatalog},
        props: {
            disabledProducts: Array
        },
        data() {
            return {
                selectedProducts: []
            };
        },
        methods: {
            open() {
                this.selectedProducts = [];
                this.$refs.dialog.open();
            },
            close() {
                this.selectedProducts = [];
                this.$refs.dialog.close();
            },
            confirm() {
                this.$emit('addProducts', this.selectedProducts.map(p => p.id));
                this.close();
            }
        }
    };
</script>

<style scoped>

</style>
