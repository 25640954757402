<template>
    <div y-spaced-3>
        <section v-for="([sectionName, sectionFilters]) in parsedCondition" :key="sectionName" y-spaced-1>
            <h1 primary-text>{{sectionName}}</h1>
            <ch-dl label-width="50%">
                <ch-dd v-for="[key, values] of sectionFilters" :label="key" :key="key" class="value">{{ valuesAsString(values)}}</ch-dd>
            </ch-dl>
        </section>
    </div>
</template>

<script>
import { CategorizationService } from '../../../../skucatalog/services/CategorizationService';
import { And, Contains, Or, Equal } from '../../model/group/Condition';
import { categorizationToFilters, DEFAULT_PRODUCT_DATA_FILTERS, getValuesForFilter } from './createproductgroup/filters/GroupProductFilters';
import * as _ from 'lodash';

    export default {
        name: "GroupConditionInfo",
        props: {
            group: Object,
            categorization: Object,
        },
        computed: {
            condition() { return this.group.condition; },
            categorizationMap() { return CategorizationService.CategorizationToMap(this.categorization)},
            categorizationFilters() { return categorizationToFilters(this.categorization) },
            categorizationFilterValues() {
                const values = this.categorizationFilters.flatMap( ([_,__, path]) => this.getValuesForFilter(this.condition, path, []))
                                                         .flatMap(p => CategorizationService.fromChildToParent(this.categorizationMap, p))
                                                         .map(({categoryKey, label}) => ({categoryKey, label}));    
                return _.groupBy( values, 'categoryKey');

            },
            productDataFilters()    { return DEFAULT_PRODUCT_DATA_FILTERS},
            parsedCondition() {
                return [
                    [ "Classification", this.categorizationFilters.map(([k, l, path]) => [l, _.uniq((this.categorizationFilterValues[k] || []).map(({label}) => label)) ])],
                    [ "Product Data",  this.productDataFilters.map(([_, l ,path]) =>    [l,this.getValuesForFilter (this.condition, path, [])])]
                ]
            }
        },
        methods: {
            getValuesForFilter(condition, path, results = []){
               return getValuesForFilter(condition, path, results);
            },
            valuesAsString(values) {
                return (!values || !values.length)    ? "---" :
                       values.length > 1 ? "Multiple values" :
                       `${values[0]}`;
            }
         }
    }
</script>

<style scoped>
    .value >>> span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
</style>