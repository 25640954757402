<template>
    <ch-application-layout>
        <div slot="header" class="header" fill>
            <ch-filters>
                <div x-spaced-1>
                    <ch-icon v-if="query.view === 'grid'" @click="query.view = 'table'" icon="list" size="18"/>
                    <ch-icon v-else @click="query.view = 'grid'" icon="view_module" size="18"/>
                    <span>Items per page</span>
                    <ch-select size="narrow small" :items="[10, 25, 50]" v-model="query.limit"/>
                    <span>{{totalItems}} placeholders</span>
                </div>
                <div>
                    <ch-pagination v-model="query.page" :max="totalPages"/>
                </div>
                <div>
                    <ch-search-bar v-model="query.matching" size="narrow"/>
                </div>
            </ch-filters>
        </div>
        <template slot="main">
            <div v-if="loading" fill-center>Loading placeholders, please wait...</div>
            <ch-empty-content v-else-if="totalMatches === 0 && query.matching"
                              firstMessage="No matches found"/>
            <ch-empty-content v-else-if="totalMatches === 0 && !query.matching" firstMessage="No placeholder has been assigned yet."/>
            <ch-grid-layout v-else-if="query.view === 'grid'" :width="300" :height="240" :gap="32"
                            class="placeholdersGrid">
                <content-card v-for="placeholder in placeholders"
                              :key="placeholder.id"
                              :placeholder="placeholder"
                              :disabled="disabled.some(p => p.id === placeholder.id)"
                              :selected="selected.some(p => p.id === placeholder.id)"
                              @click="onPlaceholderClick(placeholder)"/>
            </ch-grid-layout>
            <ch-table v-else :items="placeholders" :selectable="selectable ? p => p.id : null" :selected-rows="value"
                      @rowClicked="onPlaceholderClick" :allow-selection-on-entire-row="selectable" row-height="narrow"
                      class="placeholdersTable" :row-disabled="r => disabled.some(p => p.id === r.id)">
                <template slot="header">
                    <ch-th name="Placeholder"/>
                </template>
                <template slot-scope="{row}">
                    <ch-td>{{row.name}}</ch-td>
                </template>
            </ch-table>
            <placeholder-details ref="details"/>
        </template>
    </ch-application-layout>
</template>

<script>

    import StudioRequest      from "../../plugins/studioapi/requests/StudioRequest"
    import ContentCard        from "./ContentCard"
    import PlaceholderDetails from "./PlaceholderDetails"

    export default {
        name: "PlaceholdersCatalog",
        components: {
            PlaceholderDetails,
            ContentCard,
        },
        props: {
            query: {
                type: Object,
                default: () => ({
                    limit: 50,
                    page: 0,
                    matching: null,
                    view: 'grid'
                })
            },
            selectable: Boolean,
            value: {
                type: Array,
                default: () => []
            },
            disabled: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                loading: false,
                totalItems: 0,
                placeholders: [],
                selected: this.value
            }
        },
        computed: {
            totalPages() {
                return Math.max(Math.ceil(this.totalItems / this.query.limit), 1)
            },
            totalMatches(){
                return this.totalItems;
            }
        },
        mounted() {
            this.placeholders = []
            this.loadPlaceholders(false)
        },
        watch: {
            'query.limit'() {
                this.query.page = Math.min(this.totalPages, this.query.page);
                this.loadPlaceholders(false)
            },
            'query.page'() {
                this.loadPlaceholders(false)
            },
            'query.matching'() {
                this.loadPlaceholders(true)
            },
            value() {
                this.selected = this.value;
            },
            selected() {
                this.$emit('input', this.selected);
            }
        },
        methods: {
            loadPlaceholders(resetPageCount = true) {
                this.loading = true
                if (resetPageCount) {
                    this.totalItems = 0
                    this.query.page = 0
                }
                return new Promise((resolve, reject) => {
                    const payload = {
                        limit: this.query.limit,
                        offset: this.query.page * this.query.limit,
                        matching: this.query.matching
                    }
                    const request = new StudioRequest("listPlanogramPlaceholders", payload, (code, response) => {
                        if (code === 200) {
                            this.placeholders = response["planogramPlaceholderList"]
                            this.placeholders.forEach(p => p.type = 'PlanogramPlaceholder')
                            this.totalItems = response ["totalMatchesCount"]
                        } else
                            reject(code)
                        this.loading = false
                    })
                    window['studioApi'].sendRequest(request)
                })
            },
            onPlaceholderClick(placeholder) {
                if (this.selectable)
                    if (this.selected.some(p => p.id === placeholder.id))
                        this.selected = this.selected.filter(p => p.id !== placeholder.id)
                    else
                        this.selected.push(placeholder)
                else
                    this.$refs.details.open(placeholder)
            }
        }
    }
</script>

<style scoped>

    .header {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .placeholdersGrid {
        padding: var(--quadrupleMargin);
    }

    .placeholdersTable {
        box-sizing: border-box;
        padding: 0 var(--quadrupleMargin) var(--quadrupleMargin) var(--quadrupleMargin);
    }
</style>
