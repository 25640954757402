<template>
    <div class="productCard" @click="onClick" :disabled="disabled" :selected="selected">
        <div class="cardHeader">
            <div class="spacer"></div>
            <ch-icon v-if="product.webGLAvailable" icon="fas fa-cube"/>
        </div>
        <div class="cardBody">
            <ch-image class="productImage" :src="product.coverPhoto"/>
        </div>
        <div class="cardDescription">
            <div class="prodDescription">{{product.name}}</div>
        </div>
        <div class="cardFooter">
            <div class="prodEAN">{{product.ean}}</div>
            <div class="spacer"></div>
            <span class="photoCount">{{product.photoCount}}</span>
            <ch-icon icon="photo_camera" size="16"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProductCard',
        props: {
        	product: Object,
            selected: Boolean,
            disabled: Boolean
        },
        methods: {
            onClick() {
                if(!this.disabled)
                    this.$emit('click', this.product.id);
            }
        }
    }
</script>

<style scoped>

    .productCard {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr calc(100% - 88px) 1fr 1fr;
        grid-template-areas: "header" "body" "description" "footer";
        cursor: pointer;
    }

    .cardHeader {
        grid-area: header;
        display: flex;
    }

    .cardBody {
        box-sizing: border-box;
        grid-area: body;
        width: 100%;
        height: 100%;
        align-self: center;
        justify-self: center;
        pointer-events: none;
        user-select: none;
    }

    .cardDescription {
        grid-area: description;
        max-width: 100%;
        overflow: hidden;
        align-items: flex-end;
        display: flex;
    }

    .prodDescription {
        width: 100%;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        font-weight: 500;
        text-transform: lowercase;
    }

    .prodDescription:first-letter {
        text-transform: uppercase
    }

    .cardFooter {
        grid-area: footer;
        display: flex;
		align-items: flex-end;
		justify-content: space-between;
    }

    .prodEAN {
        flex: 1 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
    }

    .spacer {
        flex: 1 1 auto;
    }

    .photoCount {
        flex: 0 0 auto;
        font-weight: 500;
        font-size: 12px;
    }

    .productCard[selected]{
        background-color: var(--primary);
        color: var(--on-primary);
    }

    .productCard[disabled]{
        background-color: var(--disabled);
        color: var(--on-disabled);
    }

</style>
