<template>
  <ch-application-layout>
    <projects-navigation slot="navigation" />
    <template slot="version">ShelfZone Studio v. {{ version }}</template>
    <div slot="header" class="header" fill>
      <div class="actions">
        <ch-button @click="createProject" type="primary" justified>
          New project
          <ch-icon icon="add_circle_outline" />
        </ch-button>
        <ch-button
          class="downloadShelfZoneButton"
          @click="downloadLaunchZone"
          type="primary"
          size="fit"
          justified
        >
          Download ShelfZone
          <ch-icon icon="file_download" right />
        </ch-button>
      </div>
      <ch-filters>
        <div x-spaced-2>
          <ch-radio-group v-model="searchFilters" size="narrow small">
            <ch-radio-button label="all">All</ch-radio-button>
            <ch-radio-button label="research">Research</ch-radio-button>
            <ch-radio-button label="ready">Ready</ch-radio-button>
          </ch-radio-group>
          <h2>
            <template v-if="filteredProjects.length < projects.length">
              {{ filteredProjects.length }}/{{ projects.length }} projects
            </template>
            <template v-else>{{ projects.length }} projects</template>
          </h2>
        </div>
        <div></div>
        <div>
          <ch-search-bar v-model="search" realTime size="narrow" />
        </div>
      </ch-filters>
    </div>
        <template slot="main">
            <ch-table v-if="filteredProjects.length > 0" :items="filteredProjects" @rowClicked="goToProject"
                      row-height="thick" class="projectsTable" @rowContextMenu="showContextMenu">
                <template slot="header">
                    <ch-th width="74px"/>
                    <ch-th name="Project" id="name" sortable defaultOrder="ascending"/>
                    <ch-th name="Status"/>
                    <ch-th name="Last package update"/>
                    <ch-th/>
                </template>
                <template slot-scope="{ row }">
                    <ch-td :style="getTextColor(row)">
                        <ch-icon :icon="getIcon(row)" :size="32"/>
                    </ch-td>
                    <ch-td x-spaced-1>
                        <ch-icon v-if="row.hasField" icon="timeline" size="20"/>
                        <h2 class="projectName">{{row.name}}</h2>
                    </ch-td>
                    <ch-td x-spaced-1>
                        <template v-if="row.lastBuildFailed">
                            <ch-icon icon="warning" :size="18"/>
                            <span title="An error has occurred during VR package generation, we are aware of the problem and we are working on it">Error during package generation</span>
                        </template>
                        <template v-else>{{updateStatus(row)}}</template>
                    </ch-td>
                    <ch-td v-if="row.lastPackageDate" x-spaced-1>
                        <ch-icon icon="today"/>
                        <span>{{formatDateTime(row.lastPackageDate)}}</span></ch-td>
                    <ch-td v-else>None</ch-td>
                    <ch-td action x-spaced-1>
                        <ch-progress-bar v-if="currentDownloadingProjectId === row.id" class="downloadBar"
                                         :value="currentDownloadPercentage" text="Downloading..." :maxValue="1"
                                         type="primary"/>
                        <ch-context-menu :voices="buildProjectContextMenuVoices(row)">
                            <ch-button type="flat" icon>
                                <ch-icon icon="more_horiz"/>
                            </ch-button>
                        </ch-context-menu>
                    </ch-td>
                </template>
            </ch-table>
            <ch-empty-content v-else-if="projects.length > 0" firstMessage="No matching project with the given name"/>
            <ch-empty-content v-else firstMessage="You haven’t created any project yet," secondMessage="Create one!"
                              @opendialog="createProject"/>
            <new-project ref="newProjectDialog" @project-created="goToProject"/>
            <create-package ref="createPackageDialog"/>
            <outdated-package ref="outdatedPackageDialog"/>
            <create-field ref="newFieldDialog" @confirm="goToField($event.project.fieldId)"/>
            <delete-project ref="deleteProjectDialog"/>
	        <ch-context-menu ref="contextMenu"/>
        </template>
  </ch-application-layout>
</template>

<script>
import OutdatedPackage from "./dialog/DownloadOutdatedPackage";
import NewProject from "./dialog/NewProject";
import CreatePackage from "./dialog/CreatePackage";
import CreateField from "./dialog/CreateField";
import DeleteProject from "./dialog/DeleteProject";
import { Status } from "../plugins/studioapi/storage/Project";
import StringUtils from "../utils/StringUtils";
import ProjectsNavigation from "./ProjectsNavigation";
import StaticConfig from "../config.js";

    export default {
        name: "Projects",
        components: {
            ProjectsNavigation,
            NewProject,
            OutdatedPackage,
            CreatePackage,
            DeleteProject,
            CreateField,
        },
        props: {
            empty: Boolean
        },
        data() {
            return {
                projectStatus: Status,
                projects: this.$storage.projects,
                search: null,
                searchFilters: 'all'
            }
        },
        computed: {
            currentDownloadingProjectId() {
                return this.$projectDownloadManager.currentProjectIdDownload;
            },
            currentDownloadPercentage() {
                return this.$projectDownloadManager.currentProjectProgressDownload;
            },
            version() {
                return StaticConfig.version;
            },
            searchRegex() {
              try {
                  return new RegExp(this.search ? this.search : '', 'i');
              } catch (e) {
                  return new RegExp('', 'i');
              }
            },
            statusFilter() {
                switch (this.searchFilters) {
                    case 'all':
                        return p => true
                    case 'research':
                        return p => p.hasField
                    case 'ready':
                        return p => p.getState() === Status.packageAvailable
                    default:
                        return p => true
                }
            },
            filteredProjects() {
              return  this.projects.filter(project => project.name.match(this.searchRegex) && this.statusFilter(project));
            }
        },
        mounted() {
            document.title = 'Projects | ShelfZone Studio'
            this.$conn.onNotifyReceived.push(this.onNotifyReceived)
        },
        beforeDestroy() {
            this.$conn.onNotifyReceived.splice(this.$conn.onNotifyReceived.indexOf(this.onNotifyReceived), 1)
        },
        methods: {
            downloadLaunchZone() {
                const link = document.createElement("a");
                link.download = 'ShelfZone.exe'
                link.href = Config.launchZoneURI;
                link.click();
            },
            getIcon(project) {
                switch (project.getState()) {
                    case Status.noPackage:
                        if (project.lastBuildFailed)
                            return 'error'
                        return "radio_button_unchecked"
                    case Status.creatingPackage:
                    case Status.updatingPackage:
                        return "timelapse"
                    default:
                        if (project.lastBuildFailed)
                            return 'error'
                        return "check_circle"
                }
            },
            getTextColor(project) {
                switch (this.getIcon(project)) {
                    case 'error':
                        return 'color: var(--error)'
                    case 'radio_button_unchecked':
                        return 'color: var(--elevation-04)'
                    case 'timelapse':
                        return 'color: var(--alert)'
                    default:
                        return 'color: var(--primary)'
                }
            },
            updateStatus(project) {
                switch (project.getState()) {
                    case Status.noPackage:
                        return "No packages created yet"
                    case Status.creatingPackage:
                        return "Package in progress..."
                    case Status.packageAvailable:
                        return "VR package ready"
                    case Status.updatingPackage:
                        return "Updating Package"
                }
            },
	        showContextMenu(event, row) {
		        this.$refs.contextMenu.showMenu(event, this.buildProjectContextMenuVoices(row));
	        },
            createProject() {
                this.$refs.newProjectDialog.open()
            },
            createPackage(project) {
                this.$refs.createPackageDialog.open(project)
            },
            createField(project) {
                this.$refs.newFieldDialog.open(project)
            },
            viewField(fieldId) {
                if (fieldId != null)
                    this.$router.push({name: "cells", params: {fieldId: fieldId}})
            },
            goToField(fieldId) {
                this.viewField(fieldId);
            },
            downloadPackage(project) {
                const downloadProjectWithoutField = (project) => {
                    if (project.getState() === this.projectStatus.updatingPackage)
                      this.$refs.outdatedPackageDialog.open(project)
                    else {
                      this.$projectDownloadManager.downloadProject(project)
                    }
                }
                const downloadProjectWithField = ( project, field) => {
                    if (project.hasField && new Date(field.creationDate).getTime() > new Date(project.lastPackageDate).getTime()) {
                        this.$refs.outdatedPackageDialog.open(project, false)
                    } else {
                        downloadProjectWithoutField(project);
                    }
                }
                
                this.$storage.findFieldById(project.fieldId)
                    .then( 
                        field => downloadProjectWithField(project, field), 
                        () => downloadProjectWithoutField(project)
                    );
            },
            goToProject(project) {
                this.$router.push({name: "projectDetails", params: {projectId: project.id}})
            },
            update(project) {
                this.$refs.createPackageDialog.open(project, true)
            },
            deleteProject(project) {
                this.$refs.deleteProjectDialog.open(project)
            },
            getConfigurationNameForProject(project) {
                const configurations = this.$storage.getAllConfigurations()
                if (project.configurations[0]) {
                    const configuration = configurations.find(e => e.id === project.configurations[0].id)
                    if (configuration)
                        return configuration.name
                }
                return "n/a"
            },
            onNotifyReceived(notifyData) {
                this.$forceUpdate()
            },
    formatDateTime(isoDate) {
      return StringUtils.formattedDateTimeFromISO(isoDate);
    },
    buildProjectContextMenuVoices(project) {
      const fieldVoices = project.hasField
        ? [
            {
              text: "View field",
              icon: "timeline",
              action: () => this.viewField(project.fieldId),
            },
          ]
        : [
            {
              text: "Create field",
              icon: "timeline",
              disabled: project.buildInProgress,
              action: () => this.createField(project),
            },
          ];
      const packageStatusVoices =
        project.getState() === this.projectStatus.noPackage
          ? [
              {
                text: "Create package",
                icon: "add_circle_outline",
                action: () => this.createPackage(project),
              },
            ]
          : !project.buildInProgress &&
            project.getState() !== this.projectStatus.noPackage
          ? [
              {
                text: "Update package",
                icon: "update",
                action: () => this.update(project),
              },
            ]
          : [];
      const downloadPackageVoices =
        project.getState() !== this.projectStatus.noPackage &&
        project.id !== this.currentDownloadingProjectId &&
        project.getState() !== this.projectStatus.creatingPackage
          ? [
              {
                text: "Package",
                icon: "file_download",
                disabled: !!this.currentDownloadingProjectId,
                action: () => this.downloadPackage(project),
              },
            ]
          : [];
      const archiveProjectVoice = {
        text: "Delete",
        icon: "delete",
        action: () => this.deleteProject(project),
      };
      return [
        ...packageStatusVoices,
        ...downloadPackageVoices,
        ...fieldVoices,
        archiveProjectVoice,
      ];
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.downloadShelfZoneButton {
  --primary: #3966ff;
  --on-primary: #f0f5f7;
  --primary-hover: #7eabff;
  --on-primary-hover: #0d161e;
}

.downloadBar {
  max-width: 160px;
  display: inline-flex;
}

.projectName {
  display: inline;
}

.projectsTable {
  padding: 0 var(--quadrupleMargin) var(--quadrupleMargin)
    var(--quadrupleMargin);
  box-sizing: border-box;
}
</style>
