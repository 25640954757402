<template>
    <ch-access-layout>
        <div class="logo" slot="logo"></div>
        <p>Your password will expire on {{expirationDate | formatDate}}.</p>
        <p>Please change your password.</p>
        <div slot="footer" class="actions" x-spaced-2>
            <ch-button @click="changePassword" size="fill">Change password</ch-button>
            <ch-button @click="proceed" size="fill" type="secondary">Continue</ch-button>
        </div>
    </ch-access-layout>
</template>

<script>
    import StringUtils from '../utils/StringUtils';

    export default {
        name: 'PasswordExpireSoon',
        props: {
            expirationTime: String,
            redirect: String,
            identityKey: String
        },
        computed: {
            expirationDate() {
              return new Date(Number.parseInt(this.expirationTime));
            }
        },
        filters: {
            formatDate(date) {
                return StringUtils.formattedDateFromISO(date.toISOString());
            }
        },
        methods: {
            changePassword() {
                this.$router.push({name: 'changePassword'});
            },
            proceed() {
                window['studioApi'].initializeWebSocket();
                this.$loading.await(
                    this.$storage
                        .init(this.identityKey)
                        .then(() => {
                            this.$store.commit('login');
                            if (this.redirect)
                                this.$router.push(this.redirect);
                            else
                                this.$router.push({name: 'projects'});
                        })
                );
            }
        }
    };
</script>

<style scoped>

    .logo {
        background-image: url("../assets/logo.png");
        background-size: 210px;
    }

    .actions {
        display: flex;
        width: 100%;
    }

</style>
