<template>
  <ch-application-layout>
    <left-side-bar
      slot="navigation"
      :categorization="categorization"
      :showOnlyCategoryTree="!selectable"
      :inDialog="inDialog"
      @searchingByEAN="(active) => (exportEnabled = !active)"
      @categorySelected="selectCategory"
      @searchByEANList="loadPastedSKUs"
    >
    </left-side-bar>
    <!-- The version could stay when the sku catalog is moved to another repository-->
    <template v-if="!inDialog" slot="version"
      >ShelfZone Studio v. {{ version }}</template
    >
    <div v-if="currentMenu === 0" slot="header" class="header" fill>
      <div class="topFilters">
        <ch-search-bar v-model="searchString" @input="onSearch" />
        <ch-button
          v-if="exportEnabled"
          type="primary"
          size="small narrow"
          @click="exportCatalog"
          >Export
        </ch-button>
      </div>
      <ch-filters>
        <div x-spaced-1>
          <ch-icon
            v-if="gridView"
            @click="toggleGridView"
            icon="list"
            size="18"
          />
          <ch-icon
            v-else
            @click="toggleGridView"
            icon="view_module"
            size="18"
          />
          <label>Items per page</label>
          <ch-select
            size="narrow small"
            :items="[10, 25, 50]"
            v-model="pagingSize"
          />
          <div>{{ totalItems }} products</div>
        </div>
        <div>
          <ch-pagination v-model="currentPage" :max="totalPages" />
        </div>
        <div>
          <ch-checkbox-group
            :value="filterState"
            @input="onToggleChange"
            size="narrow small"
            type="primary"
          >
            <ch-checkbox-button :label="0" justified
              >Web 3D
              <ch-icon icon="fas fa-cube" size="12" />
            </ch-checkbox-button>
            <ch-checkbox-button :label="1" justified
              >2D
              <ch-icon icon="insert_photo" size="12" />
            </ch-checkbox-button>
          </ch-checkbox-group>
        </div>
      </ch-filters>
    </div>
    <div v-else class="header" slot="header" fill>
      <h1>Add products from EAN</h1>
      <ch-filters v-if="productsList.length > 0">
        <div>
          <ch-icon icon="list" size="18" @click="toggleGridView" />
        </div>
        <div></div>
        <div x-spaced-1>
          <div>{{ productsList.length }} products</div>
          <ch-button
            v-if="areAllSelected"
            @click="deselectPastedProducts"
            size="narrow"
            type="secondary"
          >
            Deselect All
          </ch-button>
          <ch-button
            v-else
            @click="selectPastedProducts"
            size="narrow"
            type="secondary"
            >Select All
          </ch-button>
        </div>
      </ch-filters>
    </div>
    <template slot="main">
      <div v-if="loadingProducts" fill-center>
        Loading products, please wait...
      </div>
      <ch-empty-content
        v-else-if="productsList.length === 0"
        firstMessage="There are no products matching the selected filters."
      />
      <ch-grid-layout
        v-else-if="gridView"
        :width="160"
        :height="200"
        :gap="16"
        class="productsGrid"
      >
        <product-card
          v-for="product of productsList"
          :key="product.id"
          :selected="selection.some((e) => e.id === product.id)"
          :disabled="disabledProducts.includes(product.id)"
          :product="product"
          @click="selectProduct"
        >
        </product-card>
      </ch-grid-layout>
      <ch-table
        v-else
        :items="productsList"
        :allow-selection-on-entire-row="selectable"
        :rowDisabled="isProductPresent"
        :selectedRows="selection"
        @update:selectedRows="updateSelection"
        :selectable="selectable ? (p) => p.id : false"
        @rowClicked="(p) => selectProduct(p.id)"
        class="productsTable"
      >
        <template slot="header">
          <ch-th width="62px" />
          <ch-th
            name="EAN"
            width="140px"
            id="ean"
            sortable
            defaultOrder="ascending"
          />
          <ch-th width="100%" name="Description" />
          <ch-th name="2D" width="56px" />
          <ch-th name="3D" width="38px" />
        </template>
        <template slot-scope="{ row }">
          <ch-td>
            <div class="listImage">
              <ch-image :src="row.coverPhoto" />
            </div>
          </ch-td>
          <ch-td>{{ row.ean }}</ch-td>
          <ch-td ellipsis>{{ row.name }}</ch-td>
          <ch-td>
            <ch-icon v-if="row.photosCount > 0" icon="image" size="22" />
          </ch-td>
          <ch-td>
            <ch-icon v-if="row.webGLAvailable" icon="fas fa-cube" size="16" />
          </ch-td>
        </template>
      </ch-table>
      <export-catalog-dialog
        ref="exportCatalogDialog"
        @openExportProgress="exportProgress"
      />
      <export-catalog-progress-dialog ref="exportProgressDialog" />
      <product-details ref="productDetails" />
    </template>
  </ch-application-layout>
</template>

<script>
/* import */
import StudioRequest from "../plugins/studioapi/requests/StudioRequest";
import SKU from "../plugins/studioapi/storage/SKU";

import ProductCard from "./SkuProductCard";
import ProductDetails from "./ProductDetails";
import LeftSideBar from "./SkuLeftSideBar";
import RightSideBar from "./SkuRightSideBar";
import StaticConfig from "../config.js";
import ExportCatalogDialog from "./ExportCatalogDialog";
import ExportCatalogProgressDialog from "./ExportCatalogProgressDialog";
/**/

export default {
  name: "SKUCatalog",
  components: {
    ExportCatalogProgressDialog,
    ExportCatalogDialog,
    ProductCard,
    ProductDetails,
    LeftSideBar,
    RightSideBar,
  },
  props: {
    selectable: Boolean,
    //TODO Remove the inDialog e create a page that contains the catalog
    inDialog: Boolean,
    search: { type: String, default: "" },
    paging: { type: String, default: "50" },
    page: { type: String, default: "0" },
    webgl: { type: String, default: "false" },
    photos: { type: String, default: "false" },
    view: { type: String, default: "grid" },
    category: { type: String, default: "" },
    selection: { type: Array, default: () => [] },
    disabledProducts: { type: Array, default: () => [] },
  },
  data() {
    const pageSize = Number(this.paging) | 50;
    const currPage = (Number(this.page) | 0) + 1;
    const itemCount = pageSize * currPage;
    return {
      categorization: null,
      productsList: [],
      gridView: this.view === "grid",
      currentMenu: 0,
      loadingProducts: false,
      menuPath: [],
      searchString: this.search,
      withWebGL: this.webgl === "true",
      withPhoto: this.photos === "true",
      pagingSize: pageSize,
      currentPage: currPage,
      totalItems: itemCount,
      exportEnabled: true,
    };
  },
  mounted() {
    document.title = "SKUs Catalog | ShelfZone Studio";
    this.productsList = [];
    if (this.inDialog) this.menuPath = [];
    this.$studio
      .findMostRecentOwnCategorization()
      .then((r) => {
        this.categorization = r.foundCategorization;
        return this.$productBank.countProductsByCategory(
          this.categorization.id,
          this.categorization.revision
        );
      })
      .then((r) => {
        const root = { categories: this.categorization.tree };
        for (const category in r.count) {
          const levelIds = category.split(",");
          const categoryNode = levelIds.reduce(
            (node, id) => node.categories.find((c) => c.id === id),
            root
          );
          categoryNode.productsCount = r.count[category];
        }
        const productsCountComputer = (node) => {
          node.productsCount = node.productsCount || 0;
          if (!node.categories) return;
          node.categories.forEach(productsCountComputer);
          node.productsCount += node.categories.reduce(
            (sum, category) => sum + category.productsCount,
            0
          );
          node.categories = node.categories.filter(
            (category) => category.productsCount > 0
          );
        };
        productsCountComputer(root);
        this.categorization.tree = root.categories;
      });
  },
  computed: {
    version() {
      return StaticConfig.version;
    },
    filterState() {
      const result = [];
      if (this.withWebGL) result.push(0);
      if (this.withPhoto) result.push(1);
      return result;
    },
    totalPages() {
      return Math.max(Math.ceil(this.totalItems / this.pagingSize), 1);
    },
    areAllSelected() {
      return this.productsList.every((product) =>
        this.selection.find((p) => p.id === product.id)
      );
    },
  },
  watch: {
    pagingSize() {
      if (this.currentPage >= this.totalPages - 1) {
        this.currentPage = this.totalPages - 1;
      } else {
        this.loadSKUs(false);
      }
    },
    currentPage() {
      this.loadSKUs(false);
    },
    category() {
      const categories = this.categorization.tree;
      this.menuPath = this.category
        .split(",")
        .filter((t) => t)
        .reduce(
          (acc, categoryId, i) => [
            ...acc,
            i === 0
              ? categories.find((category) => category.id === categoryId)
              : acc[acc.length - 1].categories.find(
                  (child) => child.id === categoryId
                ),
          ],
          []
        );
      this.loadSKUs();
    },
  },
  methods: {
    selectCategory(category) {
      this.menuPath = category;
      this.loadSKUs();
    },
    loadSKUs(resetPageCount = true) {
      this.loadingProducts = true;
      if (resetPageCount) {
        this.totalItems = 0;
        this.currentPage = 0;
      }

      const category =
        this.menuPath.length === 0
          ? null
          : {
              id: this.categorization.id,
              revision: this.categorization.revision,
              category: this.menuPath.map((c) => c.id),
            };

      this.$productBank
        .listProducts(
          this.pagingSize,
          this.currentPage * this.pagingSize,
          this.withWebGL,
          this.withPhoto,
          this.searchString,
          category
        )
        .then((response) => {
          this.productsList = [];
          response.productsSummaries.forEach((product) => {
            this.productsList.push(new SKU(product));
          });
          this.totalItems = response.totalMatchesCount;
        })
        .finally(() => (this.loadingProducts = false));

      this.updateURL();
    },
    loadPastedSKUs(eanList) {
      return new Promise((resolve, reject) => {
        this.loadingProducts = true;
        this.totalItems = 0;
        this.currentPage = 0;
        this.productsList = [];
        const request = new StudioRequest(
          "findProductsByEAN",
          { eans: eanList },
          (code, response) => {
            if (code === 200) {
              this.productsList = [];
              const excludedProducts = this.selection
                .map((p) => p.id)
                .concat(this.disabledProducts);
              const newProductsToSelect = response["productsSummaries"]
                .map((product) => {
                  const newSku = new SKU(product);
                  this.productsList.push(newSku);
                  return newSku;
                })
                .filter((product) => !excludedProducts.includes(product.id));
              this.$emit("update:selection", [
                ...this.selection,
                ...newProductsToSelect,
              ]);
              this.totalItems = response["totalMatchesCount"];
              resolve();
            } else {
              reject(code);
            }
            this.loadingProducts = false;
          }
        );
        window["studioApi"].sendRequest(request);
      });
    },
    toggleGridView() {
      this.gridView = !this.gridView;
      this.updateURL();
    },
    selectProduct(id) {
      const product = this.productsList.filter((e) => e.id === id)[0];
      if (product) {
        if (this.selectable) {
          const selected = this.selection.find((e) => e.id === product.id);
          if (selected) this.removeSku(selected);
          else this.addSku(product);
        } else {
          this.$productBank
            .findProductById(product.id)
            .then((r) =>
              this.$refs.productDetails.open(r.product, this.categorization)
            );
        }
      } else console.error("EAN not found.");
    },
    addSku(sku) {
      this.updateSelection([...this.selection, sku]);
    },
    removeSku(sku) {
      this.updateSelection(this.selection.filter((s) => s !== sku));
    },
    updateSelection(selection) {
      this.$emit("update:selection", selection);
    },
    deselectPastedProducts() {
      this.productsList.forEach((sku) => this.removeSku(sku));
    },
    selectPastedProducts() {
      this.productsList.forEach((sku) => this.addSku(sku));
    },
    addProducts(eanList) {
      if (this.selectable) {
        if (eanList.length > 0) this.updateSelection(eanList);
      }
    },
    onSearch(ev) {
      this.loadSKUs();
    },
    onToggleChange(v) {
      this.withWebGL = v.includes(0);
      this.withPhoto = v.includes(1);
      this.loadSKUs();
    },
    updateURL() {
      if (!this.inDialog) {
        this.$router.push({
          name: "sku",
          query: {
            search: this.searchString,
            paging: this.pagingSize.toString(),
            page: this.currentPage.toString(),
            webgl: this.withWebGL ? "true" : "false",
            photos: this.withPhoto ? "true" : "false",
            view: this.gridView ? "grid" : "list",
            category: this.$route.query.category,
          },
        });
      }
    },
    isProductPresent(row) {
      return this.disabledProducts.includes(row.id);
    },
    exportCatalog() {
      this.$refs.exportCatalogDialog.open();
    },
    exportProgress(settings) {
      const category =
        this.menuPath.length === 0
          ? null
          : {
              id: this.categorization.id,
              revision: this.categorization.revision,
              category: this.menuPath.map((c) => c.id),
            };
      this.$refs.exportProgressDialog.open(settings, {
        categorization: this.categorization,
        withWebGL: this.withWebGL,
        withPhoto: this.withPhoto,
        searchString: this.searchString,
        category,
      });
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.topFilters {
  align-self: flex-end;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.listImage {
  border-radius: 4px;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
}

.productsGrid {
  padding: var(--quadrupleMargin);
}

.productsTable {
  box-sizing: border-box;
  padding: 0 var(--quadrupleMargin) var(--quadrupleMargin)
    var(--quadrupleMargin);
}
</style>


