import { render, staticRenderFns } from "./SkuLeftSideBar.vue?vue&type=template&id=15956c12&scoped=true&"
import script from "./SkuLeftSideBar.vue?vue&type=script&lang=js&"
export * from "./SkuLeftSideBar.vue?vue&type=script&lang=js&"
import style0 from "./SkuLeftSideBar.vue?vue&type=style&index=0&id=15956c12&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15956c12",
  null
  
)

export default component.exports