<template>
    <div>
        <ch-button @click="replaceProducts" type="primary" size="fill" justified>Replace product
            <ch-icon icon="sync"/>
        </ch-button>
        <replace-products-dialog ref="replaceProductsDialog" :repository="repository"/>
    </div>
</template>

<script>
    import {PlanogramService}    from '../../../services/PlanogramService';
    import ReplaceProductsDialog from '../../dialog/ReplaceProductsDialog';
    import Repository            from '../../../services/Repository';

    export default {
        name: 'ReplaceProductsButton',
        components: {ReplaceProductsDialog},
        props: {
            repository: Repository,
            productIds: Array
        },
        methods: {
            replaceProducts() {
                const products = this.productIds
                    .filter((id, i) => this.productIds.indexOf(id) === i)
                    .map(id => PlanogramService.findProductWithId(this.repository.planogram, id))
                    .filter(p => p.nonEmpty())
                    .map(p => p.get());
                this.$refs.replaceProductsDialog.open(this.repository.categorization, products, this.repository.planogram.productSet);
            }
        }
    };
</script>

<style scoped>

</style>