<template>
    <ch-access-layout>
        <div class="logo" slot="logo"></div>
        <div>
            <p>Your account has been disabled.</p>
            <p>Contact you administrator.</p>
        </div>
        <div slot="footer">
            <ch-button @click="goToLogin">Go to login</ch-button>
        </div>
    </ch-access-layout>
</template>

<script>
    export default {
        name: 'AccountDisabled',
        methods: {
            goToLogin() {
                this.$router.push({name: 'login'});
            }
        }
    };
</script>

<style scoped>

    .logo {
        background-image: url("../assets/logo.png");
        background-size: 210px;
    }

</style>
