<template>
    <div v-if="field && cell" slot="main" class="analysisWrapper">
        <div class="analysisHeader">
            <div class="filters">
                <div class="fieldName" x-spaced-2>
                    <ch-icon @click="onBack()" icon="arrow_back" :size="30"/>
                    <h1>{{field.name}}</h1>
                </div>
                <ch-select id="cellSelector" size="narrow large" :items="cellsSelect" :value="cellId" @input="changeCell"/>
                <div class="analysisSettings" x-spaced-2>
                    <template v-if="!videoMode">
                        <ch-select size="narrow large"
                                   :disabled="videoMode"
                                   :items="plotsSelect"
                                   :value="plotType"
                                   @input="plotType = $event"/>
                        <div class="plotControls" x-spaced-1>
                                <div>
                                    <ch-toggle class="plotToggle" v-model="plotActive" :disabled="videoMode"></ch-toggle>
                                </div>
                                <ch-slider v-model="plotOpacity" :disabled="videoMode || !plotActive " type="primary"/>
                        </div>
                    </template>
                    <div class="uponRightSidebar">
                        <ch-button v-if="!videoMode" size="small narrow" class="primaryColors"
                            :type="areaSelectionActive ? 'primary': 'default'" 
                            @click="areaSelectionActive = !areaSelectionActive"
                            :disabled="videoMode"
                            justified>Area<ch-icon icon="fas fa-object-group"/></ch-button>
                        <ch-button size="small narrow" type="primary"  @click="openDownloadDataDialog" justified>Download data<ch-icon icon="get_app"/></ch-button>
                    </div>
                </div>
            </div>
        </div>
        <ch-application-layout>
            <div slot="navigation" class="sideBar hidden">
                <div class="recordings primaryColors">
                    <div class="recordingsHeader" y-spaced-2>
                        <h1 primary-text>Respondents</h1>
                        <ch-search-bar v-model="searchQuery" real-time size="fill narrow"/>
                        <div class="recordingsActions" x-spaced-1_2>
                            <tag-select showReset
                                        placeholder="Select filter"
                                        placeholderColor="--elevation-01"
                                        resetText="Reset filters"
                                        notFoundText="No results"
                                        notFoundTextColor="--on-disabled"
                                        :list="fieldTags"
                                        :tagIds.sync="activeTagsList" />
                            <ch-button type="primary" @click="toggleAllRecordings(!canWeDeselectRecordings)" size="narrow fill" :disabled="videoMode">
                                {{ canWeDeselectRecordings ? "Deselect All" : `Select all (${sortedRecordings.length})` }}
                            </ch-button>
                        </div>
                        <div v-if="filterTagsList.length" class="activeTagsList" x-spaced-1 y-spaced-1>
                            <template  v-for="id of filterTagsList" >
                                <tag-chip :key="id" :tag="field.tags.get(id)" removable @remove="removeTagFromFilter($event)"/>
                            </template>
                        </div>
                    </div>
                    <span class="recordingsCounter">{{activeRecordingsForList.length}} selected</span>
                    <paginated-list :items="sortedRecordings" :itemsPerPage="30">
                        <template #list={pageItems}>
                            <ch-list v-if="pageItems.length" class="recordingsList" type="flat"
                                    :selectable="videoMode ? 'single' : 'multiple'"
                                    v-model="activeRecordingsForList">
                                <ch-list-item v-for="recording of pageItems" :value="recording.id"
                                            :disabled="videoMode && !recording.hasVideo() " :key="recording.id">
                                    <template #selectionIcon>
                                        <ch-indicator :color="recordingColors[recording.id]"/>
                                    </template>
                                    {{recording.subject}}
                                    <template #actions>
                                        <tag-selection :field="field" :recording="recording" />
                                    </template>
                                </ch-list-item>
                            </ch-list>
                            <ch-empty-content  v-else firstMessage="There are no recordings for this cell" />
                        </template>
                    </paginated-list>
                </div>
            </div>
            <div slot="body" class="analysisBody">
                <div class="analysisButtons" x-spaced-2>
                    <ch-button :type="activeAnalysisType === 'Camera' ? 'default' : 'overlay'"
                               @click="showAnalysisCameras" :disabled="cameraList.length === 0" justified>Cameras
                        <ch-icon icon="videocam"/>
                    </ch-button>
                    <ch-button :type="activeAnalysisType === 'Map' ? 'default' : 'overlay'" size="small"
                               @click="setAnalysisTypeMap" justified>Map
                        <ch-icon icon="map"/>
                    </ch-button>
                    <ch-tooltip type="overlay"
                                text="Videos are available only with one respondent selected">
                        <div>
                            <ch-button :type="videoMode ? 'default' : 'overlay'" class="analysisTypeButton"
                                       size="small"
                                       @click="setAnalysisTypeVideo" :disabled="!canWeShowVideo" justified>Video
                                <ch-icon icon="ondemand_video"/>
                            </ch-button>
                        </div>
                    </ch-tooltip>
                </div>
                <div class="analysisZoom" x-spaced-2>
                    <zoom-control ref="zoom"
                        :max="zoomSettings.max"
                        :step="zoomSettings.step"
                        :min="zoomSettings.min"
                        :value="zoomValue"
                        :disabled="videoMode"
                        @change="onZoomChange"
                    />
                </div>
                <ch-elevation class="analysisCameras" v-if="isCameraAnalysisSectionOpen" y-spaced-2>
                    <div class="header">
                        <h1>Select active camera</h1>
                        <ch-button icon @click="hideAnalysisCameras">
                            <ch-icon icon="close"/>
                        </ch-button>
                    </div>
                    <div class="areasOfInterest">
                        <ch-grid-layout :width="360" :height="240" :gap="24">
                            <div v-for="camera in cameraList" @click="goToAnalysis(camera.id), hideAnalysisCameras()"
                                 :active="currentCameraId === camera.id" :key="camera.id" class="areaOfInterest" y-spaced-1>
                                <div :style="`background-image: url(${camera.image.src})`" class="cameraImage"></div>
                                <h3>{{camera.name}}</h3>
                            </div>
                        </ch-grid-layout>
                    </div>
                </ch-elevation>
                <div class="displayArea">
                    <VideoAnalysis v-if="videoMode"
                                   :field="field"
                                   :studioId="field.id"
                                   :cellId="cell.id"
                                   :activeRecordings="activeRecordings[0]"
                                   :currentCamera="currentCameraId"
                                   :currentVideoTime.sync="currentVideoTime"
                                   :recordLength="maxLength"
                                   :onVideoMissing="videoMissing"
                                   :selectedSpeed.sync="selectedSpeed"
                                   ref="videoPlayer"/>
                    <shelf-heatmap class="analysisSetArea"
                                   v-if="!videoMode"
                                   :zoom="zoom"
                                   :zoomStep="zoomSettings.step"
                                   :field="field"
                                   :fieldId="fieldId"
                                   :cellId="cellId"
                                   :activeRecordings="activeRecordings"
                                   :currentCamera="currentCameraId"
                                   :opacity="plotOpacity"
                                   :recordingsStyles="recordingColors"
                                   :plotType="!plotActive ? 'None': plotType"
                                   :analysisType="activeAnalysisType"
                                   :timeframe="timeframe"
                                   :overlaid="videoMode"
                                   :class="{overlaid : videoMode}"
                                   :areaSelectionMode="!videoMode &&  areaSelectionActive"
                                   :currentSelectedArea = "areaSelectionArea"
                                   @areaUpdated = "areaSelectionArea = $event"
                                   @updateZoom = "onZoomUpdate"
                                   @updateMinZoom = 'onMinZoomUpdate'
                                   ref="heatmap"/>
                </div>
                <div class="timelineWrapper" v-show="!videoMode && Boolean(activeRecordings.length)">
                    <audio :src="audioURL" ref="audioPlayer" @canplay="onAudioLoaded"></audio>
                    <timeline class="timelineStyle" ref='timeline'
                              :class="activeRecordings.length === 0 ? 'hidden' : ''"
                              :hidden="activeRecordings.length === 0"
                              :showCursor="activeRecordings.length <= 1"
                              :showControls="activeRecordings.length <= 1"
                              :timespan.sync="timeframe"
                              timeline-label=""
                              :selectedSpeed.sync="selectedSpeed"
                              :recordLength="maxLength">
                        <template #events>
                            <div v-if="selectedProduct && activeRecordings.length === 1" class="eventsBar">
                                <ch-tooltip v-for="(event,i) of selectedProductEvents"
                                    class="event"
                                    position="top"
                                    type="overlay"
                                    :text="event.decoration.name"
                                    :key="selectedProduct.id+'__'+i+'__'+event.event+'__'+event.timestamp" 
                                    :style="{ 'margin-left': nanoSecToPercents(event.timestamp, maxLength)}">
                                    <ch-button icon :type="selectedProduct.performances.state.decoration.type"><ch-icon :icon="event.decoration.icon"/></ch-button>
                                </ch-tooltip>
                            </div>
                        </template>
                    </timeline>

                </div>
            </div>
            <analysis-right-sidebar v-if="isProductsSidebarVisible" slot="sidebar" class="sideBar" fill 
                                    :field = "field"
                                    :recordings = "activeRecordings"
                                    :product.sync = "selectedProduct"
                                    @open-product-details = "openProductDetail"
                                    @selected-product = "({product, events}) => { selectedProduct = product; selectedProductEvents = events; }"
            />
        </ch-application-layout>
        <download-data-dialog ref="downloadDataDialog"
                              :field="field"
                              :selectedCellId="cellId"
                              :selectedCameraId="currentCameraId"
                              :cameras="cameraList"
                              :recordings="recordings"
                              :selectedRecordings="activeRecordings"
                              :timerange="timeframe"
                              :areas="areaSelection"/>
        <product-detail  ref="productDetail"/>
    </div>
</template>

<script>

    import Timeline from '../components/Timeline'
    import VideoAnalysis from '../research/VideoAnalysis'
    import ShelfHeatmap from '../research/ShelfHeatmap'
    import DownloadDataDialog from './dialogs/DownloadData'
    import ProductDetail from './dialogs/ProductDetail'
    import ZoomControl from './components/ZoomControl'
    import TagSelection from './components/tags/TagSelection';
    import TagChip      from './components/tags/TagChip';
    import TagSelect    from './components/tags/TagSelect';
    import PaginatedList from './components/PaginatedList.vue'
    import AnalysisRightSidebar from './components/sidebars/RightSidebar';
    import { plots, createRecordingsStyles, isSubstring } from './analysis-helpers';


    export default {
        name: "Analysis",
        components: {
            ShelfHeatmap,
            VideoAnalysis,
            Timeline,
            DownloadDataDialog,
            ProductDetail,
            ZoomControl,
            TagSelection,
            TagChip,
            TagSelect,
            PaginatedList,
            AnalysisRightSidebar,
        },
        props: {
            fieldId: String,
            cellId: String,
            activeRecordingId: String
        },
        data() {
            return {
                organizationId: this.$storage.user.organizationId,
                field: null,
                cell: null,
                cameraList: [],
                recordings: [],
                searchQuery: '',
                activeTagsListByCell: {},
                activeRecordingsByCell: {},
                activeAnalysisType: 'Camera',
                isCameraAnalysisSectionOpen: false,
                plotType: 'Heatmap',
                selectedSpeed: 3,
                currentVideoTime: 0,
                timeframe: {start: 0, end: 0 },
                audioURL: '',
                currentCameraId: null,

                selectedProduct: null,
                selectedProductEvents: [],
                plotsInfo : plots.reduce((acc, [key, _]) => ({ ...acc,
                    [key]: {
                        active: true,
                        opacity: 75
                    }
                }), {}),
                areaSelection: {},
                zoomSettings: {
                    min: 0,
                    max: 500,
                    step: 10
                },
                zoomValue: 100, // percents
                zoomOpts: null,
            }
        },
        computed: {
            videoMode() {
                return this.activeAnalysisType === 'Video'
            },
            mapMode() {
                return this.activeAnalysisType === 'Map'
            },
            cellsSelect() {
                return this.field.cells.map(c => ({key: c.id, label: c.name}))
            },
            cellsNames() {
                return this.field.cells.reduce((acc, c) => ({
                    ...acc,
                    [c.id]: c.name
                }), {})
            },
            plots() { return plots.map(([key, nameFn]) => [key, nameFn(this.activeAnalysisType !== 'Map')]) },
            plotsSelect() { return this.plots.map(([key, label]) => ({key, label})) },
            plotOpacity: {
                get() {
                    return this.plotsInfo[this.plotType].opacity;
                },
                set(opacity) {
                    this.plotsInfo[this.plotType].opacity = opacity
                }
            },
            areaSelectionActive: {
                get() {
                    return !this.videoMode && this.areaSelection[this.cellId].active;
                },
                set(active) {
                    this.areaSelection[this.cellId].active = !this.videoMode && active;
                   this.areaSelection = {...this.areaSelection }
                }
            },
            areaSelectionArea: {
                get() {
                    const areaOption = this.areaSelection[this.cellId];
                    return this.mapMode          ? areaOption[this.activeAnalysisType].area :
                           !this.currentCameraId ? null :
                                                   areaOption[this.currentCameraId].area
                },
                set(area) {
                    const areaOption = this.areaSelection[this.cellId];
                    this.mapMode         ? areaOption[this.activeAnalysisType].area = area :
                    this.currentCameraId ? areaOption[this.currentCameraId].area = area : null;
                    this.areaSelection = {...this.areaSelection }
                }
            },
            plotActive: {
                get() {
                    return this.plotsInfo[this.plotType].active
                } ,
                set(active) {
                    this.plotsInfo[this.plotType].active = active;
                }
            },
            maxLength() {
                return this.activeRecordings.reduce((max, recId) => Math.max(max, this.field.getRecordingWithId(recId).duration), 0)
            },
            activeRecordings: {
                get() {
                    return this.activeRecordingsByCell[this.cellId] || []
                },
                set(ar) {
                    this.activeRecordingsByCell = {
                        ...this.activeRecordingsByCell,
                        [this.cellId]: ar
                    }
                }
            },
            activeTagsList: {
                get() {
                    return this.activeTagsListByCell[this.cellId] || []
                },
                set(tagList) {
                    this.activeTagsListByCell = {
                        ...this.activeTagsListByCell,
                        [this.cellId]: tagList
                    }
                }
            },
            sortedRecordings() {
                const filteredRecordings = []
                const isTagsApplied = this.filterTagsList.length > 0;
                for (const recordingID of this.recordings) {
                    const recording = this.field.recordings.get(recordingID)
                    if (isSubstring(this.searchQuery, recording.subject) && (!isTagsApplied || this.filterTagsList.every(id => recording.tags.has(id))))
                        filteredRecordings.push(recording)
                }
                return filteredRecordings.sort((r1, r2) => r1.subject.toLowerCase() < r2.subject.toLowerCase() ? -1 : 1)
            },
            allRecordingsIds() {
                return this.recordings
            },
            activeRecordingsForList: {
                get() {
                    if (this.videoMode)
                        return this.activeAnalysisType.length > 0 ? this.activeRecordings[0] : null
                    return this.activeRecordings
                },
                set(activeRecordings) {
                    if (this.videoMode)
                        this.activeRecordings = activeRecordings ? [activeRecordings] : this.activeRecordings;
                    else
                        this.activeRecordings = activeRecordings
                }
            },
            recordingColors() {
               return createRecordingsStyles(this.sortedRecordings, this.videoMode);
            },
            canWeShowVideo() {
                const oneSubjectSelected = this.activeRecordings.length === 1;
                return oneSubjectSelected && (this.field.getRecordingWithId(this.activeRecordings[0]).hasVideo())
            },
            canWeDeselectRecordings() {
                return this.activeRecordings.length > 1
            },
            isProductsSidebarVisible() {
                return this.activeAnalysisType === 'Camera';
            },
            zoomReset() {
                return [ this.field, this.cellId, this.activeAnalysisType, this.currentCameraId ];
            },
            zoom() {
                return {
                    value: this.zoomValue,
                    ... this.zoomOpts && { opts: this.zoomOpts}
                }
            },
            fieldTags() { return [...this.field.tags.values()].map(tag => ({ key: tag.id,  label: tag.name, color: tag.color })) },
            filterTagsList() { return this.activeTagsList.filter((id) => this.field.tags.has(id))}
        },
        watch: {
            zoomReset() {
                this.zoomValue = 100;
                this.zoomOpts = null;
                 this.$refs.heatmap && this.$refs.heatmap.analysisCanvas.setViewportTransform([1,0,0,1,0,0]);
            },
            field() {
                if (this.field) {
                    document.title = this.field.name + " research | ShelfZone Studio"
                }
            },
            cellId() {
                this.pageUpdated();
                this.setDefaultTimeframe();
                //preventing video mode, when changing cell in case
                //cell(video mode, single subject/recording) -> cell with multiple selected subjects
                if(this.videoMode && this.activeRecordings.length > 1) {
                    this.cameraList.length > 0 ? this.setAnalysisTypeCamera()
                                               : this.setAnalysisTypeMap();
                }

            },
            cameraList() {
                this.currentCameraId =  (this.cameraList.length > 0) ? this.cameraList[0].id
                                                                     : null
            },
            timeframe() {
                if (this.activeRecordings.length === 1 &&
                    this.$refs.audioPlayer !== undefined &&
                    Math.abs(this.$refs.audioPlayer.currentTime - this.timeframe.current) > 0.5) {
                    this.$refs.audioPlayer.currentTime = this.timeframe.current;
                }
            },
            maxLength(_, prevLength){
                this.oldMaxLength = prevLength;
            },
            activeRecordings( _ , previousActiveRecordings ) {
                this.controlTimeframe(previousActiveRecordings);
                this.$store.commit('pause');
                this.selectedProduct = null;
                if (this.videoMode) {
                    this.setTimeframe(0,0);
                    this.currentVideoTime = this.timeframe.current || 0;
                }
                if (this.activeRecordings.length === 1) {
                    this.getAudioForRecording(this.activeRecordings[0])
                } else if (this.$refs.audioPlayer) {
                    this.audioURL = ''
                    this.$nextTick(() => this.$refs.audioPlayer.load())
                }
                if (this.$refs.timeline)
                    this.$refs.timeline.$forceUpdate()
            },
            activeAnalysisType(_, oldType) {
                this.setDefaultTimeframe(false);
                if (oldType === 'Video') {
                    this.$nextTick(() => this.setTimelinePositionFromVideoCurrent());
                }
                if (this.activeRecordings.length === 1) {
                    this.getAudioForRecording(this.activeRecordings[0])
                } else {
                    this.audioURL = ''
                    this.$nextTick(() => this.$refs.audioPlayer.load())
                }
            },
            '$store.state.volume'() {
                if (!this.videoMode)
                    this.$refs.audioPlayer.volume = this.$store.state.volume
            },
            '$store.state.muted'() {
                this.$refs.audioPlayer.muted = this.$store.state.muted
            },
            '$store.state.playing'() {
                if (!this.videoMode) {
                    if (this.$store.state.playing) {
                        this.$refs.audioPlayer && this.$refs.audioPlayer.play()
                    } else {
                        this.$refs.audioPlayer && this.$refs.audioPlayer.pause()
                    }
                }
            }
        },
      beforeRouteEnter(to, from, next) {
        window["studioApi"].storage.findFieldById(to.params.fieldId)
            .then(studio => studio.fetchData())
            .then(foundField => {
              if (foundField.getCellWithId(to.params.cellId)) {
                next(vm => {
                  vm.field = foundField;
                  vm.buildAreaSelection();
                  vm.pageUpdated();
                })
              } else {
                const error = new Error("Error 404");
                error.response = {status: 404};
                next(error);
              }
            }).catch(err => {
          console.log("analysis::beforeRouteEnter ", err)
          next(err);
        });
      },
      beforeRouteUpdate(to, from, next) {
        window["studioApi"].storage.findFieldById(to.params.fieldId)
            .then(studio => studio.fetchData())
            .then(foundField => {
                this.field = foundField;
                this.pageUpdated();
                next();
            })
            .catch(err => next(err));
      },
        methods: {
            onMinZoomUpdate(minZoom) {
                minZoom > this.zoomValue && (this.zoomValue = minZoom);
                this.zoomSettings = {
                    ...this.zoomSettings,
                    min: minZoom
                };
            },
            onZoomUpdate({ delta, opts }) {
                this.$refs.zoom.update(this.zoomValue + this.zoomSettings.step * (delta > 0 ? 1 : -1) )
                this.zoomOpts = opts;
            },
            onZoomChange( value ) {
                this.zoomValue = value;
                this.zoomOpts = {
                    offset: {
                        x: this.$refs.heatmap.$el.clientWidth / 2,
                        y: this.$refs.heatmap.$el.clientHeight / 2
                    }
                };
            },
            pageUpdated() {
                this.cell = this.field.getCellWithId(this.cellId);
                this.cameraList = this.cell.cameras.sort((r1, r2) => r1.name.toLowerCase() < r2.name.toLowerCase() ? -1 : 1)
                this.recordings = this.cell.recordings
                if (this.cameraList.length === 0)
                  this.setAnalysisTypeMap()
                else {
                  this.currentCameraId = this.cameraList[0].id
                  this.cell.getFixationsForCamera(this.currentCameraId)
                      .finally(() => this.heatmapForceUpdate())
                  if (this.activeRecordingId) {
                    this.toggleRecording(this.activeRecordingId)
                    this.timeframe.end = this.maxLength
                  }
                }
                if (this.activeAnalysisType === 'Camera') {
                  if (this.currentCameraId) {
                    this.cell.getCameraWithId(this.currentCameraId).getImage()
                        .then(() => {
                          this.cell.getFixationsForCamera(this.currentCameraId)
                              .finally(() => this.heatmapForceUpdate())
                        })
                        .catch(e => this.heatmapForceUpdate())
                  }
                  this.cell.getImage()
                      .finally(() => this.heatmapForceUpdate())
                  this.cell.getCellLocations()
                      .finally(() => this.heatmapForceUpdate())
                }
            },
            changeCell(cellId) {
                this.areaSelectionActive = false;
                const cell = this.field.cells.find(c => c.id === cellId)
                if (cell !== this.cell) {
                    if (this.videoMode) {
                        this.$refs.videoPlayer.resetVideoPlayer()
                        this.currentVideoTime = 0
                    }
                    this.$router.push({name: 'analysis', params: {fieldId: this.field.id, cellId: cell.id}})
                }
            },
            goToAnalysis(cameraId) {
                this.currentCameraId = cameraId
                this.cell.getFixationsForCamera(cameraId)
                    .then(() => this.heatmapForceUpdate())
                    .catch(() => this.heatmapForceUpdate())
                this.setDefaultTimeframe(false);
            },
            toggleAllRecordings(state) {
                // In video mode we can have a single recording selected.
                if (!this.videoMode) {
                    if (this.plotType === 'None')
                        this.plotType = 'Heatmap'
                    if (state) {
                        if (this.searchQuery !== '' || this.activeTagsList.length > 0 ) {
                            this.activeRecordings = this.sortedRecordings.map(({id}) => id);
                        } else {
                            this.activeRecordings = this.allRecordingsIds.slice(0)
                        }
                    } else
                        this.activeRecordings = []
                }
            },
            toggleRecording(recordingId) {
                // In video mode we can have a single recording selected.
                if (!this.videoMode) {
                    if (this.plotType === 'None')
                        this.plotType = 'Heatmap'
                    else {
                        !this.activeRecordings.includes(recordingId) &&
                        ( this.activeRecordings = [... this.activeRecordings, recordingId] );
                    }
                } else if (this.activeRecordings[0] !== recordingId) {
                    this.activeRecordings = [recordingId]
                }
            },
            getAudioForRecording(recordingID) {
                if (!this.videoMode) {
                    const currentRecording = this.field.getRecordingWithId(recordingID)
                    if (currentRecording && currentRecording.hasAudio())
                        this.audioURL = currentRecording.audioURL
                    else
                        this.audioURL = ''
                    if (this.$refs.audioPlayer)
                        this.$refs.audioPlayer.load()
                }
            },
            onAudioLoaded() {
                if (this.$store.state.playing)
                    this.$refs.audioPlayer && this.$refs.audioPlayer.play()
            },
            videoMissing() {
                if (this.cameraList.length)
                    this.activeAnalysisType = 'Camera'
                else
                    this.activeAnalysisType = 'Map'
            },
            showAnalysisCameras() {
                this.setAnalysisTypeCamera()
                this.isCameraAnalysisSectionOpen = true
            },
            hideAnalysisCameras() {
                this.isCameraAnalysisSectionOpen = false
            },
            setAnalysisTypeCamera() {
                this.areaSelectionActive = false;
                this.activeAnalysisType = 'Camera'
            },
            setAnalysisTypeMap() {
                this.areaSelectionActive = false;
                this.activeAnalysisType = 'Map'
                this.cell.getCellLocations()
                    .finally(() => this.heatmapForceUpdate())
            },
            setAnalysisTypeVideo() {
                this.areaSelectionActive = false;
                if (this.activeRecordings.length > 1) return
                this.activeAnalysisType = 'Video'
                const { current, end } = this.timeframe;
                this.currentVideoTime =  (!current || current === end) ? 0 : current;
            },
            onBack() {
                this.$router.push({name: "cells", params: {fieldId: this.fieldId}})
            },
            openDownloadDataDialog() {
                this.areaSelectionActive = false;
                this.$refs.downloadDataDialog.open();
            },
            closeDownloadDataDialog() {
                this.$refs.downloadDataDialog.close();
            },
            openProductDetail(product) {
                this.areaSelectionActive = false;
                this.$refs.productDetail.open(product);
            },

            buildAreaSelection() {
                const buildCellAreas = (cell) => {
                    const cameraAreas = cell.cameras.reduce((acc, {id}) => ({...acc, [id]: { area: null}}),{});
                    return {
                        ...cameraAreas,
                        'Map' :{ area: null },
                        active: false
                    }
                }
                this.areaSelection = this.field.cells.reduce((acc, cell) => {
                        return {
                            ...acc,
                            [cell.id]: buildCellAreas(cell)
                        }
                    },{})
            },
            nanoSecToPercents(nanoSecs, total) {
                const secs = nanoSecs / 1000000000;
                return `${secs/total*100}%`
            },
            setTimeframe(start, end, reset) {
                this.timeframe = { start, end , ... reset && {reset} };
            },
            setDefaultTimeframe(reset = true) {
                this.setTimeframe( 0, this.maxLength, reset);
            },
            heatmapForceUpdate() {
                this.$refs.heatmap && this.$refs.heatmap.forceUpdate();
            },
            controlTimeframe(previousActiveRecordings) {
                const { start, end } = this.timeframe;
                const isPreviousAtEnd = end.toFixed(3) === this.oldMaxLength.toFixed(3)
                const isCurrentAtEnd  = end.toFixed(3) === this.maxLength.toFixed(3)
                const isTimerangeFull = ( start === 0 && ( isCurrentAtEnd ||isPreviousAtEnd ));
                if ( isTimerangeFull || !previousActiveRecordings.length || this.maxLength <= start  || (start + 1) >= this.maxLength) {
                    this.setDefaultTimeframe();
                }
            },
            setTimelinePositionFromVideoCurrent() {
                const time = (this.currentVideoTime > this.maxLength || this.currentVideoTime === 0  ) ? this.maxLength
                                                                                                       : this.currentVideoTime
                this.$refs.audioPlayer.currentTime = time;
                this.$refs.timeline.currentValue = (time / this.maxLength) * 100;
                this.$refs.timeline.updateTimeSpan();
                this.currentVideoTime = 0
            },
            removeTagFromFilter(tagId) {
                this.activeTagsList = this.activeTagsList.filter((id) => tagId  !== id );
            }
        }
    }
</script>

<style scoped>

    .analysisWrapper {
        --sidebarWidth: 320px; /* aka aside width in application layout*/
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    /*SideBar*/

    .sideBar {
        display: flex;
        flex-direction: column;
        user-select: none;
        background-color: var(--elevation-02);
        box-sizing: border-box;
    }
    .hidden {
        overflow: hidden;
        flex-grow: 1;
    }
    /* AnalysisHeader*/

    .analysisHeader {
        flex: 0 0 auto;
        height: 36px;
        padding: var(--singleMargin);
        background-color: var(--elevation-03);
        color: var(--elevation-06);
    }

    .analysisBody {
        background-color: var(--elevation-01);
    }

    .filters {
        display: flex;
        align-items: center;
    }

    .fieldName {
        display: flex;
    }

    /* Analysis settings */
    .analysisSettings {
        user-select: none;
        margin-left: auto;
        display: flex;
        align-items: center;
        color: var(--elevation-05);

    }

    #cellSelector {
        position: absolute;
        margin-left: calc(var(--sidebarWidth) + var(--singleMargin));
    }

    .plotControls {
        display: flex;
        align-items: center;
        width: 250px;
    }

    /* Plot toggle */
    .plotToggle {
        background-color: var(--elevation-04) !important;
    }
    .plotToggle[off] >>> .switch {
        background-color: var(--elevation-05) !important;
    }

    /*placing area and download buttons upon right sidebar*/
    .uponRightSidebar {
        display: flex;
        width: calc(var(--sidebarWidth) - 2 * var(--singleMargin));
        margin: 0 var(--singleMargin);
        box-sizing: border-box;
    }

    .uponRightSidebar > * {
        flex: 1;
        margin-left: var(--singleMargin);
    }

    /* SideBar Recordings, Products*/

    .recordings {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        flex-grow: 1;
        max-height: 100%;
        overflow: hidden;
    }

    .recordingsHeader {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        padding: var(--doubleMargin);
        padding-bottom: var(--singleMargin);
    }
    .recordingsCounter {
        padding: var(--singleMargin) var(--doubleMargin);
    }

    .recordingsActions {
        display: flex;
        flex: 1
    }

    .recordingsList {
        flex: 1 1 auto;
        padding: 0 var(--doubleMargin);
        box-sizing: border-box;
    }

    /*Buttons, zoom control*/

    .analysisButtons, .analysisZoom {
        z-index: 15;
        display: flex;
        padding: var(--doubleMargin);
        position: absolute;
    }
    .analysisZoom {
        right: 0;
    }


    /*Camera*/

    .analysisCameras {
        position: absolute;
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 200px);
        margin: var(--doubleMargin);
        z-index: 20 !important;
        width: calc(100% - var(--doubleMargin) * 2);
        box-sizing: border-box;
        background-color: var(--elevation-02) !important;
        color: var(--on-elevation-02) !important;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(--doubleMargin) var(--doubleMargin) 0 var(--doubleMargin);
    }

    .areasOfInterest {
        flex: 1 1 auto;
        overflow-y: auto;
        padding: 0 var(--doubleMargin) var(--doubleMargin) var(--doubleMargin);
    }

    .areaOfInterest {
        display: flex;
        flex-direction: column;
        padding: var(--singleMargin);
        cursor: pointer;
    }

    .areaOfInterest:hover, .areaOfInterest[active] {
        background-color: var(--primary);
    }

    .cameraImage {
        flex: 1 1 auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    /* AREA */

    .displayArea {
        position: absolute;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    .timelineWrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 140px;
        background: linear-gradient(to bottom, rgba(2, 10, 20, 0) 0%, rgba(2, 10, 20, 0.8) 100%);
    }

    /* ShelfHeatmap */

    .analysisSetArea {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        height: 100%;
        user-select: none;
    }

    /* Menu */

    .timelineStyle {
        transition: bottom 0.33s ease-out;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 10px;
    }

    .timelineStyle.hidden {
        bottom: -160px;
    }

    .eventsBar {
        display: flex;
        position: absolute;
        width: 100%;
        z-index: 2;
    }
    .event  {
        position: absolute !important;
        transform: translate(-50%, -25%);
    }

    .activeTagsList {
        --tag-height: 24px;
        display: flex;
        flex-wrap: wrap;
        max-height: calc(( var(--tag-height) + var(--singleMargin))*4);
        overflow-y: auto;
    }


</style>
