<template>
    <ch-tree v-if="showOnlyCategoryTree" header="All products" :nodes="nodes" v-model="path"/>
    <div v-else fill>
        <div v-if="allProducts" class="allProducts" fill>
            <ch-tree class="tree" header="All products" :nodes="nodes" v-model="path"/>
            <div class="footer">
                <ch-button size="fill" type="primary" justified @click="hideAllProducts">Add a list of EAN
                    <ch-icon icon="view_list"/>
                </ch-button>
            </div>
        </div>
        <div v-else class="eanList" fill y-spaced-2>
            <h2>Add a list of EANs</h2>
            <textarea placeholder="Insert EANs, one per line." v-model="pastedEANs"></textarea>
            <div class="footer" x-spaced-2>
                <ch-button @click="showAllProducts" size="narrow small" type="secondary">Cancel</ch-button>
                <ch-button @click="addEANs" size="narrow small" type="primary">Add</ch-button>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'SkuLeftSideBar',
        props: {
            showOnlyCategoryTree: Boolean,
            inDialog: Boolean,
            categorization: Object
        },
        data() {
            return {
                allProducts: true,
                currentPath: [],
                pastedEANs: ''
            };
        },
        computed: {
            path: {
                get() {
                    if (this.inDialog) {
                        return this.currentPath;
                    } else {
                        if (!this.$route.query.category)
                            return [];
                        return this.$route.query.category
                            .split(',')
                            .reduce((acc, categoryId, i) => [
                                    ...acc,
                                    i === 0 ?
                                        this.nodes.find(node => node.id === categoryId) :
                                        acc[acc.length - 1].children.find(child => child.id === categoryId)]
                                , []);
                    }
                },
                set(path) {
                    if (this.inDialog) {
                        if (this.currentPath.length === path.length && this.currentPath.every((category, i) => path[i].id === category.id)) return;
                        this.currentPath = path;
                        this.$emit('categorySelected', this.currentPath);
                    } else {
                        const query = Object.assign({}, this.$route.query);
                        const category = path.map(p => p.id).join(',');
                        if (query.category === category) return;
                        query.category = category;
                        if (!query.category)
                            delete query.category;
                        this.$router.push({name: 'sku', query});
                    }
                }
            },
            nodes() {
                if (!this.categorization) return [];
                return this.categorization.tree.map(this.categoryNodeToTreeNode);
            }
        },
        watch: {
            allProducts() {
                this.$emit('searchingByEAN', !this.allProducts)
            }
        },
        methods: {
            categoryNodeToTreeNode(category) {
                return {
                    id: category.id,
                    text: category.label.capitalize(),
                    append: category.productsCount,
                    children: category.categories ? category.categories.map(this.categoryNodeToTreeNode) : []
                };
            },
            hideAllProducts() {
                this.allProducts = false;
                this.addEANs();
            },
            showAllProducts() {
                this.allProducts = true;
                this.path = [];
            },
            addEANs() {
                const reg = /\b(\d{13})\b/g;
                const eanList = [];
                let r;
                while ((r = reg.exec(this.pastedEANs)) !== null)
                    eanList.push(r[0]);
                this.$emit('searchByEANList', eanList);
            }
        }
    };
</script>

<style scoped>

    .allProducts {
        display: flex;
        flex-direction: column;
    }

    .allProducts .tree {
        flex: 1 1 auto;
    }

    .allProducts .footer {
        flex: 0 0 auto;
        padding: var(--doubleMargin);
        border-top: 1px solid var(--elevation-04);
    }

    .eanList {
        box-sizing: border-box;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        padding: var(--doubleMargin);
    }

    .eanList textarea {
        flex: 1 1 auto;
        border-radius: 4px;
        resize: none;
        padding: var(--singleMargin);
    }

    .eanList .footer {
        display: flex;
        justify-content: flex-end;
    }

</style>
