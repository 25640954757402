<template>
    <ch-access-layout>
        <div class="logo" slot="logo"></div>
        <p>Your password has expired on {{expiredAt | formatDate}}.</p>
        <p>Please set a new password.</p>
        <div slot="footer" class="actions" x-spaced-2>
            <ch-button @click="setPassword">Set new password</ch-button>
        </div>
    </ch-access-layout>
</template>

<script>
    import StringUtils from '../utils/StringUtils';

    export default {
        name: 'PasswordExpired',
        props: {
            expiredAt: String
        },
        filters: {
            formatDate(date) {
                return StringUtils.formattedDateFromISO(new Date(date).toISOString());
            }
        },
        methods: {
            setPassword() {
                this.$router.push({name: 'changePassword'});
            }
        }
    };
</script>

<style scoped>

    .logo {
        background-image: url("../assets/logo.png");
        background-size: 210px;
    }

</style>

