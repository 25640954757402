<template>
	<ch-form-dialog v-if="planogram" ref="dialog" :title="`Copy ${planogram.name}`" type="primary"
	                 :close-on-submit="false" @reset="reset" @submit="submit">
		<p>Copy planogram to</p>
		<ch-radio v-model="targetType" label="WorkGroup">WorkGroup</ch-radio>
		<ch-field :validators="[nonEmpty]">
			<ch-select v-model="workGroupId" :disabled="targetType !== 'WorkGroup'" :items="workGroupsItems" size="fill" placeholder="Search workgroup" searchable>
				<div slot-scope="{item}" x-spaced-1><ch-icon icon="fas fa-user-friends"/><span>{{ item.label }}</span></div>
			</ch-select>
		</ch-field>
		<ch-radio v-model="targetType" label="User" :disabled="isInOwnAccount">
			<identity-info :id="$storage.user.id" hide-you :disabled="isInOwnAccount">
				<template slot="append">'s library</template>
			</identity-info>
		</ch-radio>
		<template slot="actions">
			<ch-button type="secondary" size="small" cancel>Cancel</ch-button>
			<ch-button type="primary" size="small" submit>Copy</ch-button>
		</template>
	</ch-form-dialog>
</template>

<script>
import IdentityInfo from '@/user/workgroups/components/IdentityInfo';
export default {
	name: 'CopyPlanogramToDialog',
	components: {IdentityInfo},
	data() {
		return {
			planogram: null,
			workGroups: [],
			targetType: 'WorkGroup',
			workGroupId: null
		};
	},
	computed: {
		workGroupsItems() {
			return this.workGroups.map(w => {
				const isCurrent = w.id === this.$storage.workGroupId;
				return {
					key: w.id,
					label: w.name + (isCurrent ? ' (current)' : ''),
					disabled: isCurrent
				};
			});
		},
		isInOwnAccount() {
			return !this.$storage.workGroupId;
		}
	},
	watch: {
		targetType() {
			this.$refs.dialog.$refs.form.validate();
		}
	},
	methods: {
		nonEmpty(v) {
			if (this.targetType === 'WorkGroup' && !v)
				return 'Please choose a workgroup';
			return null;
		},
		open(planogram) {
			this.planogram = planogram;
			this.$loading.await(this.$iam.listWorkGroups())
				.then(({workGroups}) => {
					this.workGroups = workGroups;
					this.$refs.dialog.open();
				});
		},
		reset() {
			this.planogram = null;
			this.workGroups = [];
			this.targetType = 'WorkGroup';
			this.workGroupId = null;
		},
		submit() {
			const workGroupId = this.targetType === 'WorkGroup' ? this.workGroupId : null;
			this.$loading.await(
				this.$planogram.clonePlanogram(this.planogram.id, null, workGroupId)
					.then(() => {
						this.$refs.dialog.close();
						this.$snotify.success('Planograms successfully copied!', {timeout: 5000, showProgressBar: false});
					})
			);
		}
	}
};
</script>

<style scoped>

</style>
