<template>
    <div class="configurationsTable" :class="{ noContent }">
        <div :class=" { noConfiguration, noContent }">
            <ch-empty-content v-if="noContent || noConfiguration"
                :firstMessage="emptyMessage.first"
                :second-message="emptyMessage.second"
                @opendialog="addConfiguration"
            />
        </div>
        <template  v-for="(configurations, key) in environments">
            <ch-table class="noTable"
                :items="[]"
                :key="key"
                :caption="$storage.findEnvironmentById(key).name"
                :class="{'researchColors': project.hasField}">
                <template #captionActions>
                    <ch-button type="primary" justified @click="addConfiguration" class="primaryColors">
                        New configuration
                        <ch-icon icon="add_circle_outline"/>
                    </ch-button>
                </template>
            </ch-table>
            <ch-table v-for="config of configurations"
                :key="config.id"
                :items="toRow(config)"
                @rowClicked="goToByType"
                @rowContextMenu="showContextMenu"
                type="content"
                :rowHeight="getRowHeight"
                :rowClass="getRowClass">
                <template #header>
                    <template v-if="!field">
                        <ch-th name="Configuration" id="name" width="400px"/>
                        <ch-th name="Created on" id="creationTime"/>
                        <ch-th id="actions"/>
                    </template>
                    <template v-else>
                        <ch-th name="Configuration" id="name" width="400px"/>
                        <ch-th name="Cell" width="300px"/>
                        <ch-th name="Created on" id="creationTime"/>
                        <ch-th id="actions"/>
                    </template>
                </template>
                <template #default="{ row }">
                    <template v-if="row._type === 'configuration'">
                        <ch-td>
                            <ch-field :validators="[configurationNameValidator]">
                                <ch-editable :value="row.name" size="large" @input="newName=>onConfigRename(row.item, newName)"/>
                            </ch-field>
                        </ch-td>
                        <ch-td v-if="field"></ch-td>
                        <ch-td x-spaced-1>
                            <ch-icon icon="today"/>
                            <span>{{formattedDataFromISO(row.created)}}</span>
                        </ch-td>
                        <ch-td action x-spaced-1>
                            <ch-context-menu :voices="buildConfigurationContextMenuVoices(row.item)">
                                <ch-button type="flat" icon><ch-icon icon="more_horiz"/></ch-button>
                            </ch-context-menu>
                        </ch-td>
                    </template>
                    <template v-if="row._type === 'cell'">
                        <ch-td></ch-td>
                        <ch-td >{{getCellName(row)}}</ch-td>
                        <ch-td x-spaced-1>
                            <ch-icon icon="today"/>
                            <span>{{formattedDataFromISO(row.created)}}</span>
                        </ch-td>
                        <ch-td></ch-td>
                    </template>
                </template>
            </ch-table>
        </template>
        <ch-table v-if="fieldCellsWithNoConfig.length > 0"
            caption="Other cells"
            type="content"
            rowHeight="narrow"
            :items="fieldCellsWithNoConfig"
            :class="{'researchColors': project.hasField}"
            @rowClicked="goToAnalysisCell">
            <template #header>
                <ch-th name="Configuration" id="name" width="400px"/>
                <ch-th name="Cell" width="300px" />
                <ch-th name="Created on" id="creationTime"/>
            </template>
            <template #default={row}>
                <ch-td></ch-td>
                <ch-td>{{row.name}}</ch-td>
                <ch-td>
                    <ch-icon icon="today"/>
                    {{row.creationDate ? formattedDataFromISO(row.creationDate) : 'None'}}
                </ch-td>
            </template>
        </ch-table>
    </div>
</template>
<script>
import TimeFormatMixin from '@/mixins/TimeFormatMixin';
import StringUtils     from "@/utils/StringUtils";
import Project         from '@/plugins/studioapi/storage/Project';
import Studio          from '@/plugins/studioapi/storage/Studio';


const cellToRow = (cell) => {
    return {
        _type: "cell",
        id: cell.id,
        name: cell.name,
        created: cell.creationDate,
        item: cell,
    }
}
const configToRow = (config) => {
    return {
        _type: "configuration",
        id: config.id,
        name: config.name,
        created: config.creationTime,
        item: config
    }
}

const rowHeight = (type) => {
    switch(type) {
        case "cell": {
            return "narrow"
        }
        default: return "normal"
    }
}

export default {
    name: 'ProjectDetailTabContent',
    mixins: [ TimeFormatMixin ],
    props: {
        project: {
            type: Project
        },
        environments: {
            default: {}
        },
        field: {
            type: Studio,
        }
    },
    computed: {
        noConfiguration(){
            return !this.environments || Object.keys(this.environments).length === 0;
        },
        fieldCells() {
            return this.field ? this.field.cells : [];
        },
        availableConfigurations() {
            const confIds = this.project.configurations.map(({id}) => id );
            return new Set(confIds);
        },
        fieldCellsWithNoConfig() {
            return this.fieldCells.filter(cell => !this.availableConfigurations.has(cell.environmentCustomizationId) );
        },
        noContent() {
            return this.noConfiguration && !this.fieldCellsWithNoConfig.length 
        },
        emptyMessage() {
            return {
                first:  `This project doesn't have a ${ this.noContent ? 'content' : 'configuration'} yet,`,
                second: `Start adding some ${ this.noContent ? 'content' : 'configurations'}`
            }
        }
    },
    methods: {
        toRow(config){
            const configRow = configToRow(config);
            const cells     = this.cellsForConfig(config);
            const cellRows  = cells.map(cellToRow).sort((a,b) => b.created.localeCompare(a.created));
            if (cellRows.length > 0) {
                const [latest, ...rows ] = cellRows;
                return [configRow, { ...latest, current: true }, ...rows ]
            } else {
                return [ configRow ]
            }
        },

        cellsForConfig(configuration) {
            return this.fieldCells.filter(cell => cell.environmentCustomizationId === configuration.id);
        },

        isCurrent(cell) {
            return cell.current;
        },

        getCellName(cell) {
            return  `${cell.name}${ this.isCurrent(cell) ? ' (current)' : '' }`;
        },

        getRowClass(row) {
            return row._type === 'cell' && this.isCurrent(row) ? 'currentCell'  :'' 
        },

        getRowHeight({_type}) {
            return rowHeight(_type);
        },

        goToByType(row) {
            const goTo = row._type === 'cell' ? this.goToAnalysisCell  
                                              : this.goToLayoutEditor;
            goTo(row); 
        },

        goToLayoutEditor(configuration) {
            this.$router.push({
                name: "projectConfiguration",
                params:
                    {
                        projectId: this.project.id,
                        configurationId: configuration.id
                    }
            });
        },
        goToAnalysisCell(cell) {
            if (!this.project || this.project.lastPackageDate) {
                this.$router.push({
                    name: "analysis",
                    params: { fieldId: this.field.id, cellId: cell.id }
                });
            } else {
                alert("Cells can not be viewed until a package is created");
            }
        },
        showContextMenu(event, row) {
            if (row._type === 'configuration') {
                this.$emit('show-context-menu', { event, menu: this.buildConfigurationContextMenuVoices(row.item) });
            }
        },
        addConfiguration() {
            this.$emit('add-config');
        },
        buildConfigurationContextMenuVoices(configuration) {
            return [{
                text: 'Duplicate',
                icon: 'library_add',
                action: () => this.$emit('duplicate-config', configuration)
            }, {
                text: 'Export',
                icon: 'present_to_all',
                action: () => this.$emit('export-config', configuration)
            }, {
                text: 'Remove',
                icon: 'delete',
                action: () => this.$emit('remove-config', configuration)
            }]
        },
        onConfigRename(config, newName) {
            if (!StringUtils.validateString(newName)) {
                this.$snotify.error(`Invalid name.`, {timeout: 5000, showProgressBar: false});
                return;
            }
            this.$configuration.renameConfiguration(config.id, newName)
                .then(() => config.name = newName)
                .catch(() => this.$snotify.error(`Failed to rename configuration.`, {timeout: 5000, showProgressBar: false}));
        },
        configurationNameValidator(name) {
            if (!name || !/^(\w|-|\.|\s){1,30}$/.test(name.trim()))
                return 'Valid characters: A-z, 0-9 and . _ -';
            return null;
        },
    }
}
</script>

<style scoped>
    .configurationsTable {
        padding: 0 var(--quadrupleMargin);
    }

    .noTable >>> table {
        display: none;
    }
</style>
<style>
    .currentCell {
        --background-color: #5498B7 !important;
    }
    .currentCell:hover {
        --background-color: #87C9E9 !important;
    }

    .noConfiguration {
        height: 30vh;
    }

    .noContent {
        height: 100%;
    }
</style>