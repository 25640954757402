<template>
    <ch-dialog ref="dialog" @confirm="download" @cancel="close" title="Export Catalog" type="primary"
               :important="loading">
        <template slot="body">
            <div class="downloadStatus" y-spaced-2>
                <ch-progress-bar :max-value="itemsToDownload" :value="itemsDownloaded"
                                 :text="`${downloadPercentage}%`" :type="downloadStatus"/>
                <div v-if="!exportFailed && loading">We are retrieving all the products, please wait a few
                    moments...
                </div>
                <div v-else-if="exportFailed && !loading" error-text>An error as occurred. Please retry later.
                </div>
                <div v-else-if="!exportFailed && !loading">Exporting completed. A download will start soon.</div>
            </div>
        </template>
        <template  slot="footer">
            <ch-button @click="cancel" type="secondary" size="small">{{exportFailed ? 'Close ' : 'Cancel'}}</ch-button>
        </template>
    </ch-dialog>
</template>

<script>

    import {CSVConverter} from '../spaceallocation/planogrameditor/services/CSVConverter';
    import {CategorizationService} from './services/CategorizationService';

    export default {
        name: "ExportCatalogProgressDialog",

        data() {
            return {
                useFilter: true,
                id: true,
                info: true,
                size: true,
                category: true,
                prototype: true,
                currentFilter: null,
                loading: false,
                itemsToDownload: 1,
                itemsDownloaded: 0,
                cancelled: false,
                exportFailed: false,
            }
        },
        computed: {
            /**
             * @returns {{names: string[], extract: function(product: Product):any[]}[]}
             */
            csvColumns() {
                return [
                    ...this.id ? [{names: ['ID'], extract: p => [p.id]}] : [],
                    ...this.info ? [
                        {
                            names: ['EAN', 'Name', 'Brand', 'Manufacturer', 'Format'],
                            extract: p => [p.ean, p.name, p.brand, p.producer, p.format]
                        }
                    ] : [],
                    ...this.size ? [
                        {
                            names: ['Width', 'Height', 'Depth'],
                            extract: p => [p.size.width / 10, p.size.height / 10, p.size.depth / 10]
                        }
                    ] : [],
                    ...this.category ? [
                        {
                            names: this.currentFilter.categorization.labels,
                            extract: p => CategorizationService.ExtractCategory(this.currentFilter.categorization, p.categories)
                                .map(category => category.value)
                        }
                    ] : [],
                    ...this.prototype ? [
                        {
                            names: ['Prototype'],
                            extract: p => [p.isPrototype]
                        }
                    ] : []
                ];
            },
            downloadPercentage() {
                return Math.round(this.itemsDownloaded / this.itemsToDownload * 10000) / 100;
            },
            downloadStatus() {
                if (this.exportFailed)
                    return 'error';

                return 'success';
            }
        },
        methods: {
            open(settings, currentFilter){
                this.useFilter = settings.useFilter;
                this.id = settings.id;
                this.info = settings.info;
                this.size = settings.size;
                this.category = settings.category;
                this.prototype = settings.prototype;
                this.currentFilter = currentFilter;
                this.$refs.dialog.open();
                this.download();
            },
            close() {
                this.reset();
                this.$refs.dialog.close();
            },
            cancel() {
                this.cancelled = true;
                this.loading = false;
                this.exportFailed = false;
                this.$refs.dialog.close();
            },
            reset() {
                this.useFilter = true;
                this.id = true;
                this.info = true;
                this.size = true;
                this.category = true;
                this.cancelled = false;
            },
            download() {
                this.loading = true;
                this.itemsDownloaded = 0;
                this.cancelled = false;
                this.exportFailed = false;
                const limit = 50;
                const csv = new CSVConverter.CSV(this.csvColumns.flatMap(c => c.names), []);
                const downloader = (doneItems) => {
                    if (this.cancelled || doneItems >= this.itemsToDownload) {
                        if (!this.cancelled) {
                            const element = document.createElement('a');
                            element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv.toString()));
                            element.setAttribute('download', `Products.csv`);
                            element.click();
                        }
                        this.cancelled = false;
                        this.loading = false;
                        return;
                    }
                    const params = this.useFilter ? [
                        this.currentFilter.withWebGL, this.currentFilter.withPhoto,
                        this.currentFilter.searchString, this.currentFilter.category
                    ] : [];
                    Promise.withRetry(() => this.retrieveProducts(limit, doneItems, params), 3)
                        .then(r => {
                            csv.addRecords(r.foundProducts.map(p => this.csvColumns.flatMap(col => col.extract(p))));
                            this.itemsDownloaded = doneItems + r.foundProducts.length;
                            downloader(doneItems + limit);
                        })
                        .catch(e => {
                            console.error(e);
                            this.exportFailed = true;
                            this.loading = false;
                        });
                };
                downloader(0);
            },
            retrieveProducts(limit, doneItems, params) {
                return this.$productBank.listProducts(limit, doneItems, ...params)
                    .then(r => {
                        this.itemsToDownload = r.totalMatchesCount;
                        return this.$productBank.findProductsById(r.productsSummaries.map(p => p.id));
                    })
            },
        }


    }
</script>

<style scoped>

    .downloadStatus {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

</style>