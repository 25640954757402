<template>
    <ch-access-layout>
        <div class="logo" slot="logo"></div>
        <change-password-form @submit="passwordChanged" confirm-only/>
    </ch-access-layout>
</template>

<script>

    import ChangePasswordForm from './ChangePasswordForm';

    export default {
        name: 'CompletePasswordReset',
        components: {ChangePasswordForm},
        props: {
            resetToken: String
        },
        methods: {
            passwordChanged() {
                this.$router.push({name: 'passwordChanged'})
            }
        }
    };
</script>

<style scoped>

    .logo {
        background-image: url("../../../assets/logo.png");
        background-size: 210px;
    }

</style>
