<template>
    <ch-access-layout>
        <div class="logo" slot="logo"></div>
        <div class="licenseExpired">
            <ch-icon icon="warning" size="40"/>
            <p>License has expired.</p>
            <p>Please contact you administrator.</p>
        </div>
    </ch-access-layout>
</template>

<script>
    export default {
        name: 'LicenseExpired'
    };
</script>

<style scoped>

    .logo {
        background-image: url("../assets/logo.png");
        background-size: 210px;
    }

    .licenseExpired {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

</style>
