<template>
    <div class="sliderWrapper">
        <div class="controlButtons" v-if="showControls">
            <i class="material-icons buttonIcon"  @mousedown="togglePlay">
                {{isPlaying ? 'pause_circle_outline' : 'play_circle_outline' }}
            </i>
        </div>
        <div class="slideArea">
            <span class="sliderLabel">{{timelineLabel}}</span>
            <div class="sliderBar" ref="slider">
                <div class="selectedBar"   @mousedown="onMouseDown(showCursor ? 1 : 4)" :style="'margin-left: ' + currentMin + '%; width: ' + (currentMax - currentMin) + '%;'"></div>
                <div class="playedBar"     @mousedown="onMouseDown(4)" :style="'margin-left: ' + currentMin + '%; width: ' + (currentValue - currentMin) + '%;'" :class="playedBarClass" v-if="showCursor"></div>
                <div class="cursor"        @mousedown="onMouseDown(1)" :style="'margin-left: calc(' + currentValue + '% - 7px)'" v-if="showCursor"><span class="cursorLabel">{{timeFromPercent(currentValue)}}</span></div>
                <div class="handler start" @mousedown="onMouseDown(2)" :style="'margin-left: calc(' + currentMin + '% - 6px)'"><span class="startLabel">{{timeFromPercent(currentMin)}}</span></div>
                <div class="handler end"   @mousedown="onMouseDown(3)" :style="'margin-left: calc(' + currentMax + '% - 6px)'"><span class="endLabel">{{timeFromPercent(currentMax)}}</span></div>
                <slot name="events"></slot>
            </div>
        </div>
        <div class="controlButtons" v-if="showControls">
            <volume-slider></volume-slider>
            <i class="material-icons buttonIcon lockIcon" @mousedown="toggleLock" v-bind:style="{color: showPlaySpan ? (lockedTime ? '#DE003C' : '') : '#777'}">lock</i>
            <div class="speedOptions" v-if="!hidden">
                <ul class="speedsMenu" v-bind:style="{ opacity: optionsVisible ? 1 : 0, pointerEvents: optionsVisible ? 'all' : 'none' }">
                    <li v-for="(speed, index) of speeds" :key="'s_' + index" @click="onSpeedChange(index)" v-bind:style="{color: index == selectedSpeed ? 'red' : ''}">
                        {{ speed.toFixed(1) }} x
                    </li>
                </ul>
                <span @click="optionsVisible = !optionsVisible">{{ speeds[selectedSpeed].toFixed(1) }} x</span>
            </div>
        </div>
    </div>
</template>

<script>
    import VolumeSlider from "../components/VolumeSlider"

	export default {
        name: "timeline",
        components: { VolumeSlider },
        props: {
            timelineLabel:  {default:"Timeline", type: String},
            showControls:   {default:true, type:Boolean},
            showCursor:     {default:true, type:Boolean},
            recordLength:   Number,
            timespan:       Object,
            hidden:         {default:false, type:Boolean},
            selectedSpeed:  {default:3, type:Number}
		},
        data() {
			return {
                currentMin:     0,
                currentMax:     100,
                currentValue:   100,
                draggingID:     0,
                draggingOffset: 0,
                cursorOffset:   100,
                slideLength:    100,
                minSlide:       0.5,
                playingMode:    0,
                playInterval:   null,
                lastPlayupdate: 0,
                optionsVisible: false,
                lockedTime:     false,
                speeds:         [10.0, 5.0, 2.5, 1.0],
                //selectedSpeed:  1,
            }
        },
        computed: {
            showPlaySpan: function() {

                return (this.currentMin > 0 || this.currentMax < 100);
            },
            playedBarClass() {
                
                if(this.showPlaySpan)
                    return 'interactable';
                
                return '';
            },
            isPlaying() { return this.$store.state.playing; },
            step() { return this.recordLength / 100}
        },
        created() {
            
            document.body.addEventListener("mousemove", (e) => this.onMouseMove(e));
            document.body.addEventListener("mouseup",   (e) => this.onMouseUp(e));

            let stepValue = this.recordLength / 100;

            if(this.timespan.start)
                this.currentMin = this.timespan.start / stepValue;
            else
                this.currentMin = 0;
            
            if(this.timespan.end)
                this.currentValue = this.timespan.end / stepValue;
            else
                this.currentValue = 100;

            this.currentMax = 100;

            if(this.$store.state.playing)
                this.play();
        },
        destroyed() {
            clearInterval(this.playInterval);
        },
        updated() {
            this.updateTimeSpan();
        },
        watch: {
            showCursor() {
                if(!this.showCursor)
                {
                    this.currentValue = this.currentMax;
                    clearInterval(this.playInterval);
                }
            },
            timespan() {
                const { start, end, reset } = this.timespan;
                this.currentMin = !start ? 0   : start / this.step;
                this.currentMax = !end   ? 100 : end / this.step;
                reset && (this.currentValue = this.currentMax);
            },
            recordLength(newValue, oldValue) {

                if (newValue && oldValue != 0)
                {
                    const { start, end } = this.timespan; 
                    this.currentMin   =  start / this.step;
                    this.currentMax   = Math.min( end / this.step, 100);
                    this.currentValue = Math.min( this.currentValue * oldValue / newValue , 100);
                }
            },
            hidden() {

                if(this.hidden === true)
                    this.optionsVisible = false;
            }
        },
        methods: {
            updateTimeSpan() {
                const newSpan = {
                    start: this.currentMin * this.step,
                    end:   this.currentMax * this.step,
                    current: this.currentValue * this.step
                }
                this.$emit('update:timespan', newSpan);
            },
			onMouseDown(id, e) {

                e = e || window.event;
                this.pauseEvent(e);
                this.draggingID = parseInt(id);

                if(this.draggingID === 4)
                {
                    const originX       = this.$refs['slider'].getBoundingClientRect().left;
                    const width         = this.$refs['slider'].offsetWidth;
                    const deltaX        = e.clientX - originX;
                    this.draggingOffset = (deltaX / width * 100) - this.currentMin;
                    this.cursorOffset   = this.currentValue - this.currentMin;
                }

                this.onMouseMove(e);
            },
            onMouseMove(e) {
                if(this.draggingID > 0)
                {
                    e = e || window.event;
                    this.pauseEvent(e);

                    const originX   = this.$refs['slider'].getBoundingClientRect().left;
                    const width     = this.$refs['slider'].offsetWidth;
                    const deltaX    = e.clientX - originX;
                    
                    let newPosition = deltaX / width * 100;

                    if(this.draggingID === 1)
                    {
                        if(newPosition < this.currentMin)
                            newPosition = this.currentMin;
                        else if(newPosition > this.currentMax)
                            newPosition = this.currentMax;

                        this.currentValue = newPosition;
                    }
                    else if(this.draggingID === 2)
                    {
                        if(newPosition < 0)
                            newPosition = 0;
                        else if(newPosition > this.currentMax - this.minSlide)
                            newPosition = this.currentMax - this.minSlide;

                        if(this.currentValue < newPosition)
                            this.currentValue = newPosition;

                        this.currentMin  = newPosition;
                        this.slideLength = this.currentMax - this.currentMin;
                    }
                    else if(this.draggingID === 3)
                    {
                        if(newPosition < this.currentMin + this.minSlide)
                            newPosition = this.currentMin + this.minSlide;
                        else if(newPosition > 100)
                            newPosition = 100;

                        if(this.currentValue > newPosition)
                            this.currentValue = newPosition;

                        this.currentMax  = newPosition;
                        if(!this.showCursor)
                            this.currentValue = this.currentMax;
                            
                        this.slideLength = this.currentMax - this.currentMin;
                    }
                    else if(this.draggingID === 4)
                    {
                        newPosition -= this.draggingOffset;

                        if(newPosition < 0)
                            newPosition = 0;
                        else if((newPosition + this.slideLength) > 100)
                            newPosition = 100 - this.slideLength;
                        
                        this.currentValue = newPosition + this.cursorOffset;

                        this.currentMin  = newPosition;
                        this.currentMax  = newPosition + this.slideLength;
                    }

                    this.updateTimeSpan();
                }
            },
            onMouseUp(e) {

                this.draggingID = 0;
            },
            pauseEvent(e) {
                if(e.stopPropagation) 
                    e.stopPropagation();
                if(e.preventDefault) 
                    e.preventDefault();
                e.cancelBubble=true;
                e.returnValue=false;
                return false;
            },
            timeFromPercent(percent) {

                let p2s = (this.recordLength / 100.0) * percent;
                
                let seconds = parseInt(p2s) % 60;
                if(seconds < 10)
                    seconds = "0" + seconds;

                return Math.floor(p2s / 60) + " : " + seconds;
            },
            togglePlay(e) {

                if(!this.$store.state.playing)
                {
                    this.play();
                    this.$store.commit('play');
                }
                else
                {
                    clearInterval(this.playInterval);
                    this.$store.commit('pause');
                }
            },
            play() {

                this.lastPlayupdate = Date.now();

                if(this.currentValue === this.currentMax && !this.lockedTime)
                {
                    this.currentValue = this.currentMin;
                }
                else if(this.lockedTime && this.currentMax === 100)
                {
                    this.currentMax  -= this.currentMin;
                    this.currentValue = this.currentMax;
                    this.currentMin   = 0;
                }

                this.playInterval = setInterval(()=>
                {
                    let now             = Date.now();
                    let step            = (now - this.lastPlayupdate) / (this.recordLength * 10) * (this.speeds[this.selectedSpeed]);
                    this.lastPlayupdate = now;

                    if(this.lockedTime)
                    {
                        this.currentMax  += step;
                        this.currentMin  += step;
                        this.currentValue = this.currentMax;
                    }
                    else
                    {
                        this.currentValue += step;
                    }
                    
                    if(!this.lockedTime && this.currentValue > this.currentMax)
                    {
                        this.currentValue = this.currentMax;
                        clearInterval(this.playInterval);
                        this.$store.commit('pause');
                    }
                    else if(this.lockedTime && this.currentMax >= 100)
                    {
                        this.currentMax = 100;
                        clearInterval(this.playInterval);
                        this.$store.commit('pause');
                    }
                
                    this.updateTimeSpan();

                }, 30);
            },
            toggleLock(e) {
                
                if(!this.showPlaySpan)
                    return;

                if(this.lockedTime)
                {
                    clearInterval(this.playInterval);
                    this.$store.commit('pause');
                }

                this.lockedTime = !this.lockedTime;
            },
            showPlayOptions(e) {
                this.optionsVisible = !this.optionsVisible;
            },
            onSpeedChange(index) {
                
                this.$emit('update:selectedSpeed', index); 
                this.optionsVisible = !this.optionsVisible;
            }
        }
	}
</script>

<style scoped>

    .sliderWrapper {

        display:            flex;
        justify-content:    center;
        align-content:      flex-end;
        align-items:        flex-end;
        min-height:         80px;
        margin:             0 30px;
    }
    .controlButtons {

        display:            flex;
        align-items:        center;
        /* min-width:          160px; */
        height:             50px;
        border-radius:      30px;
        margin-bottom:      8px;
        cursor:             pointer;
    }
    .buttonIcon {
        display: block;
        color: white;
        font-size: 40px;
        padding: 5px;
        user-select: none;
        
    }
    .lockIcon {
        
        font-size:      26px;
        margin:         auto;
        margin-bottom:  10px;
    }
    .speedOptions {
        position:       relative;
        display:        inline-block;
        color:          white;
        font-size:      18px;
        width:          40px;
        vertical-align: 50%;
        margin:         2px;
        text-align:     center;
        white-space:    nowrap;
    }
    .speedsMenu {

        transition: all .3s ease;

        position: absolute;
        display: block;
        width: 60px;
        bottom: 30px;
        left: -10px;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 4px;

        list-style-type: none;
        margin: 0;
        text-align: center;
        padding: 8px;

        overflow: hidden;
        font-size: 16px;
        text-align: right;
    }
    .speedsMenu .selected {

        color: #DE003C;
    }
    .playOptions {
        display: block;
        margin-left: 35px;
        margin-top: -58px;
        background-color: #DE003C;
        border-radius: 15px;
        width: 20px;
        height: 20px;
        font-size: 16px;
        padding:1px;
        border: 1px solid white;
        color: white;
        user-select: none;
    }
    .slideArea {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-end;
        flex: 1 1 auto;
        align-self: auto;
        margin: 0 50px;
    }
    .sliderLabel {
        flex: 1 1 100%;
        margin-bottom: 60px;
        color: white;
        font-weight: 500;
    }
    .sliderBar {
        position: relative;
        flex: 1 1 100%;
        height: 6px;
        border-radius: 3px;
        background-color:rgba(255, 255, 255, 0.2);
        margin-bottom: 30px;
    }
    .selectedBar {
        z-index: 1;
        height: 100%;
        border-radius: 3px;
        background-color:rgba(255, 255, 255, 0.4);
    }
    .playedBar {
        margin-top: -6px;
        z-index: 2;
        height: 100%;
        border-radius: 3px;
        background-color:rgb(0, 255, 157);
        pointer-events: none;
    }
    .playedBar.interactable {

        pointer-events: all;
    }
    .handler {
        position: absolute;
        top: 14px;
        width: 12px;
        height: 16px;
        color: white;
        cursor: pointer;
    }
    .start {
        background-image: url('../assets/RangeLeft.png');
        background-repeat: no-repeat;
        background-size: contain;
    }
    .startLabel {
        position: relative;
        display: block;
        width: 50px;
        left: -54px;
        text-align: right;
        font-weight: 200;
        font-size: 12px;
    }
    .end {
        /*margin-top: -16px;*/
        background-image: url('../assets/RangeRight.png');
        background-repeat: no-repeat;
        background-size: contain;
    }
    .endLabel {
        position: relative;
        display: block;
        width: 50px;
        left: 16px;
        font-weight: 200;
        font-size: 12px;
    }
    .cursor {
        z-index: 3;
        width: 14px;
        height: 24px;
        margin-top: -34px;
        margin-left: -8px;
        background-image: url('../assets/Momentum.png');
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
    }
    .cursorLabel {
        
        position: relative;
        display: block;
        width: 80px;
        bottom: 20px;
        left: -34px;
        text-align: center;
        color: white;
        font-weight: 200;
    }
    
    .option {
        transition: all .3s ease;
        display:       block;
        width:         40px;
        height:        40px;
        border-radius: 20px;
        padding:       5px;
        margin-left:   auto;
        background-color: #DE003C;
        font-size:     30px;
        color:       white;
        pointer-events: none;
        cursor: pointer;
    }
    .option.hidden {
        opacity: 0;
        pointer-events: none;
        margin-top:    -20px;
    }
    .option.visible1 {
        opacity: 1;
        pointer-events: all;
        margin-top:    -70px;
    }
    .option.visible2 {
        opacity: 1;
        pointer-events: all;
        margin-top:    -100px;
    }
    .option.visible3 {
        opacity: 1;
        pointer-events: all;
        margin-top:    -100px;
    }
</style>