<template>
    <div class="groupInfo" fill>
        <div class="info">
            <div class="section group" y-spaced-2>
                <h1 primary-text>Group info</h1>
                <div  class="groupColor" x-spaced-1>
                    <ch-indicator class="wideIndicator" :color="group.color"/>
                    <span>{{group.name}}</span>
                </div>
                <ch-dl label-width="50%">
                    <ch-dd label="Positions in units">{{additionalInfo.stockInUnits}}</ch-dd>
                    <ch-dd label="Positions">{{additionalInfo.units}}</ch-dd>
                </ch-dl>
                <group-condition-info v-if="!isGroupManual" :group="group" :categorization="this.repository.categorization"/>
                <group-image-gallery :images="groupImages" @addImage="saveGroupImage" @removeImage="removeGroupImage"/>
            </div>
            <div class="section" y-spaced-2>
                <h2 primary-text>Products</h2>
                <ch-list size="narrow" type="flat" class="productsList">
                    <ch-list-item class="product" v-for="product of products" :key="product.id" @click="openProductDetails(product.id)">
                           <span class="productEan">{{product.ean}}</span>
                    </ch-list-item>
                </ch-list>
            </div>
        </div>
        <div class="actions" y-spaced-2>
             <ch-button @click="editGroup" size="fill" justified>Edit group
                <ch-icon icon="edit"/>
            </ch-button>
            <ch-button @click="deleteGroup" size="fill" type="error" justified>Remove group
                <ch-icon icon="delete"/>
            </ch-button>
        </div>
        <ch-modal ref="planogramInfoAlert"/>
        <product-details ref="productDetails"/>
    </div>
</template>

<script>
    import axios         from 'axios';
    import StudioRequest from '../../../../plugins/studioapi/requests/StudioRequest';
    import Repository    from '../../services/Repository';
    import { Trade }     from './Trade';
    import { ColorService } from '../../../layouteditor/services/ColorService';
    import HexColor from '../../../layouteditor/model/common/color/HexColor';
    import { PlanogramService } from '../../services/PlanogramService';
    import { ProductGroupImageService } from './services/ProductGroupImageService';
    import GroupImageGallery  from './createproductgroup/GroupImageGallery.vue';
    import GroupConditionInfo from './GroupConditionInfo.vue';
    import ProductDetails     from '../../../../skucatalog/ProductDetails';
    import { isGroupManual }  from '../../model/group/ConditionHelpers';
    export default {
        components: { GroupImageGallery, GroupConditionInfo, ProductDetails },
        name: 'GroupInfo',
        props: {
            groupId: String,
            repository: Repository
        },
        data() {
            return {
                colors: Trade.availableGroupColors,
            };
        },
        computed: {
            /**
             * @return {string}
             */
            planogramId() {
                return this.repository.planogram.id;
            },
            editor() {
                return this.$parent.$parent.$parent;
            },
            /**
             * @return {Group}
             */
            group() {
                return PlanogramService.findGroupWithId(this.repository.planogram, this.groupId).get();
            },
            groupImages() {
                return this.group.images.map(({file}) => file)
            },
            /**
             * @return {Product[]}
             */
            products() {
                return this.group.matchedProductsIds
                    .map(id => PlanogramService.findProductWithId(this.repository.planogram, id))
                    .filter(product => product.nonEmpty())
                    .map(product => product.get());
            },
            /**
             * @return {{stockInUnits: number, units: number}}
             */
            additionalInfo() {
                const bucketsList = [];
                for (const fixture of this.repository.planogram.fixtures)
                    for (const bucket of fixture.buckets)
                        if (this.group.matchedProductsIds && this.group.matchedProductsIds.includes(bucket.productId))
                            bucketsList.push(bucket);
                return {
                    stockInUnits: bucketsList.reduce((t, b) => t + b.facing.h * b.facing.v * b.facing.d, 0),
                    units: bucketsList.length
                };
            },
            isGroupManual() { return isGroupManual(this.group); }
        },
        watch: {
            group: {
                handler() {
                    if (this.group) {
                        const matchedBuckets = this.repository.planogram.fixtures
                            .flatMap(f => f.buckets)
                            .filter(b => this.group.matchedProductsIds.includes(b.productId))
                            .map(b => b.id);
                        const color = ColorService.hexColorToRGBColor(new HexColor(this.group.color));
                        this.$unityManager.Dispatch('SelectItems', {
                            itemIds: matchedBuckets,
                            color
                        });
                    }
                },
                immediate: true
            }
        },
        methods: {
            /**
             * @param {Event} e
             */
            saveGroupImage(files) {
                if (!files.length) return;
                if (files[0].type !== 'image/png' && files[0].type !== 'image/jpeg') {
                    this.$snotify.error(`Error ${files[0].name}. Invalid file format.`, {
                        timeout: 5000,
                        showProgressBar: false
                    });
                } else {
                    return ProductGroupImageService.addImageToGroup(this.planogramId, this.group, files[0])
                           .catch((e) => {
                                console.error(e);
                                this.$snotify.error(`Error uploading ${imageFile.name}. Please retry.`, {
                                    timeout: 5000,
                                    showProgressBar: false
                                });
                            });
                }
            },
            removeGroupImage() {
                this.$refs.planogramInfoAlert.open('Remove group image', 'Are you sure to remove image from this group?', 'error', "Remove")
                    .then(() => ProductGroupImageService.removeImageFromGroup(this.planogramId, this.group))
                    .catch(() => console.log('Remove group image failed'));
            },
            openGroupImageDialog() {
                this.$refs.fileInput.click();
            },
            deleteGroup() {
                this.editor.deleteGroup(this.groupId);
            },
            editGroup() {
                this.$emit('editGroup', this.groupId);
            },
            openProductDetails(productId) {
                this.$productBank.findProductById(productId)
                    .then(r => this.$refs.productDetails.open(r.product, this.repository.categorization));
            }
        }
    };
</script>

<style scoped>

    .groupInfo {
        display: flex;
        flex-direction: column;
    }

    .info {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

    .section {
        padding: var(--doubleMargin);
        flex: 1 1 auto;
        border-bottom: 1px solid var(--elevation-04);
        overflow-y: auto;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }

    .group {
        flex: 0 0 auto;
        max-height: 50%;
    }

    .productsList {
        overflow-y: auto;
        padding-right: 2px;
    }
    
    .product {
        cursor: pointer;
        color: var(--elevation-06) !important;
        border: 1px solid var(--elevation-05) !important; 
        border-radius: 4px !important;
        background: var(--elevation-03) 0% 0% no-repeat padding-box !important;
    }
    .productEan {
        text-decoration: underline;
    }

    .actions {
        flex: 0 0 auto;
        padding: var(--doubleMargin);
        justify-self: flex-end;
    }

    .groupColor {
        display: flex;
        align-items: center;
        height: 32px;
    }
    
    .wideIndicator {
        width: 24px !important;
        margin-right: 12px !important;
    }

</style>