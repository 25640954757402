<template>
    <component
        :is="tag"
        class="label-tag"
        :class="componentClasses"
    >
        <slot>
            {{ label }}
        </slot>
    </component>
</template>

<script>
export default {
    name: 'LabelTag',
    props: {
        tag: {
            type: String,
            default: 'span',
        },
        label: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: 'medium',
        },
    },
    computed: {
        componentClasses() {

            return {
                [`${this.size}`]: this.size,
            };

        },
    },
}
</script>

<style scoped>
.label-tag {
    --primary: #3966ff;
    --on-primary: #f0f5f7;
    --primary-hover: #7eabff;
    --on-primary-hover: #0d161e;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
}

.medium {
    height: 24px;
    min-width: 50px;
    border-radius: 3px;
    font-size: 14px;
    color: white;
}

.small {
    height: 16px;
    min-width: 40px;
    border-radius: 2px;
    font-size: 9px;
    color: var(--elevation-06);
}
</style>