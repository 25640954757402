<template>
    <ch-tree header="Fields" :nodes="nodes" v-model="currentPath"/>
</template>

<script>

    export default {
        computed: {
            currentPath: {
                get() {
                    const path = this.nodes.filter(n => n.id === this.$route.params.fieldId);
                    if (this.$route.name === 'interviews')
                        return [...path, path[0].children[0]];
                    return path;
                },
                set(path) {
                    if (path.length === 0)
                        this.$router.push({name: 'fields'});
                    else if (path.length === 1)
                        this.$router.push({name: 'cells', params: {fieldId: path[0].id}});
                    else
                        this.$router.push({name: 'interviews', params: {fieldId: path[0].id}})
                }
            },
            nodes() {
                return this.$storage.studios.sort((f1, f2) => f1.name.toLowerCase() > f2.name.toLowerCase() ? 1 : -1).map(f => ({
                    id: f.id,
                    text: f.name,
                    children: [{
                        text: 'Interviews',
                        icon: 'menu'
                    }]
                }));
            }
        }
    };
</script>

<style scoped>
</style>
