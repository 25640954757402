<template>
    <ch-form-dialog ref="dialog" title="Import planogram" type="primary" @reset="reset" @submit="importPlanogram"
                    important :close-on-submit="false">
        <ch-field label="Select format">
            <ch-select v-model="formatKey" :items="allowedFormats"/>
        </ch-field>
        <ch-field :label="`Select file (${format.extension})`"
                  :validators="[v => !v ? 'It\'s necessary to select a file' : null]">
            <ch-input-file v-model="fileToImport" :accept="format.extension"/>
        </ch-field>
        <div v-if="formatKey === 'SpaceMan'" y-spaced-2>
            <h2 primary-text>Options</h2>
            <ch-checkbox v-model="resetComplexPositions">Remove complex positions</ch-checkbox>
            <ch-checkbox v-model="computeBaseHeight">Set the base height on the lowest shelf</ch-checkbox>
        </div>
        <div slot="actions" class="dialogFooter" x-spaced-2>
            <ch-button size="small" type="secondary" cancel>Cancel</ch-button>
            <ch-button size="small" type="primary" submit>Import</ch-button>
        </div>
    </ch-form-dialog>
</template>

<script>

    export default {
        name: 'ImportPlanogramDialog',
        data() {
            return {
                fileToImport: null,
                allowedFormats: [
                    {
                        key: 'SpaceMan',
                        extension: '.pln',
                        label: 'SpaceMan'
                    },
                    {
                        key: 'ShelfZoneStudio',
                        extension: '.json',
                        label: 'ShelfZone'
                    }
                ],
                formatKey: 'SpaceMan',
                resetComplexPositions: true,
                computeBaseHeight: false,
                resolve: null,
                reject: null
            };
        },
        computed: {
            /**
             * @return {{key: string, extension: string, label: string}}
             */
            format() {
                return this.allowedFormats.find(f => f.key === this.formatKey);
            }
        },
        watch: {
            formatKey() {
                this.fileToImport = null;
            }
        },
        methods: {
            open() {
                this.$refs.dialog.open();
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                }).finally(() => this.$refs.dialog.close());
            },
            reset() {
                this.fileToImport = null;
                this.formatKey = "SpaceMan";
                this.resetComplexPositions = true;
                this.computeBaseHeight = false;
                this.reject();
            },
            importPlanogram() {
                if (this.fileToImport) {
                    const reader = new FileReader();
                    reader.readAsText(this.fileToImport, 'UTF-8');
                    reader.onload = e => {
                        this.$loading.await(
                            this.$planogram.importPlanogram(e.target.result, this.formatKey, this.resetComplexPositions, this.computeBaseHeight)
                                .then(response => this.resolve(response.planogram))
                                .catch(error => this.reject(error))
                        );
                    };
                    reader.onerror = e => this.reject(e);
                }
            }
        }
    };
</script>

<style scoped>

</style>
