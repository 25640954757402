<template>
    <div class="stepper">
        <div
            v-for="(step,index) in steps"
            :key="`${index}-${step}`"
            class="step-container"
        >
            <div class="step">
                <div
                    class="step-number"
                    :class="{
                        'step-number-current': index === currentStep
                    }"
                    @click="index !== currentStep && $emit(
                        'step-clicked',
                        index
                    )"
                >
                    {{index + 1}}
                </div>
                <p
                    class="step-description"
                    :class="{
                        'step-description-current': index === currentStep
                    }"
                >
                    <span>{{step}}</span>
                </p>
            </div>
            <div
                v-if="index !== steps.length - 1"
                class="step-divider"
            />
        </div>
    </div>
</template>

<script>
export default {
    /*
        WARNING:
        Currently the max number of steps this component could handle is 7.
        (for a minumum resolution of 1600px)
        Designers should come up with a solution for higher number of steps.
    */
    props: {
        /*
        * Sets the steps
        */
       steps: {
           type: Array,
            default: () => []
        },
        /*
        * Sets the current step
        */
        currentStep: {
            type: Number,
            default: 0,
        },
    },
};
</script>


<style scoped>
.stepper {
    display: flex;
}

.step-container {
    display: flex;
    margin-left: 8px;
}

.step {
    width: 140px;
    display: flex;
    align-items: center;
}

.step-number {
    width: 32px;
    height: 32px;

    text-align: center;
    line-height: 32px;

    background-color: var(--elevation-04);
    color: var(--elevation-06);

    border-radius: 100%;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
}

.step-description {
    width: 92px;
    height: 40px;

    display: flex;
    align-items: center;

    line-height: 18px;
    margin-left: 8px;
    color: var(--elevation-05);
}

.step-description-current {
    color: var(--elevation-06);
}

.step-divider {
    width: 76px;
    height: 2px;
    margin: 16px 8px 0 8px;
    border-bottom: 2px solid var(--elevation-05);
}

.step-number-current {
    background-color: var(--primary);
    color: var(--elevation-01);
}
</style>