<template>
  <ch-application-layout>
    <template slot="main">
      <div class="center">
        <h1 class="mainText">404</h1>
        <h2 class="secondText">Page not found</h2>
        <span>Sorry! This page doesn’t exist.</span>
        <div x-spaced-1_2 class="action">
          <span>Go back to the</span>
          <span class="actionWord" @click="toProjects">projects page</span>
        </div>
      </div>
    </template>
  </ch-application-layout>
</template>

<script>

export default {
  name: "PageNotFound",
  methods: {
    toProjects() {
      this.$router.push({name: 'projects'});
    }
  },
  mounted() {
    document.title = "Page not found | ShelfZone Studio";
  }
}
</script>

<style scoped>
*{
  color: var(--elevation-05)
}
.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.mainText{
  font-size: 100px;
  margin-bottom: var(--quadrupleMargin);
}
.secondText{
  font-size: 40px;
  margin-bottom: var(--doubleMargin);
}
.action{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.actionWord{
  text-decoration: underline;
  cursor: pointer;
}
.actionWord:hover{
  color: var(--primary);
}
</style>
