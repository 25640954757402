<template>
    <ch-dialog ref="dialog" type="primary" title="Planogram import report" size="fullscreen" important
               class="importReport">
        <ch-application-layout slot="main" headerSize="fit">
            <div slot="header" y-spaced-2 v-if="report">
                <div>
                    <div>{{report.productsImported}} products imported:</div>
                    <div>- {{report.productsFound}} products found</div>
                    <div error-text>- {{report.productsNotFound}} products not found in catalog</div>
                    <div alert-text>- {{report.productsWithMultipleEan}} products with same EAN: please review the
                        selection
                    </div>
                </div>
                <ch-checkbox v-model="usePlanogramSize">Use my planogram dimensions</ch-checkbox>
                <ch-filters>
                    <div>
                        <ch-radio-group size="small narrow" v-model="productType">
                            <ch-radio-button label="all">All</ch-radio-button>
                            <ch-radio-button label="found">Found</ch-radio-button>
                            <ch-radio-button label="notFound">Not Found</ch-radio-button>
                            <ch-radio-button label="multiple">Same EAN</ch-radio-button>
                        </ch-radio-group>
                    </div>
                    <div></div>
                    <div>
                        <ch-search-bar v-model="query" size="narrow" real-time/>
                    </div>
                </ch-filters>
            </div>
            <div slot="main" class="products">
                <ch-loader v-if="loading" ref="loader"/>
                <template v-for="product of filteredProducts">
                    <product-selection-table v-if="productsByEan" :key="product.ean"
                                             v-model="chosenProducts[product.ean]"
                                             :product="product"
                                             :disable-size="usePlanogramSize"
                                             :available-products="productsByEan[product.ean]"
                                             @showProductDetails="showProductDetails"/>
                </template>
                <ch-empty-content v-if="products.length > 0 && filteredProducts.length === 0"
                                  first-message="No products match the query"/>
                <ch-empty-content v-if="products.length === 0" first-message="No products found in the planogram"/>
            </div>
        </ch-application-layout>
        <template slot="footer">
            <ch-button type="secondary" size="small" @click="close">Cancel</ch-button>
            <ch-button type="primary" size="small" @click="importPlanogram">Import</ch-button>
            <product-details ref="productDetails"/>
        </template>
    </ch-dialog>
</template>

<script>
    import ProductDetails        from '../../../../skucatalog/ProductDetails';
    import {ProductService}      from '../../services/ProductService';
    import {PlanogramService}    from '../../services/PlanogramService';
    import ProductSelectionTable from '../components/ProductSelectionTable';

    export default {
        name: 'ImportPlanogramReportDialog',
        components: {ProductSelectionTable, ProductDetails},
        data() {
            return {
                categorization: null,
                planogram: null,
                products: [],
                productsByEan: null,
                chosenProducts: null,
                loading: false,
                productType: 'all',
                usePlanogramSize: true,
                query: null
            };
        },
        computed: {
            filteredProducts() {
                let filteredProducts = this.query ? this.products.filter(p => p.ean.includes(this.query)) : this.products;
                switch (this.productType) {
                    case 'found':
                        filteredProducts = filteredProducts.filter(e => !this.isSelected(e));
                        break;
                    case 'notFound':
                        filteredProducts = filteredProducts.filter(this.isSelected);
                        break;
                    case 'multiple':
                        filteredProducts = filteredProducts.filter(p => this.existMultipleProductsWithSameEan(p.ean));
                        break;
                }
                return filteredProducts;
            },
            report() {
                if (!this.productsByEan) return null;
                const productsImported = this.products.length;
                const productsNotFound = this.products.filter(this.isSelected).length;
                const productsFound = productsImported - productsNotFound;
                const productsWithMultipleEan = this.products.filter(p => this.existMultipleProductsWithSameEan(p.ean)).length;
                return {productsImported, productsFound, productsNotFound, productsWithMultipleEan};
            }
        },
        methods: {
            open(planogram) {
                this.$refs.dialog.open();
                this.planogram = planogram;
                this.loading = true;
                this.$loading.await(Promise.all([
                    this.retrieveCategorization(),
                    this.retrieveProducts(planogram.productSet)
                ]));
            },
            retrieveCategorization() {
                return this.$studio.findMostRecentOwnCategorization()
                    .then(r => this.categorization = r.foundCategorization);
            },
            retrieveProducts(products) {
                return this.$productBank.findProductsByEanList(products.map(p => p.ean))
                    .then(response => {
                        this.products = products;
                        this.productsByEan = {};
                        this.chosenProducts = this.products.reduce((o, p) => {
                            o[p.ean] = p.id;
                            return o;
                        }, {});
                        response.productsSummaries.forEach(product => {
                            if (!(product.ean in this.productsByEan))
                                this.productsByEan[product.ean] = [];
                            this.productsByEan[product.ean].push(product);
                        });
                        this.chosenProducts = Object.keys(this.productsByEan)
                            .map(ean => this.productsByEan[ean][0])
                            .reduce((o, p) => {
                                o[p.ean] = p.id;
                                return o;
                            }, this.chosenProducts);
                    });
            },
            isSelected(product) {
                return this.chosenProducts[product.ean] === product.id;
            },
            showProductDetails(productId) {
                this.$loading.await(
                    this.$productBank.findProductById(productId)
                        .then(response => this.$refs.productDetails.open(response.product, this.categorization))
                );
            },
            existMultipleProductsWithSameEan(ean) {
                const productsInCatalog = this.productsByEan[ean];
                return productsInCatalog ? productsInCatalog.length > 1 : false;
            },
            importPlanogram() {
                const productsInCatalogIds = Object.values(this.chosenProducts).filter(id => !this.products.some(p => p.id === id));
                this.$loading.await(
                    this.$productBank.findProductsById(productsInCatalogIds)
                        .then(response => response.foundProducts.map(ProductService.translateProductFromProductBank))
                        .then(products => {
                            const productSizeReplacer =
                                this.usePlanogramSize
                                    ? ([oldP, newP]) => {
                                        newP.width = oldP.width;
                                        newP.height = oldP.height;
                                        newP.depth = oldP.depth;
                                        return [oldP, newP];
                                    }
                                    : e => e;
                            const replaceMap = this.products.map(oldProduct => [oldProduct, products.find(newProduct => newProduct.ean === oldProduct.ean)])
                                .filter(([, newProduct]) => newProduct)
                                .map(productSizeReplacer)
                                .reduce((o, v) => {
                                    o[v[0].id] = v[1];
                                    return o;
                                }, {});
                            const planogram = PlanogramService.replaceProducts(this.planogram, replaceMap);
                            return this.$planogram.createPlanogram(planogram, this.$storage.workGroupId);
                        })
                ).then(response => this.$router.push({
                    name: 'planogram',
                    params: {planogramId: response.planogramId}
                }));
            },
            close() {
                this.$refs.dialog.close();
                this.categorization = null;
                this.planogram = null;
                this.chosenProducts = null;
                this.productsByEan = null;
            }
        }
    };
</script>

<style scoped>


    .products {
        overflow-y: auto;
        padding: 0 var(--quadrupleMargin);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

</style>
