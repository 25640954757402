<template>
	<div id="app" class="theme" :research="research">
		<router-view/>
		<ch-navigation-transition/>
		<vue-snotify class="toasts"/>
		<ch-global-loader/>
		<ch-modal ref="modal"/>
		<div id="offscreenCanvas" name="offscreenCanvas"></div>
	</div>
</template>

<script>

export default {
	name: 'app',
	computed: {
		research() {
			return this.$route.path.startsWith('/fields');
		}
	},
	mounted() {
		this.$conn.onNotifyReceived.push(this.onNotify);
	},
	beforeDestroy() {
		this.$conn.onNotifyReceived.splice(this.$conn.onNotifyReceived.indexOf(this.onNotify), 1);
	},
	methods: {
		onNotify(notifyData) {
			switch (notifyData.eventType) {
				case 'BuildStarted': {
					const project = this.$storage.findProjectById(notifyData.projectId);
					if (project)
						this.$snotify.success(`VR package generation for ${project.name} started.`, {
							timeout: 5000,
							showProgressBar: false
						});

					break;
				}
				case 'BuildCompletedWithSuccess': {
					const project = this.$storage.findProjectById(notifyData.projectId);
					if (project) {
						project.buildInProgress = false;
						project.lastBuildFailed = false;
						project.lastPackageDate = new Date().toISOString();
						this.$snotify.success(`VR package for ${project.name} is ready.`, {
							timeout: 5000,
							showProgressBar: false
						});
					}
					break;
				}
				case 'BuildCompletedWithFailure': {
					const project = this.$storage.findProjectById(notifyData.projectId);
					if (project) {
						project.buildInProgress = false;
						project.lastBuildFailed = true;
						this.$snotify.error(`VR package generation for ${project.name} failed.`, {
							timeout: 5000,
							showProgressBar: false
						});
					}
					break;
				}
				case 'IdentityEnabledStateChanged':
					this.$refs.modal.open(
						'Disabled Account',
						'Your account has been disabled<br/>Contact your administrator.',
						'error',
						'OK',
						'Cancel',
						'medium',
						false
					).then(() => {
						this.$downloadManager.emptyDownloads();
						this.$store.commit('logout');
						this.$router.push('login');
					});
					break;
				case 'MemberRemovedFromWorkGroup':
					if (this.$storage.workGroupId === notifyData.workGroupId) {
						this.$iam.findWorkGroup(notifyData.workGroupId)
							.then(({workGroup}) =>
								this.$refs.modal.open(
									'Access denied',
									[
										`You have been removed from ${workGroup.name}.`,
										`Unsaved edits won't be saved and you won't be able to switch to the workgroup anymore.`,
										`For further information please contact your administrator.`
									],
									'error',
									'Ok',
									'Cancel',
									'medium',
									false,
									true
								))
							.then(() => this.$router.push({name: 'accountSwitch'}));
					}
					break;
				case 'AdminRoleGrantedToMember':
					this.$iam.findWorkGroup(notifyData.workGroupId)
						.then(({workGroup}) => workGroup.members.find(m => m.identityId === notifyData.identityId).isAdmin = true);
					break;
				case 'AdminRoleRevokedFromMember':
					this.$iam.findWorkGroup(notifyData.workGroupId)
						.then(({workGroup}) => workGroup.members.find(m => m.identityId === notifyData.identityId).isAdmin = false);
					break;
				default:
					break;
			}
		}
	}
};
</script>
<style scoped>
@import "~vue-snotify/styles/material.css";

#app {
	display: grid;
	width: 100vw;
	height: 100vh;
	padding: 0;
	margin: 0;
	overflow-y: hidden;
	overflow-x: auto;
}

#offscreenCanvas {
	position: fixed;
	bottom: 10px;
	right: -60px;
	width: 60px;
	height: 40px;
	overflow: hidden;
}

</style>

<style>	
	#app[research], .researchColors {
		--primary-border: none;
		--primary-hover: #87C9E9;
		--on-primary-hover: #0D161E;
		--primary: #5498B7;
		--on-primary: #0D161E; 
	}

	.primaryColors {
		--primary-border: none;
		--primary-hover: #66FFCE;
		--on-primary-hover: #0D161E;
		--primary: #00E69D;
		--on-primary: #0D161E;
	}
</style>

