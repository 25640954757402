<template>
    <div y-spaced-2>
        <h2 primary-text>Aisle guide</h2>
        <div>{{element.elements.length}} elements</div>
        <div y-spaced-1>
            <ch-field label="Width (cm)" label-position="left">
                <ch-number-input type="primary" size="fill" :value="element.length / 10" :min="10" :step="10"
                                 @input="v => resize(v * 10)"/>
            </ch-field>
            <transform :controller="controller" :element="element"/>
        </div>
        <delete-selection-button text="Remove Guide"/>
    </div>
</template>

<script>
    import LayoutController      from '../services/LayoutController';
    import Transform             from './Transform';
    import DeleteSelectionButton from './DeleteSelectionButton';

    export default {
        name: 'FreeLineInfo',
        components: {DeleteSelectionButton, Transform},
        props: {
            controller: LayoutController,
            element: Object
        },
        methods: {
            resize(length) {
                this.$unityManager.layoutAPI.ResizeFreeLine(this.element.id, length);
            }
        }
    };
</script>

<style scoped>

</style>