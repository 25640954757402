<template>
  <ch-application-layout v-if="field">
    <fields-navigation slot="navigation" />
    <template slot="version">ShelfZone Studio v. {{ version }}</template>
    <div slot="header" class="header">
      <div class="fieldName" x-spaced-2>
        <ch-icon icon="arrow_back" @click="returnToFields()" :size="28" />
        <h2>{{ field.name }}</h2>
      </div>
      <div class="fieldActions" x-spaced-1>
        <ch-button
          type="primary"
          class="primaryColors"
          justified
          @click="goToProject"
          :disabled="!hasProject"
        >
          View project
          <ch-icon icon="reply" class="mirrored"></ch-icon>
        </ch-button>
        <ch-button type="primary" justified @click="manageTags()"
          >Manage tags
          <ch-icon icon="local_offer" class="outlined" />
        </ch-button>
      </div>
    </div>
    <template slot="main">
      <ch-table
        v-if="environments.length > 0"
        v-for="environment of environments"
        :items="environment.cells"
        :key="environment.name"
        @rowClicked="goToAnalysis"
        row-height="narrow"
        class="cellsTable"
      >
        <template slot="header">
          <ch-th name="Cell" />
          <ch-th name="Created at" />
          <ch-th name="N. of recordings" />
          <ch-th />
        </template>
        <template slot-scope="{ row }">
          <ch-td>{{ row.name }}</ch-td>
          <ch-td>{{
            row.creationDate ? formattedDataFromISO(row.creationDate) : "None"
          }}</ch-td>
          <ch-td>{{ row.recordings.length }}</ch-td>
          <ch-td></ch-td>
        </template>
      </ch-table>
      <ch-empty-content
        v-else
        firstMessage="You haven’t created any cells yet"
      />
    </template>
    <template slot="footer">
      <create-package ref="createPackageDialog" />
      <!-- TODO: check if 'outdatedPackageDialog' is needed -->
      <outdated-package ref="outdatedPackageDialog" />
      <manage-tags
        ref="manageTags"
        :field="field"
        type="primary"
        title="Manage tags"
      />
    </template>
  </ch-application-layout>
</template>

<script>
import TimeFormatMixin from "@/mixins/TimeFormatMixin";
import CreatePackage from "../projectsmanagement/dialog/CreatePackage";
import OutdatedPackage from "../projectsmanagement/dialog/DownloadOutdatedPackage";
import ManageTags from "../research/components/tags/ManageTagsDialog";
import FieldsNavigation from "./FieldsNavigation";
import StaticConfig from "../config.js";

export default {
  components: {
    FieldsNavigation,
    OutdatedPackage,
    CreatePackage,
    ManageTags,
  },
  mixins: [TimeFormatMixin],
  props: {
    fieldId: String,
    empty: Boolean,
  },
  data() {
    return {
      field: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    window["studioApi"].storage
      .findFieldById(to.params.fieldId)
      .then((studio) => studio.fetchData())
      .then((foundField) => next((vm) => (vm.field = foundField)))
      .catch((err) => next(err));
  },
  beforeRouteUpdate(to, from, next) {
    window["studioApi"].storage
      .findFieldById(to.params.fieldId)
      .then((studio) => studio.fetchData())
      .then((foundField) => {
        this.field = foundField;
        next();
      })
      .catch((err) => next(err));
  },
  computed: {
    version() {
      return StaticConfig.version;
    },
    project() {
      return this.$storage.findProjectByFieldId(this.fieldId);
    },
    environments() {
      if (!this.field) return [];
      return this.$storage.environments
        .map((environment) => ({
          name: environment.name,
          cells: this.field.cells.filter((cell) =>
            environment.getCustomizationById(cell.environmentCustomizationId)
          ),
        }))
        .filter((e) => e.cells.length > 0);
    },

    hasProject() {
      return !!this.project;
    },
  },
  watch: {
    field() {
      if (this.field) document.title = this.field.name + " | ShelfZone Studio";
    },
  },
  methods: {
    returnToFields() {
      this.$router.push({ name: "fields" });
    },
    goToProject() {
      this.$router.push({
        name: "projectDetails",
        params: { projectId: this.project.id },
      });
    },
    goToAnalysis(cell) {
      if (!this.project || this.project.lastPackageDate)
        this.$router.push({
          name: "analysis",
          params: { fieldId: this.field.id, cellId: cell.id },
        });
      else alert("Cells can not be viewed until a package is created");
    },
    createPackage() {
      this.$refs.createPackageDialog.open(this.project);
    },
    update() {
      this.$refs.createPackageDialog.open(this.project, true);
    },
    manageTags() {
      this.$refs.manageTags.open();
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.fieldName {
  display: flex;
  align-items: center;
}

.cellsTable {
  box-sizing: border-box;
  padding: 0 var(--quadrupleMargin) var(--quadrupleMargin)
    var(--quadrupleMargin);
}

.outlined >>> i {
  font-family: "Material Icons Outlined";
}

.mirrored {
  --webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
</style>
