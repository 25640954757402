<template>
	<ch-dialog ref="dialog" :title="`${workGroupName} - Users`" @cancel="close" type="primary"
	           :close-on-submit="false" important>
		<div slot="body" v-if="workGroup">
			<find-members-list v-model="members"/>
		</div>
		<template slot="footer">
			<ch-button cancel type="secondary" size="small" @click="close">Cancel</ch-button>
			<ch-button submit type="primary" size="small" @click="addMembers">Save</ch-button>
		</template>
	</ch-dialog>
</template>

<script>
import FindMembersList from '@/user/workgroups/components/FindMembersList';

export default {
	name: 'EditMembersDialog',
	components: {FindMembersList},
	data() {
		return {
			workGroup: null,
			members: [],
			resolve: null,
			reject: null
		};
	},
	computed: {
		workGroupName() {
			return this.workGroup ? this.workGroup.name : '';
		},
	},
	methods: {
		open(workGroup) {
			this.workGroup = workGroup;
			this.members = JSON.parse(JSON.stringify(workGroup.members))
			this.$refs.dialog.open();
			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},
		close() {
			this.$refs.dialog.close();
			this.workGroup = null;
			this.members = this.newMembers = [];
			this.reject();
			this.resolve = this.reject = null;
		},
		addMembers() {
			const allIds = [...new Set([...this.members.map(m => m.identityId), ...this.workGroup.members.map(m => m.identityId)])];
			const addedMembers = [], removedMembers = [], adminMembers = [], userMembers = [];
			allIds.forEach(id => {
				const oldMember = this.workGroup.members.find(m => m.identityId === id);
				const newMember = this.members.find(m => m.identityId === id);
				if(!oldMember && newMember)
					addedMembers.push(newMember)
				else if(oldMember && !newMember)
					removedMembers.push(id)
				else if(!oldMember.isAdmin && newMember.isAdmin)
					adminMembers.push(id)
				else if(oldMember.isAdmin && !newMember.isAdmin)
					userMembers.push(id)
			})
			this.$loading.await(
				Promise.all([
					this.$iam.addMembersToWorkGroup(this.workGroup.id, addedMembers),
					this.$iam.removeMembersFromWorkGroup(this.workGroup.id, removedMembers),
					this.$iam.grantMembersAdminRole(this.workGroup.id, adminMembers),
					this.$iam.revokeMembersAdminRole(this.workGroup.id, userMembers)
				])
			).then(() => {
				this.resolve();
				this.close();
			});
		}
	}
};
</script>

<style scoped>

</style>
