<template>
    <ch-modal ref="modal"/>
</template>

<script>
    export default {
        name: "DownloadOutdatedPackage",
        methods: {
            open(project, olderVersion = true) {
                this.$refs.modal
                    .open(
                        olderVersion ? 'Package is updating' : 'Package outdated',
                        olderVersion ?
                            'You are downloading an outdated version, Once the update process is completed this version will be replaced with the new one, do you wish to download it anyway?' :
                            'This package has been generated before the creation of the field without the research capabilities. You need to generate an updated version of the package. Download this version anyway?',
                        'alert',
                        'Download'
                    )
                    .then(() => this.$projectDownloadManager.downloadProject(project))
                    .catch(() => {
                    })

            }
        }
    };
</script>