<template>
</template>

<script>
    export default {
        name: 'InactivityTracker',
        props: {
            maximumTime: Number,
            /*
            * Disables the User activity timeout
            */
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                resetToken: null
            };
        },
        mounted() {
            window.addEventListener('mousemove', this.resetUserActivityTimeout);
            window.addEventListener('scroll', this.resetUserActivityTimeout);
            window.addEventListener('keydown', this.resetUserActivityTimeout);
            window.addEventListener('resize', this.resetUserActivityTimeout);
            this.resetUserActivityTimeout();
        },
        beforeDestroy() {
            window.removeEventListener('mousemove', this.resetUserActivityTimeout);
            window.removeEventListener('scroll', this.resetUserActivityTimeout);
            window.removeEventListener('keydown', this.resetUserActivityTimeout);
            window.removeEventListener('resize', this.resetUserActivityTimeout);
            window.clearTimeout(this.resetToken);
        },
        methods: {
            resetUserActivityTimeout() {
                window.clearTimeout(this.resetToken);
                if( ! this.disabled )
                    this.resetToken = window.setTimeout(() => this.$emit('inactive'), this.maximumTime * 60000);
            },
        },
        watch: {
            disabled() {

                this.resetUserActivityTimeout();

            },
        },
    };
</script>

<style scoped>
</style>
