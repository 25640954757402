<template>
    <ch-modal ref="modal" class="researchColors"/>
</template>

<script>
    export default {
        name: "CreateField",
        methods: {
            open(project) {
                this.project = project;
                this.$refs.modal.open(
                    'Create research field',
                    [`You are going to create a research field for ${project.name}.`, 'A research cell will be created for each configuration.'],
                    'primary',
                    'Create'
                )
                    .then(() =>
	                    this.$loading.await(project.createField())
		                    .then(field => this.$emit("confirm", {field, project}))
                    )
                    .catch(() => {
                    });
            }
        }
    };
</script>
