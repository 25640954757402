<template>
	<ch-form-dialog ref="dialog" title="Create a workgroup" @reset="reset" type="primary" @submit="createWorkGroup"
	                :close-on-submit="false" important>
		<ch-field label="Workgroup name" :validators="[nameValidator]">
			<ch-text-input placeholder="Name" v-model="name"/>
		</ch-field>
		<ch-field label="Description (max 300 characters)" :validators="[descriptionValidator]">
			<ch-text-area placeholder="Description" v-model="description"/>
		</ch-field>
		<find-members-list v-model="members"/>
		<ch-checkbox v-model="switchAfterCreation">Switch to workgroup after creation</ch-checkbox>
		<template slot="actions">
			<ch-button cancel type="secondary" size="small">Cancel</ch-button>
			<ch-button submit type="primary" size="small">Create</ch-button>
		</template>
	</ch-form-dialog>
</template>

<script>
import FindMembersList from '@/user/workgroups/components/FindMembersList';
import {nameValidator, descriptionValidator} from '@/user/workgroups/services/Validators';

export default {
	name: 'CreateWorkGroupDialog',
	components: {FindMembersList},
	data() {
		return {
			name: null,
			description: null,
			currentUser: {
				identityId: this.$storage.user.id,
				isAdmin: true
			},
			members: [],
			switchAfterCreation: true,
			resolve: null,
			reject: null
		};
	},
	methods: {
		open() {
			this.$refs.dialog.open();
			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},
		close() {
			this.$refs.dialog.close();
		},
		reset() {
			this.name = null;
			this.description = null;
			this.members = [];
			this.reject();
			this.resolve = this.reject = null;
		},
		nameValidator,
		descriptionValidator,
		createWorkGroup() {
			const members = this.members;
			this.$loading.await(
				this.$iam.createWorkGroupWithMembers(this.name, this.description, members)
			).then(workGroupCreated => {
				this.resolve(workGroupCreated.workGroup);
				this.close();
				if (this.switchAfterCreation)
					this.$router.push({name: 'accountSwitch', params: {workGroupId: workGroupCreated.id}});
			});
		}
	}
};
</script>

<style scoped>

</style>
