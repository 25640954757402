<template>
	<ch-dialog ref="dialog" title="Switch to" type="primary" @cancel="close" @confirm="switchAccount">
		<div slot="body" y-spaced-2>
			<div class="userAccount" :active="accountId === currentIdentityId" @click="accountId = currentIdentityId">
				<identity-info :id="currentIdentityId" hide-you/>
			</div>
			<div class="title">
				<h2>Workgroups</h2>
				<ch-button type="primary" justified @click="createWorkGroup">New workgroup
					<ch-icon icon="add_circle_outline"/>
				</ch-button>
			</div>
			<ch-search-bar v-if="workGroups.length > 0" v-model="matching" size="fill" real-time/>
			<div class="workGroups">
				<ch-list v-if="filteredWorkGroups.length > 0" v-model="accountId" selectable="single">
					<ch-list-item v-for="workGroup of filteredWorkGroups" :key="workGroup.id" :value="workGroup.id">
						<div x-spaced-1>
							<ch-icon icon="people"/>
							<a @click="goToWorkGroup(workGroup.id)">{{ workGroup.name }}</a>
						</div>
					</ch-list-item>
				</ch-list>
				<ch-empty-content v-else-if="workGroups.length === 0" @opendialog="createWorkGroup"
				                  first-message="There are no workgroups yet."
				                  second-message="Create a workgroup"/>
				<ch-empty-content v-else first-message="No results matched your search."/>
			</div>
			<create-work-group-dialog ref="createWorkGroupDialog"/>
		</div>
		<div slot="footer" x-spaced-1>
			<ch-button type="secondary" size="small" @click="close">Cancel</ch-button>
			<ch-button type="primary" :disabled="currentAccountId === accountId" size="small" @click="switchAccount">
				Switch
			</ch-button>
		</div>
	</ch-dialog>
</template>

<script>
import IdentityInfo from '@/user/workgroups/components/IdentityInfo';
import CreateWorkGroupDialog from '@/user/workgroups/dialog/CreateWorkGroupDialog';
import WorkGroupSummary from '@/plugins/studioapi/api/iam/model/WorkGroupSummary';

export default {
	name: 'SwitchToWorkGroupDialog',
	components: {CreateWorkGroupDialog, IdentityInfo},
	data() {
		return {
			workGroups: [],
			accountId: null,
			matching: null,
			waitingForGroupCreation: false
		};
	},
	computed: {
		currentIdentityId() {
			return this.$storage.user.id;
		},
		currentAccountId() {
			return this.$storage.accountId;
		},
		filteredWorkGroups() {
			const workGroups = this.matching
				? this.workGroups.filter(w => w.name.toLowerCase().includes(this.matching.toLowerCase()))
				: this.workGroups;
			return workGroups.sort((w1, w2) => {
				if(w1.id === this.currentAccountId) return -1;
				if(w2.id === this.currentAccountId) return 1;
				return w1.name < w2.name ? -1 : 1;
			});
		}
	},
	methods: {
		open() {
			this.$loading.await(
				this.$iam.listWorkGroups()
					.then(response => this.workGroups = response.workGroups)
			);
			this.accountId = this.currentAccountId;
			this.$refs.dialog.open();
		},
		close() {
			if (!this.waitingForGroupCreation)
				this.$refs.dialog.close();
		},
		createWorkGroup() {
			this.waitingForGroupCreation = true;
			this.$refs.createWorkGroupDialog.open()
				.then(workGroup => {
					this.workGroups.push(WorkGroupSummary.fromWorkGroup(workGroup, this.$storage.user.id))
				})
				.finally(() => this.waitingForGroupCreation = false);
		},
		switchAccount() {
			if (this.accountId !== this.currentAccountId) {
				const workGroupId = this.accountId === this.currentIdentityId ? null : this.accountId;
				this.$router.push({name: 'accountSwitch', params: {workGroupId}});
			}
		},
		goToWorkGroup(workGroupId) {
			this.$router.push({name: 'workgroup', params: {workGroupId}})
			this.close();
		}
	}
};
</script>

<style scoped>

.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.workGroups {
	height: 300px;
	overflow-y: auto;
}

.userAccount {
	cursor: pointer;
	display: flex;
	align-items: center;
	background-color: var(--elevation-03);
	border-radius: 4px;
	padding: var(--singleMargin);
	height: 32px;
	box-sizing: border-box;
}

.userAccount[active] {
	box-shadow: 0 0 0 2px var(--primary);
}

a {
	text-decoration: underline;
}

a:hover {
	color: var(--primary);
}

</style>
