<template>
    <ch-form-dialog ref="dialog" title="Export planogram" type="primary" @cancel="cancel" @submit="exportPlanogram">
        <ch-field label="Choose your format to download">
            <ch-radio-group v-model="format">
                <ch-radio v-for="allowedFormat of allowedFormats" :label="allowedFormat.value">{{allowedFormat.label}}</ch-radio>
            </ch-radio-group>
        </ch-field>
    </ch-form-dialog>
</template>

<script>

import {download, MediaType} from '@/utils/DownloadService';

    export default {
        name: 'ExportPlanogramDialog',
        data() {
            return {
                planogram: null,
                allowedFormats: [
                    {
                        value: 'ShelfZoneStudio',
                        extension: '.json',
                        label: 'ShelfZone Studio (.json)'
                    }
                ],
                format: 'ShelfZoneStudio'
            }
        },
        methods: {
            /**
             * @param {Planogram} planogram
             */
            open(planogram) {
                this.planogram = planogram;
                this.$refs.dialog.open();
            },
            cancel() {
                this.format = 'studio';
            },
            exportPlanogram() {
                const extension = this.allowedFormats.find(f => f.value === this.format).extension
                this.$conn.exportPlanogram(this.planogram.id, this.format)
                    .then(exportedPlanogram => {
                    	let mediaType;
                    	if(this.format === 'studio')
                    		mediaType = MediaType['text/json'];
                    	download(exportedPlanogram, `${this.planogram.name.trim()}${extension}`, mediaType)
                    });
            }
        }
    }
</script>

<style scoped>

</style>
