<template>
	<ch-loading-icon v-if="loading"/>
	<span class="user" :disabled="disabled" v-else-if="identity" x-spaced-1>
		<span class="avatar" :outlined="avatarOnly && isYou" :style="avatarStyle"><span class="initial">{{visibleName[0]}}</span></span>
		<span v-if="!avatarOnly" ellipsis>
			<span>{{ visibleName }}{{ isYou && !hideYou ? ' - You' : '' }}</span>
			<span><slot name="append"></slot></span>
		</span>
	</span>
</template>

<script>
import {ColorService} from '@/spaceallocation/layouteditor/services/ColorService.js';

export default {
	name: 'IdentityInfo',
	props: {
		id: String,
		avatarOnly: Boolean,
		hideYou: Boolean,
		disabled: Boolean
	},
	data() {
		return {
			loading: false,
			identity: null,
			promise: null
		};
	},
	computed: {
		isYou() {
			return this.$storage.user.id === this.id;
		},
		visibleName() {
			return this.identity.identityInfo.fullName || this.identity.identityInfo.name
		},
		avatarStyle() {
			const bgColor = ColorService.colorFromString(this.id);
			return `background-color: ${bgColor}`;
		}
	},
	watch: {
		id: {
			handler() {
				this.updateIdentity()
			},
			immediate: true
		}
	},
	methods: {
		updateIdentity() {
			//It's necessary to enqueue the changes
			//Otherwise if one of the promise is served before the old one the shown data will be stale
			if(!this.promise) {
				this.loading = true;
				this.promise = this.$iam.findIdentity(this.id)
					.then(response => this.identity = response.identity)
					.finally(() => {
						this.loading = false;
						this.promise = null;
					});
			} else {
				this.promise.finally(() => this.updateIdentity())
			}
		}
	}
};
</script>

<style scoped>

.user {
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.avatar {
	flex: 0 0 auto;
	display: inline-flex;
	justify-content: center;
	width: 20px;
	height: 20px;
	text-transform: uppercase;
	border-radius: 10px;
	user-select: none;
	box-sizing: content-box;
	font-size: 11px;
}
.avatar > .initial {
	background-color: inherit;
	color: transparent;
	-webkit-background-clip: text;
	filter: invert(1) grayscale(1) contrast(9);
}

.avatar[outlined] {
	box-shadow: 0 0 0 1px var(--elevation-06);
}

.user[disabled] .avatar {
	background-color: var(--disabled) !important;
	color: var(--on-disabled) !important;
}

</style>
