<template>
    <ch-dialog ref="dialog" @confirm="openExportProgressDialog" @cancel="close" title="Export Catalog" type="primary">
        <template slot="body">
            <ch-dl label-width="90%">
                <ch-dd label="Use filter">
                    <ch-toggle v-model="useFilter"/>
                </ch-dd>
                <ch-dd label="Id">
                    <ch-toggle v-model="id"/>
                </ch-dd>
                <ch-dd label="Info">
                    <ch-toggle v-model="info"/>
                </ch-dd>
                <ch-dd label="Size">
                    <ch-toggle v-model="size"/>
                </ch-dd>
                <ch-dd label="Category">
                    <ch-toggle v-model="category"/>
                </ch-dd>
                <ch-dd label="Prototype">
                    <ch-toggle v-model="prototype"/>
                </ch-dd>
            </ch-dl>
        </template>
        <template slot="footer">
            <ch-button @click="close" size="small">Cancel</ch-button>
            <ch-button type="primary" @click="openExportProgressDialog" size="small">Download</ch-button>
        </template>
    </ch-dialog>
</template>

<script>

    export default {
        name: 'ExportCatalogDialog',
        data() {
            return {
                useFilter: true,
                id: true,
                info: true,
                size: true,
                category: true,
                prototype: true,
            };
        },

        methods: {
            reset() {
                this.useFilter = true;
                this.id = true;
                this.info = true;
                this.size = true;
                this.category = true;
            },
            open() {
                this.$refs.dialog.open();
            },
            close() {
                this.reset();
                this.$refs.dialog.close();
            },
            openExportProgressDialog(){
                this.$emit('openExportProgress', {
                    useFilter : this.useFilter,
                    id : this.id,
                    info : this.info,
                    size : this.size,
                    category : this.category,
                    prototype: this.prototype
                });
                this.close();
            }
        }
    };
</script>

<style scoped>


</style>
