<template>
	<div class="propCard">
		<div class="body">
			<ch-image :src="prop.imageURL"/>
		</div>
		<div class="footer">
			<div ellipsis>{{ prop.name }}</div>
			<div ellipsis><size :width="prop.size.w" :height="prop.size.h" :depth="prop.size.d"/></div>
		</div>
	</div>
</template>

<script>
import Size from '@/components/Size';
export default {
	name: 'PropCard',
	components: {Size},
	props: {
		prop: Object
	}
};
</script>

<style scoped>

.propCard {
	padding: 0 !important;
	background-color: var(--elevation-03) !important;
	color: var(--on-elevation-03) !important;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	cursor: pointer;
}

.body {
	flex: 1 1 auto;
	padding: var(--doubleMargin);
	display: flex;
	overflow: hidden;
}

.footer {
	flex: 0 0 auto;
	padding: var(--halfMargin);
	background-color: var(--elevation-04) !important;
	color: var(--on-elevation-04) !important;
}

</style>