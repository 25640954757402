<template>
    <div>
	    <div class="section">
		    <h1 primary-text>User account</h1>
		    <div class="sectionContent" y-spaced-2>
			    <h2>Account info</h2>
				<div class="inputRow" x-spaced-4>
					<ch-field label="Full name" max-width="320px">
						<ch-text-input v-model="fullName" placeholder="Full name" disabled/>
					</ch-field>
					<ch-field label="Username" max-width="320px">
						<ch-text-input v-model="username" placeholder="Username" disabled/>
					</ch-field>
				</div>
				<div class="inputRow">
					<ch-field label="Email" max-width="320px">
						<ch-text-input v-model="email" placeholder="Email" disabled/>
					</ch-field>
				</div>
		    </div>
	    </div>
	    <div class="section">
		    <h1 primary-text>Security</h1>
		    <div class="sectionContent" y-spaced-2>
			    <span v-if="expirationPasswordTime">Password will expire on {{ expirationPasswordTime | formatDate}}</span>
			    <ch-button type="primary" @click="changePassword">Change password</ch-button>
		    </div>
	    </div>
	    <change-password-dialog ref="changePasswordDialog"/>
	    <ch-modal ref="modal"/>
    </div>
</template>

<script>
    import ChangePasswordDialog from './dialog/ChangePasswordDialog';
    import StringUtils from '../utils/StringUtils';

    export default {
        name: 'Info',
        components: {ChangePasswordDialog},
        data() {
            return {
            	fullName: this.$storage.user.fullName,
                username: this.$storage.user.username,
                email: this.$storage.user.email,
                expirationPasswordTime: null
            };
        },
        mounted(){
            this.$iam.findPasswordExpirationTime()
            .then(r => this.expirationPasswordTime = r.expirationTime)
        },
        filters:{
            formatDate(date){
                if(date)
                    return StringUtils.formattedDateFromISO(date.toISOString())
                return null;
            }
        },
        methods: {
            changePassword() {
                this.$refs.changePasswordDialog.open()
                    .then(() => {
                        this.$refs.modal
                            .open('Password changed', 'Your password has been changed successfully, please login with the new password.', 'primary', 'OK', 'Cancel', 'medium', false)
                            .finally(() => {

                                this.$downloadManager.emptyDownloads();

                                window['studioApi'].close();
                                this.$store.commit('logout');
                                this.$router.push({name: 'login'});

                            });
                    });
            }
        }
    };
</script>

<style scoped>

    .section {
        display: flex;
        flex-direction: column;
        padding: var(--quadrupleMargin);
        padding-bottom: 0;
    }

    .sectionContent {
        display: flex;
        flex-direction: column;
        border-top: 1px solid var(--elevation-04);
        padding-top: var(--doubleMargin);
    }

	.inputRow{
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
	}

</style>
