<template>
    <div y-spaced-2>
        <h1 primary-text class="title"><span>Product info</span><slot name="titleAction"></slot></h1>
        <ch-dl label-width="50%">
            <ch-dd label="Product"><div class="productName" @click="showProductDetails(product)">{{product.info.name.capitalize()}}</div></ch-dd>
            <ch-dd label="EAN">{{product.info.ean || product.ean || 'Not available'}}</ch-dd>
        </ch-dl>
        <div
            class="product-info-header"
        >
            <ch-icon
                v-if="! isExpanded"
                class="header-icon"
                size="32"
                icon="arrow_right"
                @click="isExpanded = ! isExpanded"
            />
            <ch-icon
                v-else
                class="header-icon"
                size="32"
                icon="arrow_drop_down"
                @click="isExpanded = ! isExpanded"
            />
            <h1 primary-text>Anagraphics</h1>
        </div>
        <div
            v-if="isExpanded"
        >
            <ch-dl label-width="50%">
                <ch-dd label="Manufacturer">{{(product.info.producer || 'Not available').capitalize()}}</ch-dd>
                <ch-dd label="Brand">{{(product.info.brand || 'Not available').capitalize()}}</ch-dd>
                <ch-dd v-for="level of category" :label="level.label">{{level.value.capitalize()}}</ch-dd>
                <ch-dd label="Catalog Dimensions (cm)">
                    <div x-spaced-1_2>
                        <ch-icon icon="fas fa-arrows-alt-v"/>
                        <b>{{originalSize.height / 10}}</b>
                        <ch-icon icon="fas fa-arrows-alt-h"/>
                        <b>{{originalSize.width / 10}}</b>
                        <ch-icon icon="fas fa-expand-alt"/>
                        <b>{{originalSize.depth / 10}}</b>
                    </div>
                </ch-dd>
            </ch-dl>
            <h3 primary-text>Dimensions in planogram</h3>
            <ch-dl label-width="50%">
                <ch-dd label="Height">
                    <ch-number-input type="primary" :value="product.height / 10" :min="0.1"
                                    @input="height => resize(product.width, height * 10, product.depth)"/>
                </ch-dd>
                <ch-dd label="Width">
                    <ch-number-input type="primary" :value="product.width / 10" :min="0.1"
                                    @input="width => resize(width *10, product.height, product.depth)"/>
                </ch-dd>
                <ch-dd label="Depth">
                    <ch-number-input type="primary" :value="product.depth / 10" :min="0.1"
                                    @input="depth => resize(product.width, product.height, depth * 10)"/>
                </ch-dd>
            </ch-dl>
            <product-details ref="productDetails"/>
        </div>
    </div>
</template>

<script>
    import Volume                  from '../../../model/common/Volume';
    import Repository              from '../../../services/Repository';
    import {CategorizationService} from '../../../../../skucatalog/services/CategorizationService';
    import ProductDetails          from '../../../../../skucatalog/ProductDetails';

    export default {
        name: 'ProductInfo',
        components: {ProductDetails},
        props: {
            repository: Repository,
            product: Object
        },
        data() {
            return {
                isExpanded: true,
            };
        },
        computed: {
            /**
             * @return {AdditionalProductInfo}
             */
            additionalProductInfo() {
                return this.repository.additionalProductsInfo[this.product.id];
            },
            /**
             * @return {{label: string, value: string}[]}
             */
            category() {
                const categorization = this.repository.categorization;
                const additionalInfo = this.additionalProductInfo;
                if (!additionalInfo) return [];
                return CategorizationService.ExtractCategory(categorization, additionalInfo.categories)
                    .filter(category => category.value);
            },
            /**
             * @return {Volume}
             */
            originalSize() {
                if(this.additionalProductInfo)
                    return this.additionalProductInfo.size;
                return new Volume(this.product.width, this.product.height, this.product.depth);
            }
        },
        methods: {
            /**
             * @param {number} width
             * @param {number} height
             * @param {number} depth
             */
            resize(width, height, depth) {
                if (width === this.product.width && height === this.product.height && depth === this.product.depth) return;
                this.$unityManager.Dispatch('ResizeProduct', {
                    productId: this.product.id,
                    volume: new Volume(width, height, depth)
                });
            },
            /**
             * @param {Product} product
             */
            showProductDetails(product) {
                this.$productBank.findProductById(product.id)
                    .then(r => this.$refs.productDetails.open(r.product, this.repository.categorization));
            }
        }
    };
</script>

<style scoped>

    .product-info-header {
        display: flex;
        align-items: center;
        border-top: 1px solid var(--elevation-04);;
        padding-top: 12px;
    }

    h1 {
        font-size: 16px;
    }

    .header-icon {
        margin-left: -8px;
        color: var(--primary);
    }

    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .title > * {
        flex: 1 1 0;
    }

    .productName{
        text-decoration: underline;
        cursor: pointer;
    }

</style>
