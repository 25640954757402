<template>
	<ch-form-dialog title="Change description" type="primary" ref="dialog" @reset="reset" @submit="submit" :close-on-submit="false">
		<ch-field label="Description (max 300 characters)" :validators="[descriptionValidator]">
			<ch-text-area placeholder="Workgroup description" v-model="description"/>
		</ch-field>
	</ch-form-dialog>
</template>

<script>
import {descriptionValidator} from '@/user/workgroups/services/Validators';

export default {
	name: 'ChangeWorkGroupDescriptionDialog',
	data() {
		return {
			workGroup: null,
			description: null
		};
	},
	methods: {
		descriptionValidator,
		open(workGroup) {
			this.workGroup = workGroup;
			this.description = this.workGroup.description;
			this.$refs.dialog.open();
			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			}).then(() => this.$refs.dialog.close());
		},
		reset() {
			this.workGroup = null;
			this.description = null;
		},
		submit() {
			this.$loading.await(
				this.$iam.changeWorkGroupInfo(this.workGroup.id, null, this.description)
			).then(() => this.resolve());
		}
	}
};
</script>

<style scoped>

</style>
