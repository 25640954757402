<template>
	<div class="wrapper">
		<div class="filtersArea">
			<div class="header">
				<div>Packages queue</div>
				<ch-search-bar v-model="search" realTime :width="300"/>
			</div>
			<div class="pagingRow">
				<div class="pagingLeftControls">
					<label>Items per page: </label>
					<ch-select class="pageSelect" size="narrow small" type="content" :items="[10, 25, 50]" v-model="pagingSize"/>
					<label class="productsCount">{{packagesList.filter(p => p.status == 'started').length}} package generation queued</label>
				</div>
				<div class="pagingControls">
					<ch-pagination v-model="currentPage" :maxLength="Math.max(Math.ceil(totalItems / pagingSize), 1)"/>
				</div>
				<div class="pagingRightSpacer"></div>
			</div>
		</div>
		<div class="packagesList">
			<ch-table :items="packagesList.filter(p => p.status == 'issued' || p.status == 'started')">
				<template slot="header">
					<ch-th name="Project" defaultOrder="ascending" sortable id="name"/>
					<ch-th name="Status"/>
					<ch-th name="Request on"/>
					<ch-th name="Started on"/>
					<ch-th name="Requested by"/>
				</template>
				<template slot-scope="{ row }">
					<ch-td>{{row.projectName}}</ch-td>
					<ch-td>{{row.status}}</ch-td>
					<ch-td>{{formattedDataFromISO(row.issuedAt)}}</ch-td>
					<ch-td>{{row.startedAt ? formattedDataFromISO(row.startedAt) : 'N/A'}}</ch-td>
					<ch-td>{{row.username}}</ch-td>
				</template>
			</ch-table>
		</div>
	</div>
</template>

<script>
import StudioRequest from "../plugins/studioapi/requests/StudioRequest";
import StringUtils   from "../utils/StringUtils";

export default {
	data() {
		return {
			search: 		"",
			pagingSize: 	25,
			currentPage: 	0,
			totalItems: 	0,
			packagesList:	[],
		}
	},
	mounted() {
		document.title = "Admin | ShelfZone Studio";
		this.getQueuedBuilds();
	},
	watch: {
		pagingSize(newValue, oldValue) {
			
			if(newValue !== oldValue)
				this.getQueuedBuilds();
		},
		currentPage() {
			this.getQueuedBuilds();
		},
		searchString() {

			this.onSearch();
		}
	},
	methods: {
		getQueuedBuilds() {
			
			let payload = {
				limit: this.pagingSize,
				offset: this.pagingSize * this.currentPage,
				matching: this.search
				/*, //Optional
				projectId: 'PRJ', //Optional
				orderBy: 'projectName', //or issuedAt Optional
				direction: 'asc' //or desc, Optional, Default asc */
			}

			let request = new StudioRequest("listOrganizationBuilds", payload, (code, response) => {

				if(code === 200)
				{
					this.packagesList = response.builds;
					this.totalItems   = response.totalMatchesCount;
				}
			});

			window['studioApi'].sendRequest(request);
		},
		formattedDataFromISO(isoDate) {

			return StringUtils.formattedDateTimeFromISO(isoDate);
		}
	}
}
</script>

<style scoped>
	.wrapper {
		display: 			flex;
		flex-direction: 	column;
		grid-area: 			content / content / rightNav / rightNav;
		padding: 			var(--quadrupleMargin);
		padding-left: 		16px;
	}
	.card {
		display: 			flex;
		flex-direction:	 	column;
		width: 				100%;
		background: 		white;
		border-radius: 		4px;
		overflow: 			hidden;
		margin-bottom: 		var(--quadrupleMargin);
	}
	.cardHeader	{
		display: 			flex;
		flex: 				0 0 auto;
		height: 			48px;
		width: 				100%;
		color: 				black;
		background: 		#BAC8D1;
		line-height: 		48px;
		padding: 			0 var(--doubleMargin);
	}
	.cardBody {
		display: 			flex;
		align-items: 		flex-end;
		flex: 				1 1 auto;
		width: 				100%;
		height: 			100%;
		background: 		#58626E;
		padding: 			var(--doubleMargin);
	}
	.cardItem {

		flex: 1 1 auto;
		margin-right: var(--doubleMargin);
	}

	.pageTitle {
		font-size: 			20px;
		margin-bottom: 		var(--doubleMargin);
	}
	.label {
		margin: 			var(--doubleMargin) 0 var(--singleMargin) 0;
		font-weight: 		600;
	}
	.value {
		line-height: 		32px;
		font-size: 			16px;
	}
	input {
		width: 					100%;
		height: 				32px;
		font-size: 				16px;
		border-radius: 			4px;
		border: 				none;
		padding: 				8px;
		outline: 				none;
	}

	.header {
        flex: 					1 1 auto;
		display: 				flex;
        justify-content: 		space-between;
		align-items: 			center;
        color:					black;
    }
	.header > div {
		font-size: 				20px;
		color: 					white;
	}
	.filtersArea {
		display:            	flex;
		flex-direction:     	column;
		align-items:        	stretch;
    }
    .pagingRow {
        display:                flex;
        box-sizing:             border-box;
        width:                  100%;
        height:                 48px;
        margin-top:             48px;
    }
    .pagingLeftControls {
        display:            flex;
        align-items:        center;
        width:              420px;
    }
    .pagingLeftControls > * {
        margin-right:       8px;
    }
    .pagingRightSpacer {
        width:              420px;
    }
    .pagingControls {
        display:            flex;
        flex:               1 0 auto;
        align-items:        center;
        justify-content:    center;
    }
    .packagesList {
        display:                flex;
        flex-direction:         column;
        padding-top:            0;
        overflow-y:             auto;
        padding-bottom:         70px;
    }
    .createOrganizationButton {
        position: fixed;
        bottom: 28px;
        right: 28px;
    }
</style>

