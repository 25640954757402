<template>
    <div y-spaced-1>
        <h2 primary-text>Asset</h2>
        <ch-select size="fill narrow" :disabled="disabled" :value="element.materializerId" :items="materializers"
                   @input="updateMaterializer"/>
		<template v-if="materializedVolume">
		    <h4 elevation-05-text>Asset dimensions (cm)</h4>
	        <div x-spaced-1_2>
	            <ch-icon icon="fas fa-arrows-alt-v"/>
				<b>{{Math.round(materializedVolume.height * 10) / 100}}</b>
	            <ch-icon icon="fas fa-arrows-alt-h"/>
				<b>{{Math.round(materializedVolume.width * 10) / 100}}</b>
	            <ch-icon icon="fas fa-expand-alt"/>
				<b>{{Math.round(materializedVolume.depth * 10) / 100}}</b>
	        </div>
	    </template>
    </div>
</template>

<script>
    import LayoutController from '../services/LayoutController';

    export default {
        name: 'Materializer',
        props: {
            controller: LayoutController,
            element: Object,
			materializedVolume: Object,
            disabled: Boolean
        },
        computed: {
            materializers() {
                return this.controller.configuration.library
                    .filter(asset => asset.type === 'PlanogramMaterializer')
                    .map(asset => ({key: asset.id, label: asset.name}));
            },
        },
        methods: {
            updateMaterializer(materializerId) {
                this.$unityManager.layoutAPI.ChangeMaterializer(this.element.id, materializerId);
            }
        }
    };
</script>

<style scoped>

</style>
