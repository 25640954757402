<template>
    <div y-spaced-2>
        <h2 primary-text>Multiple selection</h2>
        <div>{{elements.length}} elements selected</div>
        <delete-selection-button text="Delete current Selection"/>
    </div>
</template>

<script>
    import LayoutController      from '../services/LayoutController';
    import Transform             from './Transform';
    import DeleteSelectionButton from './DeleteSelectionButton';

    export default {
        name: 'MultipleSelectionInfo',
        components: {DeleteSelectionButton, Transform},
        props: {
            controller: LayoutController,
            elements: Array
        }
    };
</script>

<style scoped>

</style>