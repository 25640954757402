<template>
    <div class="tagSelectionContainer" :class="{'overlay': panelVisible}" tabindex="0" ref="container"
        @click.self.stop="onPanelClose"
        @click.stop="() => {}"
        @keydown.esc.self.stop="onPanelClose"
        @keydown.enter.self.stop="onPanelClose">
        <ch-overlay-panel ref="panel" type="overlay">
            <ch-icon icon="local_offer" :class="{outlined: !this.tagIds.length }" @click.stop="onPanelOpened($event)"/>
            <template #content>
                <div class="tagList" y-spaced-1>
                    <tag-select :list="listForSelect" 
                                :tagIds.sync="tagIds" 
                                :focusableItemOnClose="$refs.container"
                                placeholder="Select tag" 
                                notFoundText="No results"
                                placeholderColor="--elevation-01" 
                                notFoundTextColor="--on-disabled"/>
                    <div v-if="assignedTagsList.length">{{tagCounterTxt}}</div>
                    <tag-list :list="assignedTagsList" removable @remove="removeTag($event)" size="narrow" class="noMargin">
                        <template #default={tag}>
                            <ch-indicator :color="tag.color" />
                            {{tag.name}}
                        </template>
                    </tag-list>
                    <create-new-tag @newTagName="onCreateNewTag($event)" :list="list" />
                </div>
            </template>
        </ch-overlay-panel>
    </div>
</template>

<script>
import Studio, { Recording } from '../../../plugins/studioapi/storage/Studio';
import TagList               from './TagList';
import CreateNewTag          from './CreateNewTag';
import TagSelect             from './TagSelect';
import { alphabetSortFunc }  from './helpers';
export default {
    name: "TagSelection",
    components: { TagList, CreateNewTag, TagSelect },
    props: {
        field:     Studio,
        recording: Recording,
    },
    //TODO: investigate why trigger is required here but not in manage tags modal?
    data() { return { 
        tagApi: this.$research.$tagAPI,
        trigger: 0,
        panelVisible: false
    }},
    mounted() {
        this.watchers = [];
        const unwatch = this.$watch( '$refs.panel.panelVisible', (value) => this.panelVisible = value);
        this.watchers.push(unwatch);
    },
    destroyed() {
        this.watchers.forEach(u => u());
        this.watchers = [];
    },
    computed: {
        fieldTags()  { this.trigger; return this.field.tags     },
        recordTags() { this.trigger; return this.recording.tags },        
        list() {
            return [...this.fieldTags.values()].sort(alphabetSortFunc);
        },
        listForSelect() {
            return this.list.map(t => ({ key: t.id, label: t.name, color: t.color}))
        },
        tagIds: {
            set(ids){
                const { tags } = this.recording;
                const removedFromRecording = [...tags].filter(t => !ids.includes(t));
                const addedToRecording = ids.filter( id => !tags.has(id));
                const updates = [];
                
                if( removedFromRecording.length ) { updates.push(this.unassign(removedFromRecording)); }
                if( addedToRecording.length )     { updates.push(this.assign(addedToRecording));       }
                Promise.all(updates).then( () => this.recording.replaceTags(ids))
                    .finally(() =>{
                        this.trigger += 1;
                    }
                )
            },
            get() {
                return [...this.recordTags.values()]
            }
        },
        assignedTagsList() {
            const assignedTags = this.tagIds.map( id => this.field.tags.get(id)).filter(Boolean);
            return [ ...assignedTags ].sort(alphabetSortFunc);
        },
        tagCounterTxt() {
            const tagTxt = (count) => `tag${count !== 1 ? 's' : ''}`; 
            return `${this.assignedTagsList.length} ${tagTxt(this.assignedTagsList.length)}`;
        }


    },
    methods: {
        assign(ids) {
            return this.tagApi.assign(this.field.id, this.recording.id, ids);
        },
        unassign(ids) {
            return this.tagApi.unassign(this.field.id, this.recording.id, ids);
        },
        removeTag(tagId) {
            this.unassign( [tagId])
                .then(({ tagIds }) => this.recording.removeTags(tagIds))
                .finally(() => {
                    this.trigger += 1;
                });
        },
        onCreateNewTag(name) {
            this.tagApi.create(this.field.id, name)
            .then(({ tag }) => {
                this.field.addTags([tag]);
                return tag;
            }).then( ({ id }) => this.assign([id])).then(({ tagIds }) => {
                this.recording.addTags(tagIds);
            }).finally(() => {
                this.trigger += 1;
            })
        },

        onPanelOpened(e) {
            this.$nextTick(() => this.$refs.container.focus());
        },
        onPanelClose(event){
            this.$refs.panel.hidePanel();
            this.$refs.container.blur();
        }
    }

}
</script>

<style scoped>
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 20;
    }
    .tagSelectionContainer:focus {
        outline: none;
    }
    .outlined >>> i {
        font-family: "Material Icons Outlined" ;
    }

    .tagList {
        width: 210px;
    }

    .noMargin >>> .tag {
        margin-left:  0;
        margin-right: 0;
    }
</style>