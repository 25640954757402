import MathUtils from './MathUtils';

/**
 *
 *
 * @export
 */

export default function Heatmap() {

  this.gradient = {0.25: 'rgb(0,0,255)', 0.55: 'rgb(0,255,0)', 0.85: 'yellow', 1.0: 'rgb(255,0,0)'};
  this.palette  = null;

  /**
   * @type {HTMLCanvasElement}
   */
  this.canvas = document.createElement('canvas');

  /**
   * @type {HTMLCanvasElement}
   */
  this.paletteTemplate = document.createElement('canvas');

  /**
   * @type {HTMLCanvasElement}
   */
  this.alphaTemplate = document.createElement('canvas');

  this.shadowTemplate = document.createElement('canvas');

  this.radius = 0;

  this.data = [];

  this.isDirty = false;

  this.max = 100;

  this.bounds = [9999999, 9999999, 0, 0]
}

/**
 * Initialize the component
 */
Heatmap.prototype.createPalette = function() {

  this.paletteTemplate.width = 256;
  this.paletteTemplate.height = 1;

  let drawArea = this.paletteTemplate.getContext('2d');

  let gradient = drawArea.createLinearGradient(0, 0, 256, 1);
  for (let key in this.gradient) 
  {
    gradient.addColorStop(key, this.gradient[key])
  }

  drawArea.fillStyle = gradient;
  drawArea.fillRect(0, 0, 256, 1);

  this.palette = drawArea.getImageData(0, 0, 256, 1).data
};

/**
 * Initialize the component
 *
 * @param {Number} radius The container of the canvas
 */
Heatmap.prototype.createAlpha = function(radius) {

  this.radius = radius;
  this.isDirty = true;

  let drawArea = this.alphaTemplate.getContext('2d');

  this.alphaTemplate.width = this.alphaTemplate.height = this.radius * 2;
  this.shadowTemplate.width = this.shadowTemplate.height = this.radius * 2;

  let alpha = drawArea.createRadialGradient(this.radius, this.radius, 1, this.radius, this.radius, this.radius);
  alpha.addColorStop(0, 'rgba(0,0,0,1)');
  alpha.addColorStop(1, 'rgba(0,0,0,0)');

  // Fill with gradient
  drawArea.fillStyle = alpha;
  drawArea.fillRect(0, 0, this.radius * 2, this.radius * 2)
};

Heatmap.prototype.createAlphaForInversion = function(radius) {

  this.radius  = radius;
  this.isDirty = true;
  let drawArea = this.alphaTemplate.getContext('2d');

  this.alphaTemplate.width  = this.alphaTemplate.height  = this.radius * 2;
  this.shadowTemplate.width = this.shadowTemplate.height = this.radius * 2;

  let alpha = drawArea.createRadialGradient(this.radius, this.radius, 1, this.radius, this.radius, this.radius);
  alpha.addColorStop(0.0, 'rgba(0,0,0,1)');
  alpha.addColorStop(0.4, 'rgba(0,0,0,1)');
  alpha.addColorStop(1.0, 'rgba(0,0,0,0)');

  // Fill with gradient
  drawArea.fillStyle = alpha;
  drawArea.fillRect(0, 0, this.radius * 2, this.radius * 2)
};

/**
 *
 *
 * @param {Array<{x: number, y: number}>} data
 * @param {Number} width
 * @param {Number} height
 * @param {CanvasRenderingContext2D} targetContext
 */
Heatmap.prototype.drawHeatmap = function(data, width, height, ctx) {

  if (this.isDirty && data.length > 0) 
  {
    let drawArea = this.canvas.getContext('2d');

    drawArea.clearRect(0, 0, width, height);

    let minX = width;
    let minY = height;
    let maxX = 0;
    let maxY = 0;

    this.canvas.width  = width;
    this.canvas.height = height;

    data.forEach(data => 
    {
      minX = Math.min(minX, data.x);
      minY = Math.min(minY, data.y);
      maxX = Math.max(maxX, data.x);
      maxY = Math.max(maxY, data.y);

      drawArea.globalAlpha = MathUtils.clamp(data.value / this.max, 0.01, 1);
      drawArea.drawImage(this.alphaTemplate, data.x - this.radius, data.y - this.radius)
    });

    minX = Math.max(minX - this.radius, 0);
    minY = Math.max(minY - this.radius, 0);
    maxX = Math.min(maxX + this.radius, width);
    maxY = Math.min(maxY + this.radius, height);

    if(maxX !== minX && maxY !== minY) 
    {
      let imgData = drawArea.getImageData(minX, minY, maxX - minX, maxY - minY);

      for (let i = 3; i < imgData.data.length; i += 4) 
      {
        const alpha = imgData.data[i];
        
        if (alpha > 0) 
        {
          const offset = alpha * 4;
          imgData.data[i - 3] = this.palette[offset];
          imgData.data[i - 2] = this.palette[offset + 1];
          imgData.data[i - 1] = this.palette[offset + 2]
        }
      }

      drawArea.putImageData(imgData, minX, minY)
    }

    /* ctx.globalCompositeOperation = 'screen'; */
    ctx.drawImage(this.canvas, 0, 0);

    this.isDirty = false;
  }
};

/**
 * *
 * Draw a mask over the canvas from the fixations data.
 * @param {Array<{x: number, y: number}>} data
 * @param {Number} width
 * @param {Number} height
 * @param {CanvasRenderingContext2D} targetContext
 */
Heatmap.prototype.drawInverseHeatmap = function(data, width, height, ctx) {

  // We need to redraw only if the data changed.
  if (this.isDirty && data.length > 0) 
  {
    // Resize the working canvas to the same size of the original canvas.
    this.canvas.width  = width;
    this.canvas.height = height;
    
    let drawArea       = this.canvas.getContext('2d');
    drawArea.fillStyle = "#000615";
    drawArea.fillRect(0, 0, this.canvas.width, this.canvas.height);
    // Destination-out remove from the mask the part we are going to draw.
    drawArea.globalCompositeOperation = 'destination-out';      

    data.forEach(data => 
    {
      drawArea.globalAlpha = MathUtils.clamp(data.value / this.max, 0.01, 1);
      drawArea.drawImage(this.alphaTemplate, data.x - this.radius, data.y - this.radius)
    });

    // The masked is mixedw with the underlay canvas with a multiply blend mode
    ctx.globalCompositeOperation = 'multiply';
    ctx.drawImage( this.canvas, 0, 0);

    this.isDirty = false;
  }
}
