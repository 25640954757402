<template>
	<ch-loader fullscreen/>
</template>

<script>
import {deleteCookie, setCookie} from '@/utils/CookieService';

export default {
	name: 'SwitchingLoading',
	props: {
		workGroupId: String
	},
	computed: {
		currentIdentityId() {
			return this.$storage.user.id;
		}
	},
	mounted() {
		if (this.workGroupId)
			setCookie('x-workgroup-id', this.workGroupId);
		else
			deleteCookie('x-workgroup-id');
		window['studioApi'].close();
		window['studioApi'].initializeWebSocket();
		this.$storage.init()
			.then(() => this.$router.push({name: 'projects'}));
	}
};
</script>

<style scoped>

</style>
