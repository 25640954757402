<template>
    <ch-dialog ref="dialog" type="primary" title="Replace products" size="fullscreen" important
               class="replaceDialog">
        <ch-application-layout slot="main" headerSize="small">
            <div slot="header" y-spaced-2 class="header">
                <ch-filters>
                    <div x-spaced-1>
<!--                        <ch-radio-group size="small narrow" v-model="productType">-->
<!--                            <ch-radio-button label="all">All</ch-radio-button>-->
<!--                            <ch-radio-button label="multiple">No updates</ch-radio-button>-->
<!--                        </ch-radio-group>-->
                        <span>{{products.length}} products</span>
                    </div>
                    <div></div>
                    <div>
                        <ch-search-bar v-model="query" size="narrow" real-time/>
                    </div>
                </ch-filters>
            </div>
            <div slot="main" class="products">
                <ch-loader v-if="loading" ref="loader"/>
                <template v-for="product of filteredProducts">
                    <product-selection-table v-if="productsByEan" :key="product.ean"
                                             v-model="chosenProducts[product.ean]"
                                             :product="product"
                                             :available-products="productsByEan[product.ean]"
                                             @showProductDetails="showProductDetails"/>
                </template>
                <ch-empty-content v-if="products.length > 0 && filteredProducts.length === 0"
                                  first-message="No products match the query"/>
                <ch-empty-content v-if="products.length === 0" first-message="No products found in the planogram"/>
            </div>
        </ch-application-layout>
        <template slot="footer">
            <ch-button type="secondary" size="small" @click="close">Cancel</ch-button>
            <ch-button type="primary" size="small" @click="replaceProducts">Replace</ch-button>
            <product-details ref="productDetails"/>
        </template>
    </ch-dialog>
</template>

<script>
    import ProductDetails        from '../../../../skucatalog/ProductDetails';
    import {ProductService}      from '../../services/ProductService';
    import {PlanogramService}    from '../../services/PlanogramService';
    import ProductSelectionTable from '../components/ProductSelectionTable';
    import {Random}              from '../../utils/Random';
    import Repository            from '../../services/Repository';
    import Vector3               from '../../../../plugins/studioapi/api/spaceallocation/model/common/Vector3';
    import Volume                from '../../model/common/Volume';

    export default {
        name: 'ReplaceProductsDialog',
        components: {ProductSelectionTable, ProductDetails},
        props: {
            repository: Repository
        },
        data() {
            return {
                categorization: null,
                originalProducts: [],
                products: [],
                productsByEan: null,
                chosenProducts: null,
                loading: false,
                productType: 'all',
                query: null
            };
        },
        computed: {
            filteredProducts() {
                let filteredProducts = this.query ? this.products.filter(p => p.ean.includes(this.query)) : this.products;
                switch (this.productType) {
                    case 'found':
                        filteredProducts = filteredProducts.filter(e => !this.isSelected(e));
                        break;
                    case 'notFound':
                        filteredProducts = filteredProducts.filter(this.isSelected);
                        break;
                    case 'multiple':
                        filteredProducts = filteredProducts.filter(p => this.existMultipleProductsWithSameEan(p.ean));
                        break;
                }
                return filteredProducts;
            },
        },
        methods: {
            /**
             * @param categorization
             * @param {Product[]} productsToReplace
             * @param {Product[]} allProducts
             */
            open(categorization, productsToReplace, allProducts) {
                this.$refs.dialog.open();
                this.loading = true;
                this.originalProducts = productsToReplace;
                this.categorization = categorization;
                this.$loading.await(this.retrieveProducts(productsToReplace, allProducts));
            },
            /**
             * @param {Product[]} products
             * @param {Product[]} allProducts
             * @return {Promise<FindProductsByEanListResponse>}
             */
            retrieveProducts(products, allProducts) {
                return this.$productBank.findProductsByEanList(products.map(p => p.ean))
                    .then(response => {
                        this.products = products.map(p => JSON.parse(JSON.stringify(p)));
                        const ids = Random.randomIds(allProducts.map(p => p.id), ProductService.idGenerator, this.products.length);
                        this.products
                            .filter(p => p.type === 'Product')
                            .forEach((p, i) => {
                                p.type = 'Prototype';
                                p.id = ids[i];
                            });
                        this.productsByEan = {};
                        this.chosenProducts = this.products.reduce((o, p) => {
                            o[p.ean] = p.id;
                            return o;
                        }, {});
                        response.productsSummaries.forEach(productSummary => {
                            if (!(productSummary.ean in this.productsByEan))
                                this.productsByEan[productSummary.ean] = [];
                            this.productsByEan[productSummary.ean].push(productSummary);
                        });
                        this.chosenProducts = Object.keys(this.productsByEan)
                            .reduce((o, ean) => {
                                o[ean] = products.find(p => p.ean === ean).id;
                                return o;
                            }, this.chosenProducts);
                        this.loading = false;
                    });
            },
            isSelected(product) {
                return this.chosenProducts[product.ean] === product.id;
            },
            showProductDetails(productId) {
                this.$loading.await(
                    this.$productBank.findProductById(productId)
                        .then(response => this.$refs.productDetails.open(response.product, this.categorization))
                );
            },
            existMultipleProductsWithSameEan(ean) {
                const productsInCatalog = this.productsByEan[ean];
                return productsInCatalog ? productsInCatalog.length > 1 : false;
            },
            replaceProducts() {
                const productsInCatalogIds = Object.values(this.chosenProducts).filter(id => !this.products.some(p => p.id === id));
                const toProductParams = product => ({
                    ...product,
                    size: new Volume(product.width, product.height, product.depth),
                    images: {}
                })
                this.$loading.await(
                    this.$productBank.findProductsById(productsInCatalogIds,false)
                        .then(response => {
                            response.foundProducts.forEach(p => this.repository.addAdditionalProductInfo(p));
                            return response.foundProducts.map(ProductService.translateProductFromProductBank);
                        })
                        .then(products => {
                            const replaceMap = this.originalProducts.map(oldProduct => [oldProduct, products.find(newProduct => newProduct.ean === oldProduct.ean)])
                                .reduce((o, [oldProduct, newProduct]) => {
                                    o[oldProduct.id] = newProduct ? newProduct : toProductParams(this.products.find(p => p.ean === oldProduct.ean));
                                    return o;
                                }, {});
                            this.$unityManager.Dispatch('ChangeProducts', {products: replaceMap});
                        })
                ).then(() => this.close());
            },
            close() {
                this.$refs.dialog.close();
                this.categorization = null;
                this.planogram = null;
                this.chosenProducts = null;
                this.productsByEan = null;
            }
        }
    };
</script>

<style scoped>

    .products {
        overflow-y: auto;
        padding: 0 var(--quadrupleMargin);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .replaceDialog .header {
        display: flex;
        height: 100%;
        flex-direction: column;
        flex: 1 1 auto;
        justify-content: center;
    }

</style>
