<template functional>
    <div v-if="props.show">
        <ch-icon icon="warning" size="10" left/>{{props.text}}
    </div>
</template>

<script>
export default {
    name: "NoteText",
    props: {
        show: Boolean,
        text: String
    }
}
</script>
