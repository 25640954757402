<template>
    <ch-accordion-menu :active="activeVoice">
        <ch-accordion-voice v-for="voice of menu" :key="voice.id" :label="voice.label" @click="goTo(voice.to)"/>
    </ch-accordion-menu>
</template>

<script>

    export default {
        computed: {
            activeVoice() {
                return this.$route.name
            },
            menu() {
                return [
                    {id: "admin", label: "Organization and users", to: "/admin"},
                    {id: "classification", label: "Classification", to: "/admin/classification"}
                    // "VR package build queue"	: {id: "queue", values: null, to: "/queue"},
                ];
            }
        },
        methods: {
            goTo(path) {
                this.$router.push(path)
            }
        }
    };
</script>

<style scoped>
</style>