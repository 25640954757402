<template>
    <ch-modal ref="modal"/>
</template>

<script>
    export default {
        name: "DeleteProject",
        methods: {
            open(project) {
                this.$refs.modal.open(
                    'Delete',
                    [
                        `Are you sure to delete ${project.name}?`,
                        'The action will cancel all pending operations.'
                    ],
                    'error',
                    'Delete'
                )
                    .then(() => {
                        project.archive()
                            .then(() => {
                                const i = this.$storage.projects.indexOf(project);
                                if (i >= 0)
                                    this.$storage.projects.splice(i, 1);
                                this.$emit('confirm');
                            })
                            .catch(code => alert("Failed to delete project with code: " + code + ". Please try again."));
                    })
                    .catch(() => {
                    });
            }
        }
    };
</script>