<template>
	<ch-modal ref="modal"/>
</template>

<script>
export default {
	name: 'LoginDialog',
	methods: {
		open() {
			return this.$refs.modal.open(
				'Unsaved changes',
				'You have logged out having unsaved changes! If you want to save you need to login again!', 'primary',
				'Login', 'Close'
			).then(() => {
				const loginPage = this.$router.resolve({name: 'login', query: {loginOnly: true}});
				const loginWindow = window.open(loginPage.href);
				loginWindow.onLogin = () => {
					window['studioApi'].initializeWebSocket();
					this.$storage
						.init()
						.then(() => this.$store.commit('login'));
				};
			});
		}
	}
};
</script>

<style scoped>

</style>
