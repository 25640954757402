<template>
    <div y-spaced-2>
        <h2 primary-text>Starting point</h2>
        <transform :controller="controller" :element="element"/>
    </div>
</template>

<script>
    import LayoutController   from '../services/LayoutController';
    import Transform          from './Transform';

    export default {
        name: 'StartingPointInfo',
        components: {Transform},
        props: {
            controller: LayoutController,
            element: Object
        }
    };
</script>

<style scoped>

</style>