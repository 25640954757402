<template>
    <div class="groupsLibrary">
        <div class="filters" y-spaced-2>
            <ch-button type="primary" size="fill" @click="createGroup" justified>Create group 
                <ch-icon  icon="add_circle_outline"/>
            </ch-button>
            <ch-search-bar v-model="search" real-time size="fill narrow"/>
        </div>
        <div class="groups">
            <ch-list size="narrow" selectable="single" type="flat" :value="currentGroupId"
                     @input="selectGroup">
                <ch-list-item v-for="group of filteredGroups" :key="group.id" :value="group.id">
                    <template slot="selectionIcon">
                        <ch-indicator :color="group.color"/>
                    </template>
	                {{group.name}}
                </ch-list-item>
            </ch-list>
        </div>
    </div>
</template>

<script>
    import SelectionManager from '../../managers/SelectionManager';

    export default {
        name: 'GroupsLibrary',
        props: {
            groups: Array,
            selectionManager: SelectionManager
        },
        data() {
            return {
                search: null
            };
        },
        computed: {
            /**
             * @return {Group[]}
             */
            filteredGroups() {
                return this.groups
                    .filter(g => !this.search || g.name.toLowerCase().includes(this.search.toLowerCase()))
                    .sort((g1, g2) => g1.name.toLowerCase() > g2.name.toLowerCase() ? 1 : -1);
            },
            /**
             * @return {string}
             */
            currentGroupId() {
                return this.selectionManager.currentGroupId;
            }
        },
        methods: {
            /**
             * @param {string|null} id
             */
            selectGroup(id) {
                if (!id) {
	                this.$unityManager.Dispatch('DeactivateFilter', {id: this.currentGroupId});
	                this.selectionManager.currentGroupId = null;
                } else if (id !== this.currentGroupId) {
                    this.selectionManager.currentGroupId = id;
                    this.$unityManager.Dispatch('ActivateFilter', {id});
                }
            },
            createGroup() {
                this.$emit('createGroup');
            }
        }
    };
</script>

<style scoped>

    .groupsLibrary {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: 100%;
    }

    .filters {
        flex: 0 0 auto;
        padding: var(--doubleMargin);
        border-bottom: 1px solid var(--elevation-04);
    }

    .groups {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        padding: var(--doubleMargin);
    }

    .group {
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        height: 24px;
        padding: 0 var(--halfMargin);
        border-radius: 4px;
        cursor: pointer;
        justify-content: space-between;
    }

    .group:hover {
        background-color: var(--elevation-03);
        color: var(--on-elevation-03);
    }

    .group.selected {
        background-color: var(--elevation-04);
        color: var(--on-elevation-04);
    }

    .group > *:first-child {
        flex: 1 1 auto;
        overflow: auto;
    }

    .group > *:last-child {
        flex: 0 0 auto;
    }

</style>
