<template>
    <ch-field :label="label" :labelPosition="labelPosition">
        <ch-select 
            searchable 
            multiple
            :size = "size"
            :items = "data"
            :disabled = "disabled"
            :value="values"
            @input="$emit('update:values', $event)"
        ></ch-select>
    </ch-field>
</template>

<script>
export default {
    name: "GroupDataFilter",
    props: {
        filterKey: String,
        label: String,
        labelPosition: {
            type: String,
            default: 'left',
        },
        size: {
            type: String,
            default: 'narrow'
        },
        data: Array,
        values: Array,
        disabled: Boolean,
    }
}
</script>

<style>

</style>