<template>
    <ch-form-dialog ref="dialog" type="primary" title="New project" @submit="createProject">
        <ch-field label="Project name" :validators="[projectNameValidator, duplicationValidator]">
            <ch-text-input v-model="projectName" placeholder="Project name"/>
        </ch-field>
        <ch-field label="Configuration name" :validators="[configurationNameValidator]">
            <ch-text-input v-model="configurationName" placeholder="Configuration name"/>
        </ch-field>
        <div y-spaced-2>
            <ch-field v-if="templates.length > 0" label="Choose a template" :validators="[templateValidator]">
                <ch-list class="templateList" v-model="selectedTemplateId" size="thick"
                         selectable="single">
                    <ch-list-item v-for="template of templates" :key="template.id" :value="template.id">{{template.name}}</ch-list-item>
                </ch-list>
            </ch-field>
            <ch-empty-content v-else firstMessage="You haven’t created any template yet"/>
        </div>
        <ch-loader v-if="isLoading" fullscreen/>

    </ch-form-dialog>
</template>

<script>

    export default {
        name: 'NewProject',
        data() {
            return {
                templates: this.$storage.templates,
                selectedTemplateId: null,
                projectName: null,
                configurationName: null,
                isLoading: false
            };
        },
        methods: {
            open() {
                this.$refs.dialog.open();
                this.templates = this.templates.sort((t1, t2) => t1.name.toLowerCase() > t2.name.toLowerCase() ? 1 : -1);
                this.selectedTemplateId = this.templates[0].id;
                this.clean();
            },
            clean() {
                this.projectName = this.configurationName = null;
            },
            projectNameValidator(name) {
                if (!name || !/^(\w|-|\.|\s){1,17}$/.test(name.trim()))
                    return 'Valid characters: A-z, 0-9 and . _ -';
                return null;
            },
            duplicationValidator(name) {
                if (window['studioApi'].storage.projects.find(p => p.name === name))
                    return 'The name of the project is duplicated';
                return null;
            },
            configurationNameValidator(name) {
                if (!name || !/^(\w|-|\.|\s){1,30}$/.test(name.trim()))
                    return 'Valid characters: A-z, 0-9 and . _ -';
                return null;
            },
            templateValidator(v){
                if(this.selectedTemplateId === null || v === null)
                    return 'Please choose a template to create your project';
                return null;
            },
            createProject() {
                this.projectName = this.projectName.trim();
                this.configurationName = this.configurationName.trim();
                this.isLoading = true;
                const workGroupId = this.$storage.workGroupId;
                this.$storage.createProject(this.projectName, this.selectedTemplateId, this.configurationName, workGroupId)
                    .then(project => {
                        this.isLoading = false;
                        this.clean();
                        this.$emit('project-created', project);
                    })
                    .catch(error => {
                        alert('Failed to create project with code: ' + error + '. Please try again.');
                        this.clean();
                        this.isLoading = false;
                    });
            }
        }
    };
</script>

<style scoped>

    .templateList {
        max-height: 260px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: var(--doubleMargin);
    }

</style>
