export default {

    /**
     * Convert a color to the string
     *
     * @param {{r : Number, g : Number, b : Number}} color The color to convert
     * @param {Number} a The alpha component of the color
     * @returns  A string formed as "rgba([r], [g], [b], [a])"
     */
    colorWithAlphaToString(color, a) {
        return 'rgba(' + color.r + ', ' + color.g + ', ' + color.b + ', ' + a + ')';
    },

    /**
     * Convert a color to the string
     *
     * @param {Number} r The red component of the color
     * @param {Number} g The green component of the color
     * @param {Number} b The blue component of the color
     * @returns  A string formed as "rgb([r], [g], [b])"
     */
    colorRGBToString(r, g, b) {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    },

    /**
     * Convert a color to the string
     *
     * @param {Number} r The red component of the color
     * @param {Number} g The green component of the color
     * @param {Number} b The blue component of the color
     * @param {Number} a The alpha component of the color
     * @returns  A string formed as "rgba([r], [g], [b], [a])"
     */
    colorRGBWithAlphaToString(r, g, b, a) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')';
    },

    /**
     * Convert a value to a literal string adding quotes
     *
     * @param {*} value The value to convert
     * @returns The value sorrounded by quotes
     */
    valueToLiteralString(value) {
        return '"' + value + '"';
    },

    /**
     * Pad the given string with another string (repeated, if needed) so that the resulting string reaches
     *  the given length
     *
     * @param {*} value The value to pad
     * @param {Number} targetLength The length of the resulting string after the padding
     * @param {*} [padString] The value used to pad the value
     * @returns A string of the specified length padded with the pad string applied from the start
     */
    padStart(value, targetLength, padString) {
        const sourceString = String(value);

        targetLength -= sourceString.length;

        if (targetLength <= 0) {
            return String(value);
        }

        padString = String(padString || ' ');

        if (targetLength > padString.length) {
            //append to original to ensure we are longer than needed
            padString += padString.repeat(targetLength / padString.length);
        }

        return padString.slice(0, targetLength) + sourceString;
    },

    /**
     * Format the time provide in milliseconds to the format 'mm:ss'
     *
     * @param {number} ms The time in milliseconds
     * @returns The time formatted in 'mm:ss'
     */
    formatTimeMilliseconds(ms) {
        const minutes = Math.floor(ms / 60000);
        const seconds = Math.floor(ms / 1000 - minutes * 60);
        return this.padStart(minutes, 2, '0') + ':' + this.padStart(seconds, 2, '0');
    },

    /**
     *
     * @param object
     * @returns {string}
     */
    joinProperties(object) {
        let result = '';
        for (let property in object) {
            result += object[property];
        }
        return result;
    },

    isoDateToUTCDate(isoDate) {
        const [date, time] = isoDate.split('.')[0].split('T');
        const [year, month, day] = date.split('-');
        const [hour, minutes, seconds] = time.split(':');
        return new Date(Date.UTC(year, month - 1, day, hour, minutes, seconds));
    },

    formattedDateTimeFromISO(isoDate) {
        const options = {
            weekday: 'short',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };
        return this.isoDateToUTCDate(isoDate).toLocaleDateString('en-EN', options);
    },

    formattedDateFromISO(isoDate) {
        const options = {
            weekday: 'short',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        };
        return this.isoDateToUTCDate(isoDate).toLocaleDateString('en-EN', options);
    },

    validateString(str) {
        const reg = /^(\w|\d|_|-|\.|\s){0,30}$/;

        return reg.test(str);
    },

    validateEmail(str) {

        const reg = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
        return reg.test(str);
    },

    validateName(str) {

        const reg = /^(\w|_|-|\.|,|\s|'|&|[\u00C0-\u024F\u1E00-\u1EFF]){1,150}$/;
        return reg.test(str);
    },

    validateUsername(str) {

        const reg = /^(\w|\d|_|-|\.){1,30}$/;
        return reg.test(str);
    }
};
