<template>
    <ch-form-dialog ref="dialog" type="primary" title="Create planogram" @reset="onReset"
                    :close-on-submit="false" @submit="submit">
        <ch-field label="Planogram name" :validators="[nameValidator]">
            <ch-text-input v-model="name" placeholder="Planogram name"/>
        </ch-field>
        <ch-field label="Width (cm)" :validators="[numberValidator]">
            <ch-text-input v-model="width" placeholder="Planogram width" :disabled="forcedWidth"/>
        </ch-field>
        <template slot="actions">
            <ch-button cancel size="small" type="secondary">Cancel</ch-button>
            <ch-button submit size="small" type="primary">Create</ch-button>
        </template>
    </ch-form-dialog>
</template>

<script>
    import Planogram from '../../model/Planogram';
    import Fixture from '../../model/fixture/Fixture';
    import {FixtureType} from '../../model/fixture/FixtureType';
    import Geometry from '../../model/fixture/Geometry';

    export default {
        name: 'CreatePlanogramDialog',
        data() {
            return {
                name: null,
                width: null,
                forcedWidth: false,
                resolve: null,
                reject: null
            };
        },
        methods: {
            /**
             * @param {string|null} name
             * @return {string|null}
             */
            nameValidator(name) {
                if (name === null || !/^(\w|-|\.|\s){1,50}$/.test(name))
                    return 'The name is invalid';
                return null;
            },
            /**
             * @param {string|null} n
             * @return {string|null}
             */
            numberValidator(n) {
                if (n === null || !/^\d+(\.\d?)?$/.test(n))
                    return 'The value is not a valid number';
                return null;
            },
            /**
             * @param {number|null} [forcedWidth]
             * @return {Promise<CreatePlanogramResponse>}
             */
            open(forcedWidth = null) {
                this.forcedWidth = typeof forcedWidth === 'number';
                if (this.forcedWidth)
                    this.width = forcedWidth / 10;
                this.$refs.dialog.open();
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                }).finally(() => this.$refs.dialog.close());
            },
            close() {
                this.$refs.dialog.close();
            },
            onReset() {
                this.name = null;
                this.width = null;
                this.reject();
            },
            submit() {
                const maximumWidth = this.width * 10;
                this.$loading.await(this.$planogram.createPlanogram(new Planogram(
                    'empty',
                    this.name,
                    [],
                    [new Fixture('FXT00000000', FixtureType.Shelf, new Geometry(0, 50, 0, maximumWidth, 0, 550, 50, null), [])],
                    [],
                    [],
                    maximumWidth,
                    0
                ), this.$storage.workGroupId))
	                .then(this.resolve.bind(this))
                    .catch(this.reject.bind(this));
            }
        }
    };
</script>

<style scoped>

</style>
