<template>
    <div y-spaced-2>
        <h2 primary-text>Group</h2>
        <div>{{element.elements.length}} planograms</div>
        <ch-list size="narrow">
            <ch-list-item v-for="child of children">
                <ch-indicator :color="child.color.toString()"/> {{child.name}}
                <template slot="actions">
                    <ch-icon v-if="child.type === 'PlanogramReference'" icon="edit" size="12" @click="goToPlanogram(child)"/>
                </template>
            </ch-list-item>
        </ch-list>
        <ch-dl label-width="45%">
            <ch-dd label="Dimensions (cm)">
                <div x-spaced-1_2>
                    <ch-icon icon="fas fa-arrows-alt-v"/>
                    <b>{{Math.round(childrenVolume.height * 10) / 100}}</b>
                    <ch-icon icon="fas fa-arrows-alt-h"/>
                    <b>{{Math.round(childrenVolume.width * 10) / 100}}</b>
                    <ch-icon icon="fas fa-expand-alt"/>
                    <b>{{Math.round(childrenVolume.depth * 10) / 100}}</b>
                </div>
            </ch-dd>
        </ch-dl>
        <transform :controller="controller" :element="element"/>
        <materializer :controller="controller"  :element="element"/>
        <delete-selection-button text="Remove group"/>
    </div>
</template>

<script>
    import LayoutController from '../services/LayoutController';
    import Transform from './Transform';
    import Materializer from './Materializer';
    import DeleteSelectionButton from './DeleteSelectionButton';
    import {AssetService} from '../services/AssetService';
    import Volume from "../model/common/Volume";

    export default {
        name: 'PlanogramContainerInfo',
        components: {DeleteSelectionButton, Materializer, Transform},
        props: {
            controller: LayoutController,
            element: Object
        },
        computed: {
            children() {
                return this.element.elements.map(child => {
                    return this.controller.findAsset(child.assetId);
                });
            },
            childrenVolume() {
                const planogramsContainerVolume = new Volume(0, 0, 0);
                this.element.elements.forEach(child => {
                    planogramsContainerVolume.depth = Math.max(planogramsContainerVolume.depth, child.materializedVolume.depth);
                    planogramsContainerVolume.height = Math.max(planogramsContainerVolume.height, child.materializedVolume.height);
                    planogramsContainerVolume.width += child.materializedVolume.width;
                });
                return planogramsContainerVolume
            }
        },
	    methods: {
		    goToPlanogram(planogram) {
			    this.$router.push({
				    name: 'planogram',
				    params: {planogramId: planogram.planogramId}
			    });
		    }
	    }
    };
</script>

<style scoped>

    .materializerName {
        word-break: break-all;
    }

</style>