<template>
    <div class="fixtureInfo" v-if="fixture">
        <div class="info" y-spaced-2>
            <h1 primary-text>Fixture info</h1>
            <ch-dl label-width="50%">
                <ch-dd label="Type">{{fixture.type}}</ch-dd>
                <ch-dd label="Products">{{fixture.buckets.length}}</ch-dd>
                <ch-dd label="Positions in units">{{stockInUnits}}</ch-dd>
            </ch-dl>
            <div class="position" y-spaced-2>
                <h1 primary-text>Position (cm)</h1>
                <ch-field label="Horizontal position" :validators="[numberValidator]">
                    <ch-editor-input ref="x" type="number" :value="x" @input="setX"/>
                </ch-field>
                <ch-field v-if="fixture.type !== 'Chest'" label="Vertical position" :validators="[numberValidator]">
                    <ch-editor-input ref="y" type="number" :value="y" @input="setY"/>
                </ch-field>
            </div>
            <div class="size" y-spaced-2>
                <h1 primary-text>Size (cm)</h1>
                <ch-field label="Width" :validators="[numberValidator]">
                    <ch-editor-input ref="width" type="number" :value="width" @input="setWidth"/>
                </ch-field>
                <ch-field v-if="fixture.type !== 'Pegboard'" label="Depth" :validators="[numberValidator]">
                    <ch-editor-input ref="depth" type="number" :value="depth" @input="setDepth"/>
                </ch-field>
                <ch-field label="Height" :validators="[numberValidator]">
                    <ch-editor-input ref="height" type="number" :value="height" @input="setHeight"/>
                </ch-field>
                <div v-if="fixture.type === 'Chest'" y-spaced-2>
                    <h1 primary-text>Container size (cm)</h1>
                    <ch-field label="Width" :validators="[numberValidator]">
                        <ch-editor-input ref="innerWidth" type="number" :value="innerWidth" @input="setInnerWidth"/>
                    </ch-field>
                    <ch-field label="Depth:" :validators="[numberValidator]">
                        <ch-editor-input ref="innerDepth" type="number" :value="innerDepth" @input="setInnerDepth"/>
                    </ch-field>
                    <ch-field label="Height:" :validators="[numberValidator]">
                        <ch-editor-input ref="innerHeight" type="number" :value="innerHeight" @input="setInnerHeight"/>
                    </ch-field>
                </div>
            </div>
        </div>
        <div class="actions" y-spaced-2>
            <ch-button @click="deleteFixture" size="fill" type="error" justified>Delete fixture
                <ch-icon icon="delete"/>
            </ch-button>
            <ch-button @click="clearFixture" size="fill" type="error" justified>Empty fixture
                <ch-icon icon="remove_circle"/>
            </ch-button>
        </div>
    </div>
</template>

<script>

    import {BucketService} from '../../services/BucketService';
    import Repository from '../../services/Repository';

    export default {
        name: 'FixtureInfo',
        props: {
            fixtureId: String,
            repository: Repository,
            selectionManager: Object
        },
        data() {
            return {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
                thickness: 0,
                depth: 0,
                innerWidth: 0,
                innerHeight: 0,
                innerDepth: 0
            };
        },
        computed: {
            /**
             * @return {Fixture}
             */
            fixture() {
                const fixture = this.repository.planogram.fixtures.find(f => f.id === this.fixtureId);
                if (fixture) {
                    this.x = fixture.geometry.x / 10;
                    this.y = (fixture.geometry.y + this.repository.planogram.baseHeight) / 10
                    this.width = fixture.geometry.width * 0.1;
                    this.height = Math.max(fixture.geometry.height, fixture.geometry.thickness) * 0.1;
                    this.depth = fixture.geometry.depth * 0.1;
                    if (fixture.type === 'Chest') {
                        this.innerWidth = fixture.geometry.maximumVolume.width * 0.1;
                        this.innerHeight = fixture.geometry.maximumVolume.height * 0.1;
                        this.innerDepth = fixture.geometry.maximumVolume.depth * 0.1;
                    } else {
                        this.innerWidth = 0;
                        this.innerHeight = 0;
                        this.innerDepth = 0;
                    }
                }
                return fixture;
            },
            /**
             * @return {number}
             */
            stockInUnits() {
                if (!this.fixture) return 0;
                return this.fixture.buckets.reduce((a, b) => a + (b.type === 'Bucket' ? BucketService.getBucketUnits(b) : 0), 0);
            }
        },
        methods: {
            /**
             * @param {string} n
             * @return {string|null}
             */
            numberValidator(n) {
                if (n === null || !/^\d+(\.\d+)?$/.test(n))
                    return 'The value is not a valid number';
                return null;
            },
            /**
             * @param {number} x
             * @param {number} y
             * @param {number} z
             */
            updatePosition(x, y, z) {
                this.$unityManager.Dispatch('ChangeFixturesPosition', {
                    fixtureIds: [this.fixture.id], position: {
                        x: x * 10, y: y * 10, z: z * 10
                    }
                });
            },
            /**
             * @param {number} width
             * @param {number} height
             * @param {number} depth
             */
            updateSize(width, height, depth) {
                this.$unityManager.Dispatch('ResizeFixtures', {
                    fixtureIds: [this.fixture.id], size: {
                        width: width * 10, height: height * 10, depth: depth * 10
                    }
                });
            },
            /**
             * @param {number} width
             * @param {number} height
             * @param {number} depth
             */
            updateInnerSize(width, height, depth) {
                this.$unityManager.Dispatch('ResizeChestsInnerSize', {
                    fixtureIds: [this.fixture.id], innerSize: {
                        width: width * 10, height: height * 10, depth: depth * 10
                    }
                });
            },
            /**
             * @param {string} value
             */
            setX(value) {
                const x = Number.parseFloat(value);
                this.updatePosition(x, this.y, 0);
                this.$refs.x.cancelInput();
            },
            /**
             * @param {string} value
             */
            setY(value) {
                const y = Number.parseFloat(value);
                this.updatePosition(this.x, y, 0);
                this.$refs.y.cancelInput();
            },
            /**
             * @param {string} value
             */
            setWidth(value) {
                const width = Math.max(Number.parseFloat(value), this.innerWidth + 2);
                this.updateSize(width, this.height, this.depth);
                this.$refs.width.cancelInput();
            },
            /**
             * @param {string} value
             */
            setHeight(value) {
                const height = Math.max(Number.parseFloat(value), this.innerHeight + 2);
                this.updateSize(this.width, height, this.depth);
                this.$refs.height.cancelInput();
            },
            /**
             * @param {string} value
             */
            setDepth(value) {
                const depth = Math.max(Number.parseFloat(value), this.innerDepth + 2);
                this.updateSize(this.width, this.height, depth);
                this.$refs.depth.cancelInput();
            },
            /**
             * @param {string} value
             */
            setInnerWidth(value) {
                const innerWidth = Math.max(Number.parseFloat(value), 1);
                const width = Math.max(this.width, innerWidth + 2);
                this.updateSize(width, this.height, this.depth);
                this.updateInnerSize(innerWidth, this.innerHeight, this.innerDepth);
                this.$refs.innerWidth.cancelInput();
            },
            /**
             * @param {string} value
             */
            setInnerHeight(value) {
                const innerHeight = Math.max(Number.parseFloat(value), 1);
                const height = Math.max(this.height, innerHeight + 2);
                this.updateSize(this.width, height, this.depth);
                this.updateInnerSize(this.innerWidth, innerHeight, this.innerDepth);
                this.$refs.innerHeight.cancelInput();
            },
            /**
             * @param {string} value
             */
            setInnerDepth(value) {
                const innerDepth = Math.max(Number.parseFloat(value), 1);
                const depth = Math.max(this.depth, innerDepth + 2);
                this.updateSize(this.width, this.height, depth);
                this.updateInnerSize(this.innerWidth, this.innerHeight, innerDepth);
                this.$refs.innerDepth.cancelInput();
            },
            deleteFixture() {
                this.$unityManager.Dispatch('DeleteItems', {
                    itemIds: [this.fixtureId]
                });
            },
            clearFixture() {
                const bucketIds = this.fixture.buckets.map(bucket => bucket.id);
                this.$unityManager.Dispatch('DeleteItems', {
                    itemIds: bucketIds
                });
            }
        }

    };
</script>

<style scoped>

    .fixtureInfo {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        align-content: stretch;
        overflow: auto;
    }

    .info {
        flex: 1 1 auto;
        overflow-y: auto;
        padding: var(--doubleMargin);
        border-bottom: 1px solid var(--elevation-04);
    }

    .actions {
        flex: 0 0 auto;
        padding: var(--doubleMargin);
    }

</style>