<template>
    <ch-content-dialog type="primary" :title="placeholderName" ref="dialog" size="xl">
<!--        <ch-image :src="image"/>-->
        <div class="placeholderImage" v-if="image">
            <ch-image :src="image"/>
        </div>
    </ch-content-dialog>
</template>
<script>
    export default {
        name: "PlaceholderDetails",
        data() {
            return {
                placeholderName: '',
                image: '../../../static/product_placeholder.png',
            }
        },
        methods: {
            open(placeholder) {
                this.placeholderName = placeholder.name;
                this.image = placeholder.preview;
                this.$refs.dialog.open();
            }
        }
    }
</script>

<style scoped>

    .placeholderImage {
        height: 540px;
    }

</style>
