<template>
    <ch-form-dialog ref="dialog" type="primary" title="Create group" @submit="createGroup" @reset="clear">
        <ch-field label="Name" :validators="[groupNameValidator]">
            <ch-text-input placeholder="Group name" v-model="groupName"/>
        </ch-field>
        <ch-field label="Select group color">
            <ch-radio-group horizontal v-model="selectedColor">
                <ch-radio v-for="(color, i) of colors" :label="color" :key="i" >
                    <ch-indicator slot="icon" :color="color"/>
                </ch-radio>
            </ch-radio-group>
        </ch-field>
        <template slot="actions">
            <ch-button cancel type="secondary" size="small">Cancel</ch-button>
            <ch-button submit type="primary" size="small">Ok</ch-button>
        </template>
    </ch-form-dialog>
</template>

<script>
    import SelectionManager from '../../managers/SelectionManager';
    import {Trade} from './Trade';
    import Repository from '../../services/Repository';

    export default {
        name: 'CreateNewGroup',
        props: {
            repository: Repository,
            selectionManager: SelectionManager
        },
        data() {
            return {
                colors: Trade.availableGroupColors,
                productIds: null,
                groupName: null,
                selectedColor: Trade.availableGroupColors[0]
            };
        },
        methods: {
            /**
             * @param {string} name
             * @return {string|null}
             */
            groupNameValidator(name) {
                if (!name || !/^(\w|-|\.|\s){1,30}$/.test(name.trim()))
                    return 'Valid characters: A-z, 0-9 and . _ -';
                return null;
            },
            /**
             * @param {string[]} productIds
             */
            open(productIds) {
                this.productIds = productIds;
                this.$refs.dialog.open();
                //FIX: SZRE-104;
                this.groupName = '';
                this.groupName = null;
            },
            clear() {
                this.groupName = null;
                this.selectedColor = this.colors[0];
            },
            createGroup() {
                this.$trade.createGroup(
                    this.repository.planogram.id,
                    this.groupName.trim(),
                    this.selectedColor,
                    this.productIds
                ).then(response => {
                        const newGroup = response.group;
                        this.repository.planogram.productsGroups.push(newGroup);
                        this.$snotify.success(`Group created`, {timeout: 5000, showProgressBar: false});
                        this.$emit('groupCreated', newGroup);
                    }
                ).catch(() => this.$snotify.error(`Group creation failed`, {
                    timeout: 5000,
                    showProgressBar: false
                }));
            }
        }
    };
</script>

<style scoped>

</style>