<template>
	<div class="members" x-spaced-1>
		<identity-info
			v-for="member of workGroupMembers"
			:key="member.identityId"
			:id="member.identityId"
			avatar-only
		/>
		<span class="circle" v-if="remainingMembersCount > 1" @click="openMembersDialog">+{{
				remainingMembersCount
			}}</span>
		<span class="circle" v-if="isAdmin" @click="editMembers"><ch-icon icon="fas fa-user-plus" :size="11"/></span>
		<work-group-members-dialog ref="membersDialog"/>
		<edit-members-dialog ref="editMembersDialog"/>
	</div>
</template>

<script>
import IdentityInfo from '@/user/workgroups/components/IdentityInfo';
import WorkGroupMembersDialog from '@/user/workgroups/dialog/WorkGroupMembersDialog';
import EditMembersDialog from '@/user/workgroups/dialog/EditMembersWorkGroupDialog';

export default {
	name: 'MembersIndicator',
	components: {EditMembersDialog, WorkGroupMembersDialog, IdentityInfo},
	props: {
		workGroup: Object,
		maxMembers: Number
	},
	computed: {
		members() {
			return this.workGroup.members;
		},
		isAdmin() {
			const currentMember = this.members.find(m => m.identityId === this.$storage.user.id);
			return currentMember && currentMember.isAdmin;
		},
		workGroupMembers() {
			const members = this.members.sort((a, b) => a.identityId === this.$storage.user.id ? -1 : 1);
			return members.length > this.maxMembers + 1 ? members.slice(0, this.maxMembers) : members;
		},
		remainingMembersCount() {
			return this.members.length - this.maxMembers;
		}
	},
	methods: {
		openMembersDialog() {
			if(this.isAdmin)
				this.editMembers();
			else
				this.$refs.membersDialog.open(this.workGroup);
		},
		editMembers() {
			this.$refs.editMembersDialog.open(this.workGroup);
		}
	}
};
</script>

<style scoped>

.members {
	display: flex;
	margin-right: 12px;
}

.circle {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--elevation-04);
	width: 20px;
	height: 20px;
	border-radius: 10px;
	font-size: 11px;
	user-select: none;
	cursor: pointer;
}

</style>
