<template>
    <div y-spaced-2>
        <div
            class="stock-info-header"
        >
            <ch-icon
                v-if="! isExpanded"
                class="header-icon"
                size="32"
                icon="arrow_right"
                @click="isExpanded = ! isExpanded"
            />
            <ch-icon
                v-else
                class="header-icon"
                size="32"
                icon="arrow_drop_down"
                @click="isExpanded = ! isExpanded"
            />
            <h1 primary-text>Positions info</h1>
        </div>
        <ch-dl
            v-if="isExpanded"
            label-width="50%"
        >
            <ch-dd label="Units">{{stockInfo.units}}</ch-dd>
            <ch-dd label="Unit dimensions (cm)">
                <div x-spaced-1_2>
                    <ch-icon icon="fas fa-arrows-alt-h"/>
                    <b>{{stockInfo.unitSize.height / 10}}</b>
                    <ch-icon icon="fas fa-arrows-alt-v"/>
                    <b>{{stockInfo.unitSize.depth / 10}}</b>
                    <ch-icon icon="fas fa-expand-alt"/>
                    <b>{{stockInfo.unitSize.depth / 10}}</b>
                </div>
            </ch-dd>
            <ch-dd label="Position linear space">{{localLinear / 10}} cm</ch-dd>
            <ch-dd label="Product linear space">{{totalLinear / 10}} cm</ch-dd>
            <ch-dd label="Facing">
                <div x-spaced-1_2>
                    <ch-icon icon="fas fa-arrows-alt-h"/>
                    <b>{{stockInfo.bucket.facing.h}}</b>
                    <ch-icon icon="fas fa-arrows-alt-v"/>
                    <b>{{stockInfo.bucket.facing.v}}</b>
                    <ch-icon icon="fas fa-expand-alt"/>
                    <b>{{stockInfo.bucket.facing.d}}</b>
                </div>
            </ch-dd>
            <ch-dd label="Product spacing">
                <ch-number-input type="primary" :value="stockInfo.bucket.padding.h" @input="changePadding" :min="0"
                                 symbol="mm" size="fill"/>
            </ch-dd>
            <ch-dd label="Product tilt">
                <ch-number-input type="primary" :value="stockInfo.bucket.tilt" @input="changeTilt" :min="0" :max="90"
                                 :step="5" symbol="°" size="fill"/>
            </ch-dd>
            <ch-dd label="Vertical crush">
                <ch-number-input type="primary" :value="crushFactor.v * 100" :min="0" :max="99" symbol="%" size="fill"
                                 @input="v => changeCrushFactor(crushFactor.h, v / 100, crushFactor.d)"/>
            </ch-dd>
            <ch-dd label="Horizontal crush">
                <ch-number-input type="primary" :value="crushFactor.h * 100" :min="0" :max="99" symbol="%" size="fill"
                                 @input="h => changeCrushFactor(h / 100, crushFactor.v, crushFactor.d)"/>
            </ch-dd>
            <ch-dd label="Depth crush">
                <ch-number-input type="primary" :value="crushFactor.d * 100" :min="0" :max="99" symbol="%" size="fill"
                                 @input="d => changeCrushFactor(crushFactor.h, crushFactor.v, d / 100)"/>
            </ch-dd>
        </ch-dl>
    </div>
</template>

<script>
    import CrushFactor from "../../../model/product/CrushFactor";

    export default {
        name: 'StockInfo',
        props: {
            stockInfo: Object
        },
        data() {
            return {
                isExpanded: true,
            };
        },
        computed: {
            /**
             * @return {number}
             */
            localLinear() {
                return Math.round(this.stockInfo.localLinear * 10) / 10;
            },
            /**
             * @return {number}
             */
            totalLinear() {
                return Math.round(this.stockInfo.totalLinear * 10) / 10;
            },
            /**
             * @return {CrushFactor}
             */
            crushFactor() {
                const crushFactor = this.stockInfo.bucket.crushFactor;
                return new CrushFactor(
                    Math.round(crushFactor.h * 100) / 100,
                    Math.round(crushFactor.v * 100) / 100,
                    Math.round(crushFactor.d * 100) / 100
                )
            }
        },
        methods: {
            /**
             * @param {number} value
             */
            changePadding(value) {
                this.$unityManager.Dispatch('PadBucket', {
                    bucketId: this.stockInfo.bucket.id,
                    padding: {width: value, height: 0, depth: 0}
                });
            },
            /**
             * @param {number} value
             */
            changeTilt(value) {
                this.$unityManager.Dispatch('TiltBucket', {
                    bucketId: this.stockInfo.bucket.id,
                    tilt: value
                });
            },
            /**
             * @param {number} h
             * @param {number} v
             * @param {number} d
             */
            changeCrushFactor(h, v, d) {
                this.$unityManager.Dispatch('CrushBucket', {
                    bucketId: this.stockInfo.bucket.id,
                    crushFactor: {h, v, d}
                });
            }
        }
    };
</script>

<style scoped>
.stock-info-header {
    display: flex;
    align-items: center;
}

h1 {
    font-size: 16px;
}

.header-icon {
    margin-left: -8px;
    color: var(--primary);
}

</style>