<template>
    <ch-access-layout v-if="!loading">
        <div class="logo" slot="logo"></div>
        <div y-spaced-2>
            <h3>Login with your account</h3>
            <ch-form class="form" @submit="onLogin" :generic-errors="loginResult">
                <ch-field label="Username">
                    <ch-text-input placeholder="Email or username" v-model="username" size="large"/>
                </ch-field>
                <ch-field label="Password">
                    <ch-text-input placeholder="Password" v-model="password" size="large" password/>
                </ch-field>
                <span @click="goToPasswordReset" class="passwordReset">Forgot your password?</span>
                <template slot="actions">
                    <ch-button submit>Login</ch-button>
                </template>
            </ch-form>
        </div>
    </ch-access-layout>
    <ch-loader v-else fullscreen/>
</template>

<script>
import {daysToMs} from '@/utils/DateTimeService';

	export default {
        name: 'Login',
        props: {
            redirect: String,
	        loginOnly: Boolean
        },
        data() {
            return {
                username: null,
                password: null,
                loginResult: [],
                showPopup: true,
                loading: true
            };
        },
        mounted() {
            document.title = 'Login | ShelfZone Studio';
            this.loading = true;
            this.$iam.authenticate()
                .then((result) => this.onLoginSuccess(result))
                .catch(() => this.loading = false);
        },
        methods: {
            onLogin() {
                this.loading = true;
                this.username = (this.username || '').trim();
                this.$iam.authenticate(this.username, this.password)
                    .then(() => this.$iam.generateToken(this.username, this.password))
                    .then(this.onLoginSuccess.bind(this))
                    .catch(error => this.onLoginError(error.response ? error.response : error));
            },
            onLoginSuccess(response) {
            	if(this.loginOnly) {
            		window.close();
            		window.onLogin();
            		return;
	            }
                this.$iam.findPasswordExpirationTime()
                    .then(r => {
                        const expirationTime = r.expirationTime.getTime();
                        const notificationInterval = daysToMs(7);
                        const remainingTime = expirationTime - Date.now();
                        const isExpiring = 0 < remainingTime && remainingTime < notificationInterval;
                        const isExpired = remainingTime < 0;
                        const passwordExpireSoonAlreadyShown =
                            Date.now() - Number(window.localStorage.passwordExpireSoonShownAt) < daysToMs(1);
                        if (isExpired) {
                            this.$router.push({
                                name: 'passwordExpired',
                                query: {
                                    expiredAt: expirationTime
                                }
                            });
                        } else if (isExpiring && !passwordExpireSoonAlreadyShown) {
                            window.localStorage.passwordExpireSoonShownAt = Date.now();
                            this.$router.push({
                                name: 'passwordExpireSoon',
                                query: {
                                    expirationTime,
                                    redirect: this.redirect,
                                    identityKey: response['x-identity-key']
                                }
                            });
                        } else {
                            window['studioApi'].initializeWebSocket();
                            this.$storage
                                .init()
                                .then(() => {
                                    this.$store.commit('login');
                                    this.loginResult = [];
                                    if (this.redirect)
                                        this.$router.replace(this.redirect);
                                    else
                                        this.$router.push({name: 'projects'});
                                })
                                .catch((code) => this.onLoginError({status: code}))
                                .finally(() => this.loading = false);
                        }
                    });
            },
            onLoginError(error) {
                this.loading = false;
                if (error.status === 401) this.$router.push({name: 'AccountDisabled'});
                else if (error.status === 404) this.loginResult = ['Incorrect username or password'];
                else if (error.status === 451) this.$router.push({name: 'LicenseExpired'});
                else this.loginResult = ['Service unavailable'];
            },
            goToPasswordReset() {
                this.$router.push({name: 'resetPassword'});
            }
        }
    };
</script>

<style scoped>

    .logo {
        background-image: url("../assets/logo.png");
        background-size: 210px;
    }

    .passwordReset {
        text-decoration: underline;
        font-size: 14px;
        font-weight: 100;
        cursor: pointer;
        align-items: center;
        color: white;
    }

</style>
