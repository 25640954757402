<template>
    <div y-spaced-1>
        <ch-field label="x position (cm)" label-position="left" v-if="!valuesToHide.includes('x')">
            <ch-number-input type="primary" size="fill" :step="10" :disabled="disabled" :value="value.x / 10" @input="x => update(x * 10, value.y, value.z)"/>
        </ch-field>
        <ch-field label="y position (cm)" label-position="left" v-if="!valuesToHide.includes('y')">
            <ch-number-input type="primary" size="fill" :step="10" :disabled="disabled" :value="value.y / 10" @input="y => update(value.x, y * 10, value.z)"/>
        </ch-field>
        <ch-field label="Ground position (cm)" label-position="left" v-if="!valuesToHide.includes('z')">
            <ch-number-input type="primary" size="fill" :step="10" :disabled="disabled" :value="value.z / 10" @input="z => update(value.x, value.y, z * 10)"/>
        </ch-field>
    </div>
</template>

<script>
    export default {
        name: 'Position',
        props: {
            value: Object,
            disabled: Boolean,
            hide: {
                type: String,
                default: ''
            },
            elementType: String
        },
        computed: {
          valuesToHide() {
              return this.hide.split(',');
          }
        },
        methods: {
            update(x, y, z) {
                this.$emit('input', {x, y, z});
            },
        }
    };
</script>

<style scoped>

</style>