<template>
	<ch-application-layout>
		<content-navigation slot="navigation" @currentPath="updatePropType"/>
		<template slot="version">ShelfZone Studio v. {{ version }}</template>
		<assets-catalog slot="main" :query="query"/>
	</ch-application-layout>
</template>

<script>
import ContentNavigation from '../ContentNavigation';
import StaticConfig      from '../../config.js';
import AssetsCatalog     from './AssetsCatalog';

export default {
	name: 'AssetsPage',
	components: {
		AssetsCatalog,
		ContentNavigation
	},
	props: {
		matching: String,
		limit: {
			type: Number,
			default: 50
		},
		page: {
			type: Number,
			default: 0
		},
		deprecated: {
			type: Boolean,
			default: false
		},
		type: String,
		view: {
			type: String,
			default: 'table'
		}
	},
	data() {
		return {
			query: {
				limit: this.limit,
				page: this.page,
				deprecated: this.deprecated,
				matching: this.matching,
				type: this.type,
				view: this.view
			}
		};
	},
	computed: {
		version() {
			return StaticConfig.version;
		}
	},
	watch: {
		query: {
			handler() {
				this.$router.push({
					name: 'assets',
					query: this.query
				});
			},
			deep: true
		}
	},
	mounted() {
		document.title = 'Assets | ShelfZone Studio';
	},
	methods: {
		updatePropType(type) {
			this.query.type = type;
		}
	}
};
</script>

<style scoped>
</style>
