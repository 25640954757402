<template>
    <div
        v-if="repository.planogram"
        class="leftSideBar"
        fill
        @dragover="$emit('dragover')"
    >
        <ch-tabs v-model="selectedTab">
            <template slot="voices">
                <ch-tab-voice key="products" label="Products"/>
                <ch-tab-voice key="groups" :label="`Groups (${repository.planogram.productsGroups.length})`"/>
            </template>
            <template slot="tabs">
                <ch-tab-content key="products">
                    <products-library
                        :repository="repository"
                        :selectionManager="selectionManager"
                        :cameraView="cameraView"
                        @market-data-clicked="$emit('market-data-clicked')"
                    />
                </ch-tab-content>
                <ch-tab-content key="groups">
                    <groups-library
                        ref="groupsLibrary"
                        :groups="repository.planogram.productsGroups"
                        :selectionManager="selectionManager"
                        @createGroup="editor.createGroup"
                    />
                </ch-tab-content>
            </template>
        </ch-tabs>
    </div>
</template>

<script>

    import ProductsLibrary from './ProductsLibrary';
    import GroupsLibrary from '../trade/GroupsLibrary';
    import SelectionManager from '../../managers/SelectionManager';
    import Repository from '../../services/Repository';
    import {ColorService} from '../../../layouteditor/services/ColorService';
    import HexColor from '../../../layouteditor/model/common/color/HexColor';

    export default {
        name: 'LeftSideBar',
        components: {GroupsLibrary, ProductsLibrary},
        props: {
            repository: Repository,
            selectionManager: SelectionManager
        },
        data() {
            return {
                selectedTab: 'products',
                cameraView: 'Front'
            };
        },
        computed: {
            editor() {
                return this.$parent.$parent;
            }
        },
        watch: {
            selectedTab() {
                if (this.selectedTab === 'products') {
                    this.$unityManager.Dispatch('DeactivateFilter', {id: this.selectionManager.currentGroupId});
                    this.selectionManager.currentGroupId = null;
                }
            },
            'selectionManager.currentGroupId'() {
                if (this.selectionManager.currentGroupId)
                    this.selectedTab = 'groups';
            }
        },
        mounted() {
            this.$unityManager.Subscribe('CameraViewChanged', this.cameraViewChanged);
            this.$unityManager.Subscribe('PlanogramLoaded', this.planogramLoaded);
        },
        beforeDestroy() {
            this.$unityManager.Unsubscribe('CameraViewChanged', this.cameraViewChanged);
            this.$unityManager.Unsubscribe('PlanogramLoaded', this.planogramLoaded);
        },
        methods: {
            /**
             * @param {{view: string}} event
             */
            cameraViewChanged(event) {
                this.cameraView = event.view;
            },

            planogramLoaded() {
                for (const group of this.repository.planogram.productsGroups) {
                    const condition = {
                        type: 'IsBucketWithProduct',
                        productIds: group.matchedProductsIds
                    };
                    const color = ColorService.hexColorToRGBColor(new HexColor(group.color));
                    this.$unityManager.Dispatch('CreateFilter', {id: group.id, name: group.name, condition, color});
                }
            }
        }
    };
</script>

<style scoped>

    .leftSideBar {
        display: flex;
        flex-direction: column;
        outline: none;
    }

</style>