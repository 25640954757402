<template>
    <div>
        <div class="products primaryColors">
            <div class="productsHeader" y-spaced-2>
                <h1 primary-text>Products</h1>
                <ch-search-bar v-model="productSearchQuery" real-time size="fill narrow"/>
                <div class="productsActions" x-spaced-1_2>
                    <ch-field fill>
                        <ch-radio-group v-model="productFilterRadio" size="small narrow">
                            <ch-radio-button v-for="[key, title] of productFilterOptions"
                                             class="productsFilterOption"
                                             size="narrow"
                                             :key="key"
                                             :label="key">
                                {{title}}
                            </ch-radio-button>
                        </ch-radio-group>
                    </ch-field>
                </div>
                <span elevation-06-text>{{sortedProducts.length}} products</span>
            </div>
            <ch-list v-if="recordings.length  === 1"
                     class="productsList"
                     type="flat"
                     size="narrow"
                     :selectable="'single'"
                     v-model="selectedProduct">
                <ch-list-item v-for="product of sortedProducts"
                              :key="product.id"
                              :value="product" >
                    <template slot="selectionIcon">
                        <ch-indicator :type="product.performances.state.decoration.type"/>
                    </template>
                    <span class="productTitle" @click.stop="openProductDetail(product)">{{product.ean}}</span>
                    <template  slot="actions">
                        <ch-icon :icon="product.performances.state.decoration.icon"/>
                    </template>
                </ch-list-item>
            </ch-list>
            <ch-list v-else-if="recordings.length > 1"
                     class="productsList"
                     selectable="single"
                     hideSelection
                     size="narrow">
                <ch-list-item v-for="product of sortedProducts"
                              :key="product.id+'__'+product.ean"
                              :value="product" >
                    <span class="productTitle" @click.stop="openProductDetail(product)">{{product.ean}}</span>
                </ch-list-item>
            </ch-list>
            <span v-else class="productsList" elevation-05-text>Please select a subject</span>
        </div>
    </div>

</template>

<script>
import Vue from 'vue';
import * as lodash from 'lodash';
import Studio from '../../../plugins/studioapi/storage/Studio';
import { ProductEventType } from '../../../plugins/studioapi/api/research/models';
import ProductEventsService, { productFilters } from '../../services/ProductEventsService';
import { isSubstring } from '../../analysis-helpers';

const productsEventsStore = Vue.observable({ events: {} });

export default {
    name: 'AnalysisRightSidebar',
    props: {
        field: {
            type: Studio,
            required: true
        },
        recordings: {
            type:     Array,
            required: true,
            default:  () => ([]),
        },
        product: Object
    },

    data() {
        return {
            productSearchQuery: '',
            productFilterOptions: productFilters,
            productFilterRadio: 'picked',
        }
    },

    computed: {
        selectedProduct: {
            set(value) {
                this.$emit('update:product', value);
            },
            get() {
                return this.product;
            },
        },

        loadedProductsEvents: {
            get() { return productsEventsStore.events;},
            set([recId, events]) {
                this.$set(productsEventsStore.events, recId, events);
            }
        },
        productsList () {
            const products = [];
            for(const recId of this.recordings) {
                let recordingProducts;
                if( recId in this.loadedProductsEvents) {
                    recordingProducts = this.loadedProductsEvents[recId];
                } else {
                    recordingProducts = [];
                }
                products.push(...recordingProducts);
            }
            if(this.recordings.length > 1){
                const groupedByEan = lodash.groupBy(products,({ean})=> ean);
                return [...Object.values(groupedByEan)].map((ps) => ProductEventsService.mergeSameProducts(ps))
            }
            return products;
        },

        sortedProducts() {
            const [ key, title, func] = this.productFilterOptions.find((i) => i[0] === this.productFilterRadio) || [];
            const filteredProducts = [];
            for (const product of this.productsList) {
                if (isSubstring(this.productSearchQuery, product.ean) && (!this.productFilterRadio || (func && func(product))))
                    filteredProducts.push(product)
            }
            return filteredProducts.sort((a,b) => a.ean.toLowerCase().localeCompare(b.ean.toLowerCase()));
        },

        selectedProductEvents() {
            return this.selectedProduct ? this.selectedProduct.events.filter(e => e.event !== ProductEventType.CartContentPurchased) : []
        },
    },

    methods: {
        openProductDetail(product) {
            this.$emit('open-product-details', product);
        },
    },
    
    watch: {
        recordings: {
            immediate: true,
            handler: async function () {
                this.selectedProduct = null;
                for ( const recId of this.recordings) {
                    this.loadedProductsEvents = [recId, await ProductEventsService.loadEventsForRecordingId(this.field, recId)];
                }
            }
        },

        selectedProduct: {
            immediate: true,
            handler: function () {
                this.$emit('selected-product', { product: this.selectedProduct, events: this.selectedProductEvents });
            }
        }
    }
}
</script>

<style scoped>
    .products {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }
    
    .productsHeader {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        padding: var(--doubleMargin);
        padding-bottom: var(--singleMargin);
    }
    
    .productsActions {
        display: flex;
        flex: 1
    }

    .productsFilterOption {
        flex: 1
    }

    .productsList {
        flex: 1 1 auto;
        padding: 0 var(--doubleMargin);
        box-sizing: border-box;
    }
    
    .productTitle {
        text-decoration: underline;
    }

</style>