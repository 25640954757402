<template>
	<ch-content-dialog ref="dialog" type="primary" title="Element detail" size="xl">
		<div v-if="prop" y-spaced-2 fill>
			<h2>{{ prop.name }}</h2>
			<div class="details" x-spaced-2>
				<div>
					<ch-image :src="prop.imageURL"/>
				</div>
				<div>
					<ch-dl label-position="top">
						<ch-dd label="Dimensions">
							<size :width="prop.size.w" :height="prop.size.h" :depth="prop.size.d"/>
						</ch-dd>
						<ch-dd label="Category">{{ prop.archetype.type | formatArchetype }}</ch-dd>
					</ch-dl>
				</div>
			</div>
			<div class="relatedElements" y-spaced-2>
				<h2 primary-text>Related elements</h2>
				<div v-if="loading">Loading related elements...</div>
				<ch-empty-content v-else-if="relatedPropIds.length === 0" firstMessage="No asset found"/>
				<ch-grid-layout v-else :width="140" :height="200" :gap="32" class="relatedPropsGrid">
					<prop-card v-for="prop in relatedProps" :key="prop.id" :prop="prop" @click.native="open(prop)"/>
				</ch-grid-layout>
			</div>
		</div>
	</ch-content-dialog>
</template>

<script>
import {ArchetypeType} from '@/plugins/studioapi/api/propstore/model/ArchetypeType';
import Size            from '@/components/Size';
import PropCard        from '@/content/assets/PropCard';

export default {
	name: 'PropDetailsDialog',
	components: {PropCard, Size},
	props: {
		props: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			prop: null,
			relatedPropIds: [],
			loading: false
		};
	},
	computed: {
		relatedProps() {
			return this.relatedPropIds.map(id => this.props.find(prop => prop.id === id));
		}
	},
	filters: {
		/**
		 * @param {ArchetypeType} archetypeType
		 * @return {string}
		 */
		formatArchetype(archetypeType) {
			switch (archetypeType) {
				case ArchetypeType.Basket:
					return 'Basket';
				case ArchetypeType.Chest:
					return 'Chest';
				case ArchetypeType.ChestFridge:
					return 'Chest Fridge';
				case ArchetypeType.Decoration:
					return 'Decoration';
				case ArchetypeType.Fridge:
					return 'Fridge';
				case ArchetypeType.Hook:
					return 'Hook';
				case ArchetypeType.Pallet:
					return 'Pallet';
				case ArchetypeType.Pegboard:
					return 'Pegboard';
				case ArchetypeType.Shelf:
					return 'Shelf';
				case ArchetypeType.ShelfBack:
					return 'Back';
				case ArchetypeType.WolfMouthFridge:
					return 'Wolf\'s Mouth Fridge';
			}
		}
	},
	methods: {
		open(prop) {
			this.$refs.dialog.open();
			this.prop = prop;
			this.loading = true;
			this.$propStore.listRelatedProps(this.prop.id)
				.then(response => {
					this.loading = false;
					this.relatedPropIds = response.propIds;
				});
		}
	}
};
</script>

<style scoped>

.details {
	display: flex;
	flex-direction: row;
}

.details > div {
	overflow: hidden;
	flex: 1 0 0;
}

.relatedPropsGrid {
	overflow-y: auto;
	max-height: 200px;
}

</style>