<template>
    <div class="assets">
        <div class="filters" y-spaced-2>
            <ch-search-bar v-model="searchString" realTime size="fill narrow"/>
            <h4>
                {{materializers.length + ' elements'}}
            </h4>
        </div>
        <ch-list class="assetsList" v-if="filteredMaterializers.length > 0" size="narrow">
            <ch-list-item v-for="materializer of filteredMaterializers">{{materializer.name}}</ch-list-item>
        </ch-list>
        <ch-empty-content v-else-if="filteredMaterializers.length > 0" first-message="No elements match the query"/>
    </div>
</template>

<script>

    import LayoutController from '../services/LayoutController';

    export default {
        name: 'MaterializersLibrary',
        props: {
            controller: LayoutController
        },
        data() {
            return {
                searchString: null
            };
        },
        computed: {
            materializers() {
                return this.controller.configuration.library.filter(asset => asset.type === 'PlanogramMaterializer')
            },
            filteredMaterializers() {
                return this.materializers.filter(asset =>
                    !this.searchString ||
                    asset.name.toLowerCase().includes(this.searchString.toLowerCase())
                ).sort((a, b) => {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();
                    return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
                });
            }
        }
    };
</script>

<style scoped>

    .filters {
        flex: 0 0 auto;
        padding: var(--doubleMargin);
    }

    /* List */
    .assetsList {
        flex: 1 1 auto;
        padding: 0 var(--doubleMargin);
        box-sizing: border-box;
    }

    .assets {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        max-height: 100%;
        padding-bottom: var(--doubleMargin);
        box-sizing: border-box;
    }

</style>
