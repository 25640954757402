<template>
	<div y-spaced-1>
		<p>Add users to this group (users will be notified by email)</p>
		<ch-search-bar v-model="matching" :hints-promise="hintsPromise" real-time size="fill" @hintClick="addMember">
			<div slot-scope="{hint}">
				<div elevation-01-text>{{ hint.fullName || 'None' }}</div>
				<div elevation-04-text>{{ hint.email }}</div>
			</div>
		</ch-search-bar>
		<p>
			<span v-if="previousMembersCount">{{previousMembersCount}} already in the in workgroup - </span>
			<span>{{newMembers.length}} users will be added</span>
		</p>
		<div class="members">
			<ch-list>
				<ch-list-item v-for="member of allMembers">
					<identity-info :id="member.identityId">
						<template v-if="member.isAdmin" slot="append"> (admin)</template>
					</identity-info>
					<ch-context-menu slot="actions" v-if="member.identityId !== currentUserId" :voices="buildMemberContextMenu(member)">
						<ch-button type="flat" icon>
							<ch-icon icon="more_horiz"/>
						</ch-button>
					</ch-context-menu>
				</ch-list-item>
			</ch-list>
		</div>
	</div>
</template>

<script>
import IdentityInfo from '@/user/workgroups/components/IdentityInfo';
const _ = require('lodash');

export default {
	name: 'FindMembersList',
	components: {IdentityInfo},
	props: {
		value: Array
	},
	data() {
		return {
			newMembers: [],
			matching: null,
			debouncedFindUsers: null,
			hintsPromise: null
		};
	},
	computed: {
		currentUserId() {
			return this.$storage.user.id;
		},
		allMembers() {
			const currentMember = {
				identityId: this.$storage.user.id,
				isAdmin: true
			}
			return [currentMember, ...this.value.filter(m => m.identityId !== currentMember.identityId)];
		},
		previousMembersCount() {
			return this.value.length - this.newMembers.length;
		}
	},
	watch: {
		matching() {
			this.debouncedFindUsers();
		}
	},
	mounted() {
		this.debouncedFindUsers = _.debounce(this.findUsers, 500);
	},
	methods: {
		addMember(identity) {
			const member = {identityId: identity.id, isAdmin: false};
			const members = [...this.value, member];
			this.matching = null;
			this.newMembers.push(member);
			this.$emit('input', members);
		},
		grantMemberAdminRole(member) {
			member.isAdmin = true;
		},
		revokeMemberAdminRole(member) {
			member.isAdmin = false;
		},
		removeMember(member) {
			this.newMembers = this.newMembers.filter(m => m !== member);
			this.$emit('input', this.value.filter(m => m !== member));
		},
		buildMemberContextMenu(member) {
			return [member.isAdmin ? {
				text: 'Flag as user',
				icon: 'fas fa-user',
				action: () => this.revokeMemberAdminRole(member)
			} : {
				text: 'Make admin',
				icon: 'fas fa-user-cog',
				action: () => this.grantMemberAdminRole(member)
			}, {
				text: 'Remove user',
				icon: 'delete',
				action: () => this.removeMember(member)
			}];
		},
		findUsers() {
			if (!this.matching)
				this.hintsPromise = null;
			else
				this.hintsPromise =
					this.$iam.listIdentities(20, 0, this.matching, true)
						.then(response => {
							const membersId = this.allMembers.map(m => m.identityId);
							return response.identities.filter(identity => !membersId.includes(identity.id))
								.slice(0, 5);
						});
		}
	}
};
</script>

<style scoped>

.members {
	height: 240px;
	overflow-y: auto;
}

</style>
