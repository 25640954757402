<template>
    <div fill>
        <ch-tabs v-model="currentTab">
            <template slot="voices">
                <ch-tab-voice key="planograms">Planograms</ch-tab-voice>
                <ch-tab-voice key="elements">Elements</ch-tab-voice>
            </template>
            <template slot="tabs">
                <ch-tab-content key="elements">
                    <materializers-library v-if="controller" :controller="controller"/>
                </ch-tab-content>
                <ch-tab-content key="planograms">
                    <planograms-library v-if="controller" :controller="controller" :selection="selection"/>
                </ch-tab-content>
            </template>
        </ch-tabs>
    </div>
</template>

<script>

    import MaterializersLibrary from './MaterializersLibrary';
    import PlanogramsLibrary    from './PlanogramsLibrary';
    import LayoutController     from '../services/LayoutController';
    import SelectionController  from '../services/SelectionController';

    export default {
        name: 'LeftLayoutSideBar',
        components: {PlanogramsLibrary, MaterializersLibrary},
        props: {
            controller: LayoutController,
            selection: SelectionController
        },
        data() {
            return {
                currentTab: 'planograms'
            };
        },
        watch: {
            currentTab() {
                // const action = {
                //     type: 'ChangeLayer',
                //     data: {
                //         layer: this.currentTab === 'planograms' ? 13 : 12
                //     }
                // };
                // this.$unityManager.unityInstance.SendMessage('UI', 'ExecuteAction', JSON.stringify(action));
            }
        }
    };
</script>

<style scoped>

</style>
