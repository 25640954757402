<template>
    <div id="VideoAnalysisWrapper">
        <video id="videoPlayer" ref="videoPlayer" muted="true"
            @timeupdate     ="onVideoTimeChanged" 
            @click          ="togglePlay" 
            @loadeddata     ="updateTime" 
            @playing        ="onVideoPlaying"
            @seeking        ="onVideoSeeking"
            @waiting        ="onVideoWaiting"
        >
            <source :src="currentVideoURL" type="video/mp4">
            Your browser does not support the video tag.
        </video>
        <div class="videoOverlay" v-if="loading" style="background-color:#00000088">
            <div class="spinner"        v-if="loading"></div>
            <div class="material-icons" v-else>play_circle_outline</div>
        </div>
		<div class="videoOverlay">
            <div class="material-icons overlayIcon" v-if="isPlaying">play_circle_outline</div>
        </div>
		<div class="videoOverlay">
            <div class="material-icons overlayIcon" v-if="!isPlaying">pause_circle_outline</div>
        </div>
		<div ></div>
        <audio src="" ref="audioPlayer"></audio>
        <div id="clickBlocker"></div>
        <div id="mediaControl">
            <div id="controlButtonsWrapper">
                <i class="material-icons controlButton" @click.stop="togglePlay" v-if="this.isPlaying">pause_circle_outline</i>
                <i class="material-icons controlButton" @click.stop="togglePlay" v-else>play_circle_outline</i>
            </div>
            <div class="timeStamp">{{ currentTime }}</div>
            <div class ="sliderBar" 
                @mousedown  ="onVideoDragStart" 
                @mousemove  ="onVideoDragMove" 
                @mouseup    ="onVideoDragEnd" 
                @mouseout   ="onVideoDragEnd" 
                ref         ="slider">
                <div class="fill" ref="filledBar">
                    <div id="cursor" :class="{raise:isGraphVisible}">
                        <div id="cursorLine"> </div>
                    </div>
                </div>
            </div>
            <div class="timeStamp">{{ secondsToTimestamp(videoLength) }}</div>
            <div id="controlButtonsWrapper" >
                <VolumeSlider></VolumeSlider>
                <div class="speedOptions">
                    <ul class="speedsMenu" v-bind:style="{ opacity: showSpeeds ? 1 : 0, pointerEvents: showSpeeds ? 'all' : 'none'}">
                        <li v-for="(speed, index) of speeds" :key="'s_' + index" @click="onSpeedSelected(index)" v-bind:style="{color: index == selectedSpeed ? 'red' : ''}">
                            {{ speed.toFixed(1) }} x
                        </li>
                    </ul>
                    <span @click="showSpeeds = !showSpeeds">{{ speeds[selectedSpeed].toFixed(1) }} x</span>
                </div>
            </div>
        </div>
        <GraphViewer :playing="isPlaying" :values="emotionalValues" :onChangeState="onGraphStateChange" :onGraphDrag="setVideoPosition"></GraphViewer>
    </div>
</template>

<script>
import GraphViewer  from '../components/GraphViewer'
import VolumeSlider from '../components/VolumeSlider'

export default 
{
    name: 'VideoAnalysis',
    components: { GraphViewer, VolumeSlider },
    props: {
        field:              Object,
        studioId:           String,
        cellId:             String,
        activeRecordings:   String,
        currentCamera:      String,
        currentVideoTime:   Number,
        recordLength:       Number,
        onVideoMissing:     null,
        selectedSpeed:      Number,
    },
    data() {

        this.lastSeek = new Date();

        return {
            loading:            false,
            videoDragging:      false,
            audioDragging:      false,
            showSpeeds:         0,
            isGraphVisible:     true,
            speeds:             [10, 5, 2.5, 1],
            videoLength:        0,
            currentVideoURL:    '',
            //currentAudioURL:    '',
            emotionalValues:    [],
        }
    },
    mounted() {
        this.videoplayerSetup();

        this.videoLength                 = 0;
        this.$refs.filledBar.style.width = 0 + "%";
    },
    watch: {
       
        activeRecordings(oldRecording, newRecording) 
        {
            this.$refs.audioPlayer.pause();
            this.$refs.videoPlayer.pause();
            this.videoLength                 = 0;
            this.$refs.filledBar.style.width = 0 + "%";
            
            this.$emit('update:isPlaying', false)
            //this.$emit('update:currentTime', {start:0, end:this.timeframe.end});

            if(oldRecording !== newRecording)
                this.getVideoResources();
        },
        '$store.state.volume'()
        {
            this.$refs.audioPlayer.volume = this.$store.state.volume;
        },
        '$store.state.muted'()
        {
            this.$refs.audioPlayer.muted = this.$store.state.muted;
        }
    },
    computed: {
        currentTime() {

            return this.secondsToTimestamp(this.currentVideoTime);
        },
        isPlaying() {

            return this.$store.state.playing;
        }
    },
    methods: {
		resetVideoPlayer()
		{
			this.$refs.audioPlayer.pause();
            this.$refs.videoPlayer.pause();
            this.videoLength                 = 0;
            this.$refs.filledBar.style.width = 0 + "%";
            
			this.$emit('update:isPlaying', false)
			
			this.currentVideoURL 		= '';
			this.$refs.audioPlayer.src 	= '';
            this.$refs.audioPlayer.load(); 
			this.$refs.videoPlayer.load(); 
			
			this.emotionalValues = [];
		},
        videoplayerSetup() 
        {
            this.getVideoResources();
            this.$refs.videoPlayer.currentTime = this.currentVideoTime;
            this.$refs.videoPlayer.addEventListener('timeupdate', this.updateProgressBar, false);
        },
        getVideoResources()
        {



            if(this.activeRecordings !== null)
            {
                const currentRecording  = this.field.getRecordingWithId(this.activeRecordings);
                if(currentRecording)
                {
					if(currentRecording.hasAudio())
						this.$refs.audioPlayer.src = currentRecording.audioURL;
					else
						this.$refs.audioPlayer.src = '';

					this.$refs.audioPlayer.load(); 

					if(currentRecording.hasVideo())
						this.currentVideoURL = currentRecording.videoURL;
					else
					{
						this.currentVideoURL = '';

						if(this.onVideoMissing != null)
                            this.onVideoMissing();
					}

					this.$refs.videoPlayer.load();
					
                    currentRecording.getEmotionalValues()
					.then((values)=> {this.emotionalValues = values;})
					.catch(e => { this.emotionalValues = []; });
                }
            }
        },
        updateProgressBar() 
        {
            let percentage = 0;
            
            if(this.$refs.videoPlayer != undefined)
            {

                if(this.$refs.audioPlayer != undefined)
                {
                    if(Math.abs(this.$refs.audioPlayer.currentTime - this.$refs.videoPlayer.currentTime) > 0.5)
                    {
                        this.$refs.audioPlayer.currentTime = this.$refs.videoPlayer.currentTime;
                    }
                }

                percentage = (this.$refs.videoPlayer.currentTime / this.$refs.videoPlayer.duration) * 100;
            }
                
            if(this.$refs.filledBar)
                this.$refs.filledBar.style.width = percentage + "%";
        },
        updateTime()
        {   
            if( !this.$refs.videoPlayer ) return;
            if(this.videoLength === 0)
                this.$refs.videoPlayer.currentTime = 0;

            if(this.$refs.videoPlayer.duration != 0)
            {
                this.videoLength = this.$refs.videoPlayer.duration;
                this.$refs.videoPlayer.currentTime = (this.videoLength < this.currentVideoTime) ? this.videoLength : this.currentVideoTime;
                this.$refs.videoPlayer.playbackRate = this.speeds[this.selectedSpeed];
                this.$refs.audioPlayer.playbackRate = this.speeds[this.selectedSpeed];
                this.updateProgressBar();
                this.isPlaying && this.$refs.videoPlayer.play();
            }
        },
        togglePlay() 
        {
            if(this.currentVideoURL == '')
                return;

            if(this.isPlaying)
            {
                this.$refs.videoPlayer.pause();
                this.$refs.audioPlayer.pause();
                this.$store.commit('pause');
                
            }
            else
            {
                this.$refs.videoPlayer.play();
                //this.$refs.audioPlayer.play();
                this.$store.commit('play');
            }
        },
        onVideoTimeChanged() 
        {
            if(this.$refs.videoPlayer)
            {
                //this.$refs.videoPlayer.currentTime = this.timeframe.end;
                this.$emit('update:currentVideoTime', this.$refs.videoPlayer.currentTime);
            }
            else
            {
                this.$emit('update:currentVideoTime', 0);
            }
        },
        onVideoPlaying(e)
        {
            this.$refs.audioPlayer.play();
            this.loading = false;
        },
        onVideoPause(e)
        {
            if(e.target !== null)
            {
                if(this.$refs.audioPlayer)
                    this.$refs.audioPlayer.pause();
                
                this.$store.commit('pause');

                this.loading = false;
            }
        },
        onVideoSeeking(e)
        {
            this.$refs.audioPlayer.pause();
        },
        onVideoWaiting(e)
        {
            this.loading = true;
        },
        onVideoError(e)
        {
            let currentRecording      = this.field.getRecordingWithId(this.activeRecordings);
            currentRecording.videoURL = '';
            this.getVideoResources();
        },
        onVideoDragStart(e)
        {
            if(this.currentVideoURL == '')
                return;

            this.videoDragging = true;
            this.onVideoDragMove(e);
        },
        onVideoDragEnd(e)
        {
            this.videoDragging = false;
        },
        onVideoDragMove(e)
        {
            if(this.videoDragging == true)
            {
                const originX    = this.$refs.slider.getBoundingClientRect().left;
                const width      = this.$refs.slider.offsetWidth;
                const deltaX     = e.clientX - originX;
                const percentage = (deltaX / width * 100);

                this.setVideoPosition(percentage);
            }
        },
        setVideoPosition(percentValue)
        {
            this.$refs.filledBar.style.width = percentValue + "%";

            let now     = new Date();
            let elapsed = now - this.lastSeek;

            if(elapsed > 100)
            {
                this.lastSeek    = now;

                const currentTime = percentValue * this.$refs.videoPlayer.duration / 100;
                this.$refs.videoPlayer.currentTime = currentTime;
                this.$refs.audioPlayer.currentTime = currentTime < this.$refs.audioPlayer.duration ? currentTime : this.$refs.audioPlayer.duration;
            }
        },
        onSpeedSelected(index)
        {
            //this.selectedSpeed                  = index; 
            this.showSpeeds                     = !this.showSpeeds;
            this.$refs.videoPlayer.playbackRate = this.speeds[index];
            this.$refs.audioPlayer.playbackRate = this.speeds[index];
            this.$emit('update:selectedSpeed', index);
        },
        secondsToTimestamp(totalseconds)
        {
            const minutes = Math.floor(totalseconds / 60);
            const seconds = Math.floor(totalseconds % 60);

            return minutes + " : " + seconds.toString().padStart(2, "0");
        },
        onGraphStateChange(isCollapsed)
        {
            this.isGraphVisible = !isCollapsed;
        }
    }
}
</script>

<style scoped>

    #VideoAnalysisWrapper {

        position:           relative;
        display:            flex;
        width:              100%;
        height:             100%;
        background:         black;
    }
    #videoPlayer {
        margin:             auto;
        width:              100%;
        height:             100%;
    }
    .videoOverlay {
        position:        absolute;
        display:         flex;
        width:           100%;
        height:          100%;
        justify-content: center;
        align-content: 	 center;
        user-select: 	 none;
        pointer-events:  none;
    }
    .videoOverlay .material-icons {
        flex:			 0 0 auto; 
        color:           rgba(255, 255, 255, 0);
        font-size:       120px;
        align-self:      center;
    }
    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .videoOverlay .spinner {
        animation-name:             spinner;
        animation-duration:         1s;
        animation-iteration-count:  infinite;
        animation-timing-function:  linear;
        flex:                       0 0 auto; 
        width:                      110px;
        height:                     110px;
        align-self:                 center;
        border:                     solid 8px rgba(238, 238, 238, 0.35);
        border-bottom-color:        #FFF;
        border-radius:              50%;
        transform-origin:           center;
    }
	.overlayIcon {
		animation-name: 			popupIcon;
    	animation-duration: 		300ms;
		animation-timing-function:  ease-in;
	}
/* Controls */
    #clickBlocker {
        position:           absolute;
        left:               0;
        right:              0;
        bottom:             0;
        height:             100px;

    }   
    #mediaControl  {

        position:           absolute;
        z-index:            100;
        display:            flex;
        justify-content:    center;
        align-content:      center;
        align-items:        center;
        bottom:             0px;
        left:               0px;
        right:              0px;
        padding:            20px 20px 0 20px;
        min-height:         120px;
        user-select:        none;
        background:         linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.8) 100%); 
        pointer-events:     none;
    }
 /* Slidebar */
    .sliderBar {
        position:           relative;
        flex:               1 1 100%;
        height:             6px;
        border-radius:      3px;
        background-color:   rgba(255, 255, 255, 0.2);
        box-shadow:         0 0 8px black;
        color:              white;
        cursor:             pointer; 
        pointer-events:     all;   
        margin:             0px 8px;
    }
    .sliderBar .fill {
        position:           relative;
        height:             6px;
        width:              0;
        border-radius:      3px 0 0 3px;
        pointer-events:     none;
        background:         rgb(0, 255, 157);
    }
    #cursor {
        transition:         top 400ms;
        position:           absolute;
        right:              -7px;
        top:                -4px;
        width:              14px;
        height:             14px;
        background:         rgb(0, 255, 157);
        border-radius:      50%;
        box-shadow:         2px 2px 8px black;
        pointer-events:     none;
        cursor:             pointer;
    }
    #cursor.raise {

        top: -150px;
    }
    #cursor #cursorLine {
        transition:         400ms;
        position:           relative;
        width:              2px;
        height:             0;
        background-color:   rgba(0, 255, 157, 0.5);
        top:                0;
        left:               6px;
    }
    #cursor.raise #cursorLine {
        height:             150px;
    }
    .timeStamp {

        margin:             8px;
        color:              white;
        white-space:        nowrap;
    }
 /**/
  /* Buttons */
    #controlButtonsWrapper 
    {
        display:            flex;
        flex:               1 0 auto;
        align-items:        center;
        color:              white;
        text-shadow:        0 0 6px black;
        pointer-events:     all;   
    }
    .controlButton 
    {
        position:           relative;
        display:            block;
        padding:            5px;
        font-size:          36px;
        margin-left:        8px;
        cursor:             pointer;    
    }
  /* Volumes control */
    .volumeIcon
    {
        position: relative;
        font-size: 28px;
    }
    #volumeToggle 
    {
        position:       absolute;
        top:            0;
        left:           0;
        right:          0;
        bottom:         0;
    }
    #volumeControl 
    {
        transition:         0.2s;
        position:           absolute;
        width:              40px;
        height:             180px;
        background:         rgba(0, 0, 0, 0.75);
        opacity:            0;
        bottom:             54px;
        left:               50%;
        margin-left:        -20px;
        border-radius:      4px;
        cursor:             default; 
        text-align:         center;
    }
    .volumeIcon:hover #volumeControl 
    {
        opacity:            1;
    }
    #volumeControl::after  
    {
        content:            " ";
        position:           absolute;
        top:                100%;
        left:               50%;
        margin-left:        -8px;
        border-width:       8px;
        border-style:       solid;
        border-color:       rgba(0, 0, 0, 0.75) transparent transparent transparent;
    }
    #volumeSlider 
    {
        transition:             100ms;
        position:               absolute;
        left:                   16px;
        top:                    10px;
        -webkit-appearance:     none;
        width:                  160px;
        height:                 4px;
        border-radius:          2px;   
        background:             rgba(255, 255, 255, 0.5);
        outline:                none;
        transform-origin:       80px 80px;
        transform:              rotate(-90deg);
        opacity:                0;
    }
    #volumeSlider::-webkit-slider-thumb 
    {
        transition:             100ms;
        -webkit-appearance:     none;
        appearance:             none;
        width:                  14px;
        height:                 14px;
        background:             rgb(0, 255, 157);
        border-radius:          50%;
        box-shadow:             -2px 2px 8px black;
        cursor:                 pointer;
        opacity:                0;
    }
    .volumeIcon:hover #volumeControl #volumeSlider::-webkit-slider-thumb
    {
        transition:             0.2s;
        opacity:                1;
    }
    .volumeIcon:hover #volumeControl #volumeSlider
    {
        transition:             0.2s;
        opacity:                1;
    }

  /**/

  /* Speed control */
    .speedOptions {
        position:           relative;
        display:            inline-block;
        color:              white;
        font-size:          20px;
        width:              60px;
        vertical-align:     50%;
        margin:             8px;
        text-align:         center;
        cursor:             pointer;    
    }
    .speedsMenu {

        transition:         all .3s ease;

        position:           absolute;
        display:            block;
        width:              72px;
        bottom:             46px;
        left:               -6px;
        background-color:   rgba(0, 0, 0, 0.75);
        border-radius:      4px;

        list-style-type:    none;
        margin:             0;
        text-align:         center;
        padding:            8px;

        text-align:         right;
        white-space:        nowrap;
    }
    .speedsMenu::after  
    {
        content:            " ";
        position:           absolute;
        top:                100%;
        left:               50%;
        margin-left:        -8px;
        border-width:       8px;
        border-style:       solid;
        border-color:       rgba(0, 0, 0, 0.75) transparent transparent transparent;
    }
    .speedsMenu .selected 
    {

        color: #DE003C;
    }
  /**/

  /* Animations */
  @keyframes popupIcon 
  {
	  0%   {color:rgba(255, 255, 255, 0);}
	  10%  {color:rgba(255, 255, 255, 1); font-size: 120px;}
	  100% {color:rgba(255, 255, 255, 0); font-size: 240px;}
  }
  /**/

 /**/
/**/

</style>

