<template>
    <ch-modal ref="modal"/>
</template>

<script>
    export default {
        name: "RemoveConfiguration",
        methods: {
            open(project, configuration) {
                this.$refs.modal.open(
                    'Remove configuration',
                    [
                        `Remove the configuration ${configuration.name}?`,
                         !project.hasField ? '' : 'Any associated cells in the related field will remain as "old cells"',
                        'This action is not undoable.'
                    ],
                    'error',
                    'Yes,\xa0remove'
                )
                    .then(() => {
                        project.removeConfiguration(configuration)
                            .then(() => {
                                this.$snotify.success(`Configuration removed`, {timeout: 5000, showProgressBar: false});
                                this.$emit('confirm');
                            })
                            .catch(() => {
                                this.$snotify.error(`Failed to remove configuration`, {
                                    timeout: 5000,
                                    showProgressBar: false
                                });
                            });
                    })
                    .catch(() => {
                    });
            }
        }
    };
</script>