<template>
    <div class="rightBar" fill y-spaced-2>
		<div>
			<h1 primary-text>Adding products</h1>
			<h4 v-if="selection.length !== 0">{{selection.length}} products will be added</h4>
			<h4 v-else>No products selected</h4>
		</div>
        <ch-button v-if="selection.length !== 0" @click="clearList" size="narrow small" type="secondary">Clear
        </ch-button>
        <div class="productsList" y-spaced-1_2>
            <div class="productsListEntry" v-for="sku in selection" :key="sku.id">
                <span ellipsis>{{sku.name}}</span>
                <ch-icon @click="removeSku(sku)" icon="close" size="14"/>
            </div>
        </div>
        <div class="footer" x-spaced-2>
            <ch-button @click="cancel" size="small" type="secondary">Cancel</ch-button>
            <ch-button @click="confirm" size="small" type="primary" :disabled="selection.length === 0">Add</ch-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SkuRightSideBar",
        props: {
            selection: {
                type: Array,
                default: () => []
            }
        },
        methods: {
            removeSku(sku) {
                this.$emit('update:selection', this.selection.filter(p => p !== sku));
            },
            clearList() {
                this.$emit('update:selection', [])
            },
            cancel() {
                this.$emit('cancel');
            },
            confirm() {
                this.$emit('confirm');
            }
        }
    }
</script>

<style scoped>

	.rightBar {
		display: flex;
		flex-direction: column;
        padding: var(--doubleMargin);
        box-sizing: border-box;
	}

    .productsList {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        overflow-y: auto;
    }

    .productsListEntry {
        flex: 0 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 24px;
        background: white;
        color: black;
        border-radius: 4px;
        padding: 0 var(--singleMargin);
        font-weight: 600;
        cursor: pointer;
    }

    .productsListEntry:hover {
        background-color: var(--primary);
		color: var(--on-primary);
    }

    .productsListEntry span {
        margin-top: 2px;
    }

    .footer {
        flex: 0 0 auto;
        justify-self: flex-end;
		align-self: flex-end;
    }

</style>
