<template>
  <ch-application-layout>
    <admin-navigation slot="navigation" />
    <template slot="version">ShelfZone Studio v. {{ version }}</template>
    <div class="content" slot="main">
      <h2 primary-text>My organization</h2>
      <div class="section" y-spaced-2>
        <ch-field label="Organization name" inline>
          <ch-text-input v-model="organization" disabled />
        </ch-field>
        <ch-field label="Address" inline>
          <ch-text-input v-model="address" disabled />
        </ch-field>
        <div>
          <label>License expiration date</label>
          <div class="value">{{ expiration }}</div>
        </div>
        <div>
          <ch-button disabled size="small">Save</ch-button>
        </div>
      </div>
      <div class="header" y-spaced-2>
        <h2>Users</h2>
        <ch-filters>
          <div x-spaced-4>
            <h2>{{ allUsersCount }} users</h2>
            <label
              primary-text
              class="pendingInvite"
              @click="showPendingInvitesDialog"
              >{{ pendingInvites.length }} invites pending</label
            >
          </div>
          <div>
            <ch-pagination v-model="currentPage" :max="totalPages" />
          </div>
          <div x-spaced-1>
            <ch-search-bar v-model="search" size="narrow" />
            <ch-radio-group v-model="enabled" size="small narrow">
              <ch-radio-button :label="true">Enabled</ch-radio-button>
              <ch-radio-button :label="false">Disabled</ch-radio-button>
            </ch-radio-group>
          </div>
        </ch-filters>
      </div>
      <div class="usersList">
        <ch-table
          v-if="usersList.length > 0"
          :items="usersList"
          row-height="narrow"
        >
          <template slot="header">
            <ch-th name="User" defaultOrder="ascending" sortable id="name" />
            <ch-th name="Full Name" />
            <ch-th name="Created on" />
            <ch-th name="Email" />
            <ch-th width="50px"></ch-th>
          </template>
          <template slot-scope="{ row }">
            <ch-td
              >{{ row.name }}
              {{ row.id === currentUser.id ? "(you)" : "" }}</ch-td
            >
            <ch-td>{{ row.fullName ? row.fullName : "" }}</ch-td>
            <ch-td x-spaced-1>
              <ch-icon icon="today" />
              <span>{{ formattedDataFromISO(row.registeredAt) }}</span></ch-td
            >
            <ch-td>{{ row.email }}</ch-td>
            <ch-td action>
              <ch-context-menu
                :voices="buildUserContextMenu(row)"
                v-if="row.id !== currentUser.id"
              >
                <ch-button type="flat" icon>
                  <ch-icon icon="more_horiz" />
                </ch-button>
              </ch-context-menu>
            </ch-td>
          </template>
        </ch-table>
        <ch-empty-content
          v-else-if="usersList.length === 0"
          first-message="No matches found"
        />
      </div>
      <ch-content-dialog
        ref="invites"
        title="Pending invites"
        size="large"
        type="primary"
      >
        <div y-spaced-2>
          <p v-if="pendingInvites.length > 0">
            An invite has been sent to these email addresses but no users has
            been created yet.
          </p>
          <p v-else>There's no invites pending.</p>
          <div class="pendingInvites">
            <ch-table :items="pendingInvites" hide-header row-height="narrow">
              <template slot="header">
                <ch-th
                  name="Email"
                  defaultOrder="ascending"
                  sortable
                  id="sentTo"
                />
                <ch-th name="Expires on" />
                <ch-th name="Actions" />
              </template>
              <template slot-scope="{ row }">
                <ch-td>{{ row.sentTo }}</ch-td>
                <ch-td
                  >{{
                    row.hasExpired
                      ? "expired on " + formattedDataFromISO(row.expireAt)
                      : "sent on " + formattedDataFromISO(row.sentAt)
                  }}
                </ch-td>
                <ch-td x-spaced-1 action>
                  <ch-button
                    @click="cancelInvite(row)"
                    size="fit narrow"
                    type="error"
                    >Delete</ch-button
                  >
                  <ch-button @click="changeInviteEmail(row)" size="fit narrow"
                    >Change mail</ch-button
                  >
                  <ch-button @click="resendInvite(row)" size="fit narrow"
                    >Resend</ch-button
                  >
                </ch-td>
              </template>
            </ch-table>
          </div>
        </div>
      </ch-content-dialog>
      <ch-input-dialog ref="inputDialog" />
      <ch-modal ref="modal" />
    </div>
    <template slot="footer">
      <ch-button @click="sendInvite" fab type="primary">
        <ch-icon icon="add" />
      </ch-button>
    </template>
  </ch-application-layout>
</template>

<script>
import StudioRequest from "../plugins/studioapi/requests/StudioRequest";
import StringUtils from "../utils/StringUtils";
import AdminNavigation from "./AdminNavigation";
import StaticConfig from "../config.js";

export default {
  name: "Admin",
  components: { AdminNavigation },
  data() {
    return {
      organization: "My Organization",
      address: "My Organization Address",
      expiration: "01/01/2020",
      search: "",
      pagingSize: 25,
      currentPage: 0,
      totalPages: 1,
      allUsersCount: 0,
      usersList: [],
      enabled: true,
      pendingInvites: [],
    };
  },
  computed: {
    version() {
      return StaticConfig.version;
    },
    currentUser() {
      return this.$storage.user;
    },
  },
  watch: {
    search() {
      this.getUsers();
    },
    pagingSize() {
      this.getUsers();
    },
    currentPage() {
      this.getUsers();
    },
    enabled() {
      this.getUsers();
    },
  },
  mounted() {
    document.title = " Admin | ShelfZone Studio";
    this.getOrganizationInfo();
    this.getUsers();
    this.getPendingInvites();
  },
  methods: {
    mailValidator(email) {
      if (
        email === null ||
        !/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(
          email
        )
      )
        return "Email address not valid";
      return null;
    },
    getOrganizationInfo() {
      const request = new StudioRequest(
        "describeOrganization",
        null,
        (code, response) => {
          if (code === 200) {
            this.organization = response.name;
            this.address = response.address;
            this.expiration = StringUtils.formattedDateTimeFromISO(
              response.licenses[0].expireAt
            );
          }
        }
      );
      window["studioApi"].sendRequest(request);
    },
    getUsers() {
      this.$iam
        .listIdentities(
          this.pagingSize,
          this.pagingSize * this.currentPage,
          this.search,
          this.enabled
        )
        .then((response) => {
          this.usersList = response.identities;
          this.totalPages = Math.max(
            Math.ceil(response.totalMatchesCount / this.pagingSize),
            1
          );
          if (this.currentPage > this.totalPages)
            this.currentPage = this.totalPages;
          this.allUsersCount = response.totalMatchesCount;
        });
    },
    /**
     * @param {IdentitySummary} user
     */
    buildUserContextMenu(user) {
      return [
        user.enabled
          ? {
              text: "Disable account",
              icon: "fas fa-user-times",
              action: () => this.changeIdentityEnabledState(user, false),
            }
          : {
              text: "Enable account",
              icon: "fas fa-user",
              action: () => this.changeIdentityEnabledState(user, true),
            },
      ];
    },
    /**
     * @param {IdentitySummary} user
     * @param {boolean} enabled
     */
    changeIdentityEnabledState(user, enabled) {
      (enabled
        ? this.$refs.modal.open(
            "Enable account",
            `Are you sure you want to enable ${user.name}?`,
            "primary",
            "Ok"
          )
        : this.$refs.modal.open(
            "Disable account",
            `Are you sure you want to disable ${user.name}?`,
            "error",
            "Ok"
          )
      )
        .then(() => this.$iam.changeIdentityEnabledState(user.id, enabled))
        .then(() => this.getUsers())
        .then(() =>
          this.$snotify.success(
            `${user.name} has been ${enabled ? "enabled" : "disabled"}`,
            { timeout: 3000, showProgressBar: false }
          )
        );
    },
    getPendingInvites() {
      const request = new StudioRequest(
        "listInvitations",
        null,
        (code, response) => {
          if (code === 200) this.pendingInvites = response;
        }
      );
      window["studioApi"].sendRequest(request);
    },
    showPendingInvitesDialog() {
      this.$refs.invites.open();
    },
    cancelInvite(invite) {
      this.$refs.modal
        .open(
          "Delete invite",
          `Do you want to delete the invite for ${invite.sentTo}?`,
          "error",
          "Delete"
        )
        .then(() => {
          const payload = {
            invitationId: invite.id,
          };

          const request = new StudioRequest(
            "dropInvite",
            payload,
            (code, response) => {
              if (code === 200) {
                this.pendingInvites.splice(
                  this.pendingInvites.indexOf(invite),
                  1
                );
                this.$snotify.success("Invite cancelled.", {
                  timeout: 5000,
                  showProgressBar: false,
                });
              } else
                this.$snotify.error("Failed to cancel invite.", {
                  timeout: 5000,
                  showProgressBar: false,
                });
            }
          );

          window["studioApi"].sendRequest(request);
        })
        .catch(() => {});
    },
    changeInviteEmail(invite) {
      this.$refs.inputDialog
        .open(
          "Change Email",
          "text",
          [this.mailValidator],
          "primary",
          "Email",
          "your@email.com",
          "The invite will be re-sent to this address"
        )
        .then((email) => {
          const payload = {
            invitationId: invite ? invite.id : undefined,
            recipient: email,
          };
          const request = new StudioRequest(
            "refreshInvite",
            payload,
            (code, response) => {
              if (code === 200) {
                this.$snotify.success("Invite sent.", {
                  timeout: 5000,
                  showProgressBar: false,
                });
                this.getPendingInvites();
              } else
                this.$snotify.error(
                  "Failed to send invite. Please try again.",
                  {
                    timeout: 5000,
                    showProgressBar: false,
                  }
                );
            }
          );

          window["studioApi"].sendRequest(request);
        });
    },
    sendInvite() {
      this.$refs.inputDialog
        .open(
          "Create new user",
          "text",
          [this.mailValidator],
          "primary",
          "Email",
          "your@email.com",
          "An invite will be sent to this address to create the user"
        )
        .then((email) => {
          const payload = {
            recipient: email,
          };
          const request = new StudioRequest(
            "sendInvite",
            payload,
            (code, response) => {
              if (code === 200) {
                this.$refs.modal
                  .open(
                    "Invite sent",
                    `Invite to ${email} has been sent, waiting for account creation.`,
                    "primary"
                  )
                  .then(() => {})
                  .catch(() => {});
                this.getPendingInvites();
              } else
                this.$snotify.error(
                  "Failed to send invite. Please try again.",
                  {
                    timeout: 5000,
                    showProgressBar: false,
                  }
                );
            }
          );

          window["studioApi"].sendRequest(request);
        })
        .catch(() => {});
    },
    resendInvite(invite) {
      this.$refs.modal
        .open(
          "Invite resent",
          `Invite to ${invite.sentTo} has been sent, waiting for account creation`,
          "primary",
          "OK"
        )
        .then(() => {
          const payload = {
            recipient: invite.sentTo,
          };
          const request = new StudioRequest(
            "sendInvite",
            payload,
            (code, response) => {
              if (code === 200) {
                this.$snotify.success("Invite sent.", {
                  timeout: 5000,
                  showProgressBar: false,
                });
                this.getPendingInvites();
              } else
                this.$snotify.error(
                  "Failed to send invite. Please try again.",
                  {
                    timeout: 5000,
                    showProgressBar: false,
                  }
                );
            }
          );
          window["studioApi"].sendRequest(request);
        })
        .catch(() => {});
    },
    formattedDataFromISO(isoDate) {
      return StringUtils.formattedDateTimeFromISO(isoDate);
    },
  },
};
</script>

<style scoped>
.section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid var(--elevation-06);
  margin-top: var(--singleMargin);
  margin-bottom: var(--quadrupleMargin);
  padding-top: var(--doubleMargin);
}

.value {
  line-height: 32px;
  font-size: 16px;
}

.header {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  padding: var(--quadrupleMargin) var(--quadrupleMargin) 0
    var(--quadrupleMargin);
  box-sizing: border-box;
}

.pendingInvite {
  text-decoration: underline;
  cursor: pointer;
}

.pendingInvite:hover {
  color: var(--primary-hover);
}

.usersList {
  margin-top: var(--doubleMargin);
  flex: 1 1 auto;
  position: relative;
  max-height: 100%;
  overflow: auto;
}

.pendingInvites > * {
  max-height: 560px;
}
</style>
