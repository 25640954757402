<template>
    <ch-list :items="list" ref="tagList" class="tagList" :class="{ limitedList: list.length > maxItemDisplayed}" :removable="removable" :size="size">
        <ch-list-item v-for="tag of list" :key="tag.id" :value="tag.id" class="tag" @remove="onRemove(tag.id)">
            <slot :tag="tag" :list="list">  
            </slot>
        </ch-list-item>
    </ch-list>
</template>

<script>
import TagEdit from "./TagEdit";
export default {
    name: "TagList",
    components: { TagEdit },
    props: {
        list: {
            default: () => [],
            type: Array,
        },
        maxItemDisplayed: {
            validator: (v) => v > 0,
            type: Number,
            default: 10,
        },
        removable: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: "normal"
        },
    },
    mounted() {
        this.setListMaxHeight();
    },
    methods: {
        setListMaxHeight() {
            if(!this.$refs.tagList) return;
            const el = this.$refs.tagList.$el;
            el.style.setProperty('--max-list-items', this.maxItemDisplayed)
        },
        onRemove(tagId) {
            this.$emit('remove', tagId);
        }
    },
    watch: {
        maxItemDisplayed:  {
            immediate: true,
            handler: function () {this.setListMaxHeight();}, 
        }
    }
}
</script>

<style scoped>
    .tagList {
        padding-right: var(--singleMargin);
        margin: 0;
    }
    .limitedList {
        --max-list-height: calc( ( var(--height) + var(--halfMargin) ) * var(--max-list-items, 10));
        overflow-y: auto;
        max-height: var(--max-list-height );
    }
    .tag {
        position: relative;
        display: flex;
        margin-bottom: var(--halfMargin);
        min-height: var(--height) !important;
        height: auto !important;
    }
    .tag >>> .label > span {
        width: 100%;
    }
</style>