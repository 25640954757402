<template>
<research-ch-select ref="select" searchable multiple class="tagSelect"
            :placeholder="placeholder"
            :placeholderColor="placeholderColor"
            :inputPlaceholder="inputPlaceholder"
            :notFoundText="notFoundText"
            :notFoundTextColor="notFoundTextColor"
            :size="size"
            :items="list" 
            :value="tagIds"
            :clearable="clearable"
            @input="updateTags($event)"
            @keydown.native.down.prevent="nextFocused()"
            @keydown.native.up.prevent="prevFocused()"
            @keydown.native.esc.prevent="onEsc($event)"
            @keydown.native.enter.prevent="onEnter($event)"
            @dropdown-up="prevFocused($refs.select.focusPointer)"
            @dropdown-down="nextFocused($refs.select.focusPointer)"
            @dropdown-esc="closeDropdown($event)"
            @item-spacedown="$refs.select.selectItem($event)"
        >
    <template #dropdownHeader="{list}" v-if="showReset">
        <ch-button v-if="list.length" :disabled="isResetButtonDisabled" class="resetBtn" size="narrow fill" @click="reset()" left>{{resetText}}</ch-button>
    </template>
    <template #default={item}>
        <div class="item" >
            <ch-indicator :color="item.color" :style="{ 'background-color': item.color}"/>
            {{item.label}}
        </div>
    </template>
    </research-ch-select>
</template>

<script>
import ResearchChSelect from '../researchselect/ResearchChSelect';
export default {
    name: 'TagSelect',
    components: { ResearchChSelect },
    props: {
        tagIds: Array,
        list: Array,
        size: {
            type: String,
            default: "narrow fill"
        },
        resetText: {
            type: String,
            default: 'Reset'
        },
        showReset: {
            type: Boolean,
            default: false
        },
        placeholder: String,
        placeholderColor: String,
        inputPlaceholder: String,
        notFoundText: String,
        notFoundTextColor: String,
        clearable: Boolean,
        focusableItemOnClose: null
    },
    computed: {
        isResetButtonDisabled() {

            return ! this.$refs.select.query;

        },
    },
    methods: {
        updateTags(tags){
            this.$emit("update:tagIds", tags.filter(Boolean));
        },
        reset() {
            const select = this.$refs.select;
            select.query = '';
            select.inputText = select.selectedItemLabel;
            select.$emit('input', [] );
            
        },
        closeDropdown(event) {
            if (document.activeElement !== this.$refs.select.$refs.input) {
                this.$refs.select.hideDropDownOnScroll();
            }
            const itemToFocus = (this.focusableItemOnClose && !this.$refs.select.dropdownVisible) ? this.focusableItemOnClose 
                : this.$refs.select.$el;
            itemToFocus.focus();
        },
        nextFocused(pointer) {
            this.$refs.select.focusPointer = this.$refs.select.focusOption(pointer).next;
        },
        prevFocused(pointer) {
            this.$refs.select.focusPointer = this.$refs.select.focusOption(pointer).prev;
        },
        onEsc(event) {
            if (this.$refs.select.dropdownVisible) {
                this.$refs.select.filterOptions({ target : { value: ''}}); 
                this.closeDropdown(event);
            } 
        },
        onEnter(event) {
            if (document.activeElement !== this.$refs.select.$refs.input) {
                this.onEsc(event);
            }
        }
    }
}
</script>

<style scoped>

.resetBtn {
    color: var(--elevation-01);
    background-color: var(--elevation-06);
    border-radius: 0 !important;
    font-weight: bold !important;
}

.resetBtn::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: var(--halfMargin);
    width: calc( 100% - 2 * var(--halfMargin));
    height: auto;
    border-bottom: 1px solid var(--elevation-05) !important;
    background-color: var(--default);
}

.resetBtn[disabled] {
    --disabled: var(--elevation-06);
    --on-disabled: var(--elevation-05);
}

.tagSelect:focus {
    outline: none;
}
</style>