<template>
    <div class="glWrapper" ref="glContainer">
        <div class="overlay">
            <slot class="overlay" name="overlay"></slot>
        </div>
        <p class="errorMessage" v-if="errorMessage != '' && !loading" v-html="errorMessage"></p>
        <div class="glLoader" ref="glLoader" v-if="loading"></div>
    </div>
</template>

<script>

    export default {
        name: "StudioGLViewer",
        props: {
            fileURLs: {
                type: Array, default: () => [""]
            },
            zoomed: Boolean
        },
        data() {

            return {
                currentEAN: 0,
                camera: null,
                controls: null,
                scene: null,
                renderer: null,
                animationRequest: null,
                loading: true,
                errorMessage: "",
            }
        },
        watch: {

            zoomed() {

                this.onResize();
            }
        },
        mounted() {

            this.init();
            this.onResize();

        },
        beforeDestroy() {

            if (this.animationRequest)
                window.cancelAnimationFrame(this.animationRequest);

            if (this.controls)
                this.controls.dispose();
        },
        methods: {

            init() {

                window.THREE = require('three')
                const OrbitControls = require('three/examples/jsm/controls/OrbitControls')
                const GLTFLoader = require("../utils/GLTFLoader.js");

                const container = this.$refs.glContainer;

                this.camera = new window.THREE.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 0.01, 100);
                this.controls = new OrbitControls.OrbitControls(this.camera, container);
                this.scene = new window.THREE.Scene();
                this.renderer = new window.THREE.WebGLRenderer({antialias: true});

                this.camera.position.set(0, 4, 4);

                this.controls.target.set(0, 0, 0);
                this.controls.enableDamping = true;
                this.controls.dampingFactor = 0.5;
                this.controls.enablePan = false;

                this.renderer.setSize(container.clientWidth, container.clientHeight);
                this.renderer.gammaFactor = 1.35;
                this.renderer.gammaOutput = true;
                this.renderer.setClearColor(0xFFFFFF, 1);

                //container.appendChild(this.renderer.domElement);
                container.insertBefore(this.renderer.domElement, this.$refs.glLoader);

                // Lights
                var ambientLight = new THREE.AmbientLight(0x020202); // soft white light
                this.scene.add(ambientLight);

                var directionalLight = new THREE.DirectionalLight(0xededed, .8);
                directionalLight.position.set(0.5, 0.5, 1).normalize();
                this.scene.add(directionalLight);

                var domeLight = new THREE.HemisphereLight(0xDBDBDB, 0xBBBBBB, 0.85);
                domeLight.position.set(0, 1, 0).normalize();
                this.scene.add(domeLight);

                this.loadCurrentEan();

                window.addEventListener("resize", this.onResize, false);
                requestAnimationFrame(this.onUpdate);
            },
            loadGLTF(filepath, renderer) {

                return new Promise((resolve, reject) => {
                    this.loading = true;
                    // Env
                    var path = '../../static/env/';
                    var format = '.png';
                    var envMap = new THREE.CubeTextureLoader().load(
                        [
                            path + 'posx' + format, path + 'negx' + format,
                            path + 'posy' + format, path + 'negy' + format,
                            path + 'posz' + format, path + 'negz' + format
                        ]);

                    // Models
                    let loader = new THREE.GLTFLoader();

                    loader.load(filepath,
                        (gltf) => {
                            const maxAnisotropy = renderer.capabilities.getMaxAnisotropy();

                            gltf.scene.traverse((child) => {

                                if (child.isMesh) {
                                    child.material.normalScale.set(1, 1);
                                    child.material.envMap = envMap;
                                    child.material.roughness = 0.75;
                                    child.material.envMapIntensity = 0.75;

                                    child.material.map.anisotropy = maxAnisotropy;
                                    child.material.metalnessMap.anisotropy = 4;
                                    child.material.normalMap.anisotropy = 4;
                                    child.material.roughnessMap.anisotropy = 4;
                                }
                            });

                            gltf.scene.scale.set(20, 20, 20);
                            gltf.scene.position.x = 0;				    //Position (x = right+ left-)
                            gltf.scene.position.y = 0;				    //Position (y = up+, down-)
                            gltf.scene.position.z = 0;				    //Position (z = front +, back-)

                            gltf.scene.name = "model";
                            gltf.scene.background = envMap;


                            let box = new THREE.Box3().setFromObject(gltf.scene);

                            const halfHeight = (box.max.y - box.min.y) * 0.5;
                            const halfWidth = (box.max.x - box.min.x) * 0.5;
                            const halfDepth = (box.max.z - box.min.z) * 0.5;

                            const maxZoom = Math.min(halfHeight, Math.min(halfWidth, halfDepth));

                            this.controls.minDistance = maxZoom + 1;
                            this.controls.target.set(0, halfHeight, 0);
                            this.controls.update();
                            resolve(gltf);
                            this.loading = false;
                        },
                        (xhr) => {
                            //console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' )
                        },
                        (error) => {
                            this.loading = false;
                            reject(error);
                        });
                })
            },
            loadCurrentEan() {
                this.loadGLTF(this.fileURLs[this.currentEAN], this.renderer)
                    .then(gltf => {
                        this.scene.add(gltf.scene);
                        this.errorMessage = "";
                    })
                    .catch(e => {
                        console.log(e);
                        this.errorMessage = "An error has occured.<br/>If the problem persist please contact us.";
                    });
            },
            onUpdate() {
                //this.controls.update();
                this.renderer.render(this.scene, this.camera);
                this.animationRequest = requestAnimationFrame(this.onUpdate);
            },
            onResize() {
                this.camera.aspect = this.$refs.glContainer.clientWidth / this.$refs.glContainer.clientHeight;
                this.camera.updateProjectionMatrix();
                this.renderer.setSize(this.$refs.glContainer.clientWidth, this.$refs.glContainer.clientHeight);
            }
        }

    }
</script>

<style scoped>

    .glWrapper {
        position: relative;
    }

    .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .glLoader {
        width: 40px;
        height: 40px;
        border: 4px #05FF6F solid;
        border-top: 4px transparent solid;
        border-radius: 50%;
        position: absolute;
        top: 45%;
        left: 45%;
        animation: threeLoading 1.2s linear infinite;
    }

    .errorMessage {
        position: absolute;
        width: 100%;
        top: 40%;
        left: 0;
        text-align: center;
        color: red;
        line-height: 26px;
        font-weight: 600;
    }

    @keyframes threeLoading {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
