<template>
    <div class="assets">
        <div class="filters" y-spaced-2>
            <ch-button @click="$refs.addAssetDialog.open()" size="fill" justified type="primary">Add
                planograms
                <ch-icon icon="add_circle_outline"/>
            </ch-button>
            <ch-search-bar v-model="searchString" realTime size="fill narrow"/>
            <h4>{{planograms.length + ' Planograms'}} </h4>
        </div>
        <ch-list class="assetsList" v-if="filteredPlanograms.length > 0" size="narrow" selectable="single"
                 hide-selection v-model="selectedAssetId" draggable removable>
            <ch-list-item v-for="planogram of filteredPlanograms" @remove="removePlanogram(planogram)"
                          :value="planogram.id"
                          :used="usedAssets.includes(planogram.id)"
                          :highlight="planogram.id === highlightedAssetId"
                          @mousedown.native.prevent="onAssetDown($event, planogram)"
                          @mouseup.native="onAssetUp($event, planogram)"
                          @mouseenter.native="showElementsHighlight($event, planogram)"
                          @mouseleave.native="removeElementsHighlight($event, planogram)">
                <div x-spaced-1>
                    <ch-indicator :color="`rgb(${planogram.color.r},${planogram.color.g},${planogram.color.b})`"
                                  :striped="planogram.type === 'PlanogramPlaceholderReference'"/>
                    <span>{{planogram.name}}</span>
                </div>
                <template slot="actions">
                    <ch-icon v-if="planogram.type === 'PlanogramReference'" icon="add_to_photos"
                             @click.stop="duplicatePlanogram(planogram)" size="12"/>
                </template>
            </ch-list-item>
        </ch-list>
        <ch-empty-content v-else-if="planograms.length > 0" first-message="No planograms match the query"/>
        <ch-empty-content v-else first-message="No planograms available"/>
        <ch-input-dialog ref="inputDialog"/>
        <add-assets-dialog ref="addAssetDialog" :controller="controller"
                           @planogramsAdded="addPlanograms" @placeholdersAdded="addPlaceholders"/>
        <ch-modal ref="modal"/>
    </div>
</template>

<script>

    import AddAssetsDialog from '../dialog/AddAssetDialog';
    import LayoutController from '../services/LayoutController';
    import SelectionController from '../services/SelectionController';
    import Vector3 from '../model/common/Vector3';
    import Transform from '../../../plugins/studioapi/api/spaceallocation/model/common/Transform';
    import Volume from '../model/common/Volume';
    import PlanogramInstance
        from '../../../plugins/studioapi/api/spaceallocation/model/configuration/element/PlanogramInstance';
    import PlanogramPlaceholderInstance
        from '../../../plugins/studioapi/api/spaceallocation/model/configuration/element/PlanogramPlaceholderInstance';

    export default {
        name: 'PlanogramsLibrary',
        components: {AddAssetsDialog},
        props: {
            controller: LayoutController,
            selection: SelectionController
        },
        data() {
            return {
                searchString: null,
                highlightedAssetId: null,
                clonedPlanogram: null,
                duplicating: false,
                canBeDeleted: true,
                dragging: false,
                dragInitialPosition: null,
                draggedAsset: null,
                draggedElementId: null,
                isCameraOrtographic: true,
                isIsolationMode: false

            };
        },
        computed: {
            planograms() {
                return this.controller.configuration.library
                    .filter(a => (a.type === 'PlanogramReference' || a.type === 'PlanogramPlaceholderReference'));
            },
            filteredPlanograms() {
                return this.planograms.filter(asset =>
                    !this.searchString ||
                    asset.name.toLowerCase().includes(this.searchString.toLowerCase())
                ).sort((a, b) => {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();
                    return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
                });
            },
            materializers() {
                return this.controller.configuration.library
                    .filter(a => a.type === 'PlanogramMaterializer');
            },
            usedAssets() {
                return [];
            },
            selectedAssetId: {
                get() {
                    const selection = this.selection.selectedObjects;
                    return selection.length > 0 ? selection[0].type === 'Asset' ? selection[0].id : null : null;
                },
                set(value) {
                    this.$unityManager.layoutAPI.SelectObject(value);
                }
            }
        },
        mounted() {
            document.addEventListener('mouseup', this.onDragEnd);
            this.$unityManager.SubscribeToEvent('ElementCreated', this.elementCreated);
            this.$unityManager.SubscribeToEvent('CameraProjectionChanged', this.preventDrag);
        },
        beforeDestroy() {
            document.removeEventListener('mouseup', this.onDragEnd);
            document.removeEventListener('mousemove', this.onDragMove);
            this.$unityManager.UnsubscribeToEvent('ElementCreated', this.elementCreated);
            this.$unityManager.UnsubscribeToEvent('CameraProjectionChanged', this.preventDrag);
        },
        methods: {
            onHovered(highlightedAssetId) {
                this.highlightedAssetId = highlightedAssetId;
            },
            onExit() {
                this.highlightedAssetId = null;
            },
            showElementsHighlight(event, asset) {
                this.$unityManager.layoutAPI.OnHoverEnterAsset(asset.id);
            },
            removeElementsHighlight(event, asset) {
                this.$unityManager.layoutAPI.OnHoverExitAsset(asset.id);
            },
            onAssetDown(event, asset) {
                if(this.isCameraOrtographic){
                    this.dragInitialPosition = {x: event.clientX, y: event.clientY};
                    this.draggedAsset = asset;
                    document.addEventListener('mousemove', this.onDragMove);
                }
            },
            onAssetUp(event, asset) {
                this.draggedAsset = null;
                document.removeEventListener('mousemove', this.onDragMove);
            },
            onDragMove(e) {
                if (this.draggedAsset !== null && this.isCameraOrtographic)
                {
                    if (!this.dragging) {
                        const v = {
                            x: Math.abs(e.clientX - this.dragInitialPosition.x),
                            y: Math.abs(e.clientY - this.dragInitialPosition.y)
                        };
                        if (v.x > 10 || v.y > 10) {
                            this.dragging = true;
                            if (this.draggedAsset.type === 'PlanogramReference') {
                                const planogram = new PlanogramInstance("empty", Transform.zero, this.draggedAsset.id, this.materializers[0].id, true);
                                this.controller.materializeElement(planogram);
                                this.$unityManager.layoutAPI.CreatePlanogramInstance(planogram);
                            } else {
                                const placeholder = new PlanogramPlaceholderInstance("empty", Transform.zero, this.draggedAsset.id, this.materializers[0].id, new Volume(1000, 1000, 500), true);
                                this.controller.materializeElement(placeholder);
                                this.$unityManager.layoutAPI.CreatePlanogramPlaceholderInstance(placeholder);
                            }

                        }
                    } else if (this.draggedElementId) {
                        const canvas = this.$unityManager.canvas;
                        const rect = canvas.getBoundingClientRect();
                        const x = e.clientX - rect.left;
                        const y = rect.bottom - e.clientY;
                        const wRatio = canvas.width / rect.width;
                        const hRatio = canvas.height / rect.height;
                        this.$unityManager.layoutAPI.DragElement(new Vector3(x * wRatio, y * hRatio, 0));
                    }
                }
            },
            elementCreated(element) {
                if (this.dragging && this.isCameraOrtographic) {
                    this.draggedElementId = JSON.parse(element).id;
                    this.$unityManager.layoutAPI.StartDragElement(this.draggedElementId);
                    this.$unityManager.canvas.focus();
                }
            },
            onDragEnd() {
                if (this.dragging && this.isCameraOrtographic) {
                    document.removeEventListener('mousemove', this.onDragMove);
                    this.$unityManager.layoutAPI.DropElement();
                    this.dragging = false;
                    this.draggedAsset = null;
                    this.draggedElementId = null;
                }
            },
            addPlanograms(planograms) {
                planograms.forEach(p => this.$unityManager.layoutAPI.CreatePlanogramReference(p.id, p.name, p.volume));
                this.$loading.await(
                    this.$planogram.retrievePlanograms(planograms.map(p => p.id))
                        .then(response => this.controller.addPlanograms(response.planograms))
                );
            },
            addPlaceholders(placeholders) {
                placeholders.forEach(p => this.$unityManager.layoutAPI.CreatePlanogramPlaceholderReference(p.id, p.name, p.preview));
                this.$loading.await(
                    this.$planogram.findPlanogramPlaceholdersByIds(placeholders.map(p => p.id))
                        .then(response => this.controller.addPlanogramPlaceholders(response.planogramPlaceholders))
                );
            },
            duplicatePlanogram(planogram) {
                this.clonedPlanogram = planogram;
                this.duplicating = false;
                const nameValidator = name => {
                    if (name === null || !/^(\w|\d|_|-|\.|\s){1,30}$/.test(name))
                        return 'This name is invalid';
                    return null;
                };
                this.$refs.inputDialog.open(
                    'Duplicate planogram',
                    'text',
                    [nameValidator],
                    'primary',
                    'Planogram name',
                    'Planogram name',
                    null, null, planogram.name + ' copy'
                ).then(newName => {
                    this.$planogram.clonePlanogram(planogram.id, newName, this.$storage.workGroupId)
                        .then(response => {
                            const clonedPlanogram = response.planogram;
                            clonedPlanogram.productsCount = this.clonedPlanogram.productsCount;
                            clonedPlanogram.volume = this.clonedPlanogram.volume;
                            this.$configuration.addPlanograms(this.configurationId, [clonedPlanogram.id])
                                .then(() => this.$emit('planogramsAdded', [clonedPlanogram]));
                        })
                        .finally(() => this.duplicating = false);
                }).catch(() => {
                });
            },
            removePlanogram(asset) {
                this.canBeDeleted = !this.usedAssets.includes(asset.id);
                this.$refs.modal.open(
                    'Remove planogram',
                    'Are you sure to remove ' + asset.name + ' from this configuration?',
                    'error',
                    'Remove',
                    'Cancel',
                    'medium'
                ).then(() => {
                    this.$unityManager.layoutAPI.DeleteAsset(asset.id);
                }).catch(() => {
                });
            },
            preventDrag(cameraView){
                this.isCameraOrtographic = JSON.parse(cameraView);
            }
        }
    };
</script>

<style scoped>

    .filters {
        flex: 0 0 auto;
        padding: var(--doubleMargin);
    }

    /* List */
    .assetsList {
        flex: 1 1 auto;
        padding: 0 var(--doubleMargin);
        box-sizing: border-box;
    }

    .assets {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        max-height: 100%;
        padding-bottom: var(--doubleMargin);
        box-sizing: border-box;
    }

</style>
