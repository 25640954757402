<template>
  <ch-application-layout>
    <content-navigation slot="navigation" />
    <template slot="version">ShelfZone Studio v. {{ version }}</template>
    <placeholders-catalog slot="main" :query="query" />
  </ch-application-layout>
</template>

<script>
import ContentNavigation from "../ContentNavigation";
import StaticConfig from "../../config.js";
import PlaceholdersCatalog from "./PlaceholdersCatalog";

export default {
  name: "PlaceholdersPage",
  components: {
    PlaceholdersCatalog,
    ContentNavigation,
  },
  props: {
    matching: String,
    limit: {
      type: String | Number,
      default: "50",
    },
    page: {
      type: String | Number,
      default: "0",
    },
    view: {
      type: String,
      default: "grid",
    },
  },
  data() {
    return {
      loading: false,
      query: {
        limit: Number.parseInt(this.limit) || 50,
        page: Number.parseInt(this.page) || 50,
        matching: this.matching,
        view: this.view,
      },
    };
  },
  computed: {
    version() {
      return StaticConfig.version;
    },
  },
  watch: {
    query: {
      handler() {
        this.$router.push({
          name: "placeholders",
          query: {
            matching: this.query.matching,
            limit: this.query.limit,
            page: this.query.page,
          },
        });
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    document.title = "Placeholders | ShelfZone Studio";
  },
};
</script>

<style scoped>
</style>
