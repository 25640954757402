<template>
    <ch-dialog ref="dialog" type="primary" title="Add planograms to configuration" size="fullscreen"
               @confirm="addAssets" @cancel="close">
        <ch-application-layout slot="main">
            <ch-tabs slot="main" class="tabs" v-model="activeSection">
                <template slot="voices">
                    <ch-tab-voice :key="0" label="Planograms"/>
                    <ch-tab-voice :key="1" label="Placeholders"/>
                </template>
                <template slot="tabs">
                    <ch-tab-content :key="0">
                        <planograms-catalog selectable v-model="assetsToAdd" :disabled="usedAssets"/>
                    </ch-tab-content>
                    <ch-tab-content :key="1">
                        <placeholders-catalog selectable v-model="assetsToAdd" :disabled="usedAssets"/>
                    </ch-tab-content>
                </template>
            </ch-tabs>
            <div class="sidebar" slot="sidebar" fill y-spaced-1>
                <h1 primary-text>Adding assets</h1>
                <h4>{{assetsToAdd.length}} assets will be added</h4>
                <ch-button type="secondary" size="small narrow" @click="assetsToAdd = []">Clear</ch-button>
                <ch-list class="assetsToAdd" removable size="narrow">
                    <ch-list-item v-for="asset of assetsToAdd" @remove="removeAssetToAdd(asset)">
                        <div x-spaced-1>
                            <ch-indicator :color="colorFromId(asset.id)"
                                          :striped="asset.type === 'PlanogramPlaceholder'"/>
                            <span>{{asset.name}}</span>
                        </div>
                    </ch-list-item>
                </ch-list>
                <div class="actions" x-spaced-2>
                    <ch-button type="secondary" size="small" @click="close">Close</ch-button>
                    <ch-button size="small" type="primary" @click="addAssets">Add</ch-button>
                </div>
            </div>
        </ch-application-layout>
    </ch-dialog>
</template>

<script>

    import PlaceholdersCatalog from '../../../content/placeholders/PlaceholdersCatalog';
    import PlanogramsCatalog   from '../../../content/planograms/PlanogramsCatalog';
    import {ColorService}      from '../services/ColorService';
    import LayoutController    from '../services/LayoutController';

    export default {
        name: 'AddAssetsDialog',
        components: {PlanogramsCatalog, PlaceholdersCatalog},
        props: {
            controller: LayoutController
        },
        data() {
            return {
                activeSection: 0,
                assetsToAdd: []
            };
        },
        computed: {
            usedAssets() {
                return this.controller.configuration.library
                    .filter(a => a.type === 'PlanogramPlaceholderReference' || a.type === 'PlanogramReference')
                    .map(a => ({id: a.type === 'PlanogramReference' ? a.planogramId : a.planogramPlaceholderId}));
            }
        },
        methods: {
            open() {
                this.assetsToAdd = [];
                this.$refs.dialog.open();
            },
            close() {
                this.$refs.dialog.close();
            },
            removeAssetToAdd(asset) {
                this.assetsToAdd = this.assetsToAdd.filter(p => p.id !== asset.id);
            },
            colorFromId(id) {
                return ColorService.colorFromString(id).toString();
            },
            addAssets() {
                const planograms = this.assetsToAdd.filter(p => p.type === 'Planogram');
                const placeholders = this.assetsToAdd.filter(p => p.type === 'PlanogramPlaceholder');
                if (planograms.length > 0)
                    this.$emit('planogramsAdded', planograms);
                if (placeholders.length > 0)
                    this.$emit('placeholdersAdded', placeholders);
                this.close();
            }
        }
    };
</script>

<style scoped>

    .sidebar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        padding: var(--doubleMargin);
    }

    .assetsToAdd {
        flex: 1 1 auto;
    }

    .asset {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 24px;
        background: white;
        color: black;
        border-radius: 4px;
        padding: 0 var(--singleMargin);
        font-weight: 600;
        margin-bottom: 4px;
        user-select: none;
        cursor: pointer;
    }

    .asset:hover {
        background-color: var(--primary);
        color: var(--on-primary);
    }

    .planogramListEntry span {
        margin-top: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .actions {
        display: flex;
        justify-content: flex-end;
    }

</style>
