<template>
    <div id="heatmapShelf" >
        <div id="heatmap" ref="heatmap" :active="(isAreaSelectionModeEnabled && !areaExists)" :class="{ 'moving': isAnalysisCanvasDragging }">
            <canvas id="heatmapCanvas" class="heatmap-canvas" ref="canvas"></canvas>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash.debounce';
    import Heatmap from '../utils/HeatMap';
    import { fabric } from 'fabric';
    import AreaSelectionMixin from './mixins/AreaSelectionMixin';
    import { isImageValid , aggregatePoints, drawPath, drawHeatMap, 
             heatmapGradient, heatmapMaxValue, getScaledImgSizes } from './analysis-helpers';

    export default {
        name: 'shelf-heatmap',
        mixins: [AreaSelectionMixin],
        props: {
            field: Object,
            fieldId: String,
            cellId: String,
            recordingsStyles: Object,
            activeRecordings: Array,
            currentCamera: String,
            opacity: Number,
            plotType: String,
            analysisType: String,
            timeframe: Object,
            overlaid: Boolean,
            zoom: Object,
            zoomStep: Number,
        },
        data() {
            return {
                organizationId: this.$storage.user.organizationId,
                user: this.$storage.user,
                heatmap: new Heatmap(),
                analysisCanvas: null,
                isAnalysisCanvasDragging: false,
                plotCanvas: null,
                plotImage: null,
                backgroundImage: null,
                isDirty: true,
                capIntensity: 600,
                centerImage: true,
                defaultCanvasSizes: { width: 2048, height: 2048 },
                zoomValue: 1,
            }
        },
        computed: {
            cell() {
                return this.field.getCellWithId(this.cellId)
            },
            heatmapMaxValue() {
                return heatmapMaxValue(this.opacity);
            },
            dirty() {
                return [ 
                    this.cellId, this.recordingsStyles, this.currentCamera, this.timeframe, this.opacity,
                    this.cell,   this.activeRecordings, this.analysisType,  this.plotType,
                ];
            }
        },
        created() {
            window.addEventListener('resize', this.onResize);
            window.addEventListener('keyup', (e) => {
                if (['+','-'].includes(e.key)) {
                    let delta = e.key === '+' ? 1 :
                                e.key === '-' ? -1:
                                0
                    this.$emit('updateZoom', { delta }); 
                }
            })
            this.centerImage = true;
        },
        mounted() {
            this.heatmap.gradient = heatmapGradient;
            this.heatmap.createPalette();
            this.analysisCanvas = new fabric.Canvas(this.$refs.canvas, { preserveObjectStacking: true, selectable: false, originX: 'center', originY: "center"});
            this.plotCanvas     = document.createElement('canvas');
            this.updateDimensions(this.defaultCanvasSizes);
            this.plotImage    = this.getPlotImage();
            this.zoomControlSetup();
            window['canvas']=this.analysisCanvas;
            window.requestAnimationFrame(this.draw);
        },
        destroyed() {
            this.analysisCanvas.dispose();
            window.removeEventListener('resize', this.forceUpdate);
            window.removeEventListener('keyup', this.forceUpdate);
        },
        watch: {
            dirty() {
                this.isDirty = true;
            },
            currentCamera() {
                this.currentCamera && this.cell.getFixationsForCamera(this.currentCamera)
                    .finally(() => this.isDirty = true);
                this.centerImage = true;
            },
            zoom() {
              this.handleZoom(this.zoom);
            },
            plotImage(){
                window['plotImage'] = this.plotImage;
            }
        },
        methods: {
            onResize() {
                this.isDirty = true;
            },
            setBackgroundImage(fabricImage,width) {
                fabricImage.scaleToWidth(width);
                !this.backgroundImage && this.analysisCanvas.setViewportTransform([1,0,0,1,0,0]);
                this.backgroundImage = fabricImage;
                this.analysisCanvas.setBackgroundImage(this.backgroundImage, () => {
                    this.calculateMinZoom();
                    this.analysisCanvas.renderAll();
                });
                this.analysisCanvas.renderAll();
            },
            drawBackgroundImage() {
                if (this.overlaid) {
                    const width = 1920;
                    const height = 1080;
                    this.updateDimensions({width, height});
                    return true;
                }
                const image = this.getImageForAnalysisType(this.analysisType);
                if (isImageValid(image)) {
                    const { width, height } = image;
                    this.updateDimensions({width, height});
                    this.setBackgroundImage(new fabric.Image(image, {id: 'backgroundImage', evented: false, selectable: false, objectCaching: false}), width);
                    return true;
                } else {
                    this.analysisCanvas.setBackgroundImage(null);
                    this.backgroundImage = null;
                    this.analysisCanvas.remove(this.plotImage);
                    return true;
                }
                return false;
            },
            getImageForAnalysisType(type) {
                if (type === 'Camera') {
                    this.centerImage = true;
                    let camera = this.cell.getCameraWithId(this.currentCamera);
                    return camera ? camera.image : null;
                } else if (type === 'Map') {
                    this.centerImage = true;
                    return this.cell.perspectiveImage;
                }
                return '';
            },
            createPointsMap(activeRecordings) {
                let points = {};
                let camera = this.cell.getCameraWithId(this.currentCamera);
                if (this.analysisType === 'Map') {
                    for (const recordingId of activeRecordings) {
                        points[recordingId] = this.filterPoints(aggregatePoints(this.cell.locations[recordingId]));
                    }
                } else {
                    for (const recordingId of activeRecordings)
                        points[recordingId] = this.filterPoints(camera.viewpoints[recordingId]);
                }
                return points;
            },
            filterPoints(points) {
                if (points && points.length > 0) {
                    const startTime = this.timeframe.start * 1000000000;
                    const endTime = Math.min(this.timeframe.end, this.timeframe.current || this.timeframe.end ) * 1000000000;
                    const timeOffset = points[0].time;
                    const begin = startTime + timeOffset;
                    const end = endTime + timeOffset;
                    return points.reduce(( fixations, { time, duration , x, y} ) => {
                        // Filtering point considering them as time ranges to avoid sudden appearing or disappearing of area in the heat map.
                        if((time < end && (time + duration * 1000000) > begin)){
                            // We consider only the duration inside our selected timespan.
                            const x1 = Math.max(time, begin);
                            const x2 = Math.min(time + duration * 1000000, end);
                            // Scaling v to milliseconds to avoid conflict with capIntensity.
                            const v = (x2 - x1) / 1000000;
                            fixations.push({
                                x: x * this.plotCanvas.width,
                                y: y * this.plotCanvas.height,
                                value: v
                            });
                        }
                        return fixations;
                    }, []);
                }
            },
            drawHeatMap(ctx, points, maxValue) {
                const alphaRadius = 60;
                drawHeatMap(this.heatmap, this.plotCanvas, ctx, points, maxValue, alphaRadius/this.zoomValue);
            },
            drawPath(ctx, points, maxValue) {
                drawPath(ctx, points, maxValue, this.recordingsStyles);
            },
            getMaxValue(points) {
                if (this.analysisType === 'Map') {
                    let max = 0;
                    for (let key in points) {
                        if (points[key])
                            max = points[key].reduce((m, c) => Math.max(m, c.value), max);
                    }
                    return max;
                } else
                    return 700 * Object.keys(points).length;
            },
            draw() {
                
                if (this.isDirty) {
                    let ctx = this.plotCanvas.getContext('2d');
                    if (this.drawBackgroundImage()) {
                        ctx.globalAlpha = 0.75;
                        let points = this.createPointsMap(this.activeRecordings);
                        let maxValue = this.heatmapMaxValue;
                        switch(this.plotType) {
                            case 'Heatmap': {
                                this.drawHeatMap(ctx, points, maxValue);
                                break;
                            };
                            case 'Path': {
                                this.drawPath(ctx, points, maxValue);
                                break;
                            }
                            case 'None': 
                            default: break;
                        }
                        this.plotImage = this.getPlotImage()
                    }
                    this.backgroundImage && this.insertOrReplacePlotObj(this.plotImage);
                    this.analysisCanvas.renderAll();
                }
                this.isDirty = false;
                if (this.centerImage) {
                    this.centerImage = false;
                    const containerHeight = this.$refs.heatmap.clientHeight;
                    const contentHeight = this.$refs.canvas.clientHeight;
                    const offset = (contentHeight - containerHeight) / 2;
                    this.$refs.heatmap.scrollTop = offset;
                }

                window.requestAnimationFrame(this.draw);
            },

            forceUpdate() {
                this.isDirty = true;
            },
            updateDimensions({width,height}) {
                if (this.$refs.heatmap) {
                    this.analysisCanvas.setDimensions({width: this.$refs.heatmap.clientWidth, height: this.$refs.heatmap.clientHeight});
                    this.plotCanvas.width = width * (this.plotType === 'Path' ? this.zoomValue : 1);
                    this.plotCanvas.height = height * (this.plotType === 'Path' ? this.zoomValue : 1);
                    this.updateOverlayDimensions({width, height})
                }
            },
            getPlotImage() {
                return new fabric.Image(this.plotCanvas, {id: 'plotImage', evented: false, selectable: false, objectCaching: false});
            },
            insertOrReplacePlotObj(plotObj) {
                const objs = this.analysisCanvas.getObjects();
                const obj = objs.find(o => o.id && o.id === plotObj.id);
                !!obj && this.analysisCanvas.remove(obj);
                this.analysisCanvas.insertAt(plotObj, 0, !!!obj);
                plotObj.scaleToWidth(this.backgroundImage ? this.backgroundImage.getScaledWidth()*this.zoomValue: 2048);
            },
            zoomControlSetup() {
                fabric.util.addListener(document.getElementsByClassName('upper-canvas')[0], 'contextmenu', function(e) {
                    e.preventDefault();
                 });
                const canvas = this.analysisCanvas;
                const self = this;
                canvas.on('mouse:wheel', function(opt) {
                    const pointer = canvas.getPointer(opt.e);
                    const offset =  { x: opt.e.offsetX, y: opt.e.offsetY };
                    self.$emit('updateZoom',{ delta: opt.e.wheelDelta, opts: { pointer, offset }}); 
                    opt.e.preventDefault();
                    opt.e.stopPropagation();
                });
                canvas.on('mouse:down:before', function(opt) {
                    const evt = opt.e;
                    if (evt.button === 2 || evt.which === 3) {
                        this.isDragging = true;
                        self.isAnalysisCanvasDragging =true;
                        this.lastPosX = evt.clientX;
                        this.lastPosY = evt.clientY;
                    }
                });
                canvas.on('mouse:move:before', function(opt) {
                    if (this.isDragging) {
                        const e = opt.e;
                        this.viewportTransform[4] += e.clientX - this.lastPosX;
                        this.viewportTransform[5] += e.clientY - this.lastPosY;
                        this.requestRenderAll();
                        this.lastPosX = e.clientX;
                        this.lastPosY = e.clientY;
                    }
                });
                canvas.on('mouse:up:before', function(opt) {
                    this.isDragging = false;
                    self.isAnalysisCanvasDragging = false;
                    self.analysisCanvas.getObjects().forEach( i => i.setCoords());
                });
                        
            },
            handleZoom(zoom){
                const { value , opts} = zoom;
                const factor = value / 100;
                this.zoomValue = factor === 0 ? 0.01: factor
                !opts ? this.analysisCanvas.setZoom(this.zoomValue) :
                        this.analysisCanvas.zoomToPoint(opts.offset, this.zoomValue);
                setTimeout(() => this.isDirty = true);
            },
            calculateMinZoom() {
                if(!this.$refs.heatmap) return;
                const { clientHeight , clientWidth } = this.$refs.heatmap;
                let minZoom = 0;
                const padSize = 140; // height of timeline wrapper
                if(this.backgroundImage) {
                    const width = this.backgroundImage.getScaledWidth();
                    const height = this.backgroundImage.getScaledHeight();
                    const start = 100; 
                    const step = this.zoomStep || 10;
                    for ( let i = start; i>0; i=i-step) {
                        if ((width+padSize*2)*i/100 < clientWidth && (height+padSize*2)*i/100 < clientHeight) {
                            minZoom = i;
                            break;
                        }
                    }
                }
                this.$emit('updateMinZoom', minZoom);
            }
        }
    }
</script>

<style scoped>

    #heatmapShelf {
        width: 100%;
        height: 100%;
    }
    #heatmap {
        position: relative;
        width: 100%;
        height: 100%;
    }

    #heatmap[active] >>> .canvas-container > * {
        cursor: crosshair !important;
    }


    #heatmap::-webkit-scrollbar {
        background-color: #0C2234; /*transparent;*/
        width: 5px;
        height: 5px;
    }

    #heatmap::-webkit-scrollbar-thumb {
        background: white;
    }

    .moving >>> .canvas-container > * {
        cursor: move !important;
    }
    
</style>
