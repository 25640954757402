<template>
	<div x-spaced-1>
		<ch-icon icon="fas fa-arrows-alt-v"/>
		<b>{{ Math.trunc(height / 10) }}</b>
		<ch-icon icon="fas fa-arrows-alt-h"/>
		<b>{{ Math.trunc(width / 10) }}</b>
		<ch-icon icon="fas fa-expand-alt"/>
		<b>{{ Math.trunc(depth / 10) }}</b>
	</div>
</template>

<script>
export default {
	name: 'Size',
	props: {
		width: Number,
		height: Number,
		depth: Number
	},
	data() {
		return {
			icons: {
				width: 'fa-arrows-alt-h',
				height: 'fa-arrows-alt-v',
				depth: 'fa-expand-alt'
			}
		};
	},
	methods: {}
};
</script>

<style scoped>

</style>