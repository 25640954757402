<template>
    <div y-spaced-1>
        <h2 primary-text>Planogram Placeholder</h2>
        <div class="placeholderName" x-spaced-1>
            <ch-indicator :color="color" striped/>
            <span>{{asset.name}}</span>
        </div>
        <div>
            <ch-image :src="asset.image"/>
        </div>
    </div>
</template>

<script>
    import RGBColor from '../model/common/color/RGBColor';

    export default {
        name: "PlanogramPlaceholderReferenceInfo",
        props: {
            asset: Object
        },
        computed: {
            color() {
                const color = this.asset.color;
                return new RGBColor(color.r, color.g, color.b).toString();
            }
        }
    }
</script>

<style scoped>

    .placeholderName {
        word-break: break-all;
    }

</style>