<template>
  <ch-application-layout>
    <content-navigation slot="navigation" />
    <template slot="version">ShelfZone Studio v. {{ version }}</template>
    <planograms-catalog slot="main" :query="query" />
  </ch-application-layout>
</template>

<script>
import ContentNavigation from "../ContentNavigation";
import StaticConfig from "../../config.js";
import PlanogramsCatalog from "./PlanogramsCatalog";

export default {
  name: "PlanogramsPage",
  components: { PlanogramsCatalog, ContentNavigation },
  props: {
    matching: String,
    limit: {
      type: String | Number,
      default: "50",
    },
    page: {
      type: String | Number,
      default: "0",
    },
    visible: {
      type: String | Boolean,
      default: "true",
    },
  },
  data() {
    return {
      query: {
        limit: Number.parseInt(this.limit) || 50,
        page: Number.parseInt(this.page) || 0,
        matching: this.matching,
        visible: this.visible === "true",
      },
    };
  },
  mounted() {
    document.title = "Planograms | ShelfZone Studio";
  },
  computed: {
    version() {
      return StaticConfig.version;
    },
  },
  watch: {
    query: {
      handler() {
        this.$router.push({
          name: "planograms",
          query: {
            matching: this.query.matching,
            limit: this.query.limit,
            page: this.query.page,
          },
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
</style>
