<template>
    <ch-form-dialog ref="dialog" type="primary" title="New configuration" @submit="addConfiguration">
        <note-text :show="showNote" text="Please note: The corresponding cell will be created only with the package creation" />
        <ch-field label="Configuration name" :validators="[nameValidator]">
            <ch-text-input v-model="configurationName" placeholder="Configuration name"/>
        </ch-field>
        <div y-spaced-2>
            <ch-field v-if="templates.length > 0" label="Choose a template" :validators="[templateValidator]">
                <ch-list class="templateList" v-model="selectedTemplateId" size="thick" selectable="single">
                    <ch-list-item v-for="template of templates" :key="template.id" :value="template.id">{{template.name}}</ch-list-item>
                </ch-list>
            </ch-field>
            <ch-empty-content v-else firstMessage="You haven’t created any template yet"/>
        </div>
        <ch-loader v-if="isLoading" fullscreen/>
    </ch-form-dialog>
</template>

<script>
    import NoteText from '../components/NoteText';
    export default {
        name: "newProject",
        components: { NoteText },
        data() {
            return {
                templates: this.$storage.templates,
                selectedTemplateId: null,
                configurationName: null,
                isLoading: false,
                project: null
            };
        },
        computed: {
            showNote() {
                if (!this.project) return false;
                const hasField = this.project.hasField && this.project.fieldId;
                if (!hasField) return false;
                const field = this.$storage.studiosById[this.project.fieldId];
                if (!field) return false;
                return this.project.lastPackageDate && (new Date(this.project.lastPackageDate).getTime() > new Date(field.creationDate).getTime());
            }
        },
        methods: {
            open(project) {
                this.clean();
                this.project = project;
                this.templates = this.$storage.templates;
                if (this.project.configurations.length > 0)
                    this.templates = this.templates.filter(t => t.environmentId === this.project.configurations[0].environmentId);
                this.templates.sort((t1, t2) => t1.name.toLowerCase() > t2.name.toLowerCase() ? 1 : -1);
                this.selectedTemplateId = this.templates[0].id;
                this.$refs.dialog.open();
            },
            nameValidator(v) {
                if (v === null || !/^(\w|-|\.|\s){1,30}$/.test(v))
                    return 'Valid characters: A-z, 0-9 and . _ -';
                return null;
            },
            templateValidator(v){
                if(this.selectedTemplateId === null || v === null)
                    return 'Please choose a template to create your project';
                return null;
            },
            clean() {
                this.configurationName = null;
                this.selectedTemplate = this.$storage.templates[0];
            },
            addConfiguration() {
                this.isLoading = true;
                this.configurationName = this.configurationName.trim();
                this.project.addNewConfiguration(this.selectedTemplateId, this.configurationName)
                    .then(({ configurationId }) => {
                        this.isLoading = false;
                        this.$snotify.success("Configuration added successfully.", {
                            timeout: 5000,
                            showProgressBar: false
                        });
                        this.$emit("configurationAdded", configurationId)
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.$snotify.error("Failed to add configuration to project.", {
                            timeout: 5000,
                            showProgressBar: false
                        });
                        console.error(e);
                    });
            }
        }
    }
</script>

<style scoped>

    .templateList {
        max-height: 260px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: var(--doubleMargin);
    }

</style>
