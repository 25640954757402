<template>
    <div y-spaced-1>
        <h2 primary-text>Location</h2>
        <position :value="worldTransform.position" @input="move" :disabled="disabled"/>
        <ch-field label="Rotation" label-position="left">
            <ch-number-input type="primary" size="fill" :disabled="disabled" :step="90" symbol="°"
                             :value="((worldTransform.rotation % 360) + 360) % 360" @input="rotate"/>
        </ch-field>
    </div>
</template>

<script>
    import {TransformService} from '../services/TransformService';
    import LayoutController   from '../services/LayoutController';
    import Position           from './Position';
    import Transform          from '../../../plugins/studioapi/api/spaceallocation/model/common/Transform';

    export default {
        name: 'Transform',
        components: {Position},
        props: {
            controller: LayoutController,
            element: Object,
            disabled: Boolean
        },
        computed: {
            parentWorldTransform() {
                const parent = this.controller.findParent(this.element.id);
                return parent ? this.controller.computeWorldTransform(parent) : Transform.zero;
            },
            worldTransform() {
                return TransformService.joinTransform(this.element.transform, this.parentWorldTransform);
            }
        },
        methods: {
            move(position) {
                if (TransformService.isSamePosition(this.worldTransform.position, position)) return;
                this.worldTransform.position = position;
                const localTransform = TransformService.worldToLocal(this.worldTransform, this.parentWorldTransform);
                this.$unityManager.layoutAPI.TranslateElement(this.element.id, localTransform.position);
            },
            rotate(rotation) {
                if (this.worldTransform.rotation === rotation) return;
                this.worldTransform.rotation = rotation;
                const localTransform = TransformService.worldToLocal(this.worldTransform, this.parentWorldTransform);
                this.$unityManager.layoutAPI.RotateElement(this.element.id, localTransform.rotation);
            }
        }
    };
</script>

<style scoped>

</style>
