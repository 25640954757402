<template>
    <ch-application-layout v-if="recordingsList.length > 0" headerSize="fit">
        <div class="header" slot="header" fill>
             <ch-filters>
                <div x-spaced-1>
                    <label class="productsCount">{{recordingsList.length}} recordings</label>
                </div>
                <div>
                    <ch-pagination ref="pagination" v-model="pagination.currentPage" :max="getMaxPageNumber(filteredRecordingsList)"/>
                </div>
                <div x-spaced-2>
                    <ch-button class="researchColors" type="primary" size="narrow fit" justified @click="() => $refs.manageTags.open()">
                        <span class="manageBtnTxt">Manage tags</span>
                        <ch-icon icon="local_offer" class="outlined"></ch-icon>
                    </ch-button>
                    <ch-search-bar size="narrow" v-model="filters.searchQuery"/>
                </div>
            </ch-filters>
        </div>
        <div class="main" slot="main">
            <ch-table class="recordingsTable" 
                :items="[]" 
                @rowClicked="$emit('row-clicked', { field, recording: $event})"
                :dataProvider="dataProviderFn" > 
                <template slot="header">
                    <ch-th id="subject" name="Respondent" width="300px" sortable default-order="ascending"></ch-th>
                    <ch-th width="300px">
                        <research-ch-select
                            searchable multiple 
                            size="narrow" 
                            inputPlaceholder="Search..."
                            :items="selectFieldCells" 
                            v-model="filters.cells"
                        />
                    </ch-th>
                    <ch-th id="date" name="Date" sortable></ch-th>
                    <ch-th id="duration" name="Duration" width="100px" sortable></ch-th>
                    <ch-th>
                        <tag-select showReset 
                            resetText="Reset filters"
                            notFoundText="No results" 
                            notFoundTextColor="--on-disabled"
                            size="narrow"
                            :list="fieldTags" 
                            :tagIds.sync="filters.tags" />
                    </ch-th>
                </template>
                <template slot-scope="{ row }">
                    <ch-td>{{row.subject}}</ch-td>
                    <ch-td>{{row.cellName}}</ch-td>
                    <ch-td x-spaced-1>
                        <ch-icon icon="today"/>
                        <span>{{formattedDataFromISO(row.date)}}</span>
                    </ch-td>
                    <ch-td x-spaced-1>
                        <ch-icon icon="schedule"/>
                        <span>{{formatDuration(row.duration)}}</span>
                    </ch-td>
                    <ch-td>
                        <div class="tags" x-spaced-1>
                            <template v-for="tag of getTags(row, maxTagsToShow)">
                                <tag-chip :key="tag.id" :tag="tag" colored/>
                            </template>
                            <ch-overlay-panel v-if="row.tags.size > maxTagsToShow" ref="panel" type="overlay">
                                <ch-button type="secondary" size="fit narrow" class="plusNtagsBtn">{{`+ ${row.tags.size - maxTagsToShow + 1} tags`}}</ch-button>
                                <template #content>
                                    <div y-spaced-1 class="tagList" style="--max-items: 5">
                                        <template v-for="tag of getTags(row, maxTagsToShow, true)">
                                            <tag-chip :key="tag.id" :tag="tag" colored/>
                                        </template>
                                    </div>
                                </template>
                            </ch-overlay-panel>
                            <tag-selection :field="field" :recording="row" />
                        </div>
                    </ch-td>
                </template>
            </ch-table>
        </div>
        <template slot="footer">
            <manage-tags ref="manageTags" :field="field" type="primary" title="Manage tags"/>
        </template>
    </ch-application-layout>
     <ch-empty-content v-else
        firstMessage="You haven't any recording yet."/>
</template>

<script>
import Studio from '../../plugins/studioapi/storage/Studio';
import TimeFormatMixin from '@/mixins/TimeFormatMixin';
import PaginationMixin from '../mixins/PaginationMixin';
import ManageTags from './tags/ManageTagsDialog';
import TagChip from './tags/TagChip';
import TagSelect from './tags/TagSelect';
import TagSelection from './tags/TagSelection';
import ResearchChSelect from './researchselect/ResearchChSelect';

export default {
    name: 'ReRecordingTable',
    components: { ManageTags, TagChip, TagSelect, TagSelection, ResearchChSelect },
    mixins: [ TimeFormatMixin, PaginationMixin ],
    props: {
        field: {
            type: Studio
        },
        maxTagsToShow: {
            type: Number,
            default: 6
        }
    },

    data() {
        return {
            filters: {
                tags: [],
                cells: [],
                searchQuery: null,
            }
        };
    },

    computed: {
        recordings() {
            return this.field.recordings;
        },
        recordingsList() {
            return [...this.recordings.values()].map(r =>  {
                r.date = this.getRecordingCreationDate(r); 
                r.cellName = this.getRecordingCellName(r);
                return r;
            }).sort((rA, rB) => rB.subject.toLowerCase().localeCompare(rA.subject.toLowerCase()));
        },
        filteredRecordingsList() {
            const { tags, cells, searchQuery } = this.filters;
            return this.recordingsList.filter( (recording) => {
                const isCellInFilter     = cells.length === 0 || cells.some( cellId => recording.cellId === cellId);
                const isEveryTagsInFilter = tags.length === 0  || tags.every(tagId => recording.tags.has(tagId));
                if (!Boolean(searchQuery)) {
                    return isCellInFilter && isEveryTagsInFilter;
                } else {
                    const query     = searchQuery.trim().toLowerCase();
                    const subject   = recording.subject.toLowerCase();
                    const isInQuery = subject.includes(query) || [...recording.tags].map( tagId => this.field.tags.get(tagId)).some(({name}) => name.toLowerCase().includes(query));
                    return isCellInFilter && isEveryTagsInFilter && isInQuery;
                }
            });
        },

        fieldTags() {
            return [...this.field.tags.values()].map(tag => ({ key: tag.id,  label: tag.name, color: tag.color }));
        },
        selectFieldCells() {
            return this.field.cells.map(({ id, name }) => ({key: id, label: name}));
        },
        dataProviderFn() {
            return ( order ) => {
                const items = this.dataProvider(order, this.filteredRecordingsList);
                return this.getItemsForPage(items, this.pagination.currentPage);
            }
        }
    },
    methods: {
        getRecordingTags(recording, maxTagsToShow) {
            const tags = [...recording.tags.values()].map(id =>  this.field.tags.get(id));
            return (tags.length > maxTagsToShow) ? [ tags.slice(0, maxTagsToShow - 1), tags.slice(maxTagsToShow - 1)]: [ tags, [] ];
        },
        getTags( rec, max, isHidden ) {
            const [ showed, hidden] = this.getRecordingTags(rec, max);
            return isHidden ? hidden : showed
        },
        getRecordingCreationDate(recording) {
            return this.field.getInteviewWithId(recording.interviewId).creationDate;
        },
        getRecordingCellName(recording) {
            return this.field.getCellWithId(recording.cellId).name;
        },
        dataProvider(order, items) {
            const orderCriteria = order.criteria;
            const orderType = order.type;
            if (items.length > 0 && (typeof items[0][orderCriteria]) === 'string') {
                if (orderType === "ascending")
                    return items.slice().sort((i1, i2) => i1[orderCriteria].toLowerCase() > i2[orderCriteria].toLowerCase() ? 1 : -1);
                else if (orderType === "descending")
                    return items.slice().sort((i1, i2) => i1[orderCriteria].toLowerCase() > i2[orderCriteria].toLowerCase() ? -1 : 1)
            } else {
                if (orderType === "ascending")
                    return items.slice().sort((i1, i2) => i1[orderCriteria] > i2[orderCriteria] ? 1 : -1);
                else if (orderType === "descending")
                    return items.slice().sort((i1, i2) => i1[orderCriteria] > i2[orderCriteria] ? -1 : 1)
            }
            return items;
        }
    },
    watch: {
        fieldTags() {
            this.filters.tags = [...this.filters.tags].filter(tagId => this.field.tags.has(tagId));
        }
    }
}
</script>

<style scoped>
    .header {
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
    }
    .manageBtnTxt {
        white-space: nowrap;
    }
    .outlined >>> i {
        font-family: "Material Icons Outlined" ;
    }
    .tags {
        display: flex;
        flex-wrap: nowrap;
        flex: 1 1 auto;
    }
    .recordingsTable {
        box-sizing: border-box;
        padding: 0 var(--quadrupleMargin);
    }

    .plusNtagsBtn {
        margin-right: var(--singleMargin)
    }

    .tagList {
        --tag-chip-height: 22px;
        display: flex; 
        flex-direction: column;
        padding-right: var(--singleMargin);
        max-height: calc((var(--tag-chip-height) + var(--singleMargin)) * var(--max-items, 5));
        overflow: auto;
    }
</style>