<template>
    <ch-access-layout>
        <div class="logo" slot="logo"></div>
        <div y-spaced-1>
            <p>Please insert a new password for your account</p>
            <ch-form @submit="completePasswordReset" :generic-errors="errors">
                <ch-field label="New password" :validators="passwordValidators">
                    <ch-text-input v-model="newPassword" placeholder="New password" password size="large"/>
                </ch-field>
                <ch-field label="Confirm password" :validators="[passwordMatchValidator(newPassword)]">
                    <ch-text-input v-model="confirmPassword" placeholder="Confirm password" password size="large"/>
                </ch-field>
                <template slot="actions">
                    <ch-button submit>Ok</ch-button>
                </template>
            </ch-form>
        </div>
    </ch-access-layout>
</template>

<script>

    import {PasswordValidators} from '../../../user/services/PasswordValidators';

    export default {
        name: "CompletePasswordReset",
        props: {
            resetToken: String
        },
        data() {
            return {
                newPassword: null,
                confirmPassword: null,
                userEmail: null,
                passwordValidators: PasswordValidators.validators,
                passwordMatchValidator: PasswordValidators.passwordMatch,
                errors: []
            };
        },
        methods: {
            completePasswordReset() {
                this.errors = [];
                this.$loading.await(
                    this.$iam.completePasswordReset(this.resetToken, this.newPassword)
                        .then(() => this.$router.push({name: 'passwordChanged'}))
                        .catch(e => {
                            if(e.response.data.code === 'PasswordAlreadyUsed')
                                this.errors = [e.response.data.msg]
                            else
                                this.$router.push({name: 'passwordResetTokenInvalid'})
                        })
                );
            }
        }
    };
</script>

<style scoped>

    .logo {
        background-image: url("../../../assets/logo.png");
        background-size: 210px;
    }

</style>
