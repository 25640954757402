<template>
    <ch-content-dialog type="primary" title="Product" ref="detailsDialog" size="xl">
        <template v-if="product">
            <div v-if="!zoomPhoto" class="productDetails">
                <div class="mainArea">
                    <div class="productDescription">
                        <h1>{{ product.name.capitalize() }}</h1>
                        <product-assets-icon :product="product"/>
                    </div>
                    <div class="productId" x-spaced-1>
                        <div v-if="product.isPrototype" class="isPrototype">
                            <ch-icon icon="fas fa-vector-square" :size="12"/>
                        </div>
                        <h3 elevation-05-text>Product ID:</h3>
                        <h3 primary-text>{{ product.id }}</h3>
                    </div>
                    <div class="productPhoto">
                        <ch-image v-if="currentPhoto >= 0"
                                  :src="photos[currentPhoto] ? photos[currentPhoto].url : ''"/>
                        <g-l-viewer v-else :fileURLs="[webGLUrl]" fill/>
                        <ch-icon @click="zoomImage" class="zoomIcon" icon="zoom_in"/>
                    </div>
                    <div class="productThumbs">
                        <div class="productThumbnail" :class="{selected: currentPhoto === -1}"
                             v-if="product && product.webGL"
                             @click="currentPhoto = -1">
                            <ch-icon icon="3d_rotation" size="32"/>
                        </div>
                        <div class="productThumbnail" :class="{selected: currentPhoto === i}"
                             v-for="(photo, i) in photos"
                             :key="photo['name']" @click="currentPhoto = i">
                            <ch-image :src="photo['thumbUrl']"/>
                        </div>
                    </div>
                    <div class="details">
                        <ch-dl label-position="top">
                            <ch-dd label="EAN" primary-text>
                                {{product.ean}}
                            </ch-dd>
                            <ch-dd v-if="product.producer" label="Manufacturer">{{product.producer.capitalize()}}
                            </ch-dd>
                            <ch-dd v-if="product.brand" label="Brand">{{product.brand.capitalize()}}</ch-dd>
                            <ch-dd v-for="level of category" :label="level.label">
                                {{level.value.capitalize()}}
                            </ch-dd>
                        </ch-dl>
                        <ch-dl label-position="top">
                            <ch-dd v-if="product.format" label="Format">{{product.format}}</ch-dd>
                            <ch-dd label="Dimensions (cm)">
								<size :width="product.size.width" :height="product.size.height" :depth="product.size.depth"/>
                            </ch-dd>
                        </ch-dl>
                    </div>
                </div>
            </div>
            <div v-else slot="main">
                <div class="zoomedPhoto">
                    <ch-image v-if="currentPhoto >= 0" :src="photos[currentPhoto].url"/>
                    <g-l-viewer v-else :fileURLs="[webGLUrl]" :zoomed="zoomPhoto" fill/>
                    <ch-icon @click="zoomImage" class="zoomIcon" icon="zoom_out" size="24"/>
                </div>
            </div>
        </template>
    </ch-content-dialog>
</template>

<script>
import GLViewer                from '../components/StudioGLViewer';
import {CategorizationService} from './services/CategorizationService';
import ProductAssetsIcon from './ProductAssetsIcon';
import Size              from '@/components/Size';

export default {
        name: 'ProductDetails',
        components: {
			Size,
            ProductAssetsIcon,
            GLViewer
        },
        data() {
            return {
                product: null,
                categorization: null,
                currentPhoto: 0,
                zoomPhoto: false
            };
        },
        computed: {
            category() {
                return CategorizationService.ExtractCategory(this.categorization, this.product.categories)
                    .filter(category => category.value);
            },
            webGLUrl() {
                return this.product.webGL ? this.product.webGL.highQuality : null;
            },
            photos() {
                return this.product.photos ? this.product.photos.map(photo => ({
                    importance: photo.importance,
                    thumbUrl: photo.thumbnail,
                    url: photo.file,
                    name: photo.side
                })).sort((p1, p2) => p2.importance - p1.importance) : [];
            }
        },
        mounted() {
            window.addEventListener('keyup', this.onArrowKeysPress);
        },
        beforeDestroy() {
            window.removeEventListener('keyup', this.onArrowKeysPress);
        },
        methods: {
            open(product, categorization) {
                this.currentPhoto = 0;
                this.zoomPhoto = false;
                this.product = product;
                this.categorization = categorization;
                this.$refs.detailsDialog.open();
            },
            zoomImage() {
                this.zoomPhoto = !this.zoomPhoto;
            },
            onArrowKeysPress(e) {
                if (this.product) {
                    const char = e.which;
                    const min = this.product.webGLAvailable ? -1 : 0;
                    if (char === '39') {
                        this.currentPhoto++;
                        if (this.currentPhoto >= this.photos.length)
                            this.currentPhoto = min;
                    } else if (char === '37') {
                        this.currentPhoto--;
                        if (this.currentPhoto < min)
                            this.currentPhoto = (this.photos.length - 1);
                    }
                }
            }
        }
    };
</script>

<style scoped>

    .productDetails {
        max-width: 100%;
    }

    .zoomedPhoto {
        height: 448px;
        align-items: center;
        justify-content: center;
        background-color: white;
        padding: var(--doubleMargin);
    }

    .zoomIcon {
        position: absolute !important;
        top: var(--doubleMargin);
        right: var(--doubleMargin);
        color: var(--elevation-02);
    }

    .mainArea {
        grid-area: main;
        display: grid;
        grid-gap: 16px;
        grid-template-columns: 440px 1fr;
        grid-template-rows: 28px 16px 300px 1fr;
        grid-template-areas: "title title" "subtitle subtitle" "photo details" "thumbs details";
    }

    .productDescription {
        grid-area: title;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .productDescription .title {
        flex: 1 1 auto;
        font-size: 20px;
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .productDescription .infoIcons > *:not(:last-child) {
        margin-right: var(--singleMargin);
    }

    .isPrototype {
        background-color: var(--alert);
        color: var(--on-alert);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 2px
    }

    .productId {
        grid-area: subtitle;
        align-items: center;
        display: flex;
    }

    .productPhoto {
        position: relative;
        grid-area: photo;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border: 1px solid #BAC8D1;
        background: white;
        box-sizing: border-box;
    }

    .productPhoto.zoomed {
        grid-area: title / title / details / details;
        margin-left: 0;
        z-index: 9999;
    }

    .productPhoto img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
    }

    .productPhoto .zoom {
        position: absolute;
        top: 16px;
        right: 16px;
        color: black;
        cursor: pointer;
    }

    .productThumbs {
        grid-area: thumbs;
        display: grid;
        grid-template-columns: repeat(auto-fill, 54px);
        grid-gap: 7px;
    }

    .productThumbnail {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--elevation-04);
        height: 56px;
        padding: 4px;
        cursor: pointer;
        box-sizing: border-box;
    }

    .productThumbnail.selected {
        border: 2px solid var(--primary);
    }

    .productThumbnail img {
        object-fit: scale-down;
        max-width: 46px;
        max-height: 46px;
        margin: 4px;
    }

    .details {
        grid-area: details;
        overflow-y: auto;
        display: flex;
    }

    .details > * {
        flex: 0 0 auto;
        width: 50%;
    }

</style>
