<template>
    <ch-application-layout v-if="project">
        <projects-navigation slot="navigation"/>
        <template #version>ShelfZone Studio v. {{version}}</template>
        <template #header>
            <div class="header">
                <ch-alert :type="selectType(project)" :text="setMessage(project)" class="header-alert"/>
                <div class="projectName" x-spaced-1>
                    <ch-icon @click="returnToProjects()" icon="arrow_back" size="30"/>
                    <ch-field :validators="[projectNameValidator, duplicationValidator]">
                        <ch-editable :value="project.name" size="large" @input="renameProject"/>
                    </ch-field>
                </div>
                <div class="projectActions" x-spaced-2>
                    <span>{{statusMessage}}</span>
                    
                    <ch-button v-if="projectWithoutPackage" 
                        @click="createPackage"
                        type="secondary" justified 
                        :disabled="missingConfiguration">
                        Create package
                        <ch-icon icon="add_circle_outline"/>
                    </ch-button>
                    <ch-button v-else-if="packageIsCreating || packageIsUpdating"
                        class="notInteractable"
                        type="secondary" left>
                        <ch-icon left icon="history"/>
                        {{ `${ packageIsCreating ? 'Generating' : 'Updating' }...`}}
                    </ch-button>
                    <ch-button v-else 
                        @click="update" 
                        :disabled="missingConfiguration"
                        type="secondary" justified>
                        Update package
                        <ch-icon icon="history"/>
                    </ch-button>
                    <template>
                        <ch-button v-if="packageCanBeLoaded"
                            @click="downloadPackage" type="primary" :disabled="!!currentDownloadingProjectId" justified>
                            Package
                            <ch-icon icon="file_download"/>
                        </ch-button>
                        <div class="downloadBar" v-if="currentDownloadingProjectId === projectId">
                            <ch-progress-bar 
                                :value="currentDownloadPercentage" 
                                text="Downloading..." 
                                :maxValue="1"
                                status="content"/>
                        </div>
                    </template>
                    <template>
                        <ch-button v-if="!projectHasField"
                            @click="createField"
                            type="secondary" justified
                            :disabled="missingConfiguration">
                            Create field
                            <ch-icon icon="timeline"/>
                        </ch-button>
                        <ch-button v-else
                            @click="viewField" 
                            justified>
                            View field
                            <ch-icon icon="timeline"/>
                        </ch-button>
                    </template>
                    <ch-button @click="deleteProject" type="error" justified>Delete project
                        <ch-icon icon="delete"/>
                    </ch-button>
                </div>
            </div>
        </template>
        <template #main>
            <ch-tabs v-model="activeTab" class="tabs">
                <template #voices>
                    <ch-tab-voice :key="'content'"    label="Content"   />
                    <ch-tab-voice v-if="showRecordingsTab" :key="'recordings'" label="Recordings" class="researchColors"/>
                </template>
                <template #tabs>
                    <ch-tab-content :key="'content'">
                        <project-content
                            :environments="environments" 
                            :project="project"
                            :field="field"
                            @add-config="addConfiguration"
                            @duplicate-config="duplicateConfiguration"
                            @export-config="exportConfiguration"   
                            @remove-config="removeConfiguration"
                            @show-context-menu="showContextMenu" />
                    </ch-tab-content>
                    <ch-tab-content v-if="showRecordingsTab" :key="'recordings'">
                        <re-recordings-table v-if="field" :field="field"/>
                    </ch-tab-content>
                </template>
            </ch-tabs>
        </template>
        <template #footer>
            <create-package ref="createPackageDialog" @confirm="() => loadField(project)"/>
            <create-field ref="newFieldDialog"/>
            <delete-project ref="deleteProjectDialog" @confirm="returnToProjects"/>
            <remove-configuration ref="removeConfiguration" @confirm="onConfigurationAdded"/>
            <outdated-package ref="outdatedPackageDialog"/>
            <add-configuration ref="addConfigurationDialog" @configurationAdded="onConfigurationAdded"/>
            <ch-input-dialog ref="inputDialog"/>
	        <ch-context-menu ref="contextMenu"/>
        </template>
    </ch-application-layout>
</template>

<script>
    /* Imports */
    import CreatePackage from "./dialog/CreatePackage";
    import CreateField from "./dialog/CreateField";
    import AddConfiguration from "./dialog/AddConfiguration";
    import OutdatedPackage from "./dialog/DownloadOutdatedPackage";
    import DeleteProject from "./dialog/DeleteProject";
    import RemoveConfiguration from "./dialog/RemoveConfiguration";
    import {Status} from "../plugins/studioapi/storage/Project";
    import StringUtils from "../utils/StringUtils";
    import ProjectsNavigation from "./ProjectsNavigation";
    import StaticConfig from "../config.js";
    import {download, MediaType} from '@/utils/DownloadService';
    import ProjectContent    from './components/projectdetails/ProjectDetailsTabContent';
    import ReRecordingsTable from '@/research/components/ReRecordingsTable.vue';
    
    /**/

    export default {
        components: {
            ProjectsNavigation,
            CreatePackage,
            CreateField,
            AddConfiguration,
            DeleteProject,
            RemoveConfiguration,
            OutdatedPackage,
            ProjectContent,
            ReRecordingsTable
        },
        props: {
            projectId: String
        },
        data() {
            return {
                project: null,
                field: null,
                projectStatus: Status,
                nameIsEditable: false,
                editedName: "",
                environments: null,
                activeTab: 'content',
            };
        },
        beforeRouteEnter(to, from, next) {

          const project = window["studioApi"].storage.findProjectById(to.params.projectId);
          if (!project){
              const error = new Error("Error 404");
              error.response = { status: 404 };
              next(error);
          } else {
            document.title = project.name + " | ShelfZone Studio";

            const environments = {};
            project.configurations.forEach(configuration => {
              if (!(configuration.environmentId in environments))
                environments[configuration.environmentId] = [];
              environments[configuration.environmentId].push(configuration);
            });

            next(vm => {
                vm.project = project;
                vm.environments = environments;
                vm.editedName = project.name;
                vm.loadField(vm.project);
            });
          }
        },
        beforeRouteUpdate(to, from, next) {
            const project = window["studioApi"].storage.findProjectById(to.params.projectId);
            const environments = {};
            project.configurations.forEach(configuration => {
                if (environments[configuration.environmentId])
                    environments[configuration.environmentId].push(configuration);
                else
                    environments[configuration.environmentId] = [configuration];
            });
            this.project = project;
            this.environments = environments;
            this.editedName = this.project.name;
            this.loadField(this.project);
            this.activeTab = 'content';
            document.title = project.name + " | ShelfZone Studio";
            next();
        },
        computed: {
            currentDownloadingProjectId() {
                return this.$projectDownloadManager.currentProjectIdDownload;
            },
            currentDownloadPercentage() {
                return this.$projectDownloadManager.currentProjectProgressDownload;
            },
            version() {
                return StaticConfig.version;
            },
            statusMessage() {
                if (this.project == null)
                    return;
                else if (this.projectWithoutPackage || this.packageIsCreating) {
                    return 'No package created yet';
                }
                else if (this.project.getState() !== Status.noPackage && this.project.lastPackageDate)
                    return "Package created on " + StringUtils.formattedDateTimeFromISO(this.project.lastPackageDate);
            },
            elapsedTime() {
                if (!this.project.estimatedDuration)
                    return 95;
                return Math.min(this.project.estimatedDuration * 0.95, (new Date() - this.project.startedAt) / 1000);
            },
            isCreatingPackage() {
                return (
                    this.project.getState() === Status.creatingPackage ||
                    this.project.getState() === Status.updatingPackage
                );
            },
            missingConfiguration(){
                return !this.environments || Object.keys(this.environments).length === 0;
            },

            projectWithoutPackage() { 
                const trigger = [this.project.lastPackageDate, this.project.buildInProgress];
                return  this.project.getState() === this.projectStatus.noPackage ;
            },

            packageIsCreating()   { 
                const trigger = [this.project.lastPackageDate, this.project.buildInProgress];
                return this.project.getState() === this.projectStatus.creatingPackage;
            },

            packageIsUpdating()   { return this.project.buildInProgress  },
            packageCanBeLoaded()  { return !this.projectWithoutPackage && this.currentDownloadingProjectId !== this.projectId && !this.packageIsCreating},

            projectHasField()               { return this.project.hasField && !!this.project.fieldId; },

            showRecordingsTab() { 
                return this.projectHasField && this.field && 
                    ( this.project.getState() === Status.packageAvailable || this.project.getState() === Status.updatingPackage ) 
            }
        },
        methods: {
            projectNameValidator(name) {
                if (!name || !/^(\w|-|\.|\s){1,17}$/.test(name.trim()))
                    return 'Valid characters: A-z, 0-9 and . _ -';
                return null;
            },
            duplicationValidator(name) {
                const trimmedName = name ? name.trim() : name;
                if (trimmedName !== this.project.name && window['studioApi'].storage.projects.find(p => p.name === trimmedName))
                    return 'The name of the project is duplicated';
                return null;
            },
            createPackage() {
                this.$refs.createPackageDialog.open(this.project);
            },
            createField() {
                this.$refs.newFieldDialog.open(this.project);
            },
            downloadPackage() {
                if (this.project.getState() === this.projectStatus.updatingPackage)
                    this.$refs.outdatedPackageDialog.open(this.project);
                else {
                    this.$projectDownloadManager.downloadProject(this.project);
                }
            },
            deleteProject() {
                this.$refs.deleteProjectDialog.open(this.project);
            },
            selectType(project) {
                if (this.project == null)
                    return null;
                switch (project.getState()) {
                    case Status.noPackage:
                        return project.lastBuildFailed ? "error" : "default";
                    case Status.creatingPackage:
                    case Status.updatingPackage:
                        return "alert";
                    case Status.packageAvailable:
                    case Status.recordingsAvailable:
                        return project.lastBuildFailed ? "error" : "success";
                }
            },
            setMessage(project) {
                const errMsg = "Error during package generation. You can restart the process, if the problem persist please contact your administrator.";
                if (this.project == null)
                    return null;
                switch (project.getState()) {
                    case Status.noPackage:
                        return project.lastBuildFailed ? errMsg : "No package created yet.";
                    case Status.creatingPackage:
                        return "A package generation has started, you can edit the project but the VR package will not be updated";
                    case Status.updatingPackage:
                        return "The package is updating, you can edit the project but the VR package will not be updated";
                    case Status.packageAvailable:
                    case Status.recordingsAvailable:
                        return project.lastBuildFailed ?  errMsg : "VR package ready to download!";
                }
            },
            update() {
                this.$refs.createPackageDialog.open(this.project, true);
            },
            returnToProjects() {
                this.$router.push({name: "projects"});
            },
            viewField() {
                this.$router.push({name: "cells", params: {fieldId: this.project.fieldId}});
            },
            onEditNameFocus() {
                document.execCommand('selectAll', false, null);
            },
            onEditNameBlur() {
                this.$refs.nameInput.innerText = this.editedName;
                this.nameIsEditable = false;
                document.execCommand('deselectAll', false, null);
            },
            renameProject(newName) {
                this.project.rename(newName)
                    .catch(() => alert("Something went wrong. Please retry."));
            },
            addConfiguration() {
                this.$refs.addConfigurationDialog.open(this.project);
            },
            onConfigurationAdded(configId) {
                if (configId && this.projectHasField && (this.projectWithoutPackage || ( new Date(this.project.lastPackageDate) < new Date(this.field.creationDate) )  ) ) {
                    this.project.updateField(configId).then( () => this.loadField(this.project)); 
                };
                const environments = {};
                this.project.configurations.forEach(configuration => {
                    if (!(configuration.environmentId in environments))
                        environments[configuration.environmentId] = [];
                    environments[configuration.environmentId].push(configuration);
                });
                this.environments = environments;
            },
            duplicateConfiguration(configuration) {
                const nameValidator = name => {
                    if (name === null || !/^(\w|\d|_|-|\.|\s){1,30}$/.test(name))
                        return 'This name is invalid';
                    return null;
                };
                this.$refs.inputDialog.open(
                    'Duplicate configuration',
                    'text',
                    [nameValidator],
                    'primary',
                    'Configuration name',
                    'Configuration name',
                    null, null, configuration.name.concat('', ' copy'),
                    false,'Create'
                ).then(configurationName => {
                    this.project.duplicateConfiguration(configuration, configurationName)
                        .then(({ configurationId }) => {
                            this.$snotify.success(`Configuration successfully duplicated`, {
                                timeout: 5000,
                                showProgressBar: false
                            });
                            this.onConfigurationAdded(configurationId);
                        })
                        .catch(() => {
                            this.$snotify.error(`Failed to duplicate configuration`, {
                                timeout: 5000,
                                showProgressBar: false
                            });
                        })
                }).catch(() => {
                })
            },
            showContextMenu({ event, menu}) {
                this.$refs.contextMenu.showMenu(event, menu);
            },
	        exportConfiguration(row) {
		        this.$configuration.findConfiguration(row.id)
			        .then(({configuration}) =>
			        	download(
			        		JSON.stringify(configuration),
					        `${configuration.info.name}.json`,
					        MediaType['text/json']
				        )
			        );
	        },
            removeConfiguration(row) {
                this.$refs.removeConfiguration.open(this.project, row);
            },
            loadField(project) {
                if( this.projectHasField ) {
                  window['studioApi'].storage
                    .findFieldById(project.fieldId)
                    .then(studio => {
                        studio.emptyCache();
                        return studio.fetchData();
                    })
                    .then( field => {
                        this.field = field;
                    })
                    .catch( () => this.field = null);
                } else {
                    this.field = null;
                }
            }
        },
        watch: {
            'project.fieldId': function () {
                this.loadField(this.project)
            }
        }
    };
</script>

<style scoped>

    .header {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    .projectName {
        display: flex;
        align-items: center;
        flex: 1 1 auto;
    }

    .projectActions {
        display: flex;
        align-items: center;
    }

    .header-alert {
        width: calc(100% + 64px) !important;
        position: absolute !important;
        top: -16px;
        left: -32px !important;
    }

    .downloadBar {
        width: 160px;
        display: inline-flex;
        border-radius: 4px;
        border: 1px white solid;
        overflow: hidden;
    }
    .tabs >>> .voices {
        padding-left: var(--quadrupleMargin); 
    }

    .notInteractable {
        pointer-events: none;
    }
</style>
