<template>
    <div y-spaced-2>
        <planogram-placeholder-reference-info :asset="asset"/>
        <ch-button type="secondary" size="narrow fill" @click="createPlanogram" justified>
            Create planogram
            <ch-icon icon="add_circle_outline" size="12"/>
        </ch-button>
        <div y-spaced-1>
            <h2 primary-text>Dimensions (cm)</h2>
            <ch-field label="Height" label-position="left">
                <ch-number-input type="primary" size="fill" :min="10" :step="10" :value="Math.round(element.materializedVolume.height * 10) / 100"
                                 disabled/>
            </ch-field>
            <ch-field label="Width" label-position="left">
                <ch-number-input type="primary" size="fill" :min="10" :step="10" :value="element.volume.width / 10"
                                 @input="setWidth"/>
            </ch-field>
            <ch-dl label-width="45%">
                <ch-dd label="In layout">
                    <div x-spaced-1_2>
                        <ch-icon icon="fas fa-arrows-alt-v"/>
                        <b>{{Math.round(element.materializedVolume.height * 10) / 100}}</b>
                        <ch-icon icon="fas fa-arrows-alt-h"/>
                        <b>{{Math.round(element.materializedVolume.width * 10) / 100}}</b>
                        <ch-icon icon="fas fa-expand-alt"/>
                        <b>{{Math.round(element.materializedVolume.depth * 10) / 100}}</b>
                    </div>
                </ch-dd>
            </ch-dl>
            <transform :controller="controller" :element="element" :disabled="!!container"/>
        </div>
        <materializer v-if="container" :controller="controller" :element="container" disabled/>
        <materializer v-else :controller="controller" :element="element"/>
        <delete-selection-button text="Remove element"/>
        <create-planogram-dialog ref="createPlanogramDialog"/>
    </div>
</template>

<script>
    import PlanogramPlaceholderReferenceInfo from './PlanogramPlaceholderReferenceInfo';
    import LayoutController from '../services/LayoutController';
    import Transform from './Transform';
    import Materializer from './Materializer';
    import DeleteSelectionButton from './DeleteSelectionButton';
    import CreatePlanogramDialog from '../../planogrameditor/ui/dialog/CreatePlanogramDialog';
    import PlanogramInstance
        from '../../../plugins/studioapi/api/spaceallocation/model/configuration/element/PlanogramInstance';
    import {PlanogramService} from '../../planogrameditor/services/PlanogramService';

    export default {
        name: 'PlanogramPlaceholderInstanceInfo',
        components: {
            CreatePlanogramDialog,
            DeleteSelectionButton, Materializer, Transform, PlanogramPlaceholderReferenceInfo
        },
        props: {
            controller: LayoutController,
            element: Object
        },
        computed: {
            asset() {
                return this.controller.findAsset(this.element.assetId);
            },
            /**
             * @returns {IElement[]}
             */
            path() {
                return this.controller.extractBranch(this.element.id);
            },
            container() {
                return this.path.find(e => e.type === 'PlanogramsContainer');
            },
            materializers() {
                return this.controller.configuration.library
                    .filter(a => a.type === 'PlanogramMaterializer');
            }
        },
        methods: {
            setWidth(value) {
                this.element.volume.width = value * 10;
                this.$unityManager.layoutAPI.ResizePlanogramPlaceholderInstance(this.element.id, this.element.volume);
            },
            setHeight(value) {
                this.element.volume.height = value * 10;
                this.$unityManager.layoutAPI.ResizePlanogramPlaceholderInstance(this.element.id, this.element.volume);
            },
            createPlanogram() {
                this.$refs.createPlanogramDialog.open(this.element.volume.width)
                    .then(({planogram}) => {
                        const onAssetCreated = jsonAsset => {
                            const asset = JSON.parse(jsonAsset);
                            const planogramInstance = new PlanogramInstance("empty", Transform.zero, asset.id, this.element.materializerId);
                            this.controller.materializeElement(planogramInstance);
                            this.$unityManager.SubscribeToEvent('ElementCreated', onElementCreated);
                            this.$unityManager.layoutAPI.CreatePlanogramInstance(planogramInstance);
                            this.$unityManager.UnsubscribeToEvent('AssetCreated', onAssetCreated);
                        };
                        const onElementCreated = jsonElement => {
                            const element = JSON.parse(jsonElement);
                            this.$unityManager.layoutAPI.EmplaceElement(element.id, this.element.id);
                            this.$unityManager.layoutAPI.DeleteSelection();
                            this.$unityManager.layoutAPI.SelectObject(element.id);
                            this.$unityManager.UnsubscribeToEvent('ElementCreated', onElementCreated);
                        };
                        this.$unityManager.SubscribeToEvent('AssetCreated', onAssetCreated);
                        this.controller.addPlanograms([planogram]);
                        this.$unityManager.layoutAPI.CreatePlanogramReference(planogram.id, planogram.name, PlanogramService.computeVolume(planogram));
                    });
            }
        }
    };
</script>

<style scoped>

    .placeholderName {
        word-break: break-all;
    }

</style>
