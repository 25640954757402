<template>
    <ch-access-layout>
        <div class="logo" slot="logo"></div>
        <div>
            <p>A new password has been set for your account.</p>
            <p>Please login with your new credentials.</p>
        </div>
        <div slot="footer">
            <ch-button @click="returnToLogin">Go to login</ch-button>
        </div>
    </ch-access-layout>
</template>

<script>
    export default {
        name: 'PasswordChanged',
        methods: {
            returnToLogin() {
                this.$router.push({name: 'login'});
            }
        }
    };
</script>

<style scoped>

    .logo {
        background-image: url("../../../assets/logo.png");
        background-size: 210px;
    }

</style>
