<template>
    <ch-form-dialog title="Add element" ref="elementDialog" type="primary" @submit="addElement">
        <h3>Choose type</h3>
        <div class="firstElementType" y-spaced-2>
            <ch-field inline>
                <ch-radio-group v-model="selectedFixture">
                    <ch-radio label="Shelf">Shelf</ch-radio>
                    <ch-radio label="Separator">Separator</ch-radio>
                    <ch-radio label="Pegboard">Pegboard</ch-radio>
                    <ch-radio label="Chest">Chest</ch-radio>
                </ch-radio-group>
            </ch-field>
            <ch-field label="Size" inline label-position="left" :label-width="80">
                <ch-select :disabled="selectedFixture !== 'Shelf'" placeholder="Full width"
                           size="narrow large" :items='["50cm","80cm","100cm","133cm","Full width"]'
                           v-model="currentSize"/>
            </ch-field>
        </div>
        <span>Chest size (cm)</span>
        <div x-spaced-3>
            <ch-field label="Width" inline>
                <ch-number-input type="primary" :disabled="selectedFixture !== 'Chest'"
                                 v-model="chestWidth" :step="5" symbol="cm" :min="2"/>
            </ch-field>
            <ch-field label="Depth" inline>
                <ch-number-input type="primary" :disabled="selectedFixture !== 'Chest'"
                                 v-model="chestDepth" :step="5" symbol="cm" :min="2"/>
            </ch-field>
            <ch-field label="Height" inline>
                <ch-number-input type="primary" :disabled="selectedFixture !== 'Chest'"
                                 v-model="chestHeight" :step="5" symbol="cm" :min="2"/>
            </ch-field>
        </div>
        <span>Container size (cm)</span>
        <div x-spaced-3>
            <ch-field label="Width" inline>
                <ch-number-input type="primary" :disabled="selectedFixture !== 'Chest'"
                                 v-model="innerChestWidth" :step="5" symbol="cm" :min="1"/>
            </ch-field>
            <ch-field label="Depth" inline>
                <ch-number-input type="primary" :disabled="selectedFixture !== 'Chest'"
                                 v-model="innerChestDepth" :step="5" symbol="cm" :min="1"/>
            </ch-field>
            <ch-field label="Height" inline>
                <ch-number-input type="primary" :disabled="selectedFixture !== 'Chest'"
                                 v-model="innerChestHeight" :step="5" symbol="cm" :min="1"/>
            </ch-field>
        </div>
        <template slot="actions">
            <ch-button cancel size="small" type="secondary">Cancel</ch-button>
            <ch-button submit size="small" type="primary">Add</ch-button>
        </template>
    </ch-form-dialog>
</template>

<script>
    import SelectionManager from '../../managers/SelectionManager';
    import Repository from '../../services/Repository';

    export default {
        name: 'AddElementDialog',
        props: {
            repository: Repository,
            selectionManager: SelectionManager
        },
        data() {
            return {
                selectedFixture: 'Shelf',
                currentSize: 'Full width',
                chestWidth: 0,
                chestDepth: 0,
                chestHeight: 0,
                innerChestWidth: 0,
                innerChestDepth: 0,
                innerChestHeight: 0
            };
        },
        watch: {
            chestWidth() {
                this.applyChestSize();
            },
            chestHeight() {
                this.applyChestSize();
            },
            chestDepth() {
                this.applyChestSize();
            },
            innerChestWidth() {
                this.applyInnerChestSize();
            },
            innerChestHeight() {
                this.applyInnerChestSize();
            },
            innerChestDepth() {
                this.applyInnerChestSize();
            }
        },

        methods: {
            open() {
                this.$refs.elementDialog.open();
                this.chestWidth = 50;
                this.chestHeight = 50;
                this.chestDepth = 50;
                this.innerChestWidth = 48;
                this.innerChestHeight = 48;
                this.innerChestDepth = 48;
            },
            addElement() {
                const y = this.repository.planogram.fixtures.reduce((maxY, f) => Math.max(maxY, f.geometry.y + f.geometry.height), 0);
                if (this.selectedFixture === 'Shelf') {
                    if (this.currentSize === 'Full width')
                        this.addShelf(this.repository.planogram.maximumWidth, y);
                    else if (this.currentSize === '50cm')
                        this.addShelf(500, y);
                    else if (this.currentSize === '80cm')
                        this.addShelf(800, y);
                    else if (this.currentSize === '100cm')
                        this.addShelf(1000, y);
                    else if (this.currentSize === '133cm')
                        this.addShelf(1330, y);
                } else if (this.selectedFixture === 'Pegboard')
                    this.addPegboard(this.repository.planogram.maximumWidth, y);
                else if (this.selectedFixture === 'Chest')
                    this.addChest();
                else if (this.selectedFixture === 'Separator')
                    this.addSeparator();

                this.$refs.elementDialog.close();
            },
            /**
             * @param {number} width
             * @param {number} y
             */
            addShelf(width, y) {
                const height = 50;
                this.$unityManager.Dispatch('PlaceNewFixture', {
                    fixtureFactory: {
                        type: 'ShelfFactory',
                        size: {
                            width,
                            height,
                            depth: 550
                        }
                    },
                    parentId: 'FXTModule',
                    position: {x: 0, y: y + height, z: 0}
                });
            },
            addSeparator() {
                const fixture = this.selectionManager.selection.find(s => s.type === 'Shelf');
                if (!fixture) return;
                this.$unityManager.Dispatch('PlaceNewUnit', {
                    unitFactory: {
                        type: 'SeparatorFactory',
                        size: {
                            width: 10,
                            height: 150,
                            depth: 550
                        }
                    },
                    fixtureId: fixture.id,
                    position: {x: 0, y: 0, z: 0}
                });
            },
            /**
             * @param {number} width
             * @param {number} y
             */
            addPegboard(width, y) {
                this.$unityManager.Dispatch('PlaceNewFixture', {
                    fixtureFactory: {
                        type: 'PegboardFactory',
                        size: {
                            width,
                            height: 500,
                            depth: 550
                        }
                    },
                    parentId: 'FXTModule',
                    position: {x: 0, y, z: 0}
                });
            },
            addChest() {
                this.$unityManager.Dispatch('PlaceNewFixture', {
                    fixtureFactory: {
                        type: 'ChestFactory',
                        size: {
                            width: this.chestWidth * 10,
                            height: this.chestHeight * 10,
                            depth: this.chestDepth * 10
                        },
                        innerSize: {
                            width: this.innerChestWidth * 10,
                            height: this.innerChestHeight * 10,
                            depth: this.innerChestDepth * 10
                        }
                    },
                    parentId: 'FXTModule',
                    position: {x: 0, y: 0, z: 0}
                });
            },
            applyChestSize() {
                this.innerChestWidth = Math.min(this.chestWidth - 2, this.innerChestWidth);
                this.innerChestHeight = Math.min(this.chestHeight - 2, this.innerChestHeight);
                this.innerChestDepth = Math.min(this.chestDepth - 2, this.innerChestDepth);
            },
            applyInnerChestSize() {
                this.chestWidth = Math.max(this.chestWidth, this.innerChestWidth + 2);
                this.chestHeight = Math.max(this.chestHeight, this.innerChestHeight + 2);
                this.chestDepth = Math.max(this.chestDepth, this.innerChestDepth + 2);
            }
        }


    };
</script>

<style scoped>

    .firstElementType {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

</style>