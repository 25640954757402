<template>
    <div class="groupSelectionActions" y-spaced-2>
        <ch-button v-if="productIds.length > 1 || areSomeProductsNotInGroup" @click="addProductsToGroup" type="primary"
                   size="fill"
                   :disabled="!(currentGroup.nonEmpty && areSomeProductsNotInGroup)">Add product to group
        </ch-button>
        <ch-button v-if="productIds.length > 1 || areSomeProductsInGroup" @click="removeProductsFromGroup"
                   type="primary" size="fill"
                   :disabled="!(currentGroup.nonEmpty && areSomeProductsInGroup)">Remove product from group
        </ch-button>
        <ch-button @click="createGroup" type="secondary" size="fill" justified>Create new group
            <ch-icon icon="add_circle"/>
        </ch-button>
        <create-new-group ref="createGroupDialog" :selectionManager="selectionManager" :repository="repository"
                          @groupCreated="onGroupCreated"/>
        <ch-modal ref="modal" class="classificationAlertModal"/>
    </div>
</template>

<script>
    import {PlanogramService} from '../../services/PlanogramService';
    import SelectionManager from '../../managers/SelectionManager';
    import Repository from '../../services/Repository';
    import CreateNewGroup from './CreateNewGroup';
    import {ColorService} from '../../../layouteditor/services/ColorService';
    import HexColor from '../../../layouteditor/model/common/color/HexColor';
    import { isGroupManual, makeManualCondition, parseCondition } from '../../model/group/ConditionHelpers';
    import * as _ from 'lodash';

    export default {
        name: 'GroupSelectionActions',
        components: {CreateNewGroup},
        props: {
            productIds: Array,
            repository: Repository,
            selectionManager: SelectionManager
        },
        computed: {
            /**
             * @return {string}
             */
            planogramId() { return this.repository.planogram.id; },
            /**
             * @return {Option<Group>}
             */
            currentGroup() { return PlanogramService.findGroupWithId(this.repository.planogram, this.selectionManager.currentGroupId); },
            /**
             * @return {boolean}
             */
            areSomeProductsInGroup() {
                return this.currentGroup.map(group =>
                    this.productIds.some(p => group.matchedProductsIds.includes(p))
                ).getOrElse(false);
            },
            /**
             * @return {boolean}
             */
            areSomeProductsNotInGroup() {
                return this.currentGroup.map(group =>
                    this.productIds.some(p => !group.matchedProductsIds.includes(p))
                ).getOrElse(false);
            }
        },
        methods: {
            createGroup() { this.$refs.createGroupDialog.open(this.productIds); },
            onGroupCreated(group) {
                const condition = {
                    type: 'IsBucketWithProduct',
                    productIds: group.matchedProductsIds
                };
                const color = ColorService.hexColorToRGBColor(new HexColor(group.color));
                this.$unityManager.Dispatch('CompoundAction', {
                    actions: [
                        {type: 'CreateFilter', data: {id: group.id, name: group.name, condition, color}},
                        {type: 'ActivateFilter', data: {id: group.id}}
                    ]
                });
                this.selectionManager.currentGroupId = group.id;
            },
            addProductsToGroup() {
                this.currentGroup.forEach(group => {
                    const products = [...new Set(group.matchedProductsIds.concat(this.productIds))];
                    this.updateGroup(group, products, true);
                });
            },

            removeProductsFromGroup() {
                this.currentGroup.forEach(group => {
                    const products = [...new Set(group.matchedProductsIds.filter(id => !this.productIds.includes(id)))];
                    this.updateGroup(group, products, false);
                });
            },
            
            updateGroup(group, products, isAdd = true) {
                if(isGroupManual(group)) {
                    this.manualizeGroup(group, products);
                } else {
                    this.askToManualizeGroup(group, products, isAdd);
                }
            },

            manualizeGroup(group, products) {
                group.matchedProductsIds = [...products];
                group.condition = makeManualCondition([ ...group.matchedProductsIds]);
                this.triggerUpdate(group, group.condition);
            },

            askToManualizeGroup(group, products, isAdd = true) {
                this.$refs.modal.open(
                    'Group editing',
                    `You are manually ${isAdd ? 'adding' : 'removing'} a product to a group created using a classification: this classification will be lost and the group will not be updated automatically anymore`,
                    'alert',
                    `${isAdd ? 'Add': 'Remove'}\xA0product`,
                    'Cancel'
                ).then(() => {
                    this.manualizeGroup(group, products);
                }, () => {});
            },

            triggerUpdate(group, groupCondition) {
                const condition = {
                        type: 'IsBucketWithProduct',
                        productIds: group.matchedProductsIds
                };
                this.$unityManager.Dispatch('ChangeFilterCondition', {id: group.id, condition});
                this.$trade.editGroup(this.planogramId, group.id, group.name, group.color, group.matchedProductsIds, groupCondition);
            },

        }
    }
</script>

<style scoped>

    .groupSelectionActions {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        justify-self: flex-end;
    }
    
    .classificationAlertModal >>> footer > button:nth-child(2) {
        width: fit-content !important;
    }

</style>