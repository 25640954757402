<template>
    <div class="rightSideBar" fill>
        <bucket-info
            v-if="selectedBucketsIds && selectedBucketsIds.length === 1"
            :selectionManager="selectionManager"
            :repository="repository"
            :bucketId="selectedBucketsIds[0]"
            :cameraView="cameraView"
            @market-data-clicked="$emit('market-data-clicked')"
        />
        <multiple-buckets-info v-else-if="selectedBucketsIds && selectedBucketsIds.length > 1" :repository="repository"
                               :bucketsIds="selectedBucketsIds" :selectionManager="selectionManager"/>
        <fixture-info v-else-if="selectedFixtureId" :fixtureId="selectedFixtureId" :repository="repository"/>
        <group-info v-else-if="selectedGroupId" :groupId="selectedGroupId" :repository="repository"
                    @deleteGroup="deleteGroup" @editGroup="editGroup"/>
        <base-height v-else-if="selectedBaseHeight" :repository="repository"/>
        <div v-else class="noSelectionMessage">
            <h1 primary-text>No items selected</h1>
            <p elevation-05-text>Select an item to view info</p>
        </div>
    </div>
</template>

<script>
    import BucketInfo from './bucket/BucketInfo';
    import FixtureInfo from './FixtureInfo';
    import CreateNewGroup from '../trade/CreateNewGroup';
    import GroupInfo from '../trade/GroupInfo';
    import MultipleBucketsInfo from './bucket/MultipleBucketsInfo';
    import SelectionManager from '../../managers/SelectionManager';
    import Repository from '../../services/Repository';
    import BaseHeight from "./baseheight/BaseHeight";

    export default {
        name: 'RightSideBar',
        components: {BaseHeight, MultipleBucketsInfo, FixtureInfo, CreateNewGroup, BucketInfo, GroupInfo},
        props: {
            repository: Repository,
            selectionManager: SelectionManager
        },
        data() {
            return {
                cameraView: 'Front'
            };
        },
        computed: {
            /**
             * @return {string[]}
             */
            selectedBucketsIds() {
                if (this.selectionManager.selection.some(i => i.type !== 'Bucket'))
                    return [];
                return this.selectionManager.selection.map(b => b.id);
            },
            /**
             * @return {string|*}
             */
            selectedFixtureId() {
                const selection = this.selectionManager.selection;
                if (selection.length === 1 && ['Shelf', 'Chest', 'Pegboard'].includes(selection[0].type))
                    return selection[0].id;
                return null;
            },
            /**
             * @return {string}
             */
            selectedGroupId() { return this.selectionManager.currentGroupId; },

            selectedBaseHeight() {
                const selection = this.selectionManager.selection;
                if(selection.length === 1 && selection[0].type === 'BaseHeight')
                    return selection[0].type === 'BaseHeight';
                return null;
            }
        },
        mounted() {
            this.$unityManager.Subscribe('CameraViewChanged', this.cameraViewChanged);
        },
        beforeDestroy() {
            this.$unityManager.Unsubscribe('CameraViewChanged', this.cameraViewChanged);
        },
        methods: {
            /**
             * @param {{view: string}} event
             */
            cameraViewChanged(event) { this.cameraView = event.view; },
            /**
             * @param {{any}} event
             */
            deleteGroup(event) { this.$emit('deleteGroup', event); },
            editGroup(groupId) { this.$emit('editGroup', groupId); }
        }
    };
</script>

<style scoped>

    .rightSideBar {
        display: flex;
        flex-direction: column;
        outline: none;
    }

    .noSelectionMessage {
        flex: 1 1 auto;
        overflow-y: auto;
        padding: var(--doubleMargin);
        border-bottom: 1px solid var(--elevation-04);
    }

</style>
