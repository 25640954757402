<template>
   <ch-dialog :title='title' ref="dialog"  type="primary" size="fullscreen" important>
        <ch-application-layout slot="main">
            <div slot="body" class="content">
                <div class="header">
                    <div class="selection" x-spaced-4>
                        <h3>{{selectedProductsMessage}}</h3>
                        <ch-button size="large narrow" 
                                   type="primary" 
                                   justified 
                                   :disabled="hasAppliedFilters || isAllProductsSelected"
                                   @click="selectAll">
                            Select all
                        </ch-button>
                    </div>
                    <ch-search-bar class="searchbar" 
                                   real-time 
                                   placeholder="Search by EAN or keyword"
                                   v-model="searchQuery"/>
                </div>
                <div class="grid">
                    <ch-grid-layout :width="156" :height="210" :gap="30" >
                        <product-card v-for="p in searchedProducts" 
                                      :key="p.id" 
                                      :product="p"
                                      :selectable="!hasAppliedFilters"
                                      :manual="isManualGroup"
                                      :selected="isProductSelected(p.id)" 
                                      @click="onCardClick(p)" />
                    </ch-grid-layout>
                </div>
            </div>
            <div class="sidebar" slot="sidebar">
                <ch-form ref="form" @cancel="close" class="form">
                    <div class="groupTitle" y-spaced-2>
                        <ch-field label="Group name" :validators="[groupNameValidator]">
                            <ch-text-input placeholder="Group name" v-model="groupName"/>
                        </ch-field>
                        <ch-field label="Group color">
                            <ch-radio-group horizontal v-model="selectedColor">
                                <ch-radio v-for="(color, i) of colors" :label="color" :key="i" >
                                    <ch-indicator slot="icon" :color="color" size="small"/>
                                </ch-radio>
                            </ch-radio-group>
                        </ch-field>
                        <group-image-gallery :images="imagesAsDataURLs" @addImage="onAddImage" @removeImage="onRemoveImage"/>
                    </div>
                    <div class="filters groupFilters">
                        <div v-if="!isManualGroup">
                            <section v-for="([sectionName, sectionFilters]) in filters" :key="sectionName" class="filterSection" y-spaced-1>
                                <h1 primary-text>{{sectionName}}</h1>
                                <group-data-filter v-for="([filterKey ,filterLabel, filter]) in sectionFilters" 
                                                   :data="filter.data" 
                                                   :values.sync="filter.values"
                                                   :label="filterLabel"
                                                   :filterKey="filterKey" 
                                                   :key="filterKey"
                                                   :disabled="filter.disabled()"/>
                            </section>
                        </div>
                        <div v-else>
                            <div>You're creating a manual group:</div>
                            <div>classification filters are disabled</div>
                        </div>
                        <ch-button class="resetBtn" 
                                   type="secondary" 
                                   size="fill" justified
                                   :disabled="isManualGroup ? !selectedProductsAsList.length : !hasAppliedFilters"
                                   @click="reset"
                        >
                            {{resetBtnText}}
                        <ch-icon icon="close"/></ch-button>
                    </div>
                    <div slot="actions" class="actions" x-spaced-2>
                       <ch-button type="secondary" size="fill" cancel>Cancel</ch-button>
                       <ch-button type="primary"   size="fill" :disabled="submitBtnDisabled" @click="confirm">{{confirmButtonTxt}}</ch-button>
                    </div>
                </ch-form>
            </div>
        </ch-application-layout>
   </ch-dialog>
</template>

<script>
import {Trade} from '../Trade';
import ProductCard from '@/skucatalog/SkuProductCard.vue'
import GroupImageGallery from './GroupImageGallery';
import GroupDataFilter from './filters/GroupDataFilter';
import * as _ from 'lodash';
import axios from 'axios';
import StudioRequest from '@/plugins/studioapi/requests/StudioRequest';
import { CategorizationService } from '@/skucatalog/services/CategorizationService';
import { isGroupManual, parseCondition, makeManualCondition } from '../../../model/group/ConditionHelpers';
import { ColorService } from '../../../../layouteditor/services/ColorService';
import { ProductGroupImageService } from '../services/ProductGroupImageService';
import { Equal, Contains, Value, And, Or } from '../../../model/group/Condition';
import { categorizationToFilters, categorizeProducts, DEFAULT_PRODUCT_DATA_FILTERS, getAvailableCategoriesKeys, getValuesForFilter } from './filters/GroupProductFilters';

export default {
    name: 'CreateProductGroupDialog',
    components: { ProductCard, GroupImageGallery, GroupDataFilter },
    props: {
        title: { type: String, default: "Create group" },
        repository: Object,
    },
    data() {
        return this.getDefaultData()
    },
    created() {
        this.unwatchers= [];
    },

    computed: {
        additionalProductInfoMap() { return this.repository.additionalProductsInfo },
        categorization()           { return this.repository.categorization; },
        categorizationList()       { return CategorizationService.CategorizationToList(this.categorization); },
        categorizationMap()        { return CategorizationService.CategorizationToMap(this.categorization);  },
        productDataFilters()       { return DEFAULT_PRODUCT_DATA_FILTERS; },
        categorizationFilters()    { return categorizationToFilters(this.categorization)},
        availableKeysSet()         { return getAvailableCategoriesKeys(this.categorization, this.products)},
        selectedProductsMessage()  {
            const length = this.selectedProductsAsList.length;
            return length > 0 ? `${length} item${length > 1 ? 's': ''} selected` : 'No items selected'; 
        },
        resetBtnText() {
            return !this.isManualGroup ? 'Reset filters and selection' : 'Reset selection';
        },
        imagesAsDataURLs() { return this.groupImages.map(([dataUrl, _]) => dataUrl) },
        searchedProducts() {
            const query = (this.searchQuery || "").toLowerCase() ;
            return (this.filteredProducts).reduce((acc, p) => {
                const { ean , info: { name } } = p;
                if( ean.includes(query) || name.toLowerCase().includes(query)) {
                    acc.push(p);
                }
                return acc;
            } ,[])
        },
        filteredProducts () { 
           return this.filterProductsByFilters(this.products, this.currentCondition);
        },
        selectedProductsAsSet() {
            const triggers = [this.selectedProducts.changeTracker];
            return this.selectedProducts.values;
        },
        selectedProductsAsList() {
            return Array.from(this.selectedProductsAsSet);
        },
        isAllProductsSelected() {
            return this.selectedProductsAsList.length === this.products.length;
        },
        isEdit() { return !!this.group },
        confirmButtonTxt() { return  `${ !this.isEdit ? 'Create' : 'Save'} group`},
        submitBtnDisabled() {
            const isGroupNameValid = !this.groupNameValidator(this.groupName);
            const hasSelectedProducts = this.selectedProductsAsList.length > 0;
            return !(isGroupNameValid && hasSelectedProducts) || this.isSaving;
        },
        hasAppliedFilters() {
            const isActive = ({disabled, values}) => !disabled() && values.length > 0;
            return Object.values(this.filtersValue).some(isActive);
        },
        currentCondition() {
            const triggers = [
                ...this.productDataFilters.map(([key]) => this.filtersValue[key]),
                ...this.categorizationFilters.map(([key]) => this.filtersValue[key])
            ].map(({values = []} = {}) => values);
            return this.makeCondition();
        }

    },
    methods: {
        
        getDefaultData() {
            return {
                colors:           Trade.availableGroupColors,
                selectedColor:    Trade.availableGroupColors[4],
                
                group:            null,
                groupName:        '',
                groupImages:      [],
                isManualGroup:    false,
                
                searchQuery:      '',
                products:         [],
                selectedProducts: {
                    changeTracker: 1,
                    values: new Set()
                },
                
                filters:          [],
                filtersValue:     {},
                isSaving:         false,

                resolve:          null,
                reject:           null,
            }
        },
        open( group ) {
            Object.assign(this.$data,this.getDefaultData());
            const { planogram: { fixtures, productSet } } = this.repository;
            const usedProductIds = (fixtures || []).flatMap(f => f.buckets.map(b => b.productId));
            const usedProducts   = productSet.filter(({id}) => usedProductIds.includes(id));
            this.products        = categorizeProducts(usedProducts, this.repository, this.productDataFilters ).map( p => ({
                ...p,
                name: p.info.name,
                coverPhoto: !(p.type === "Prototype") ? p.images["front"] : ProductGroupImageService.createSVGImageAsDataUri(p.id)
            }));
            this.initFilters();
            this.$refs.dialog.open();
            this.group = group;
            if ( this.isEdit) {
                this.groupName     = this.group.name;
                this.selectedColor = this.group.color.toUpperCase();
                this.groupImages = this.group.images.map(({file, name}) => [ file, name]);
                if ( isGroupManual(this.group) ) {
                    this.group.matchedProductsIds.forEach(p => this.selectedProducts.values.add(p));
                    this.selectedProducts.changeTracker += 1;
                    this.isManualGroup = true;
                } else {
                    const { condition } = group;
                    this.populateFilters(condition);

                }
            }
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject  = reject;
            }).finally(() => this.close())
        },
        close() {
            this.$refs.dialog.close();
            this.unwatchers.forEach( unwatch => unwatch());
            this.unwatchers = [];
            this.reject();
        },

        initProductFilters() {
            return this.productDataFilters.map(([ key, label, path, getter ]) => {
                this.$set(this.filtersValue, key, { values: [], data: [], path, disabled: () => false });
                return [key, label, this.filtersValue[key]];

            });
        },

        initCategorizationFilters() {
            this.unwatchers.forEach( u => u());
            this.unwatchers = [];
            const toKeyLabel = ({key, label}) => ({key, label});
            const isFilterDisabled = (filterKey, previousFilterKey) => () => {
                if(!previousFilterKey) { return false; }
                else {
                    const { disabled , values } = this.filtersValue[previousFilterKey];
                    return disabled() || !(values.length > 0);
                }
            }
            //populate categorization filters with default values
            const { filters } = this.categorizationFilters.reduce( (acc, [ key, label, path]) => {
                this.$set(this.filtersValue, key, { 
                    values: [], 
                    data: this.categorizationList.filter(item =>  item.categoryKey === key).map(toKeyLabel), 
                    path, 
                    disabled: isFilterDisabled(key, acc.previousFilterKey)
                });
                acc.filters.push([key, label, this.filtersValue[key]]);
                acc.previousFilterKey = key;
                return acc;
            } , { filters: [], previousFilterKey: null });
            // setting up watchers to  populate current filter with values based on values of previous filter;
            this.categorizationFilters.reduce((acc, [key]) => {
                if (acc) {
                    const unwatchFn = this.$watch( () => this.filtersValue[acc].values, (values) => {
                        if(values.length) {
                            this.filtersValue[key].data = this.categorizationList.filter(item =>  item.categoryKey === key 
                                                                                                  && this.availableKeysSet.has(item.key) 
                                                                                                  && values.includes(item.parent.key)
                            ).map(toKeyLabel);
                            this.filtersValue[key].values = this.filtersValue[key].values.filter(item => values.some(v => CategorizationService.isChild(this.categorizationMap, v, item)));
                        } else {
                            this.filtersValue[key].values = [];
                            this.filtersValue[key].data   = this.categorizationList.filter( item => item.categoryKey === key && this.availableKeysSet.has(item.key))
                                                                .map(toKeyLabel);
                        }
                    });
                    this.unwatchers.push(unwatchFn);
                } else {
                    this.filtersValue[key].data = this.categorizationList.filter(item => {
                        return item.categoryKey === key && this.availableKeysSet.has(item.key);
                    }).map(toKeyLabel);
                }
                return key;
            }, null);

            return filters;
        },

        
        
        initFilters() {
            const initializedProductDataFilters    = this.initProductFilters();
            const initializedCategorizationFilters = this.initCategorizationFilters(); 
            this.filters = [
                [ "Classification", initializedCategorizationFilters ],
                [ "Products Data" , initializedProductDataFilters    ]
            ]; 
        },
        groupNameValidator(name) {
            if (!name || !/^(\w|-|\.|\s){1,30}$/.test(name.trim()))
                return 'Valid characters: A-z, 0-9 and . _ -';
            return null;
        },
        onCardClick({id}) {
            if (this.hasAppliedFilters) return;  
            this.toggleProductsSelection([id]); this.isManualGroup = true; 
        },
        toggleProductsSelection( productIds) {
            productIds.forEach( (id) => !this.selectedProducts.values.has(id) ? this.selectedProducts.values.add(id) 
                                                                              : this.selectedProducts.values.delete(id));
            this.selectedProducts.changeTracker += 1; 
        },
        discardProductSelection() {
            this.selectedProducts.values.clear();
            this.selectedProducts.changeTracker = 1;
        },
        resetFilters() {
            Object.values(this.filtersValue).forEach( (v)=> v.values = [] );
        },
        isProductSelected(id) {
            return this.selectedProductsAsSet.has(id);
        },
        reset() {
            if(!this.isManualGroup) {
                this.resetFilters();
            }
            this.discardProductSelection();
            this.isManualGroup = false;
        },
        selectAll() {
            this.reset();
            this.isManualGroup = true;
            this.toggleProductsSelection(this.products.map(({id}) => id));
        },
        onAddImage(images) {
            if (!images.length) return;
            const [ image, ...restImages ] = images;
            const { type, name } = image;
            if ( !['image/png', 'image/jpeg'].includes(type) ) {
                this.$snotify.error(`Error ${name}. Invalid file format.`, {
                    timeout: 5000,
                    showProgressBar: false
                });
                return;
            }
            return this.readImageFromFile(image).then( imageAsDataURL => {
                this.groupImages = [];
                this.groupImages.push([imageAsDataURL, image]);
            });
        },
        onRemoveImage() {
            this.groupImages = [];
        },
        readImageFromFile( file ) {
            return new Promise((res, rej) => {
                const reader = new FileReader();
                reader.addEventListener("load", (e) => res(e.target.result) )
                reader.readAsDataURL(file);
            }) 
        },
        makeCondition() {
            return this.isManualGroup ? this.makeManualGroupCondition() : this.makeFilteredGroupCondition();
        },
        makeManualGroupCondition() {
            return makeManualCondition(this.selectedProductsAsList);
        },      

        makeCategorizationCondition() {

            const expressions = this.categorizationFilters.map(([key, _, path]) => {
                return {
                        type: Contains.staticType,
                        property: {
                            type: Value.staticType,
                            path
                        },
                        values: this.filtersValue[key].values
                    }
            }).filter( ({values}) => values.length > 0);
            return { type: And.staticType, expressions };
        },
        makeProductDataCondition() {
            return this.productDataFilters.reduce((acc, [key, _ , path]) => {
                const filterVals = this.filtersValue[key].values;
                if( filterVals.length ) {
                    const condition = {
                        type: Contains.staticType,
                        property: {
                            type: Value.staticType,
                            path
                        },
                        values: filterVals
                    }
                    acc.expressions.push(condition)
                }
                return acc;
            }  , { type: And.staticType, expressions: []});
        },

        makeFilteredGroupCondition() {
            const condition = { type: And.staticType, expressions: [] }
            if ( !this.hasAppliedFilters) return condition;
            [this.makeCategorizationCondition(), this.makeProductDataCondition()].forEach(cond => {
                if(cond.expressions.length) {
                    condition.expressions.push(cond);
                }
            })
            return condition;
        },

        filterProductsByFilters(products, condition) {
            if (this.hasAppliedFilters) {
                const ids = this.products.filter(this.parseConditionFunc(condition)).map(({id}) => id);
                return products.filter(({id}) => ids.includes(id))
            } else {
                return products;
            }
        },

        saveGroup() {
            this.isSaving = true;
            const condition   = this.currentCondition;
            const request     = (!this.isEdit ? this.$trade.createGroup : this.$trade.editGroup).bind(this.$trade);
            const planogramId = this.repository.planogram.id;
            const groupName   = this.groupName.trim();
            const color       = this.selectedColor;
            const products    = this.products.filter(this.parseConditionFunc(condition)).map(({id}) => id);

            const requestParams = [ planogramId, groupName, color, products, condition ];
            if (this.isEdit) { requestParams.splice(1,0, this.group.id) };
            
            request(...requestParams).then((response) => {
                let savedGroup;
                if (this.isEdit) {
                    const { groupId, name, color, productIds: matchedProductsIds , condition } = response;
                    this.group.name = name;
                    this.group.color = color;
                    this.group.matchedProductsIds = matchedProductsIds;
                    this.group.condition = condition;
                    savedGroup = this.group;
                } else {
                    const { group } = response;
                    savedGroup = group;
                    this.repository.planogram.productsGroups.push(savedGroup);
                }
                this.$snotify.success(`Group ${this.isEdit ? 'is updated' : 'created'}`, {timeout: 5000, showProgressBar: false});
                this.resolve(savedGroup);
                return savedGroup;
            }).catch((e) => {
                console.error(e);
                this.$snotify.error(`Group ${this.isEdit ? 'edit' : 'creation'} failed`, { timeout: 5000, showProgressBar: false } )
            }).then((group) => {
                if (!group) return;
                if(this.groupImages.length) {
                    const [ [ _, file] , ...rest ] = this.groupImages;
                    if (file instanceof File ) {
                        return this.addImageToGroup(group,file);
                    }
                } 
                else {
                    return this.removeGroupImage(group);
                }
            }).finally(() => {
                this.isSaving = false;
            });
        },
        parseConditionFunc(condition) {
            return parseCondition(condition);
        },
        populatePoductDataFilters(condition) {
            this.productDataFilters.forEach(([k,_,path]) => {
                const values = getValuesForFilter(condition, path, []);
                this.filtersValue[k].values.push(...values);
            })
        },
        populateCategorizationFilters(condition) {
            const values = this.categorizationFilters.flatMap( ([_,__, path]) => getValuesForFilter(condition, path, []))
                                                     .flatMap(p => CategorizationService.fromChildToParent(this.categorizationMap, p))
                                                     .map(({categoryKey, key}) => ({categoryKey, key}));
            const valuesByFilterKey = _.groupBy(values, 'categoryKey');
            this.categorizationFilters.forEach(([k]) => {
                this.filtersValue[k].values.push(...(valuesByFilterKey[k] || []).map(({key}) => key));
            });
        },
        populateFilters(condition) {
            this.populateCategorizationFilters(condition);
            this.populatePoductDataFilters(condition);
        },
        confirm() {
            this.saveGroup();
        },
        addImageToGroup(group, imageFile) {
            return ProductGroupImageService.addImageToGroup( this.repository.planogram.id, group, imageFile)
            .catch((e) => {
                console.error(e);
                this.$snotify.error(`Error uploading ${imageFile.name}. Please retry.`, {
                    timeout: 5000,
                    showProgressBar: false
                });
            });
        },
        removeGroupImage(group) {
            return ProductGroupImageService.removeImageFromGroup(this.repository.planogram.id, group)
            .catch((e) =>
                console.error("Error: group image is not removed", e)
            )
        },
    },
    watch: {
        selectedProductsAsList() {
            ( this.selectedProductsAsList.length === 0 && this.isManualGroup ) && this.reset()
        },
        filteredProducts() {
            const filtered = this.hasAppliedFilters;
            if ( filtered ) {
                this.selectedProducts.values.clear();
                this.filteredProducts.forEach(({id}) => this.selectedProducts.values.add(id));
                this.selectedProducts.changeTracker += 1;
            } else if ( !filtered && !this.isManualGroup) {
                this.discardProductSelection();
            }
        },
        products() {
            this.productDataFilters.forEach(([key, _, __, getter]) => {
                const items = this.products.map(p => getter(p));
                this.filtersValue[key].data = Array.from(new Set(items));
            });
        },
    },

}
</script>

<style scoped>
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: var(--doubleMargin);
        background-color: var(--elevation-02);        
    }
    .searchbar {
        padding: 0 var(--doubleMargin);
    }
    .selection {
        display: flex;
        justify-content: space-between;
        align-items: center;

    }
    .sidebar {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        height: 100%;
        box-sizing: border-box;
    }
    .groupFilters {
        --border-opt: 1px solid var(--elevation-04);
        border-top: var(--border-opt);
        border-bottom: var(--border-opt);

    }

    .groupTitle, .groupFilters, .actions {
        padding: var(--doubleMargin);
        margin: 0 !important;
    }

    .groupTitle {
        max-height: 50%;
        min-height: 20%;
        overflow: auto;
    }

    .content {
        padding: var(--doubleMargin);
        overflow-y: auto;
        box-sizing: border-box;
    }
    .filterSection {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        padding-bottom: var(--singleMargin);
    }
    .form {
        height: 100%;
    }
    .filters {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        overflow-y: auto;
    }
    .resetBtn {
        margin-top: var(--quadrupleMargin);
    }
</style>