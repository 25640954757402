<template>
    <div class="actionBar" v-if="repository.planogram">
        <div class="buttons" x-spaced-1>
            <ch-button type="overlay" icon @click="goBack">
                <ch-icon icon="arrow_back"/>
            </ch-button>
            <ch-context-menu type="overlay" :voices="voices">
                <ch-button type="overlay" class="contextButton" icon>
                    <ch-icon icon="more_horiz"/>
                </ch-button>
            </ch-context-menu>
            <ch-button type="overlay" icon @click="save" :disabled="!saveEnabled">
                <ch-icon icon="save"/>
            </ch-button>
            <ch-button type="overlay" icon @click="undo" :disabled="!couldUndo">
                <ch-icon icon="undo"/>
            </ch-button>
            <ch-button type="overlay" icon @click="redo" :disabled="!couldRedo">
                <ch-icon icon="redo"/>
            </ch-button>
        </div>
        <div class="planogramName" x-spaced-1>
            <ch-field :validators="[nameValidator]">
                <ch-editable size="narrow" :value="repository.planogram.name" @input="renamePlanogram"/>
            </ch-field>
            <span class="saveInfo" v-if="saveMessage">{{saveMessage}}</span>
        </div>
        <div class="info">
            <ch-overlay-panel
                title="Keyboard shortcuts"
                type="overlay"
                has-main-trigger-slot
                :main-trigger-button-props="{  type:'overlay', icon: true }"
            >
                <template slot="mainTrigger">
                    ?
                </template>
                <ch-dl class="shortcuts" slot="content" label-width="50%">
                    <ch-dd label="Ctrl + z">Undo action</ch-dd>
                    <ch-dd label="Ctrl + y">Redo action</ch-dd>
                    <ch-dd label="Mouse scroll">Zoom</ch-dd>
                    <ch-dd label="Drag with right click">Pan</ch-dd>
                    <ch-dd label="Canc">Delete selected</ch-dd>
                    <ch-dd label="Shift + click">Multiple selection</ch-dd>
                    <ch-dd label="Ctrl + drag">Area selection</ch-dd>
                    <ch-dd label="Alt + drag element">Duplicate</ch-dd>
                    <ch-dd label="Arrows with product">Increase facing</ch-dd>
                    <ch-dd label="Arrows with element">Nudge element</ch-dd>
                </ch-dl>
            </ch-overlay-panel>
            <export-planogram-dialog ref="exportPlanogramDialog"/>
            <ch-input-dialog ref="inputDialog"/>
            <ch-modal ref="modal"/>
        </div>
    </div>
</template>

<script>
    import StudioRequest from '../../../plugins/studioapi/requests/StudioRequest';
    import ExportPlanogramDialog from './dialog/ExportPlanogramDialog';
    import {PlanogramService} from '../services/PlanogramService';
    import Repository from '../services/Repository';
    import B64ToBlob from "@/utils/ImageUtils";

    export default {
        name: 'ActionBar',
        components: {ExportPlanogramDialog},
        props: {
            repository: Repository,
            saveEnabled: Boolean
        },
        data() {
            return {
                cameraView: 'Front',
                couldUndo: false,
                couldRedo: false,
                saveMessage: null
            };
        },
        computed: {
            voices() {
                return [
                    {
                        text: 'Save (ctrl + s)',
                        icon: 'save',
                        disabled: !this.saveEnabled,
                        action: () => this.save()
                    },
                    {
                        text: 'Change Width...',
                        icon: 'space_bar',
                        disabled: this.cameraView === 'Top',
                        action: () => this.changePlanogramWidth()
                    },
                    {
                        text: 'Empty',
                        icon: 'delete',
                        disabled: this.cameraView === 'Top',
                        action: () => this.emptyPlanogram()
                    },
                    {
                        text: 'Export...',
                        icon: 'present_to_all',
                        action: () => this.exportPlanogram()
                    }
                ];
            }
        },
        watch: {
            'repository.planogram.name'() {
                document.title = `${this.repository.planogram.name} | ShelfZone Studio`;
            }
        },
        mounted() {
            document.title = 'Planogram editor | ShelfZone Studio';
            this.$unityManager.Subscribe('HistoryLevelChanged', this.historyLevelChanged);
            this.$unityManager.Subscribe('CameraViewChanged', this.cameraViewChanged);
            this.$unityManager.Subscribe('ThumbnailChanged',this.screenshotThumbnail);
            window.addEventListener('keydown', this.onKeyDown);
        },
        beforeDestroy() {
            this.$unityManager.Unsubscribe('HistoryLevelChanged', this.historyLevelChanged);
            this.$unityManager.Unsubscribe('CameraViewChanged', this.cameraViewChanged);
            this.$unityManager.Unsubscribe('ThumbnailChanged',this.screenshotThumbnail);
            window.removeEventListener('keydown', this.onKeyDown);
        },
        methods: {
            /**
             * @param name
             * @return {string|null}
             */
            nameValidator(name) {
                if (!name || !/^(\w|-|\.|\s){1,50}$/.test(name.trim()))
                    return 'Valid characters: A-z, 0-9 and . _ -';
                return null;
            },
            goBack() {
                if (this.cameraView === 'Front')
                    this.$router.go(-1);
                else
                    this.$unityManager.Dispatch('ChangeCameraView', {view: 'Front'});
            },
            /**
             * @param {KeyboardEvent} event
             */
            onKeyDown(event) {
                if (event.code === 'KeyS' && event.ctrlKey) {
                    this.save();
                    event.preventDefault();
                }
            },
            /**
             * @param {string} name
             */
            renamePlanogram(name) {
                this.$planogram.renamePlanogram(this.repository.planogram.id, name)
                    .then(() => this.repository.planogram.name = name );
            },
            emptyPlanogram() {
                this.$refs.modal.open(
                    'Empty Planogram',
                    'All placed products will be removed from the shelf',
                    'primary'
                ).then(() => {
                    const bucketIds = PlanogramService.getAllBuckets(this.repository.planogram).map(bucket => bucket.id);
                    this.$unityManager.Dispatch('DeleteItems', {
                        itemIds: bucketIds
                    });
                });
            },
            changePlanogramWidth() {
                const isNumberValidator = input => {
                    const n = Number.parseFloat(input);
                    if (Number.isNaN(n))
                        return 'Input is not a number';
                    if (n < 1)
                        return 'Width must be positive';
                    return null;
                };
                this.$refs.inputDialog.open('Planogram width', 'text', [isNumberValidator], 'primary', 'Width', (this.repository.planogram.maximumWidth * 0.1).toString())
                    .then(value => {
                        const width = Number.parseFloat(value) * 10;
                        this.$planogram.resizePlanogram(this.repository.planogram.id, width)
                            .then(() => {
                                this.repository.planogram.maximumWidth = width;
                                this.$unityManager.Dispatch('ResizeFixtures', {
                                    fixtureIds: ['FXTModule'],
                                    size: {
                                        width,
                                        height: 2300,
                                        depth: 10
                                    }
                                });
                            });
                    });
            },
            save() {
                if (!this.saveEnabled) return;
                this.$unityManager.lastSave = null;
                this.saveMessage = 'Saving...';
                this.$unityManager.UpdateThumbnail();
            },
			screenshotThumbnail(event) {
				this.thumbnail = event.image
				this.$planogram.updatePlanogram(this.repository.planogram.id, this.repository.planogram)
					.then(() => {
						const time = new Date();
						this.saveMessage = 'Saved at ' + time.getHours() + ':' + String(time.getMinutes()).padStart(2, '0	');
						window.onbeforeunload = null;
						this.$emit('save');
						const image = B64ToBlob(this.thumbnail, 'image/png');
						this.$planogram.updatePlanogramThumbnail(this.repository.planogram.id, image);
					})
					.catch(code => this.saveMessage = 'Error saving. Code: ' + code);
			},
            exportPlanogram() {
                this.$refs.exportPlanogramDialog.open(this.repository.planogram);
            },
            undo() {
                this.$unityManager.Dispatch('Undo', null);
            },
            redo() {
                this.$unityManager.Dispatch('Redo', null);
            },
            /**
             * @param {{view: string}} event
             */
            cameraViewChanged(event) {
                this.cameraView = event.view;
            },
            /**
             * @param {{isFirst: boolean, isLast: boolean}} event
             */
            historyLevelChanged(event) {
                this.couldUndo = !event.isFirst;
                this.couldRedo = !event.isLast;
            },

        }
    };
</script>

<style scoped>

    .actionBar {
        padding: var(--doubleMargin);
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        z-index: 10;
        pointer-events: none;
    }

    .actionBar > * {
        flex: 1 1 0;
    }

    .actionBar .buttons > * {
        pointer-events: all;
    }

    .actionBar > .planogramName {
        pointer-events: all;
        flex: 0 0 auto;
        color: var(--on-overlay);
        background-color: var(--overlay);
        border: var(--overlay-border);
        border-radius: 4px;
        padding: 2px var(--singleMargin);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .saveInfo {
        color: var(--elevation-05);
    }

    .actionBar > .info {
        display: flex;
        justify-content: flex-end;
    }

    .actionBar > .info > * {
        pointer-events: all;
    }

    .shortcuts {
        width: 300px;
    }

    .contextButton{
        margin-right: 8px;
    }

</style>
