<template>
  <ch-application-layout>
    <admin-navigation slot="navigation" />
    <template slot="version">ShelfZone Studio v. {{ version }}</template>
    <div class="content" slot="main">
      <h2 primary-text>SKU catalog classification</h2>
      <div class="section" y-spaced-2>
        <div>
          <p>
            Changing the classification tree will affect the SKU catalog
            visualization and new planograms.
          </p>
          <p>
            Please upload a CSV file with the first column for product EANs and
            the subsequent ones with product categories.
          </p>
        </div>
        <ch-form @submit="importCategorization">
          <ch-field
            label="Select classification file"
            :validators="[(v) => (!v ? 'Provide a csv file' : null)]"
          >
            <ch-input-file
              v-model="categorizationFile"
              accept=".csv"
            ></ch-input-file>
          </ch-field>
          <div class="importCategorizationActions" slot="actions">
            <ch-button type="primary" size="small" submit>Submit</ch-button>
          </div>
        </ch-form>
      </div>
      <ch-loader v-if="loading" fullscreen />
      <ch-modal ref="modal" />
    </div>
  </ch-application-layout>
</template>

<script>
import AdminNavigation from "./AdminNavigation";
import StaticConfig from "../config.js";
import Vue from "vue";

export default {
  name: "Classification",
  components: { AdminNavigation },
  data() {
    return {
      categorization: null,
      categorizationFile: null,
      loading: false,
    };
  },
  computed: {
    version() {
      return StaticConfig.version;
    },
  },
  beforeRouteEnter(to, from, next) {
    Vue.$studio
      .findMostRecentOwnCategorization()
		//Remove the ECR categorization since it's shared across all the organizations
      .then((r) => next((vm) => (vm.categorization = r.foundCategorization.id === 'ECR' ? null : r.foundCategorization)));
  },
  mounted() {
    document.title = "Classification | ShelfZone Studio";
  },
  methods: {
    importCategorization() {
      const { id, revision } = this.categorization || {
        id: null,
        revision: null,
      };
      this.loading = true;
      this.$productBank
        .importCategorization(id, revision, this.categorizationFile)
        .then((response) => {
          this.categorization = response.categorization;
          this.$refs.modal.open(
            "Import",
            "Import completed with success",
            "success"
          );
          this.loading = false;
        })
        .catch((error) => {
          this.$refs.modal.open(
            "Import",
            "Some error happened during import",
            "error"
          );
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.content {
  padding: var(--quadrupleMargin);
}

.section {
  border-top: 2px solid var(--elevation-06);
  margin-top: var(--singleMargin);
  padding-top: var(--doubleMargin);
}

.importCategorizationActions {
  flex: 1 1 auto;
}
</style>

