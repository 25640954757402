<template>
    <ch-dialog  ref="dialog" type="primary" :title="product ? product.properties.name : ''" @cancel="close" size="large">
        <template v-if="product" slot="body">
            <div class="details primaryColors" y-spaced-1>
                <section class="general">
                    <h1 class="sectionHeader no-border"><span>{{product.properties.name}}</span><ch-icon v-if='product.performances.state.decoration.icon' :icon="product.performances.state.decoration.icon"/></h1> 
                    <ch-dl label-position="left" label-width="10%">
                        <ch-dd label="EAN" primary-text>{{product.ean}}</ch-dd>
                    </ch-dl>
                </section>
                <section class="performances">
                    <h1 class="sectionHeader" primary-text>Product performances</h1> 
                    <div class="performanceOptions">
                        <div class="option">
                            <ch-dl label-position="left" label-width="50%">
                                <ch-dd label="Picked">{{product.performances.events.ProductTouched | nTimes}}</ch-dd>
                                <ch-dd label="In cart">{{product.performances.events.ProductAddedToCart | nTimes}}</ch-dd>
                                <ch-dd label="Purchased">{{product.performances.isPurchased ? 'Yes' : 'No'}}</ch-dd>
                            </ch-dl>
                        </div>
                        <div class="option">
                            <ch-dl label-position="left" label-width="70%">
                                <ch-dd label="Dropped">{{product.performances.events.ProductDiscarded | nTimes}}</ch-dd>
                                <ch-dd label="Removed from cart">{{product.performances.events.ProductRemovedFromCart | nTimes}}</ch-dd>
                            </ch-dl>
                        </div>
                    </div>
                </section>
            </div>
        </template>
        <template slot="footer">
            <ch-button @click="close" size="small" type="secondary">Close</ch-button>
        </template>
    </ch-dialog>
</template>

<script>
    export default {
        name: 'ProductDetail',
        data() {
            return {
                product: null
            }
        },
        methods: {
            open(product) {
                this.product = product;
                this.$refs.dialog.open();
            },
            close() {
                this.$refs.dialog.close();
            }
        },
        filters: {
            nTimes(count) {
               return `${count} time${count !== 1 ? 's': ''}`
            }
        }
    }
</script>

<style scoped>
    .sectionHeader {
        border-bottom: 2px solid var(--elevation-03);
        margin-bottom: var(--doubleMargin);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .general .sectionHeader {
        margin-bottom: var(--singleMargin);
    }
    .general .sectionHeader > span {
        max-width: 90%;
    }

    .no-border {
        border: none;
    }
    .option {
        margin: 0;
        display: flex;
        flex-direction: column;
        flex-basis: 33%;
    }
    .performanceOptions {
        display: flex;
    }
</style>