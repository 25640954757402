<template>
    <div class="downloadManagerSection">
        <ch-icon
            class="expandIcon"
            :class="expandIconClasses"
            :icon="isExpanded ? 'arrow_drop_up' : 'arrow_drop_down'"
            @click.stop.native="handleExpand"
        />
        <div class="downloadManagerSectionDetailsAndProgressBar">
            <div class="downloadManagerSectionDetails">
                <h2>
                    <span
                        class="downloadManagerSectionName"
                        :class="downloadManagerSectionNameClasses"
                    >
                        {{ name }}
                    </span>
                    <span
                        v-if="! isExpanded"
                        class="downloadManagerSectionTime"
                    >
                        {{ sectionTimeLeft }}
                    </span>
                </h2>
                <ch-button
                    v-if="isCancellable"
                    type="secondary"
                    size="narrow fit"
                    @click.stop.native="cancelSectionDownloads()"
                >
                    <span class="cancelCta">Cancel</span>
                    <ch-icon
                        icon="close"
                        @click.stop.native="cancelSectionDownloads()"
                    />
                </ch-button>
            </div>
            <ch-progress-bar
                v-if="! isExpanded"
                :value="sectionProgress"
                size="thin"
            />
            <div v-else>
                <download-manager-section-item
                    v-for="(item, index) in items"
                    :key="`${item.name}-${index}`"
                    class="downloadItem"
                    :time="( item.timeLeft ? `${item.timeLeft} left` : '' )"
                    :item-status="item.status"
                    :progress="item.progress"
                    :item-name="item.name"
                    @retry="retryDownload( item )"
                    @delete="removeDownload( item )"
                    @close="cancelDownload( item )"
                />
            </div>
        </div>
        <ch-modal
            ref="modal"
            class="cancel-download-modal"
        />
    </div>
</template>


<script>
import DownloadManagerSectionItem from "./DownloadManagerSectionItem"

export default {
    name: 'DownloadManagerSection',
    components: {
        DownloadManagerSectionItem,
    },
    props: {
        /*
        * Sets the section name
        */
        name: {
            type: String,
            required: true,
        },
        /*
        * Sets the section expandability
        */
        expandable: {
            type: Boolean,
            default: false,
        },
        /*
        * Sets the section expandability
        */
        items: {
            type: Array,
            default: () => [],
        },
        /*
        * Sets the download speed.
        */
        speed: {
            type: Number,
            default: 0.000001,
        },
    },
    data() {
        return {
            isExpanded: false,
        }
    },
    computed: {
        sectionSizes() {

            let currentSize = 1;
            let size = 1;

            this.items.forEach(
                item => {

                    if(item.status !== 'Cancelled' && item.status !== 'Failed') {

                        currentSize += item.currentSize;
                        size += item.size;

                    }

                }
            );

            return {
                currentSize,
                size
            };

        },
        sectionCurrentSize() {

            return this.sectionSizes.currentSize;

        },
        sectionSize() {

            return this.sectionSizes.size;

        },
        sectionTimeLeft() {

            return this.$downloadManager.fromSecondsToHHMMSS(
                ( this.sectionSize - this.sectionCurrentSize ) / this.speed
            );

        },
        sectionProgress() {

            return ( this.sectionCurrentSize / this.sectionSize ) * 100 ;

        },
        expandIconClasses() {

            return {
                'isVisible': this.expandable,
            }

        },
        downloadManagerSectionNameClasses() {

            return {
                'isNotExpandable': ! this.expandable,
            }

        },
        isCancellable() {

            return this.items.some(
                item => item.status === 'ToDownload' || item.status === 'Downloading'
            );

        },
    },
    methods: {
        cancelSectionDownloads() {
            
            if( this.name === 'Overall progress' ) {

                this.$emit(
                    'cancel-all-downloads'
                );

            } else {

                return this.$refs.modal.open(
                    'Ongoing downloads',
                    `Do you want to cancel every pending download in the ${this.name} section?`,
                    'primary',
                    'Yes, cancel',
                    'No',
                ).then(
                    () => {
                        this.$downloadManager.cancelSectionDownloads( this.name );
                        this.$downloadManager.handleQueue();
                    }
                ).catch(
                    () => {
                        this.$emit('section-downloads-cancel-aborted')
                    }
                );

            }

        },
        cancelDownload( item ) {

            return this.$refs.modal.open(
                    'Ongoing downloads',
                    `Do you want to cancel this download?`,
                    'primary',
                    'Yes, cancel',
                    'No',
            ).then(
                () => {
                    this.$downloadManager.cancelDownload( item );
                    this.$downloadManager.handleQueue();
                }
            ).catch(
                () => {
                    this.$emit('item-download-cancel-aborted')
                }
            );

        },
        retryDownload( item ) {

            this.$downloadManager.retry( item );

        },
        removeDownload( item ) {

            this.$downloadManager.removeItem( item );

        },
        handleExpand() {

            this.isExpanded = ! this.isExpanded;

            this.$emit(
                `${this.isExpanded ? 'is-expanded' : 'not-expanded'}`
            );

        },
    }
}
</script>

<style scoped>
    .downloadManagerSection {
        display: flex;
        padding-bottom: 12px;
    }

    .expandIcon {
        margin-right: 8px;
        opacity: 0;
    }

    .downloadManagerSectionDetailsAndProgressBar {
        width: 100%;
    }

    .downloadManagerSectionDetails {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
    }

    .downloadManagerSectionName {
        color: var(--primary);
    }

    .downloadManagerSectionTime {
        margin-left: 8px;
        font-family: Lato, sans-serif;
        font-size: 12px;
        line-height: 12px;
        color: var(--elevation-05)
    }

    .cancelCta {
        margin: 0 16px 0 8px;
    }

    .isVisible {
        opacity: 1;
    }

    .downloadItem {
        margin-bottom: 16px;
    }

    .isNotExpandable {
        color: var(--elevation-06)
    }

    ::v-deep .cancel-download-modal > .dialog > footer > .primary {

        width: 100px;

    }
</style>