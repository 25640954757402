export default class LayoutUnityAPI {

    constructor(unityManager) {
        this.unityManager = unityManager;
    }

    LoadLayout(layout) {
        this.unityManager.unityInstance.SendMessage('Controller', 'ExecuteCommand', JSON.stringify({
            type: 'LoadLayout',
            data: layout
        }));
    }

    UnloadLayout() {
        this.unityManager.unityInstance.SendMessage('Controller', 'ExecuteCommand', JSON.stringify({
            type: 'UnloadLayout',
            data: null
        }));
    }

    CreateFreeLine(transform, length) {
        this.unityManager.unityInstance.SendMessage('Controller', 'ExecuteCommand', JSON.stringify({
            type: 'CreateFreeLine',
            data: {transform, length}
        }));
    }

    /**
     * @param {PlanogramInstance} planogramInstance
     */
    CreatePlanogramInstance(planogramInstance) {
        this.unityManager.unityInstance.SendMessage('Controller', 'ExecuteCommand', JSON.stringify({
            type: 'CreatePlanogramInstance',
            data: {
                transform: planogramInstance.transform,
                assetId: planogramInstance.assetId,
                materializerId: planogramInstance.materializerId,
                materializedVolume: planogramInstance.materializedVolume,
                isEditMode: planogramInstance.isEditMode
            }
        }));
    }

    /**
     * @param {PlanogramPlaceholderInstance} planogramPlaceholderInstance
     */
    CreatePlanogramPlaceholderInstance(planogramPlaceholderInstance) {
        this.unityManager.unityInstance.SendMessage('Controller', 'ExecuteCommand', JSON.stringify({
            type: 'CreatePlanogramPlaceholderInstance',
            data: {
                transform: planogramPlaceholderInstance.transform,
                assetId: planogramPlaceholderInstance.assetId,
                materializerId: planogramPlaceholderInstance.materializerId,
                volume: planogramPlaceholderInstance.volume,
                materializedVolume: planogramPlaceholderInstance.materializedVolume,
                isEditMode: planogramPlaceholderInstance.isEditMode
            }
        }));
    }

    CreatePlanogramReference(planogramId, name, volume) {
        this.unityManager.unityInstance.SendMessage('Controller', 'ExecuteCommand', JSON.stringify({
            type: 'CreatePlanogramReference',
            data: {planogramId, name, volume}
        }));
    }

    CreatePlanogramPlaceholderReference(planogramPlaceholderId, name, image) {
        this.unityManager.unityInstance.SendMessage('Controller', 'ExecuteCommand', JSON.stringify({
            type: 'CreatePlanogramPlaceholderReference',
            data: {planogramPlaceholderId, name, image}
        }));
    }

    DeleteAsset(assetId) {
        this.unityManager.unityInstance.SendMessage('Controller', 'ExecuteCommand', JSON.stringify({
            type: 'DeleteAsset',
            data: {assetId}
        }));
    }

    DeleteSelection() {
        this.unityManager.unityInstance.SendMessage('Controller', 'ExecuteCommand', JSON.stringify({
            type: 'DeleteSelection',
            data: null
        }));
    }

    StartDragElement(elementId, mousePosition) {
        this.unityManager.unityInstance.SendMessage('Controller', 'StartDragElement', JSON.stringify({
            elementId
        }));
    }

    DragElement(mousePosition) {
        this.unityManager.unityInstance.SendMessage('Controller', 'DragElement', JSON.stringify({
            mousePosition
        }));
    }

    DropElement() {
        this.unityManager.unityInstance.SendMessage('Controller', 'DropElement');
    }

    TranslateElement(elementId, position) {
        this.unityManager.unityInstance.SendMessage('Controller', 'ExecuteCommand', JSON.stringify({
            type: 'TranslateElement',
            data: {
                id: elementId,
                position
            }
        }));
    }

    RotateElement(elementId, rotation) {
        this.unityManager.unityInstance.SendMessage('Controller', 'ExecuteCommand', JSON.stringify({
            type: 'RotateElement',
            data: {
                id: elementId,
                rotation
            }
        }));
    }

    EmplaceElement(elementId, elementToReplaceId) {
        this.unityManager.unityInstance.SendMessage('Controller', 'ExecuteCommand', JSON.stringify({
            type: 'EmplaceElement',
            data: {
                elementId,
                elementToReplaceId
            }
        }))
    }

    ResizeFreeLine(freeLineId, length) {
        this.unityManager.unityInstance.SendMessage('Controller', 'ExecuteCommand', JSON.stringify({
            type: 'ResizeFreeLine',
            data: {
                id: freeLineId,
                length
            }
        }));
    }

    ResizePlanogramPlaceholderInstance(planogramPlaceholderInstanceId, volume) {
        this.unityManager.unityInstance.SendMessage('Controller', 'ExecuteCommand', JSON.stringify({
            type: 'ResizePlanogramPlaceholderInstance',
            data: {
                id: planogramPlaceholderInstanceId,
                volume
            }
        }));
    }

    ChangeMaterializer(elementId, materializerId) {
        this.unityManager.unityInstance.SendMessage('Controller', 'ExecuteCommand', JSON.stringify({
            type: 'ChangeMaterializer',
            data: {
                id: elementId,
                materializerId
            }
        }));
    }

    SelectObject(objectId) {
        this.unityManager.unityInstance.SendMessage('Controller', 'ExecuteCommand', JSON.stringify({
            type: 'SelectObject',
            data: objectId
        }));
    }

    PopLevel() {
        this.unityManager.unityInstance.SendMessage('Controller', 'ExecuteCommand', JSON.stringify({
            type: 'PopLevel',
            data: null
        }));
    }

    ChangeCameraProjection(){
        this.unityManager.unityInstance.SendMessage('Controller','ChangeCameraProjection');
    }

    OnHoverEnterAsset(assetId){
        this.unityManager.unityInstance.SendMessage('Controller', 'OnHoverEnterAsset', JSON.stringify({
            assetId
        }));
    }

    OnHoverExitAsset(assetId){
        this.unityManager.unityInstance.SendMessage('Controller', 'OnHoverExitAsset',JSON.stringify({
            assetId
        }));
    }

}
