<template>
    <div class="baseHeightInfo" >
        <div class="info" y-spaced-2>
            <h1 primary-text>Base height info</h1>
            <div y-spaced-2>
                <h1 primary-text>Position (cm)</h1>
                <ch-field label="Vertical position" :validators="[numberValidator]">
                    <ch-editor-input ref="height" type="number" :value="baseHeight" @input="setBaseHeight"/>
                </ch-field>
            </div>
        </div>
    </div>
</template>

<script>
    import Repository from "../../../services/Repository";

    export default {
        name: "BaseHeight",
        props: {
            repository: Repository
        },
        computed: {
            baseHeight() {
                return this.repository.planogram.baseHeight / 10;
            },
        },
        methods: {
            numberValidator(n) {
                if (n === null || !/^\d+(\.\d+)?$/.test(n))
                    return 'The value is not a valid number';
                return null;
            },
            setBaseHeight(value) {
                this.$unityManager.Dispatch('ChangeBaseHeight', {height: Math.round(value * 10)});

                this.$refs.height.cancelInput();
            }
        }
    }
</script>

<style scoped>

    .baseHeightInfo {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        align-content: stretch;
        overflow: auto;
    }

    .info {
        flex: 1 1 auto;
        overflow-y: auto;
        padding: var(--doubleMargin);
        border-bottom: 1px solid var(--elevation-04);
    }

</style>