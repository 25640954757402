<template>
    <div id="wrapper">
        <div id="collapseTag" @click="collapseGraph(null)"><div id="collpaseText" :class="{collapsed}">></div></div>
        <div id="graphWrapper" :class="{collapsed}">
            <div id="graphdiv" ref="graph"></div>
        </div>
    </div>
</template>

<script>

import Dygraph      from 'dygraphs';
import {Smoothed}   from 'dygraphs/src/extras/smooth-plotter.js';

export default {
    name: 'GraphViewer',
    components: {},
    props: {
        values:             Array,
        playing:            false,
        onChangeState:      null,
        onGraphDrag:        null,
    },
    data() {
    
        return {
            graph:          null,
            collapsed:      false,
            dragging:       false,
        }
    },
    mounted() {

        this.renderValues();
    },
    watch: {
        values()
        {
            this.renderValues();
        }
    },
    methods: 
    {
        renderValues() 
        {
            let csv = "";

            if(this.values.length > 0)
            {
                csv = this.values.reduce((resultString, value) => 
                {
                    return resultString += '\n' + (value.time / 1000) + ',' + value.v;
                }, "Time,Value");
                this.collapseGraph(false);
            }
            else
            {
                csv = "Time, Value\n0,-2\n1,-2";
                this.collapseGraph(true);
            }
            
            this.graph =  new Dygraph(
                this.$refs.graph, 
                csv, 
                {
                    plotter:                window.smoothPlotter,
                    color:                  '#00E69D',
                    gridLineColor:          '#00E69D10',
                    axisLineColor:          '#00E69D88',
                    height:                 120,
                    showLabelsOnHighlight:  true,
                    drawAxesAtZero:         true,
                    valueRange:             [-1.01, 1.01],
                    interactionModel : {
                        'mousedown' :   this.onMouseDown,
                        'mousemove' :   this.onMouseMove,
                        'mouseup' :     this.onMouseUp,
                        'dblclick' :    ()=>{}
                    },
                    highlightCircleSize:    0,
                    legendFormatter:        ()=>'',
                    axes: {
                        x: { drawAxis: true, ticker: ()=>[], valueFormatter:()=>''},
                        y: { drawAxis: false, drawGrid: false, ticker: ()=>[],valueFormatter:()=>'dfdfd'}
                    },
                });
            
            smoothPlotter.smoothing = 0.33;
        },
        collapseGraph(value)
        {
            if(value != null)
                this.collapsed = value;
            else
                this.collapsed = !this.collapsed;

            if(this.onChangeState != null)
                this.onChangeState(this.collapsed);
        },
        onMouseDown(e, g, context)
        {
            context.initializeMouseDown(event, g, context);
            this.dragging = true;

            this.onMouseMove(e, g, context);
        },
        onMouseUp(e, g, context)
        {
            this.dragging = false;
        },
        onMouseMove(e, g, context)
        {
            if(this.dragging)
            {
                const graphPos = Dygraph.findPos(g.graphDiv);
                const canvasx  = Dygraph.pageX(event) - graphPos.x;
                const lastXPos = g.toDomCoords(g.getValue(g.numRows() - 1, 0), null)[0];

                if(this.onGraphDrag != null)
                    this.onGraphDrag(Math.max(Math.min(canvasx / lastXPos * 100, 100), 0));
            }
        }
    }
}
</script>

<style scoped>

    #wrapper {

        position:       absolute;
        bottom:         44px;
        left:           0;
        right:          0;
        background:     #0c2234AA;
        border-radius:  6px 6px 0 0;
        z-index:        1;
        box-sizing:     border-box;
        margin:         10px 184px 10px 134px;

    }
    #graphWrapper
    {
        transition:     0.4s;
        height:         120px;
        margin:         20px 0 0 0;
        overflow:       hidden;
    }
    #graphWrapper.collapsed 
    {
        height:         0;
        margin:         0;
    }
    #graphdiv {
        width:          100%;
        height:         100%;
    }
    #collapseTag {

        position:       absolute;
        background:     #0c2234AA;
        width:          36px;
        height:         24px;
        top:            -24px;
        right:          8px;
        border-radius:  6px 6px 0 0;
        cursor:         pointer;
        user-select:    none;
    }
    #collpaseText {
        transform:      rotate(90.0deg) scaleY(3);
        color:          rgba(255, 255, 255, 0.5);
        margin-top:     18px;
        font-size:      12px;
        font-weight:    lighter;
    }
    #collpaseText.collapsed {

        transform: rotate(90.0deg) scaleY(3) scaleX(-1);
        margin-top:     -12px;
    }
</style>