<template>
    <i class="material-icons volumeIcon">
        {{ audioIcon }}
        <div id="volumeToggle" @click.stop="onAudioToggle"></div>
        <div id="volumeControl">
            <input id="volumeSlider" type="range" min="0" max="1" step="0.05" value="1.0" @input="onVolumeChange"/>
        </div>
    </i>
</template>

<script>
    export default {

        name: 'VolumeSlider',
        computed: {

            audioIcon() {

                if(this.$store.state.muted)
                    return "volume_off";
                
                if(this.$store.state.volume == 0)
                    return "volume_mute";
                
                if(this.$store.state.volume < 0.75)
                    return "volume_down";
                
                return "volume_up";
            }
        },
        methods: {

            onVolumeChange(event) {

                this.$store.commit('setVolume', event.target.value);
            },
            onAudioToggle(event) {

                this.$store.commit('audioToggle');
            }
        }
    }
</script>

<style>
    .volumeIcon
    {
        position:               relative;
        font-size:              28px;
        color:                  white;
    }
    #volumeToggle 
    {
        position:               absolute;
        top:                    0;
        left:                   0;
        right:                  0;
        bottom:                 0;
    }
    #volumeControl 
    {
        transition:             0.2s;
        position:               absolute;
        width:                  40px;
        height:                 180px;
        background:             rgba(0, 0, 0, 0.75);
        opacity:                0;
        bottom:                 54px;
        left:                   50%;
        margin-left:            -20px;
        border-radius:          4px;
        cursor:                 default; 
        text-align:             center;
    }
    .volumeIcon:hover #volumeControl 
    {
        opacity:                1;
    }
    #volumeControl::after  
    {
        content:                " ";
        position:               absolute;
        top:                    100%;
        left:                   50%;
        margin-left:            -8px;
        border-width:           8px;
        border-style:           solid;
        border-color:           rgba(0, 0, 0, 0.75) transparent transparent transparent;
    }
    #volumeSlider 
    {
        transition:             100ms;
        position:               absolute;
        left:                   16px;
        top:                    10px;
        -webkit-appearance:     none;
        width:                  160px;
        height:                 4px;
        border-radius:          2px;   
        background:             rgba(255, 255, 255, 0.5);
        outline:                none;
        transform-origin:       80px 80px;
        transform:              rotate(-90deg);
        opacity:                0;
    }
    #volumeSlider::-webkit-slider-thumb 
    {
        transition:             100ms;
        -webkit-appearance:     none;
        appearance:             none;
        width:                  14px;
        height:                 14px;
        background:             rgb(0, 255, 157);
        border-radius:          50%;
        box-shadow:             -2px 2px 8px black;
        cursor:                 pointer;
        opacity:                0;
    }
    .volumeIcon:hover #volumeControl #volumeSlider::-webkit-slider-thumb
    {
        transition:             0.2s;
        opacity:                1;
    }
    .volumeIcon:hover #volumeControl #volumeSlider
    {
        transition:             0.2s;
        opacity:                1;
    }
</style>
