<template>
  <ch-application-layout :empty="studios.length < 0">
    <fields-navigation slot="navigation" />
    <template slot="version">ShelfZone Studio v. {{ version }}</template>
    <div slot="header" class="header" v-if="studios.length > 0">
      <ch-filters>
        <div></div>
        <div></div>
        <div><ch-search-bar v-model="search" realTime /></div>
      </ch-filters>
    </div>
    <template slot="main">
      <ch-table
        v-if="studios.length > 0"
        :items="filteredFields"
        @rowClicked="goToField"
        :rowType="rowType"
        class="fieldsTable"
        row-height="narrow"
      >
        <template slot="header">
          <ch-th width="48px" />
          <ch-th name="Field" id="name" sortable default-order="ascending" />
          <ch-th name="Creation Date" />
          <ch-th name="Cells" />
          <ch-th name="Interviews" />
          <ch-th />
        </template>
        <template slot-scope="{ row }">
          <ch-td>
            <ch-indicator :type="getIndicatorStatus(row)" />
          </ch-td>
          <ch-td>{{ row.name }}</ch-td>
          <ch-td>{{
            row.creationDate ? formattedDataFromISO(row.creationDate) : ""
          }}</ch-td>
          <ch-td>{{ row.cellsCount }}</ch-td>
          <ch-td>{{ row.interviewsCount }}</ch-td>
          <ch-td action>
            <ch-button
              v-if="findProject(row)"
              @click="downloadPackage(row)"
              justified
              >Package
              <ch-icon icon="file_download" />
            </ch-button>
          </ch-td>
        </template>
      </ch-table>
      <ch-empty-content
        v-else
        firstMessage="You haven’t created any field yet"
        secondMessage=""
      />
      <outdated-package ref="outdatedPackageDialog" />
    </template>
  </ch-application-layout>
</template>

<script>
import { Status } from "../plugins/studioapi/storage/Studio";
import OutdatedPackage from "../projectsmanagement/dialog/DownloadOutdatedPackage";
import StringUtils from "../utils/StringUtils";
import FieldsNavigation from "./FieldsNavigation";
import StaticConfig from "../config.js";

export default {
  components: { FieldsNavigation, OutdatedPackage },

  data() {
    return {
      search: "",
      studios: this.$storage.studios,
    };
  },
  mounted() {
    document.title = "Fields | Shelfzone Studio";
  },
  computed: {
    version() {
      return StaticConfig.version;
    },
    filteredFields() {
      return this.studios
        .filter((studio) => {
          return studio.name.match(new RegExp(this.search, "i"));
        })
        .sort((s1, s2) => (s1.name > s2.name ? 1 : -1));
    },
  },
  methods: {
    goToField(field) {
      this.$router.push({ name: "field", params: { fieldId: field.id } });
    },
    getIndicatorStatus(studio) {
      switch (studio.getState()) {
        case Status.hasRecordings:
          return "success";
        case Status.noRecordingsAvailable:
          return "default-30";
      }
    },

    findProject(field) {
      return this.$storage.findProjectByFieldId(field.id);
    },
    downloadPackage(field) {
      // FIXME: this.projectStatus is not defined
      // this.findProject(field).downloadPackage();
      let project = this.$storage.findProjectByFieldId(field.id);
      if (project.hasField && new Date(field.creationDate).getTime() > new Date(project.lastPackageDate).getTime())
          this.$refs.outdatedPackageDialog.open(project, false)
      else if (project.getState() === this.projectStatus.updatingPackage)
        this.$refs.outdatedPackageDialog.open(project);
      else project.downloadPackage();
    },
    rowType() {
      return "research";
    },
    formattedDataFromISO(isoDate) {
      return StringUtils.formattedDateTimeFromISO(isoDate);
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.fieldsTable {
  box-sizing: border-box;
  padding: 0 var(--quadrupleMargin) var(--quadrupleMargin)
    var(--quadrupleMargin);
}
</style>
