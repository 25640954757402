import Vue  from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const store = new Vuex.Store({
    
    state: {
        volume:     1,
        muted:      false,
		playing:    false,
		logged:     false,
    },
    mutations: {
        
        play(state) 
        { state.playing = true; },
        pause(state)
        { state.playing = false; },
        setVolume(state, volume) 
        { state.volume = volume; },
        audioToggle(state) 
		{ state.muted = !state.muted; },
		login(state)
		{state.logged = true;},
		logout(state)
		{state.logged = false;}
    }
})

export default store;