<template>
  <ch-application-layout>
    <navigation slot="navigation" />
    <template slot="version">ShelfZone Studio v. {{ version }}</template>
    <template slot="main">
      <router-view />
    </template>
  </ch-application-layout>
</template>

<script>
import Navigation from "./Navigation";
import StaticConfig from "../config.js";
export default {
  name: "Account",
  components: { Navigation },
  computed: {
    version() {
      return StaticConfig.version;
    },
  },
};
</script>

<style scoped>
</style>
