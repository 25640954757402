<template>
	<ch-application-layout v-if="workGroup" header-size="small">
		<div class="header" slot="header">
			<div class="workGroupName" x-spaced-1>
				<ch-icon icon="arrow_back" size="30" @click="returnToWorkGroups"/>
				<ch-field v-if="isAdmin" :validators="[nameValidator]">
					<ch-editable :value="workGroup.name" size="large" @input="renameWorkGroup"/>
				</ch-field>
				<h2 v-else>{{workGroup.name}}</h2>
			</div>
			<div x-spaced-1>
				<ch-button :disabled="currentAccountId === workGroupId" type="primary" size="fit" @click="switchToWorkGroup" justified><span>Switch to workgroup</span><ch-icon icon="people"/></ch-button>
				<ch-button type="error" @click="leaveWorkGroup" justified>Leave workgroup <ch-icon icon="fas fa-door-open"/></ch-button>
			</div>
		</div>
		<div class="main" slot="main" y-spaced-4>
			<div class="description" y-spaced-1>
				<h4>Description</h4>
				<div y-spaced-3>
					<ch-text-area placeholder="Workgroup description" v-model="workGroup.description" disabled/>
					<ch-button :disabled="!isAdmin" type="primary" @click="changeWorkGroupDescription">Change Description</ch-button>
				</div>
			</div>
			<div class="users">
				<div class="usersHeader">
					<h1 primary-text>Users</h1>
					<ch-button :disabled="!isAdmin" justified type="primary" @click="editMembers">Edit users
						<ch-icon icon="add_circle_outline"/>
					</ch-button>
				</div>
				<div class="sectionContent">
					<ch-table :items="filteredMembers" @rowContextMenu="showContextMenu">
						<template slot="header">
							<ch-th name="Full Name"/>
							<ch-th name="Joined on"/>
							<ch-th/>
						</template>
						<template slot-scope="{row}">
							<ch-td>
								<identity-info :id="row.identityId">
									<template v-if="row.isAdmin" slot="append"> (admin)</template>
								</identity-info>
							</ch-td>
							<ch-td>{{ row.joinedAt | formatDateTime }}</ch-td>
							<ch-td action>
								<ch-context-menu v-if="row.identityId !== currentUserId && isAdmin" :voices="buildMemberContextMenu(row)">
									<ch-button type="flat" icon>
										<ch-icon icon="more_horiz"/>
									</ch-button>
								</ch-context-menu>
							</ch-td>
						</template>
					</ch-table>
				</div>
			</div>
			<ch-context-menu ref="contextMenu"/>
			<ch-modal ref="modal"/>
			<edit-members-dialog ref="editMembersDialog"/>
			<change-work-group-description-dialog ref="changeWorkGroupDescriptionDialog"/>
		</div>
	</ch-application-layout>
</template>

<script>
import Vue from 'vue';
import {nameValidator} from '@/user/workgroups/services/Validators';
import IdentityInfo from '@/user/workgroups/components/IdentityInfo';
import StringUtils from '@/utils/StringUtils';
import EditMembersDialog from '@/user/workgroups/dialog/EditMembersWorkGroupDialog';
import ChangeWorkGroupDescriptionDialog from '@/user/workgroups/dialog/ChangeWorkGroupDescriptionDialog';

export default {
	name: 'WorkGroup',
	components: {ChangeWorkGroupDescriptionDialog, EditMembersDialog, IdentityInfo},
	props: {
		workGroupId: String
	},
	data() {
		return {
			workGroup: null
		};
	},
	computed: {
		currentUserId() {
			return this.$storage.user.id;
		},
		currentAccountId() {
			return this.currentUserId;
		},
		isAdmin() {
			return this.workGroup.members.find(m => m.identityId === this.currentUserId).isAdmin;
		},
		isLastAdmin() {
			return !this.workGroup.members.filter(m => m.isAdmin).some(m => m.identityId !== this.currentUserId);
		},
		filteredMembers() {
			return this.workGroup.members.slice()
				.sort((m1, m2) => m1.identityId === this.currentUserId ? -1 : 1)
		}
	},
	beforeRouteEnter(to, from, next) {
		Vue.$iam.findWorkGroup(to.params.workGroupId)
			.then(response => next(vm => {
				vm.workGroup = response.workGroup;
			})).catch(err => {
			  next(err);
    });
	},
	beforeRouteUpdate(to, from, next) {
		this.$iam.findWorkGroup(to.params.workGroupId)
			.then(response => {
				this.workGroup = response.workGroup;
				next();
			}).catch(err => {
			  next(err);
    });
	},
	filters: {
		formatDateTime(date) {
			return StringUtils.formattedDateTimeFromISO(date);
		}
	},
	methods: {
		nameValidator,
		showContextMenu(event, row) {
			if(row.identityId !== this.currentUserId)
				this.$refs.contextMenu.showMenu(event, this.buildMemberContextMenu(row));
		},
		buildMemberContextMenu(member) {
			return [member.isAdmin ? {
				text: 'Flag as user',
				icon: 'fas fa-user',
				action: () => this.revokeMemberAdminRole(member)
			} : {
				text: 'Make admin',
				icon: 'fas fa-user-cog',
				action: () => this.grantMemberAdminRole(member)
			}, {
				text: 'Remove user',
				icon: 'delete',
				action: () => this.removeMember(member)
			}];
		},
		returnToWorkGroups() {
			this.$router.push({name: 'workGroups'});
		},
		renameWorkGroup(name) {
			this.$iam.changeWorkGroupInfo(this.workGroupId, name)
		},
		changeWorkGroupDescription() {
			this.$refs.changeWorkGroupDescriptionDialog.open(this.workGroup);
		},
		editMembers() {
			this.$refs.editMembersDialog.open(this.workGroup);
		},
		switchToWorkGroup() {
			this.$router.push({name: 'accountSwitch', params: {workGroupId: this.workGroupId}});
		},
		leaveWorkGroup() {
			if (this.isLastAdmin)
				this.$refs.modal
					.open(
						`Leave ${this.workGroup.name}`,
						`You can't leave the workgroup because you are the only admin.<br/>Please make another user admin for this workgroup.`,
						'error',
						'Ok',
						null, 'medium',
						false
					);
			else
				this.$refs.modal
					.open(
						`Leave ${this.workGroup.name}`,
						`Do you want to leave ${this.workGroup.name}? Once you have left, you will no longer be able to have access to its content`,
						'error',
						'Leave',
						'Cancel'
					)
					.then(() => {
						this.$loading.await(this.$iam.removeMembersFromWorkGroup(this.workGroup.id, [this.currentUserId]))
							.then(() => this.$router.push({name: 'workGroups'}));
					});
		},
		grantMemberAdminRole(member) {
			this.$loading.await(
				this.$iam.grantMembersAdminRole(this.workGroupId, [member.identityId])
			);
		},
		revokeMemberAdminRole(member) {
			this.$loading.await(
				this.$iam.revokeMembersAdminRole(this.workGroupId, [member.identityId])
			);
		},
		removeMember(member) {
			this.$iam.findIdentity(member.identityId)
				.then(identity => identity.identity.identityInfo.fullName || identity.identity.identityInfo.name)
				.then(username => {
					this.$refs.modal.open(
						`Remove ${username}`,
						[
							`Remove ${username} from ${this.workGroup.name}?`,
							`Unsaved edits won't be saved and the user won't be able to switch to the workgroup anymore.`
						],
						'error',
						'Remove'
					).then(() =>
						this.$loading.await(this.$iam.removeMembersFromWorkGroup(this.workGroupId, [member.identityId]))
					)
				});
		}
	}
};
</script>

<style scoped>

.header {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}

.workGroupName {
	display: flex;
	align-items: center;
	flex: 1 1 auto;
}

.main {
	padding: var(--quadrupleMargin);
	overflow-y: auto;
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	height: 100%;
	box-sizing: border-box;
}

.description {
	max-width: 400px;
}

.users {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	overflow-y: auto;
}

.usersHeader {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid var(--elevation-04);
	padding-bottom: var(--doubleMargin);
}

.sectionContent {
	flex: 1 1 auto;
	overflow-y: auto;
}

</style>
