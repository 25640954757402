<template>
    <ch-tree header="Projects" :nodes="nodes" v-model="currentPath"></ch-tree>
</template>

<script>

    export default {
        data() {
            return {
                projects: this.$storage.projects
            }
        },
        computed: {
            currentPath: {
                get() {
                    return this.nodes.filter(n => n.id === this.$route.params.projectId);
                },
                set(path) {
                    if (path.length === 0)
                        this.$router.push({name: 'projects'});
                    else
                        this.$router.push({name: 'projectDetails', params: {projectId: path[0].id}})
                }
            },
            nodes() {
                return this.projects.sort((p1, p2) => p1.name.toLowerCase() > p2.name.toLowerCase() ? 1 : -1)
                    .map(p => ({
                        id: p.id,
                        text: p.name
                    }))
            }
        }
    };
</script>

<style scoped>
</style>
