<template>
    <request-reset-password v-if="!resetToken"/>
    <complete-password-reset v-else :resetToken="resetToken"/>
</template>

<script>

    import RequestResetPassword  from './RequestResetPassword';
    import CompletePasswordReset from './CompletePasswordReset';

    export default {
        name: 'ResetPassword',
        components: {CompletePasswordReset, RequestResetPassword},
        props: {
            resetToken: String
        },
        mounted() {
            document.title = 'Reset Password | ShelfZone Studio';
        }
    };
</script>
