<template>
    <ch-button :type="'secondary'" :size="size" justified  class="tagChip" :style="style">
        <ch-indicator v-if="!colored" :color="tag.color"/>
        <span class="tagName">
            {{tag.name}}
        </span>
        <ch-icon v-if="removable" icon="close" class="tagChipIcon" @click="onRemove(tag.id)"/>
    </ch-button>
</template>

<script>
export default {
    props: {
        tag: Object,
        colored: Boolean,
        removable: Boolean,
    },
    methods: {
        onRemove(id) {
            this.$emit('remove', id);
        }
    },
    computed: {
        size() {
            return `narrow ${ this.colored ? 'fit': ''}`
        },
        style() {
            return this.colored && {
                '--secondary-border':   `${this.tag.color}`,
                '--secondary-hover':    `${this.tag.color}`,
                '--secondary':          `${this.tag.color}`,
                'color':                'black'
            }
        }
    }
}
</script>

<style scoped>
    .tagChip {
        display: flex;
        width: auto !important;
        min-width: 50px;
    }
    
    .tagChipIcon {
        margin-left:  var(--halfMargin) !important
    }
    .tagName {
        white-space: nowrap;
        text-overflow: ellipsis;
    }
</style>