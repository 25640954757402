<template>
    <ch-button @click="deleteSelection" type="error" size="narrow fill" justified>{{text}}<ch-icon icon="cancel"/></ch-button>
</template>

<script>
    import LayoutController from '../services/LayoutController';

    export default {
        name: 'DeleteSelectionButton',
        props: {
            text: String
        },
        methods: {
            deleteSelection() {
                this.$unityManager.layoutAPI.DeleteSelection();
            }
        }
    };
</script>

<style scoped>

</style>