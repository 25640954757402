<template>
    <div class="createContainer">
        <ch-button v-if="!isCreating" type="secondary" size="fill" justified @click.stop="startCreating()" x-spaced-1>
            <span>Create new tag</span> 
            <ch-icon icon="add_circle"/>
        </ch-button>
        <tag-edit v-else ref="createTagInput" class="createTag" 
                  cancelOnEmptyEnter
                  placeholder="Enter tag name"
                  isEditing
                  :tag="{ name: '' }" 
                  :validators="validators"
                  :tagList="list"
                  @startEdit    ="isCreating = true"
                  @endEdit      ="isCreating = false"
                  @cancelledEdit="isCreating = false"
                  @doneEdit     ="onEditDone($event)" />
    </div>
</template>

<script>
import TagEdit  from './TagEdit';
export default {
    name: "CreateNewTag",
    components: { TagEdit },
    props: {
        validators: {
            type: Array,
            default: () => []
        },
        list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            isCreating: false,
        }
    },
    methods: {
        onEditDone({name}) {
            this.$emit('newTagName', name);
            this.isCreating = false;
        },
        startCreating() {
            this.isCreating = true;
            this.$nextTick( () => {
                this.$refs.createTagInput.$refs.tagEdit.focus();
            } );
        }
    }
}
</script>

<style scoped>
    .createContainer {
        display: flex;
        flex: 1 1 auto;
    }
    .createTag {
        --background-color: var(--elevation-06);
        --color: var(--on-elevation-06); 
        background-color: var(--background-color);
        color: var(--color);
        padding: 0 var(--singleMargin);
        border-radius: var(--halfMargin);
        box-sizing: border-box;
    }
</style>