<template>
    <div class="groupImages" y-spaced-2>
        <input ref="fileInput" type="file" name="name" v-show="false" accept=".jpg,.png"  @change="addImage"/>
        <ch-button v-if="images.length === 0" @click="openAddImageDialog" size="fill" justified>
            Add group image
            <ch-icon icon="image"/>
        </ch-button>
        <ch-button v-else @click="openAddImageDialog" size="fill" justified>Change image
            <ch-icon icon="image"/>
        </ch-button>
        <ch-button v-if="images.length" @click="removeImage" size="fill" type="error"
                    justified>Delete image
            <ch-icon icon="delete_forever"/>
        </ch-button>
        <ch-image v-if="images.length" ref="groupImage" class="groupImage"
                    :src="images[0]"/>
    </div>
</template>

<script>
export default {
    name: "GroupImageGallery",
    props: {
        images: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        openAddImageDialog() {
            this.$refs.fileInput.value = "";
            this.$refs.fileInput.click();
        },
        addImage(e)   {
            const imageFiles = e.target.files || e.dataTransfer.files;
            if(!imageFiles.length) return;
            this.$emit('addImage', imageFiles)
        },
        removeImage() { this.$emit('removeImage')},
    }
}
</script>

<style scoped>
</style>