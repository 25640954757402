<template>
    <ch-access-layout>
        <div class="logo" slot="logo"></div>
        <div y-spaced-2>
            <p>An email has been sent to {{emailAddress}} please check your inbox and follow the instructions
                provided.</p>
        </div>
        <template slot="footer">
            <ch-button @click="returnToLogin">Go to login</ch-button>
        </template>
    </ch-access-layout>
</template>

<script>
    export default {
        name: 'PasswordResetRequested',
        props: {
            emailAddress: String
        },
        methods: {
            returnToLogin() {
                this.$router.push({name: 'login'});
            }
        }
    };
</script>

<style scoped>

    .logo {
        background-image: url("../../../assets/logo.png");
        background-size: 210px;
    }

</style>
